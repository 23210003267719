import { createAction, createSlice } from '@reduxjs/toolkit';

export const parseError = (
  error:
    | {
        response?: { data: { non_field_errors?: string | readonly string[] } };
      }
    | string,
): string => {
  if (typeof error === 'string') return error;

  if (error.response?.data.non_field_errors != null) {
    const nonFieldErrors = error.response.data.non_field_errors;
    if (typeof nonFieldErrors === 'string') return nonFieldErrors;
    if (nonFieldErrors[0] != null) return nonFieldErrors[0]!;
  }

  return 'Something bad happened, try again...';
};

export const errorAnnouncement = createAction(
  'error/errorAnnouncement',
  (payload: string) => {
    return { payload: parseError(payload) };
  },
);

const slice = createSlice({
  name: 'error',
  initialState: '',
  reducers: {
    errorDismiss: (_state) => '',
  },
  extraReducers: (builder) =>
    builder.addCase(
      errorAnnouncement,
      (_state, { payload }: { readonly payload: string }) => payload,
    ),
});

export const errorReducer = slice.reducer;

export const { errorDismiss } = slice.actions;

type State = { error: ReturnType<typeof errorReducer> };

export const selectError = (state: State) => state.error;
