import {
  paradigmLightBlue,
  lima,
  razzmatazz,
  cloudBurst,
  pickledBluewood,
  firefly,
  paradigmBlue,
} from '#/colors';
import { ButtonProps } from './Button';

export const BUTTON_THEMES: Record<
  Required<ButtonProps>['theme'],
  { color: string; bgColor: string; primaryColor: string }
> = {
  default: {
    bgColor: paradigmLightBlue,
    primaryColor: paradigmLightBlue,
    color: 'white',
  },
  'primary-green': {
    bgColor: lima,
    primaryColor: lima,
    color: 'white',
  },
  'primary-red': {
    bgColor: razzmatazz,
    primaryColor: razzmatazz,
    color: 'white',
  },
  'primary-dark': {
    bgColor: firefly,
    primaryColor: 'white',
    color: 'white',
  },
  'secondary-light-blue': {
    bgColor: paradigmLightBlue,
    primaryColor: paradigmBlue,
    color: paradigmBlue,
  },
  'secondary-dark-blue': {
    bgColor: firefly,
    primaryColor: paradigmLightBlue,
    color: paradigmLightBlue,
  },
  'secondary-green': {
    color: lima,
    primaryColor: lima,
    bgColor: cloudBurst,
  },
  'secondary-red': {
    color: razzmatazz,
    primaryColor: razzmatazz,
    bgColor: cloudBurst,
  },
  'quick-action': {
    color: paradigmLightBlue,
    primaryColor: paradigmLightBlue,
    bgColor: pickledBluewood,
  },
};

export const BUTTON_SIZES: Record<
  Required<ButtonProps>['size'],
  {
    height: string;
    fontSize: string;
    padding: string;
    pillBorderRadius: string;
    squaredBorderRadius: string;
    loadingIconSize: number;
  }
> = {
  'extra-small': {
    height: '22px',
    fontSize: '0.8125rem',
    padding: '0 0.75rem',
    pillBorderRadius: '11px',
    squaredBorderRadius: '3px',
    loadingIconSize: 12,
  },
  small: {
    height: '24px',
    fontSize: '0.8125rem',
    padding: '0 0.75rem',
    pillBorderRadius: '12px',
    squaredBorderRadius: '3px',
    loadingIconSize: 15,
  },
  medium: {
    height: '28px',
    fontSize: '0.8125rem',
    padding: '0 1rem',
    pillBorderRadius: '15px',
    squaredBorderRadius: '4px',
    loadingIconSize: 18,
  },
  large: {
    height: '30px',
    fontSize: '0.875rem',
    padding: '0 1.25rem',
    pillBorderRadius: '15px',
    squaredBorderRadius: '5px',
    loadingIconSize: 20,
  },
  'extra-large': {
    height: '36px',
    fontSize: '1rem',
    padding: '0 1.375rem',
    pillBorderRadius: '18px',
    squaredBorderRadius: '6px',
    loadingIconSize: 22,
  },
};
