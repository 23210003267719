import { PropsWithChildren, useMemo, useState, useCallback } from 'react';
import { Maybe } from '@metamask/providers/dist/utils';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { AccountResponse, listAccounts } from '@paradigm/api/src/accounts';
import { useEffectOnce } from '@paradigm/utils/src/hooks';
import { Source, VENUES } from '../entities/products/venue';

type VenueAccountsMap = Readonly<{
  [venue: string]: ReadonlyArray<AccountResponse>;
}>;
interface State {
  readonly accounts: VenueAccountsMap;
  readonly isLoading: boolean;
  readonly fetchAccounts: () => void;
  readonly updateVenueDefault: (
    venue: Source,
    accountId: Maybe<number>,
  ) => void;
}

interface View {
  readonly isLoading: boolean;
  readonly accounts: ReadonlyArray<AccountResponse>;
  readonly updateVenueDefault: State['updateVenueDefault'];
  readonly selectByVenue: (venue: Source) => ReadonlyArray<AccountResponse>;
  readonly selectDefaultByVenue: (venue: Source) => AccountResponse | null;
  readonly selectByAccountName: (
    venue: Source,
    name: string,
  ) => AccountResponse | null;
  readonly fetchAccounts: () => void;
}

const [useViewCtx, ViewCtxProvider] = createSafeContext<View>('AccountsView');

export { useViewCtx as useAccounts };

export default function AccountsProvider({ children }: PropsWithChildren) {
  const state = useAccountsFetcher();
  const view = useMemo(() => createView(state), [state]);

  return <ViewCtxProvider value={view}>{children}</ViewCtxProvider>;
}

function useAccountsFetcher() {
  const [accounts, setAccounts] = useState<VenueAccountsMap>({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchAccounts = useCallback((controller?: AbortController) => {
    const fetchAll = async () => {
      const promises = VENUES.map(async (venue) =>
        listAccounts({ venue: venue.code, signal: controller?.signal }),
      );
      return Promise.all(promises);
    };

    setIsLoading(true);

    fetchAll()
      .then((responses) =>
        setAccounts(
          Object.fromEntries(
            responses.map((response, index) => {
              if (response.ok) {
                return [VENUES[index]!.code, response.data];
              }
              return [];
            }),
          ) as VenueAccountsMap,
        ),
      )
      .catch((_err) => [])
      .finally(() => setIsLoading(false));
  }, []);

  const updateVenueDefault = useCallback(
    (venue: Source, accountId: Maybe<number>) => {
      const venueAccounts = accounts[venue];
      if (venueAccounts == null) return;

      setAccounts({
        ...accounts,
        [venue]: venueAccounts.map((account) => ({
          ...account,
          is_default: account.id === accountId,
        })),
      });
    },
    [accounts],
  );

  useEffectOnce(() => {
    const controller = new AbortController();
    fetchAccounts(controller);
    return () => controller.abort();
  });

  return { accounts, isLoading, fetchAccounts, updateVenueDefault };
}

function createView(state: State): View {
  return {
    isLoading: state.isLoading,
    fetchAccounts: state.fetchAccounts,
    updateVenueDefault: state.updateVenueDefault,
    accounts: Object.values(state.accounts).flat(),
    selectByVenue: (venue) => state.accounts[venue] ?? [],
    selectByAccountName: (venue, name) =>
      state.accounts[venue]?.find((account) => account.name === name) ?? null,
    selectDefaultByVenue: (venue) =>
      state.accounts[venue]?.find((account) => account.is_default) ?? null,
  };
}
