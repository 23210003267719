import { Modal as BaseModal, ModalBody } from 'reactstrap';
import styled, { css } from 'styled-components';
import { MOBILE_BREAKPOINT } from '#/units';
import { darkCornflowerBlue } from '#/colors';
import { ModalProps } from './Modal';

export const ModalContainer = styled(BaseModal).withConfig({
  shouldForwardProp: (prop) =>
    !['width', 'isFullScreenMobile'].includes(prop.toString()),
})<ModalProps>`
  max-width: none;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    place-items: center;
  }

  && {
    margin: 50px auto;
    width: ${({ width }) =>
      typeof width === 'number' ? `${width}px` : width} !important;

    ${({ isFullScreenMobile }) =>
      isFullScreenMobile === true &&
      css`
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          margin: 0;
        }
      `}
  }

  .modal-content {
    background-color: ${darkCornflowerBlue};
    color: #fff;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    overflow: auto;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.5);
    overflow: visible;
    border-radius: 10px;

    ${({ isFullScreenMobile }) =>
      isFullScreenMobile === true &&
      css`
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100vw;
          min-height: 100vh;
          border-radius: 0;
          box-sizing: content-box;
          border: 0;
        }
      `}
  }
`;

export const Body = styled(ModalBody)`
  padding: 0;
`;

export const Footer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 20px;
`;
