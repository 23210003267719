import { logException } from '@paradigm/logging/src/logging';

const { localStorage } = window;

/**
 * Create instance of local storage that treats QuotaExceededError,
 * removing keys that are not protected.
 *
 * If after cleaning the store, the issue persists, we throw the QuotaExceededErrors
 */
const safeLocalStorage = {
  ...localStorage,
  clear: localStorage.clear.bind(localStorage),
  getItem: localStorage.getItem.bind(localStorage),
  removeItem: localStorage.removeItem.bind(localStorage),
  key: localStorage.key.bind(localStorage),
  setItem(key: string, value: string, throwQuotaExceeded = false) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.name === 'QuotaExceededError'
      ) {
        if (throwQuotaExceeded) {
          logException(
            {
              level: 'fatal',
              message: `Cleaning up local storage failed to prevent quota exceeded. ${error.message}`,
              data: {
                localStorageKeys: getLocalStorageKeysSize(),
              },
            },
            error,
          );
          throw error;
        }
        clearUnprotectedKeys();
        this.setItem(key, value, true);
      }
    }
  },
} as const;

/**
 * Substring of keys we want to keep
 */
const PROTECTED_KEYS_SUBSTR = [
  'token',
  'ajs', // Segment analytics
  'filter', // Keep all stored filters
  'fs:', // Keep every local storage key related to FS
  'last_route',
  'split-screen-flex/unified-rfq-main',
  'um:drfq:anonymous',
  'pwa-prompt-permanent-dismiss',
  'paradex-points-upsell-dismiss',
  '__anon_id',
  '__user_id',
  '__user_traits',
];

function clearUnprotectedKeys() {
  Object.entries(localStorage).forEach(([key, _value]) => {
    if (isProtectedKey(key)) return;
    localStorage.removeItem(key);
  });
}

function isProtectedKey(key: string) {
  return PROTECTED_KEYS_SUBSTR.some((protectedKey) =>
    key.includes(protectedKey),
  );
}

function getLocalStorageKeysSize() {
  const keysSize: Record<string, number> = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key == null) continue;
    keysSize[key] = localStorage.getItem(key)?.length ?? 0;
  }

  return keysSize;
}

export default safeLocalStorage;
