import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { NavLink as NavLinkRouter } from 'react-router-dom';
import isElectron from 'is-electron';

import { useUnifiedMarketsNotifications } from '@paradigm/features/src/unified-rfqs/ui/notifications/notifications-context';
import {
  blackTangaroa,
  blueBubble,
  blueMana,
  darkerBlackPearl,
  prussianBlue,
  razzmatazz,
} from '@paradigm/design-system/src/colors';

import DovIcon from '@paradigm/design-system/src/assets/DovIcon';
import FsIcon from '@paradigm/design-system/src/assets/FsIcon';
import HelpIcon from '@paradigm/design-system/src/assets/HelpIcon';
import SettingsIcon from '@paradigm/design-system/src/assets/SettingsIcon';
import UnifiedMarketsIcon from '@paradigm/design-system/src/assets/UnifiedMarketsIcon';

import SettingsContainer from '#/components/settings-modal/SettingsContainer';
import {
  VRFQS_ROUTE,
  FS_ROUTE,
  UNIFIED_RFQS_ROUTE,
  LEADERBOARD_ROUTE,
  PARADEX_ROUTE,
} from '#/routes';
import { MOBILE_BREAKPOINT } from '#/containers/layout/constants';
import { useSidebarState } from '#/contexts/sidebar';
import useIsMobile from '@paradigm/design-system/src/hooks/useIsMobile';
import StatsIcon from '@paradigm/design-system/src/assets/StatsIcon';
import PrimeLogo from '@paradigm/design-system/src/assets/PrimeLogo';
import AnimatedStars from '@paradigm/design-system/src/assets/AnimatedStarsIcon';
import useIsViewerPrime from '@paradigm/features/src/prime/hooks/useIsViewerPrime';
import NotificationBadge from '@paradigm/design-system/src/components/Badge';
import ParadexIcon from '@paradigm/design-system/src/assets/ParadexIcon';
import { TrackingCtxProvider } from '@paradigm/features/src/analytics';

export default function SidebarV2() {
  const { isVisible, toggle } = useSidebarState();
  const isMobile = useIsMobile();
  const isViewerPrime = useIsViewerPrime();
  const { hasNewNotifications: hasUnifiedMarketsNotification } =
    useUnifiedMarketsNotifications();

  const onBackdropClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };

  if (!isVisible) return null;

  return (
    <>
      <NavContainer isViewerPrime={isViewerPrime}>
        <NavLink
          to={UNIFIED_RFQS_ROUTE}
          activeClassName="active"
          aria-label="UNIFIED-RFQ"
          title="UNIFIED-RFQ"
        >
          <IconContainer>
            {hasUnifiedMarketsNotification && <Badge />}
            {!isMobile && <UnifiedMarketsIcon width={20} height={20} />}
            {isMobile && <UnifiedMarketsIcon width={26} height={26} />}
          </IconContainer>
          <Label>Unified Markets</Label>
        </NavLink>

        {!isMobile && (
          <NavLink
            to={FS_ROUTE}
            activeClassName="active"
            aria-label="Delta-1 Spreads"
            title="Delta-1 Spreads"
          >
            <IconContainer>
              <FsIcon width={24} height={24} />
            </IconContainer>
            <Label>Delta-1 Spreads</Label>
          </NavLink>
        )}

        <NavLink
          to={PARADEX_ROUTE}
          activeClassName="active"
          aria-label="PARADEX"
          title="PARADEX"
        >
          <NotificationBadge variant="hot" top={-12} right={6}>
            HOT🔥
          </NotificationBadge>
          <IconContainer>
            {!isMobile && <ParadexIcon width={20} height={20} />}
            {isMobile && <ParadexIcon width={26} height={26} />}
          </IconContainer>
          <Label>Paradex</Label>
        </NavLink>

        {!isMobile && (
          <NavLink
            to={VRFQS_ROUTE}
            activeClassName="active"
            aria-label="Defi Auctions"
            title="Defi Auctions"
          >
            <IconContainer>
              <DovIcon width={22} height={22} />
            </IconContainer>
            <Label>Defi Auctions</Label>
          </NavLink>
        )}

        <Divider />
        <NavLink
          to={LEADERBOARD_ROUTE}
          activeClassName="active"
          aria-label="Stats"
          title="Stats"
        >
          <IconContainer>
            {!isMobile && <StyledStatsIcon width={20} height={20} />}
            {isMobile && <StyledStatsIcon width={28} height={28} />}
          </IconContainer>
          <Label>
            Leader
            <br />
            Board
          </Label>
        </NavLink>
        <BottomContainer>
          <TrackingCtxProvider value="settings modal">
            <SettingsContainer>
              <Item>
                {!isMobile && <SettingsIcon width={22} height={22} />}
                {isMobile && <SettingsIcon width={30} height={30} />}
                <Label>Settings</Label>
              </Item>
            </SettingsContainer>
          </TrackingCtxProvider>
          <Link
            title="Help"
            href="https://t.me/+KItTM8HI4O1kMWEx"
            target="_blank"
            rel="noopener"
          >
            {!isMobile && <HelpIcon width={28} height={28} />}
            {isMobile && <HelpIcon width={30} height={30} />}

            <Label>Help</Label>
          </Link>
          {isViewerPrime && (
            <PrimeContainer>
              <PrimeLogoContainer>
                {!isMobile && <PrimeLogo width={40} />}
                {isMobile && <PrimeLogo width={46} />}
              </PrimeLogoContainer>
              <AnimatedStarsContainer>
                {!isMobile && <AnimatedStars width={60} height={60} />}
                {isMobile && <AnimatedStars width={100} height={100} />}
              </AnimatedStarsContainer>
            </PrimeContainer>
          )}
        </BottomContainer>
      </NavContainer>
      {isMobile && <Backdrop onClick={onBackdropClick} />}
    </>
  );
}
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  width: 100%;
  height: 100%;
`;
const PrimeContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 80px;
  }
`;

const PrimeLogoContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const AnimatedStarsContainer = styled.div`
  position: absolute;
  z-index: 2;
`;

const Label = styled.div`
  opacity: 0.7;
  margin-top: 0.125rem;
  text-align: center;
  color: ${blueMana};
  font-size: 0.6875rem;
  line-height: 0.75rem;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 1rem;
    line-height: 1.125rem;
    margin-top: 0.5rem;
  }
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid transparent;
  border-radius: 7px;
  background-color: ${prussianBlue};
  position: relative;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 44px;
    height: 44px;
  }
`;

const itemCSS = css`
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  &.active {
    ${Label} {
      opacity: 1;
      font-weight: bold;
    }

    ${IconContainer} {
      border: 2px solid ${blueBubble};
    }
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 1.75rem;
    width: 100%;
  }
`;

const NavLink = styled(NavLinkRouter)`
  ${itemCSS}
  position: relative;
`;

const Link = styled.a`
  ${itemCSS}
  background-color: transparent;
`;

const Item = styled.div`
  ${itemCSS}
  background-color: transparent;
`;

const Divider = styled.div`
  height: 2px;
  width: 34px;
  margin-bottom: 16px;
  background: linear-gradient(
    270deg,
    rgba(238, 238, 238, 0) 0%,
    rgba(216, 216, 216, 0.2) 53.67%,
    rgba(216, 216, 216, 0) 100%
  );

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 46px;
    margin-bottom: 1.75rem;
  }
`;

const NavContainer = styled.aside<{ isViewerPrime?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${darkerBlackPearl};

  ${({ isViewerPrime = false }) =>
    isViewerPrime &&
    css`
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        ${darkerBlackPearl} 80%,
        #000000
      );
    `}
  padding: 20px 0 0;
  width: 60px;
  height: 100%;
  min-height: 100%;
  flex: 0 0 auto;
  z-index: 1; /* on top of main content shadows */
  border-right: 1px solid #294160;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: ${isElectron() ? 44 : 12}px;
    height: 100vh; // necessary specially for iPhone mobile view
    position: absolute;
    z-index: 21;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.5);
    width: 100px;
    background-color: ${blackTangaroa};
    padding-top: 1.75rem;
    border-right: 0px solid;

    ${({ isViewerPrime = false }) =>
      isViewerPrime &&
      css`
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0) 80%,
          ${blackTangaroa} 80%,
          #000000
        );
      `}
  }
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index: 20;
`;

const Badge = styled.span.attrs({
  'aria-label': 'New notifications',
})`
  background-color: ${razzmatazz};
  border-radius: 50%;
  display: block;
  height: 8px;
  width: 8px;
  position: absolute;
  top: -4px;
  right: -4px;
`;

const StyledStatsIcon = styled(StatsIcon)`
  overflow: visible;
`;
