// @ts-nocheck
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserNotificationsChange as updateApi } from '@paradigm/api/auth';
import { updateUserNotificationAction } from '@paradigm/store/auth';
import Toggle from '@paradigm/design-system/src/components/Toggle';

import useUpdateEffect from '#/hooks/update-effect';
import { AuthUser } from '@paradigm/store/src/auth/types';
import { exceptionLogger } from '@paradigm/logging/src/logging';
import { useTrackAction } from '@paradigm/features/src/analytics';
import usePersistedMatchingDetection from '@paradigm/features/src/preferences/usePersistedMatchingDetection';

interface Props {
  updateNotifications: (payload?: unknown) => void;
  initialValues: {
    visual_notifications_enabled: boolean;
    audio_notifications_enabled: boolean;
    email_notifications_enabled: boolean;
    trading_notifications_enabled: boolean;
  };
}

const UserNotificationsChange = (props: Props) => {
  const [values, setValues] = useState(props.initialValues);
  const { isMatchingDetectionEnabled, toggleMatchingDetection } =
    usePersistedMatchingDetection();
  const track = useTrackAction();

  useUpdateEffect(() => {
    updateApi(values)
      .then(({ data }) => {
        props.updateNotifications(data);
      })
      .catch(exceptionLogger('failed to update notifications change'));
  }, [values]);

  return (
    <div className="settings-modal__chat-group">
      <Toggle
        label="Desktop"
        ariaLabel="toggle desktop notifications"
        labelPlacement="left"
        className="my-2"
        size="large"
        fluid
        checked={values.visual_notifications_enabled}
        name="visual_notifications_enabled"
        onChange={(e) => {
          e.persist();
          setValues((v) => ({
            ...v,
            visual_notifications_enabled: e.target.checked,
          }));
          track('toggle desktop notification', { isEnabled: e.target.checked });
        }}
      />
      <Toggle
        label="Matching Detection"
        className="my-2"
        ariaLabel="toggle matching detection notifications"
        labelPlacement="left"
        size="large"
        fluid
        checked={isMatchingDetectionEnabled}
        name="matching_detection_notifications_enabled"
        onChange={(e) => {
          e.persist();
          toggleMatchingDetection();
          track('toggle matching detection notification', {
            isEnabled: e.target.checked,
          });
        }}
      />
      <Toggle
        type="checkbox"
        className="my-2"
        size="large"
        labelPlacement="left"
        fluid
        ariaLabel="toggle audio notifications"
        checked={values.audio_notifications_enabled}
        name="audio_notifications_enabled"
        onChange={(e) => {
          e.persist();
          setValues((v) => ({
            ...v,
            audio_notifications_enabled: e.target.checked,
          }));
          track('toggle notification sounds', { isEnabled: e.target.checked });
        }}
        label="Sounds"
      />
    </div>
  );
};

/*
  populating form redux state
*/
const mapStateToProps = (state: { auth: { user: AuthUser } }) => ({
  updateNotifications: PropTypes.func,
  initialValues: {
    visual_notifications_enabled: Boolean(
      state.auth.user.visual_notifications_enabled,
    ),
    audio_notifications_enabled: Boolean(
      state.auth.user.audio_notifications_enabled,
    ),
    email_notifications_enabled: Boolean(
      state.auth.user.email_notifications_enabled,
    ),
    trading_notifications_enabled: Boolean(
      state.auth.user.trading_notifications_enabled,
    ),
  },
});

const mapActionsToProps = {
  updateNotifications: updateUserNotificationAction,
};
const ConnectedUserNotificationsChange = connect(
  mapStateToProps,
  mapActionsToProps,
)(UserNotificationsChange);
export default ConnectedUserNotificationsChange;
