export const SHOW_AGREEMENT_OVERLAY = 'SHOW_AGREEMENT_OVERLAY';
export const ACCEPTED_AGREEMENT = 'ACCEPTED_AGREEMENT';

export const TFA_CODE_REQUIRED = 'TFA_CODE_REQUIRED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CHANGE_NOTIFICATIONS_SUCCESS = 'CHANGE_NOTIFICATIONS_SUCCESS';
export const CLEAR_AUTH_MESSAGES = 'CLEAR_AUTH_MESSAGES';

export const TOGGLE_IP_WARNING = 'TOGGLE_IP_WARNING';

export interface AuthUser {
  readonly id: number;
  readonly email: string;
  readonly color: string;
  readonly first_name: string;
  readonly firm: string;
  readonly last_name: string;
  readonly has_totp_enabled: boolean;
  readonly trading_desk?: TradingDesk | null;
  readonly visual_mobile_notifications_enabled: boolean;
  readonly audio_mobile_notifications_enabled: boolean;
  readonly active_products: readonly string[];
  readonly has_accepted_us_agreement: boolean;
  readonly has_accepted_non_us_agreement: boolean;
  readonly paradex_token: string;
  readonly preferences: PreferencesResp | undefined;
  readonly [key: string]: unknown;
}

export interface TradingDesk {
  id: number;
  ticker: string;
}

export interface PreferencesResp {
  readonly data:
    | {
        readonly state: unknown; // We intentionally have an unknown type here to handle migration.
        readonly version: number;
      }
    | Record<string, never>;
}
