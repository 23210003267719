import { ComponentPropsWithoutRef } from 'react';

const CloseIcon = ({
  height = 12,
  width = 12,
  ...rest
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg viewBox="0 0 14.142 14.142" height={height} width={width} {...rest}>
    <path
      d="m 12.728,0 1.414,1.414 -5.657,5.657 5.657,5.657 -1.414,1.414 L 7.071,8.485 1.414,14.142 0,12.728 5.656,7.071 0,1.414 1.414,0 7.071,5.656 Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default CloseIcon;
