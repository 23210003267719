import * as Retry from 'retry';

export interface ExecSuccess<T> {
  readonly ok: true;
  readonly value: T;
}

export interface ExecFailure<T> {
  readonly ok: false;
  readonly retriable: boolean;
  readonly value: T;
}

export type ExecResult<S, F> = ExecSuccess<S> | ExecFailure<F>;

export type Executor<S, F> = (
  currentAttempt: number,
) => Promise<ExecResult<S, F>>;

export type RetryOptions = Retry.OperationOptions;

export async function retryExecutor<S, F>(
  executor: Executor<S, F>,
  options?: RetryOptions,
): Promise<ExecResult<S, F>> {
  const operation = Retry.operation(options);

  return new Promise<ExecResult<S, F>>((resolve, reject) => {
    const doAttempt = async (currentAttempt: number) => {
      try {
        const result = await executor(currentAttempt);
        const error =
          result.ok || !result.retriable ? undefined : new Error('not-ok');
        if (operation.retry(error)) return;
        resolve(result);
      } catch (error) {
        operation.stop();
        reject(error);
      }
    };

    // We can safely disable this rule as the function body is completely
    // wrapped in a trycatch block.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    operation.attempt(doAttempt);
  });
}
