import styled from 'styled-components';

import Tooltip from '@paradigm/design-system/src/components/Tooltip';

export default function CountryPickerLabel() {
  return (
    <Container>
      Country of Residence
      <Tooltip
        id="country-of-residence"
        tooltipContent="Please provide the jurisdiction of your primary residence"
      />
    </Container>
  );
}

const Container = styled.div`
  place-items: center;
  display: flex;
  gap: 4px;
`;
