import * as PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import CloseIcon from '@paradigm/design-system/src/assets/CloseIcon';

class ConfirmModal extends PureComponent {
  render() {
    const {
      isOpen,
      toggle,
      onConfirm,
      confirmText,
      onCancel,
      cancelText,
      message,
      title,
      children,
      confirmDisabled,
      contentClassName,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        keyboard
        className="custom-dialog paradigm-confirm-modal"
        contentClassName={classNames(
          'paradigm-confirm-modal-content',
          contentClassName,
        )}
        fade={false}
      >
        <div className="d-flex justify-content-between px-4 align-items-center">
          <div className="modal-title">{title}</div>
          <CloseIcon
            width={15}
            height={15}
            className="close-icon"
            onClick={toggle}
          />
        </div>
        <ModalBody className="px-4 pt-4">{message || children}</ModalBody>
        <ModalFooter className="pb-0">
          {onCancel && (
            <button onClick={onCancel} className="btn tertiary-button">
              {cancelText}
            </button>
          )}
          {onConfirm && (
            <button
              onClick={onConfirm}
              disabled={confirmDisabled}
              className="btn danger-button"
            >
              {confirmText}
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  contentClassName: PropTypes.string,
};

export default ConfirmModal;
