import { useCallback } from 'react';
import { noop } from 'lodash';
import {
  createStatus,
  installEventObserver,
  installStatusObserver,
} from '#/components/pwa/chrome-listeners';
import { useObservable } from '@paradigm/utils/src/hooks';

export default function useChromiumPWACustomPrompt() {
  const installStatus = useObservable(installStatusObserver);
  const installEvent = useObservable(installEventObserver);

  const handleOnInstall = useCallback(() => {
    if (installEvent == null) return;
    installStatusObserver.set(createStatus({ isInstallWaitingConfirm: true }));
    installEvent.prompt().then(noop).catch(noop);
    installEvent.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          installStatusObserver.set(
            createStatus({ isInstalling: true, isInstallAllowed: false }),
          );
        } else {
          installStatusObserver.set(
            createStatus({ isInstallCancelled: true, isInstallAllowed: true }),
          );
        }
      })
      .catch(() => {
        installStatusObserver.set(
          createStatus({ isInstallFailed: true, isInstallAllowed: true }),
        );
      });
    installEventObserver.set(null);
  }, [installEvent]);

  return { handleOnInstall, installStatus };
}
