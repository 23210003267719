import styled, { css } from 'styled-components';

import LoadingSpinner from '#/components/LoadingSpinner';
import { ButtonProps } from './Button';
import { BUTTON_SIZES, BUTTON_THEMES } from './config';

type StyledButtonProps = Omit<ButtonProps, 'ariaLabel'>;

const withVariant = ({
  variant = 'pill',
  size = 'medium',
  theme = 'default',
}: StyledButtonProps) => css`
  ${variant === 'pill' &&
  css`
    border-radius: ${BUTTON_SIZES[size].pillBorderRadius};
  `}
  ${variant === 'squared' &&
  css`
    border-radius: ${BUTTON_SIZES[size].squaredBorderRadius};
  `}
  ${variant === 'link' &&
  css`
    border-radius: none;
    color: ${BUTTON_THEMES[theme].primaryColor};
    background-color: transparent;
    height: unset;
    padding: 0;

    &:hover {
      text-decoration: underline;
      background-image: none;
    }
  `}
`;

const withTheme = ({ theme = 'default' }: StyledButtonProps) => css`
  color: ${BUTTON_THEMES[theme].color};
  background-color: ${BUTTON_THEMES[theme].bgColor};

  &:focus {
    outline: 1px solid ${BUTTON_THEMES[theme].primaryColor};
  }
`;

const withOutline = ({
  theme = 'default',
  outline = false,
}: StyledButtonProps) =>
  outline &&
  css`
    background-color: transparent;
    border: 1px solid ${BUTTON_THEMES[theme].primaryColor};
    color: ${BUTTON_THEMES[theme].primaryColor};
  `;

const withSize = ({ size = 'medium' }: StyledButtonProps) => css`
  font-size: ${BUTTON_SIZES[size].fontSize};
  height: ${BUTTON_SIZES[size].height};
  padding: ${BUTTON_SIZES[size].padding};
`;

export const StyledButton = styled.button<StyledButtonProps>`
  border: none;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  line-height: 1;
  transition: all ease-in-out 0.1s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }

  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  ${withTheme}
  ${withSize}
  ${withVariant}
  ${withOutline}
  ${(props) => props.fluid === true && 'width: 100%;'}
  ${(props) => props.isValid === false && 'opacity: 0.65;'}
  font-weight: ${(props) => (props.bold === true ? 'bold' : 'normal')};
`;

export const IconContainer = styled.div<{
  placement: ButtonProps['iconPlacement'];
}>`
  display: inline-block;
  ${(props) => props.placement === 'right' && 'padding-left: 8px;'}
  ${(props) => props.placement === 'left' && 'padding-right: 8px;'}
`;

interface StyledLoadingSpinnerProps {
  iconSize: Required<ButtonProps>['size'];
}

export const StyledLoadingSpinner = styled(LoadingSpinner).attrs(
  (props: StyledLoadingSpinnerProps) => ({
    ...props,
    size: BUTTON_SIZES[props.iconSize].loadingIconSize,
  }),
)<StyledLoadingSpinnerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
