import { ComponentPropsWithRef, ReactNode } from 'react';
import { UncontrolledPopoverProps } from 'reactstrap';
import { CSSProp } from 'styled-components';
import {
  TooltipPopover,
  TooltipIcon,
  TooltipPopoverBody,
  ChildrenWrapper,
} from './styles';

export interface TooltipProps
  extends ComponentPropsWithRef<typeof TooltipIcon> {
  readonly id: string;
  readonly iconName?: string;
  readonly placement?: UncontrolledPopoverProps['placement'];
  readonly showIconInPopover?: boolean;
  readonly tooltipContent: ReactNode;
  readonly children?: ReactNode;
  readonly popoverCss?: CSSProp;
}

const Tooltip = ({
  id,
  iconName = 'help_outline',
  placement = 'bottom-start',
  showIconInPopover = true,
  tooltipContent,
  popoverCss,
  children,
  ...props
}: TooltipProps) => {
  return (
    <>
      {children != null ? (
        <ChildrenWrapper id={`${id}-popover`} cursor="pointer" {...props}>
          {children}
        </ChildrenWrapper>
      ) : (
        <TooltipIcon id={`${id}-popover`} cursor="pointer" {...props}>
          {iconName}
        </TooltipIcon>
      )}
      <TooltipPopover
        placement={placement}
        target={`${id}-popover`}
        hideArrow
        trigger="click hover focus"
        css={popoverCss}
      >
        <TooltipPopoverBody>
          {showIconInPopover && <TooltipIcon>{iconName}</TooltipIcon>}
          <div>{tooltipContent}</div>
        </TooltipPopoverBody>
      </TooltipPopover>
    </>
  );
};

export default Tooltip;
