import { DefaultRootState, connect } from 'react-redux';
import { Line } from 'rc-progress';
import styled from 'styled-components';

import LoadingSpinner from '@paradigm/design-system/src/components/LoadingSpinner';

const size = '100px';

interface Props {
  updateAvailable: boolean;
  progressBarValue: number;
}

const Loading = ({ updateAvailable, progressBarValue }: Props) => (
  <div className="loading-screen">
    <LoadingIcon>
      <LoadingSpinner size={100} alt="Loading Paradigm" />
    </LoadingIcon>
    {updateAvailable && (
      <>
        <h6 className="progress-bar__text text-center">
          Downloading Update...
        </h6>
        <Line
          className="progress-bar"
          percent={progressBarValue}
          strokeWidth={4}
          strokeColor="#0069ee"
          trailWidth={3.5}
          trailColor="rgba(255,255,255,0.1)"
        />
      </>
    )}
  </div>
);

const LoadingIcon = styled.div`
  & {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: ${size};
    width: ${size};
  }
`;

const mapStateToProps = ({ electron }: DefaultRootState) => ({
  updateAvailable: electron.available,
  progressBarValue: electron.progress,
  updateReady: electron.ready,
});

const LoadingScreen = connect(mapStateToProps, {})(Loading);
export default LoadingScreen;
