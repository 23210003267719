import styled from 'styled-components';
import {
  mountainMeadow,
  paradigmLightBlue,
  razzmatazz,
  scarlet,
} from '#/colors';
import { MOBILE_BREAKPOINT } from '#/units';
import { Props } from './types';

export const BadgeContainer = styled.div<Props>`
  position: absolute;
  color: #ffffff;
  font-size: 0.625rem;
  line-height: 1rem;
  padding: 0rem 0.25rem;
  border-radius: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
  min-width: 16px;
  height: 16px;
  text-align: center;
  background-color: ${razzmatazz};
  box-shadow: 0 0 10px 0 ${razzmatazz};

  top: ${({ top }) => (top != null ? `${top}px` : '0px')};
  right: ${({ right }) => (right != null ? `${right}px` : '0px')};

  margin: ${({ margin }) => (margin != null ? `${margin}` : 'auto')};

  ${({ variant }) =>
    variant === 'hot' &&
    `
      background-color: ${scarlet};
      box-shadow: 0 0 10px 0 ${scarlet};
      `}

  ${({ variant }) =>
    variant === 'new' &&
    `
      background-color: ${mountainMeadow};
      box-shadow: 0 0 10px 0 ${mountainMeadow};
      `}

  ${({ variant }) =>
    variant === 'count' &&
    `
      background-color: rgba(255, 255, 255, 0.1);
      box-shadow: none;
      border-radius: 3px;
      color: ${paradigmLightBlue};
      `}

  ${({ position }) =>
    position === 'relative' &&
    `
      position: relative;
      top: auto;
      right: auto;
      `}
  
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.75rem;
    line-height: 1rem;
    height: 16px;
    padding: 0rem 0.5rem;
  }
`;
