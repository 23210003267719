import localStorage from '@paradigm/utils/src/safeLocalStorage';

type Token = string | null;

let storedToken: Token = null;

export function getToken(): Token {
  return storedToken !== null ? storedToken : localStorage.getItem('token');
}

export function setToken(token: Token) {
  if (token !== null) {
    storedToken = token;
    localStorage.setItem('token', token);
  } else {
    storedToken = null;
    localStorage.removeItem('token');
  }
}
