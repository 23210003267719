import { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { logException } from '@paradigm/logging/src/logging';
import { ErrorBoundaryError } from '@paradigm/logging/src/errors';

interface Props {
  readonly children: ReactNode;
  readonly fallback?: ReactNode;
}

interface State {
  readonly hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  override state: State = { hasError: false };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logException(
      errorInfo.componentStack,
      new ErrorBoundaryError(error.message),
    );
  }

  override render() {
    const { fallback, children } = this.props;

    if (this.state.hasError) {
      return fallback == null ? <ErrorPage /> : fallback;
    }

    return children;
  }
}

function ErrorPage() {
  const reloadApp = () => window.location.reload();
  return (
    <ErrorContainer>
      <Heading>Something went wrong…</Heading>
      <Button onClick={reloadApp}>Reload app</Button>
    </ErrorContainer>
  );
}

const ErrorContainer = styled.div`
  margin: 3rem;
  text-align: center;
`;

const Heading = styled.h2`
  opacity: 0.7;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  padding-bottom: 12px;
`;
