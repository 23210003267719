import { useMemo, useState, PropsWithChildren } from 'react';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { useDefaultAccountsActions } from '#/preferences/default-accounts/context';
import { VenueCode } from '#/preferences/default-accounts/types';

interface DefaultAccountModalMethods {
  readonly open: (selectedVenue: string) => void;
  readonly close: () => void;
}

interface DefaultAccountModalState {
  readonly isOpen: boolean;
}

export const [useDefaultAccountModalState, ModalStateProvider] =
  createSafeContext<DefaultAccountModalState>('DefaultAccountModalState');

export const [useDefaultAccountModalMethods, ModalMethodsProvider] =
  createSafeContext<DefaultAccountModalMethods>('DefaultAccountModalMethods');

export default function DefaultAccountModalProvider({
  children,
}: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);
  const { setVenue } = useDefaultAccountsActions();

  const state: DefaultAccountModalState = {
    isOpen,
  };

  const methods = useMemo(
    (): DefaultAccountModalMethods => ({
      open: (venue) => {
        setVenue(venue as VenueCode);
        setIsOpen(true);
      },
      close: () => setIsOpen(false),
    }),
    [setVenue],
  );

  return (
    <ModalStateProvider value={state}>
      <ModalMethodsProvider value={methods}>{children}</ModalMethodsProvider>
    </ModalStateProvider>
  );
}
