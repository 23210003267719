// @ts-nocheck
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required, length, confirmation } from 'redux-form-validators';

import { FormEventHandler, MouseEvent } from 'react';
import { changeUserPasswordRequest } from '@paradigm/store/auth';

import { PASSWORD_VALIDATION_HINT, notEquals } from '#/utils/customValidators';
import { renderInputField } from '#/utils/renderFormFields';
import { renderError } from '#/utils/renderErrors';

const propNamespace = 'userPasswordChangeProps';

interface Props {
  error?: string;
  success?: string;
  userPasswordChangeProps: {
    handleSubmit: FormEventHandler<HTMLFormElement>;
    dirty: boolean;
    valid: boolean;
  };
}
const UserPasswordChange = (props: Props) => {
  const {
    error,
    [propNamespace]: { handleSubmit, dirty, valid },
    success,
  } = props;

  const focusOnClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.currentTarget.focus();
  };

  return (
    <form onSubmit={handleSubmit}>
      {renderError(error)}
      <Field
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onClick={focusOnClick}
        component={renderInputField}
        type="password"
        name="old_password"
        containerClassName="form-group"
        label="Old Password"
        labelClassName="label-margin"
        className="form-control form-input"
        validate={[required(), length({ min: 1 })]}
        autoComplete="off"
        displayError={dirty}
      />
      <Field
        onClick={focusOnClick}
        component={renderInputField}
        type="password"
        name="new_password1"
        containerClassName="form-group"
        label="New Password"
        labelClassName="label-margin"
        className="form-control form-input"
        validate={[
          required(),
          length({ min: 10 }),
          notEquals({
            formName: 'userPasswordChange',
            field: 'old_password',
            fieldLabel: 'Old Password',
          }),
        ]}
        autoComplete="off"
        displayError={dirty}
      />
      <Field
        onClick={focusOnClick}
        component={renderInputField}
        type="password"
        name="new_password2"
        containerClassName="form-group"
        label="Confirm New Password"
        labelClassName="label-margin"
        className="form-control form-input"
        hint={PASSWORD_VALIDATION_HINT}
        validate={[
          required(),
          confirmation({
            field: 'new_password1',
            fieldLabel: 'New Password',
            msg: 'Passwords do not match',
          }),
        ]}
        autoComplete="off"
        displayError={dirty}
      />
      {success != null && (
        <div className="text-success text-center">{success}</div>
      )}
      <div className="d-flex justify-content-end mt-4">
        <button
          disabled={!valid}
          type="submit"
          className="btn primary-btn-sm"
          style={{ height: 30 }}
        >
          Save
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: {
  auth: { changePasswordError?: string; changePasswordSuccess: boolean };
}) => ({
  error: state.auth.changePasswordError,
  success: state.auth.changePasswordSuccess,
});
const ConnectedUserPasswordChange = connect(mapStateToProps)(
  reduxForm({
    form: 'userPasswordChange',
    onSubmit: (passwordForm: Record<string, unknown>, dispatch) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      dispatch(changeUserPasswordRequest(passwordForm)),
    propNamespace,
  })(UserPasswordChange),
);
export default ConnectedUserPasswordChange;
