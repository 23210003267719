import { ComponentPropsWithoutRef } from 'react';

export default function DovIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21px"
      height="20px"
      viewBox="0 0 21 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="um-/-icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="um-/-tab-/-2"
          transform="translate(-20.000000, -157.000000)"
          stroke="#73A7F0"
        >
          <g id="Group-18" transform="translate(8.009804, 151.000000)">
            <g id="widget" transform="translate(6.480392, 0.000000)">
              <g id="Group-4" transform="translate(6.278714, 7.000000)">
                <g id="Group-3" transform="translate(0.000000, 9.247341)">
                  <polygon
                    id="Rectangle"
                    points="0 2.17296213 3.65538724 4.36619447 3.65538724 8.75265916 0 6.55942682"
                  />
                  <polygon
                    id="Rectangle"
                    transform="translate(5.483081, 5.462811) scale(-1, 1) translate(-5.483081, -5.462811) "
                    points="3.65538724 2.17296213 7.31077448 4.36619447 7.31077448 8.75265916 3.65538724 6.55942682"
                  />
                  <polygon
                    id="Path-2"
                    points="0 2.17296213 3.65538724 4.36619447 7.31077448 2.17296213 3.65538724 1.05527494e-16"
                  />
                </g>
                <g id="Group" transform="translate(12.131798, 9.247341)">
                  <polygon
                    id="Rectangle"
                    points="0 2.17296213 3.65538724 4.36619447 3.65538724 8.75265916 0 6.55942682"
                  />
                  <polygon
                    id="Rectangle"
                    transform="translate(5.483081, 5.462811) scale(-1, 1) translate(-5.483081, -5.462811) "
                    points="3.65538724 2.17296213 7.31077448 4.36619447 7.31077448 8.75265916 3.65538724 6.55942682"
                  />
                  <polygon
                    id="Path-2"
                    points="0 2.17296213 3.65538724 4.36619447 7.31077448 2.17296213 3.65538724 1.05527494e-16"
                  />
                </g>
                <g id="Group-3" transform="translate(6.065899, 0.000000)">
                  <polygon
                    id="Rectangle"
                    points="0 2.17296213 3.65538724 4.36619447 3.65538724 8.75265916 0 6.55942682"
                  />
                  <polygon
                    id="Rectangle"
                    transform="translate(5.483081, 5.462811) scale(-1, 1) translate(-5.483081, -5.462811) "
                    points="3.65538724 2.17296213 7.31077448 4.36619447 7.31077448 8.75265916 3.65538724 6.55942682"
                  />
                  <polygon
                    id="Path-2"
                    points="0 2.17296213 3.65538724 4.36619447 7.31077448 2.17296213 3.65538724 1.05527494e-16"
                  />
                </g>
                <line
                  x1="7.31077448"
                  y1="13.6236704"
                  x2="12.1317978"
                  y2="13.6236704"
                  id="Path-3"
                />
                <line
                  x1="13.3766734"
                  y1="10.4944433"
                  x2="11.5489798"
                  y2="7.65336482"
                  id="Path-4"
                />
                <line
                  x1="7.90680216"
                  y1="10.4944433"
                  x2="5.78931445"
                  y2="7.65336482"
                  id="Path-4"
                  transform="translate(6.848058, 9.073904) scale(-1, 1) translate(-6.848058, -9.073904) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
