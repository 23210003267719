import styled from 'styled-components';
import { firefly_2, pickledBluewood } from '@paradigm/design-system/src/colors';
import { CheckboxVariant } from './Checkbox';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;

export const Circle = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: currentColor;
`;

export const Line = styled.div`
  height: 2px;
  width: 8px;
  border-radius: 4px;
  background-color: currentColor;
`;

export const Label = styled.label<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  gap: 6px;
  opacity: ${({ isChecked }) => (isChecked ? 1 : 0.7)};
  cursor: pointer;
  user-select: none;
`;

export const Box = styled.div<{ variant: CheckboxVariant }>`
  box-sizing: border-box;
  display: grid;
  place-content: center;
  height: 14px;
  width: 14px;
  border: 1px solid ${pickledBluewood};
  border-radius: 3px;
  color: #72adf2;
  background-color: ${({ variant }) =>
    variant === 'border' ? firefly_2 : pickledBluewood};
`;
