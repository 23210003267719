import { BadgeContainer } from './styles';
import { Props } from './types';

export default function Badge({
  variant = 'default',
  children,
  top,
  right,
  margin,
  position = 'absolute',
}: Props) {
  return (
    <BadgeContainer
      variant={variant}
      top={top}
      right={right}
      position={position}
      margin={margin}
    >
      {children != null ? children : <span>•</span>}
    </BadgeContainer>
  );
}
