import { PropsWithChildren, useEffect, useState } from 'react';
import createSafeContext from '@paradigm/utils/src/createSafeContext';
import useIsMobile from '@paradigm/design-system/src/hooks/useIsMobile';

interface BottomTabsToggleState {
  isVisible: boolean;
  toggle: () => void;
}
const [useBottomTabsToggleState, BottomTabsToggleStateContext] =
  createSafeContext<BottomTabsToggleState>('BottomTabsToggleStateContext');

export function BottomTabsToggleStateProvider({ children }: PropsWithChildren) {
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(!isMobile);

  useEffect(() => {
    setIsVisible(!isMobile);
  }, [isMobile]);

  const value = {
    isVisible,
    toggle: () => setIsVisible((prev) => !prev),
  };
  return (
    <BottomTabsToggleStateContext value={value}>
      {children}
    </BottomTabsToggleStateContext>
  );
}

export { useBottomTabsToggleState };
