import { VenueCode } from '#/preferences/default-accounts/types';
import { usePreferencesStore } from '#/preferences/store';
import { getAccountValue } from './store';

/**
 * Persist to local storage and sync with API.
 */
export default function usePersistedAccounts() {
  const preferencesStore = usePreferencesStore((state) => ({
    get: () => state.defaultAccounts,
    defaultAccountsMigrated: state.defaultAccountsMigrated,
    setHasMigratedAccounts: state.setHasMigratedAccounts,
    set: state.setDefaultAccounts,
    setProductDefault: (
      venueCode: VenueCode,
      productCode: string,
      accountName: string | null,
    ) =>
      state.mergeDefaultAccounts({
        // Save no-default as null, because we do not need to tell
        // apart implicit vs explict defaults
        [venueCode]: { [productCode]: getAccountValue(accountName) },
      }),
    setVenueDefault: (venueCode: VenueCode, accountName: string | null) =>
      state.mergeDefaultAccounts({ [venueCode]: { all: accountName } }),
  }));

  return preferencesStore;
}
