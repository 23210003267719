import { useEffect, useState } from 'react';

import { logException } from '@paradigm/logging';
import localStorage from '@paradigm/utils/src/safeLocalStorage';

/**
 * @template T
 * @param {string} key Local storage key, must never change
 * @param {T} initialValue
 * @param {(value: T) => T} [formatter]
 * @returns {[T,  (action: T | ((prevState: T) => T)) => void]}
 */
export default function useLocalStorage(key, initialValue, formatter) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      if (item) {
        if (formatter) return formatter(JSON.parse(item));
        return JSON.parse(item);
      }
      return initialValue;
    } catch (error) {
      logException(`Failed reading from local storage: ${key}`, error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      logException(`Failed saving to local storage: ${key}`, error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}
