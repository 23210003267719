import styled from 'styled-components';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { mirage, paradigmLightBlue } from '#/colors';

export const TooltipIcon = styled.i.attrs((props) => ({
  ...props,
  className: `material-icons ${props.className ?? ''}`,
}))<{
  cursor?: 'pointer' | 'default';
}>`
  && {
    color: ${paradigmLightBlue};
    cursor: ${({ cursor }) => cursor ?? 'default'};
    font-size: 1rem;
  }
`;

export const TooltipPopover = styled(UncontrolledPopover)`
  background: ${mirage};
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;

  .popover-body {
    color: white;
    opacity: 0.9;
    padding: 0.5rem;
  }

  .popover {
    background: ${mirage};
    line-height: 1.25rem;
  }
`;

export const TooltipPopoverBody = styled(PopoverBody)`
  display: flex;
  flex-direction: row;
  gap: 4px;

  ${TooltipIcon} {
    margin-top: 2px;
  }
`;

export const ChildrenWrapper = styled.div<{
  cursor?: 'pointer' | 'default';
}>`
  color: ${paradigmLightBlue};
  cursor: ${({ cursor }) => cursor ?? 'default'};
  font-size: 1rem;
`;
