import { requestPapi, BaseReq } from './fetch-api';

interface EchoReq extends BaseReq {}

interface EchoResp {
  readonly message?: string;
}

export async function echo(req: EchoReq) {
  return requestPapi<EchoResp>({
    ...req,
    method: 'GET',
    url: '/v1/echo/',
  });
}
