import ReconnectingWebSocket from 'reconnecting-websocket';

import { logException } from '@paradigm/logging';
import createTopic, { EventHandler } from '@paradigm/utils/createTopic';
import { Nullable } from '@paradigm/utils/types';

import { WSAbortError, WSTimeoutError } from '@paradigm/logging/src/errors';
import { createCounter } from './utils';

export interface ApiWebSocket<N = WSNotification> {
  readonly request: (reqParams: WSReqParams) => Promise<WSResponse<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  /** @returns Unsubscribe function */
  readonly addNotificationHandler: (handler: EventHandler<N>) => () => void;
  readonly removeNotificationHandler: (handler: EventHandler<N>) => void;
  readonly readyState: ReconnectingWebSocket['readyState'];
  readonly close: ReconnectingWebSocket['close'];
  readonly reconnect: ReconnectingWebSocket['reconnect'];
  readonly addEventListener: ReconnectingWebSocket['addEventListener'];
  readonly removeEventListener: ReconnectingWebSocket['removeEventListener'];
}

export type { EventHandler } from '@paradigm/utils/createTopic';

export interface WSRequest {
  readonly jsonrpc: '2.0';
  readonly id: string | number;
  readonly request_timestamp: number;
  readonly request_uuid: string;
  readonly connection_uuid: Nullable<string>;
  readonly connection_opened_timestamp: Nullable<number>;
  readonly method: string;
  readonly params?: { readonly [key: string]: unknown };
}

export interface WSNotification {
  readonly jsonrpc: '2.0';
  readonly method: string;
  readonly params: {
    readonly channel: string;
    readonly data: unknown;
  };
}

export type AsyncWSResp<T> = Promise<WSResponse<T>>;

export interface WSResponse<T = unknown> {
  readonly jsonrpc: '2.0';
  readonly id: string | number;
  readonly request_timestamp: number;
  readonly request_uuid: string;
  readonly connection_uuid: Nullable<string>;
  readonly connection_opened_timestamp: Nullable<number>;
  readonly result?: T;
  readonly error?: WSError;
}

export interface WSError {
  readonly code?: number;
  readonly message?: string;
  readonly data?: {
    readonly method?: string;
    readonly timestamp?: number;
  };
}

export interface WSReqParams {
  readonly method: string;
  readonly params?: Record<string, unknown>;
  readonly signal?: AbortSignal;
  /** Milliseconds, defaults to 5000 ms. Use <= 0 to disable. */
  readonly timeout?: number;
}

type ReqCallback = (resp: WSResponse<unknown>) => void;

export interface CreateWebSocketOptions<
  RawNotification extends WSNotification,
  Notification,
> {
  readonly urlProvider: () => Promise<string>;
  readonly processNotification: (raw: RawNotification) => Notification;
  /** Milliseconds */
  readonly heartbeatPeriod?: number;
  /** Milliseconds */
  readonly heartbeatTimeout?: number;
  /**
   * @deprecated
   * To be removed once GRFQ's single_grfq.ts module is removed.
   *
   * Subscribe to current active channels on timeout reconnection
   */
  readonly subscribeChannelsOnReconnect?: boolean;
}

const READY_STATE = {
  [ReconnectingWebSocket.CONNECTING]: 'CONNECTING',
  [ReconnectingWebSocket.OPEN]: 'OPEN',
  [ReconnectingWebSocket.CLOSING]: 'CLOSING',
  [ReconnectingWebSocket.CLOSED]: 'CLOSED',
};

/**
 * Create an instance of our lightweight abstraction over a WebSocket.
 *
 * Key features are:
 * - Auto reconnection if the connection closes
 * - JSON-RPC requests
 * - Notification messages
 * - Simple keep alive protocol with heartbeats
 */
export default function createWebSocket<
  RawNotification extends WSNotification = WSNotification,
  Notification = WSNotification,
>(
  options: CreateWebSocketOptions<RawNotification, Notification>,
): ApiWebSocket<Notification> {
  const {
    urlProvider,
    processNotification,
    heartbeatPeriod,
    heartbeatTimeout,
    subscribeChannelsOnReconnect = false,
  } = options;

  const ws = new ReconnectingWebSocket(urlProvider);
  const wsConnectionRequestedTimestamp = Date.now();
  let wsConnectionOpenedTimestamp: WSRequest['connection_opened_timestamp'] =
    null;
  let wsConnectionUUID: WSRequest['connection_uuid'] = null;
  const channels: Set<string> = new Set();
  const notificationTopic = createTopic<Notification>('ws-notification');
  const getId = createCounter();
  const reqCallbacksMap = new Map<string | number, ReqCallback>();

  function _manageSubscriptions(reqParams: WSReqParams) {
    if (!subscribeChannelsOnReconnect) return;

    const { method, params } = reqParams;
    const channel = params?.channel;
    if (typeof channel !== 'string') return;

    if (method === 'subscribe') channels.add(channel);
    if (method === 'unsubscribe') channels.delete(channel);
  }

  const logInfo = (log = {}) =>
    console.info({
      readyState: READY_STATE[ws.readyState],
      connection_opened_timestamp: wsConnectionOpenedTimestamp,
      connection_uuid: wsConnectionUUID,
      url: ws.url.split('?')[0], // avoid exposing auth `token` sent in url params
      timestamp: Date.now(),
      ...log,
    });

  async function request(reqParams: WSReqParams) {
    const { signal, timeout = 5000, ...reqRest } = reqParams;

    /**
     * this checks for cases we're initiating a request from an ws instance
     * that is already aborted by the time this request is called
     */
    if (signal != null && signal.aborted) {
      throw new WSAbortError('Web socket request aborted');
    }

    _manageSubscriptions(reqParams);
    const id = getId();
    const requestTimestamp = Date.now();
    const requestUUID = window.crypto.randomUUID();

    const req: WSRequest = {
      id,
      request_timestamp: requestTimestamp,
      request_uuid: requestUUID,
      connection_opened_timestamp: wsConnectionOpenedTimestamp,
      connection_uuid: wsConnectionUUID,
      jsonrpc: '2.0',
      ...reqRest,
    };

    ws.send(JSON.stringify(req));
    if (req.method === 'subscribe') {
      logInfo({
        message: `Web socket 'subscribe' message sent`,
        request: req,
        timestamp: req.request_timestamp,
      });
    }

    return new Promise<WSResponse>((_resolve, _reject) => {
      const resolve = (resp: WSResponse) => {
        cleanUp();
        _resolve(resp);

        if (req.method === 'subscribe') {
          logInfo({
            message: 'Web socket channel subscribed',
            request: req,
            response: resp,
          });
        }
      };

      const reject = (error: Error) => {
        cleanUp();
        _reject(error);
      };

      reqCallbacksMap.set(id, resolve);

      const timeoutHandle = setTimeout(() => {
        reject(new WSTimeoutError('Web socket request timed out'));

        logInfo({
          message: 'Web socket request timed out',
          request: req,
          timeout,
        });
      }, timeout);

      const handleAbort = () =>
        reject(new WSAbortError('Web socket request aborted'));
      signal?.addEventListener('abort', handleAbort);

      const cleanUp = () => {
        reqCallbacksMap.delete(id);
        clearTimeout(timeoutHandle);
        signal?.removeEventListener('abort', handleAbort);
      };
    });
  }

  function messageListener(message: MessageEvent<string>): void {
    const parsedMsg = JSON.parse(message.data) as WSResponse | RawNotification;
    if ('id' in parsedMsg) {
      // message due to a request (response)
      const handler = reqCallbacksMap.get(parsedMsg.id);
      if (handler == null) {
        logInfo({
          message: 'No handler for incoming Web socket response',
          response: parsedMsg,
        });
      } else {
        handler(parsedMsg);
      }
    } else {
      // message due to a subscription (notification)
      notificationTopic.publish(processNotification(parsedMsg));
    }
  }

  function reconnect() {
    ws.reconnect();
    if (!subscribeChannelsOnReconnect) return;

    for (const channel of channels) {
      request({
        method: 'subscribe',
        params: { channel },
        timeout: SUBSCRIBE_TIMEOUT_MS,
      }).catch((error) => {
        logException(`Failed to reconnect to channel ${channel}.`, error);
      });
    }
  }

  function close(...args: Parameters<typeof ws.close>) {
    ws.close(...args);
    channels.clear();
  }

  ws.addEventListener('message', messageListener);
  ws.addEventListener('open', () => {
    wsConnectionOpenedTimestamp = Date.now();
    wsConnectionUUID = window.crypto.randomUUID();
    const wsConnectionEstablishedInMs =
      wsConnectionOpenedTimestamp - wsConnectionRequestedTimestamp;
    logInfo({
      message: `Web socket new connection established`,
      connection_established_in_ms: wsConnectionEstablishedInMs,
      connection_opened_timestamp: wsConnectionOpenedTimestamp,
      connection_uuid: wsConnectionUUID,
    });
  });
  ws.addEventListener('close', () => {
    logInfo({
      message: `Web socket connection closed`,
      connection_uuid: wsConnectionUUID,
    });
  });

  const apiWs: ApiWebSocket<Notification> = {
    request,
    addNotificationHandler: notificationTopic.subscribe,
    removeNotificationHandler: notificationTopic.unsubscribe,
    get readyState() {
      return ws.readyState;
    },
    close,
    reconnect,
    addEventListener: ws.addEventListener.bind(ws),
    removeEventListener: ws.removeEventListener.bind(ws),
  } as const;

  enhanceWithKeepAlive(apiWs, {
    period: heartbeatPeriod,
    timeout: heartbeatTimeout,
  });

  return apiWs;
}

/** Milliseconds */
const DEFAULT_HEARTBEAT_PERIOD = 5000;
/** Milliseconds */
const DEFAULT_HEARTBEAT_TIMEOUT = 5000;
/** Milliseconds */
export const SUBSCRIBE_TIMEOUT_MS = 12000;

interface KeepAliveParams {
  /** Milliseconds */
  readonly period?: number;
  /** Milliseconds */
  readonly timeout?: number;
}

function enhanceWithKeepAlive<N>(
  ws: ApiWebSocket<N>,
  {
    period = DEFAULT_HEARTBEAT_PERIOD,
    timeout = DEFAULT_HEARTBEAT_TIMEOUT,
  }: KeepAliveParams = {},
) {
  let keepAliveController = new AbortController();

  function startKeepAlive() {
    // Always abort possible previous keep alive
    keepAliveController.abort();
    keepAliveController = new AbortController();
    keepAlive(keepAliveController.signal).catch(handleKeepAliveFailure);
  }

  async function keepAlive(signal: AbortSignal) {
    let lastPingSentAt: number;
    while (!signal.aborted && ws.readyState === WebSocket.OPEN) {
      lastPingSentAt = Date.now();
      try {
        await ws.request({ method: 'heartbeat', timeout, signal });
      } catch (_error) {
        if (signal.aborted as boolean) return;
        const error = _error as Error;
        if (error.name === 'TimeoutError') {
          ws.reconnect();
        } else {
          logException(
            'Unexpected error on web socket heartbeat request',
            error,
          );
        }
      }

      const msForNextHeartbeat = period - (Date.now() - lastPingSentAt);
      if (msForNextHeartbeat > 0) await sleep(msForNextHeartbeat);
    }
  }

  function handleKeepAliveFailure(error: unknown) {
    logException('Web socket keep alive error', error);
    startKeepAlive();
  }

  ws.addEventListener('open', startKeepAlive);
  ws.addEventListener('close', () => keepAliveController.abort());
}

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
