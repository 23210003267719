import { createRoot } from 'react-dom/client';
import { setupDatadog, setupSentry, setupAmplitude } from '@paradigm/logging';
import App from '#/App';
import registerServiceWorker from '#/registerServiceWorker';
import history from '#/history';
import initChromePWAListeners from '#/components/pwa/chrome-listeners';

if (ENABLE_SENTRY === 'true') setupSentry({ history });
if (ENABLE_DATADOG === 'true') setupDatadog();
if (ENABLE_AMPLITUDE === 'true') setupAmplitude();

const setAppHeight = () => {
  const doc = document.documentElement;
  const appHeight = window.innerHeight;
  doc.style.setProperty('--app-height', `${appHeight}px`);
};
window.addEventListener('resize', setAppHeight);
setAppHeight();

const root = createRoot(document.getElementById('root'));
root.render(<App />);

registerServiceWorker();

/* PWA listeners needs to be initialized alongside window load. This because the event 
listeners 'beforeinstallprompt' runs only one time and are NOT GUARANTEED to run at any
deterministic time, so we need to guarantee that we're able to listen this as the page loads
and pass in to the state of our hooks through our observables.
*/
initChromePWAListeners();
