import { useState } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { endOfYesterday, isAfter } from 'date-fns';
import { paradigmLightBlue } from '#/colors';
import CloseIcon from '#/assets/CloseIcon';
import { Container, RangeDateLabel } from './styles';

type EventValue<DateType> = DateType | null;
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>];

export interface RangeDatePreset<Label = string> {
  readonly label: Label;
  readonly value: RangeValue<Date>;
}
export interface RangeDatePickerProps {
  readonly onChange: (date: RangeValue<Date> | null) => void;
  readonly presets: RangeDatePreset[];
  readonly format: string;
  readonly value: RangeValue<Date>;
  readonly dataTestId?: string;
  readonly label?: string;
  readonly placeholder?: [string, string];
}

const { RangePicker } = AntdDatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
);

export default function RangeDatePicker({
  value: [start, end],
  onChange,
  presets,
  format,
  dataTestId,
  placeholder,
  label,
}: RangeDatePickerProps) {
  const [isOpen, setOpen] = useState(false);
  const isActive = start !== null || end !== null;

  const separator = <span>-</span>;
  const closeIcon = <CloseIcon color={paradigmLightBlue} />;
  const expandIcon = <i className="material-icons">arrow_drop_down</i>;
  const collapseIcon = <i className="material-icons">arrow_drop_up</i>;

  return (
    <Container isActive={isActive} data-testid={dataTestId}>
      <RangeDateLabel>{label}</RangeDateLabel>
      <RangePicker
        presets={presets}
        placeholder={placeholder}
        onChange={onChange}
        separator={!isActive ? null : separator}
        format={format}
        value={[start, end]}
        allowEmpty={[true, true]}
        suffixIcon={isOpen ? collapseIcon : expandIcon}
        clearIcon={closeIcon}
        popupClassName="date-picker-pop-up"
        onOpenChange={() => setOpen(!isOpen)}
        allowClear={false}
        disabledDate={(current) => isAfter(current, endOfYesterday())}
        getPopupContainer={(trigger) => {
          return trigger;
        }}
      />
    </Container>
  );
}
