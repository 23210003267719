import createObservable from '@paradigm/utils/src/createObservable';
import { Nullable } from '@paradigm/utils/types';

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}
export interface Status {
  isInstallAllowed: boolean;
  isInstallWaitingConfirm: boolean;
  isInstalling: boolean;
  isInstallCancelled: boolean;
  isInstallSuccess: boolean;
  isInstallFailed: boolean;
}

export const createStatus = (object: Partial<Status>): Status => {
  return {
    isInstallAllowed: object.isInstallAllowed ?? false,
    isInstallWaitingConfirm: object.isInstallWaitingConfirm ?? false,
    isInstalling: object.isInstalling ?? false,
    isInstallCancelled: object.isInstallCancelled ?? false,
    isInstallSuccess: object.isInstallSuccess ?? false,
    isInstallFailed: object.isInstallFailed ?? false,
  };
};

export const installStatusObserver = createObservable<Status>(
  'pwa-install-status',
  createStatus({}),
);
export const installEventObserver = createObservable<
  Nullable<BeforeInstallPromptEvent>
>('pwa-install-status', null);

const beforeAppInstallpromptHandler = (e: BeforeInstallPromptEvent) => {
  e.preventDefault();
  const {
    isInstalling,
    isInstallSuccess,
    isInstallAllowed,
    isInstallCancelled,
  } = installStatusObserver.get();
  if (isInstalling || isInstallSuccess) return;
  installEventObserver.set(e);
  if (!isInstallAllowed) {
    installStatusObserver.set(
      createStatus({
        isInstallAllowed: true,
        isInstallCancelled,
      }),
    );
  }
};

const appInstalledHandler = (e: Event) => {
  const { isInstallSuccess } = installStatusObserver.get();
  if (isInstallSuccess) return;
  window.removeEventListener(
    'beforeinstallprompt',
    beforeAppInstallpromptHandler,
  );
  e.preventDefault();
  installStatusObserver.set(createStatus({ isInstallSuccess: true }));
};

export default function initChromePWAListeners() {
  window.addEventListener('beforeinstallprompt', beforeAppInstallpromptHandler);
  window.addEventListener('appinstalled', appInstalledHandler);
}
