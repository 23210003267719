import ReactSelect from 'react-select';
import { noop } from 'lodash';
import { useStyles, makeTheme } from './styles';
import { makeComponents } from './components';
import { OptionTypeBase, SelectProps } from './types';
import MaterialSelect from './MaterialSelect';

export default function Select<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false,
>(props: SelectProps<OptionType, IsMulti>) {
  const styles = useStyles<OptionType, IsMulti>(props);
  const theme = makeTheme<OptionType, IsMulti>(props);
  const components = makeComponents<OptionType, IsMulti>(props);

  if (props.variant === 'material') {
    const onChange = props.onChange ?? noop;
    return <MaterialSelect {...props} onChange={onChange} />;
  }

  return (
    <ReactSelect
      theme={theme}
      styles={styles}
      // @ts-expect-error
      components={components}
      {...props}
    />
  );
}
