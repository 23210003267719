export type Source = 'BIT' | 'DBT';

export interface Venue {
  readonly id: Source;
  readonly value: Source;
  readonly name: string;
  readonly label: string;
  readonly custom: boolean;
}

const VENUES: readonly Venue[] = [
  {
    id: 'BIT',
    value: 'BIT',
    name: 'Bit.com',
    label: 'Bit.com (BIT)',
    custom: true,
  },
  {
    id: 'DBT',
    value: 'DBT',
    name: 'Deribit',
    label: 'Deribit (DBT)',
    custom: true,
  },
];

export const getVenues = () => VENUES;

export const isSupportedSource = (source: string): source is Source =>
  getVenues().some((venue) => venue.id === source);

export const sourceSupportsCustomStrategy = (code: Source) => {
  return getVenue(code).custom;
};

export const getVenue = (code: string) => {
  const venue = getVenues().find((vn) => vn.id === code);
  if (venue === undefined)
    throw new Error(`Venue with code "${code}" doesn't exist.`);
  return venue;
};
