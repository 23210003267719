import { StorageValue } from 'zustand/middleware';
import { request } from '@paradigm/api/src/fetch-api';
import { AuthUser } from '@paradigm/store/src/auth/types';
import { isFeatureFlagEnabled } from '@paradigm/features/src/feature-flags/utils';
import { PersistedPreferences } from './store';

export async function setPreferences(
  payload: StorageValue<PersistedPreferences>,
): Promise<void> {
  if (isFeatureFlagEnabled('feature_um_user_preferences_json')) {
    await request<{
      readonly data: string;
    }>({
      body: {
        data: payload,
      },
      url: '/account/user/preferences/',
      method: 'POST',
    });
  }
}

export async function getPreferences(): Promise<StorageValue<PersistedPreferences> | null> {
  if (!isFeatureFlagEnabled('feature_um_user_preferences_json')) return null;
  const resp = await request<AuthUser>({
    url: '/account/user/',
    method: 'GET',
  });
  if (resp.ok && resp.data.preferences?.data) {
    return resp.data.preferences.data as StorageValue<PersistedPreferences>;
  }
  return null;
}
