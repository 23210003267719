import { useCallback } from 'react';
import usePersistedAccounts from '#/preferences/default-accounts/usePersistedAccounts';
import { useAccounts } from '#/unified-rfqs/contexts/accounts';
import {
  VENUES,
  VenueCode,
  isEmptyAccount,
} from '#/preferences/default-accounts/types';

export default function useCleanInvalidAccounts() {
  const accountsView = useAccounts();
  const persistedAccountsStore = usePersistedAccounts();

  const cleanUpAccountsByVenue = useCallback(
    (venue: VenueCode) => {
      const venueAccounts = persistedAccountsStore.get()[venue];
      for (const [productCode, accountName] of Object.entries(venueAccounts)) {
        if (isEmptyAccount(accountName)) continue;
        const account = accountsView.selectByAccountName(venue, accountName);
        if (account == null)
          persistedAccountsStore.setProductDefault(venue, productCode, null);
      }
    },
    [accountsView, persistedAccountsStore],
  );

  return useCallback(() => {
    VENUES.forEach(cleanUpAccountsByVenue);
  }, [cleanUpAccountsByVenue]);
}
