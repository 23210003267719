import * as React from 'react';

const ParadexIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 18"
    fill="#7FABEC"
    {...props}
  >
    <path d="m12.233 5.174 1.963 3.015h-1.86l-1.878-2.887h-2.55V3.797h3.452c.677 0 1.226-.514 1.226-1.147 0-.316-.136-.602-.359-.808a1.26 1.26 0 0 0-.867-.337H7.909V0h3.451c.784 0 1.492.296 2.006.777.144.135.273.285.383.446a2.496 2.496 0 0 1 .168 2.574 2.79 2.79 0 0 1-1.684 1.377M5.841 1.223a2.718 2.718 0 0 0-.384-.447A2.927 2.927 0 0 0 3.452 0H0v1.505h3.452c.34 0 .645.128.867.336.222.207.359.493.359.809 0 .633-.549 1.146-1.226 1.146H0V8.189h1.511V5.301h1.941c1.127 0 2.1-.614 2.557-1.505.179-.347.279-.735.279-1.146 0-.526-.164-1.015-.447-1.427M14.196 9.811h-1.85l-1.728 2.667L8.89 9.81H7.039l2.653 4.094L7.04 18h1.85l1.729-2.667L12.346 18h1.85l-2.653-4.095zM6.208 13.905a4.094 4.094 0 0 1-1.19 2.896A4.032 4.032 0 0 1 2.149 18H0v-1.507h2.147a2.577 2.577 0 0 0 2.567-2.588 2.59 2.59 0 0 0-.752-1.829 2.547 2.547 0 0 0-1.815-.758H0V9.811h2.147c2.243 0 4.061 1.833 4.061 4.094" />
  </svg>
);

export default ParadexIcon;
