import { PropsWithChildren, useEffect, useState } from 'react';
import createSafeContext from '@paradigm/utils/src/createSafeContext';
import useIsMobile from '@paradigm/design-system/src/hooks/useIsMobile';

interface SidebarState {
  isVisible: boolean;
  toggle: () => void;
}
const [useSidebarState, SidebarCtxProvider] =
  createSafeContext<SidebarState>('Sidebar');

export function SidebarProvider({ children }: PropsWithChildren) {
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(!isMobile);

  useEffect(() => {
    setIsVisible(!isMobile);
  }, [isMobile]);

  const value = {
    isVisible,
    toggle: () => setIsVisible((prev) => !prev),
  };
  return <SidebarCtxProvider value={value}>{children}</SidebarCtxProvider>;
}

export { useSidebarState };
