import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as types from '#/redux/types/electron';

function* deepLinkAvailableSaga({ payload }) {
  yield put(push(payload));
}

function* deepLinkAvailableListener() {
  yield takeEvery(types.DEEP_LINK_AVAILABLE, deepLinkAvailableSaga);
}

const electronSagas = [deepLinkAvailableListener];

export default electronSagas;
