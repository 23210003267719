import {
  Comparer,
  createEntityAdapter,
  EntityAdapter,
  EntityId,
  EntitySelectors,
  EntityState,
  IdSelector,
} from '@reduxjs/toolkit';

import { v4 as uuidv4 } from 'uuid';

import createObservable, {
  Observable,
  EventHandler,
  ReadonlyObservable,
} from '@paradigm/utils/src/createObservable';

export interface BaseEntity {
  readonly id: EntityId;
}

/**
 * Subset of methods of `EntityStore` that are designed to be
 * used in reactive applications to observe the state of the
 * store.
 *
 * Use this interface to safely expose an store publicly, without
 * allowing writes or non-reactive read methods.
 */
export interface PublicEntityStore<T extends BaseEntity> {
  id: string;
  list(): ReadonlyObservable<readonly T[]>;
  listIds(): ReadonlyObservable<readonly T['id'][]>;
  item(entityId: EntityId): ReadonlyObservable<T | null>;
  selectById(entityId: EntityId): T | undefined;
}

interface CreateEntityAdapterOptions<T> {
  readonly selectId?: IdSelector<T>;
  readonly sortComparer?: Comparer<T>;
}

const INITIAL_STATE = { ids: [], entities: {} };

export default class EntityStore<Entity extends BaseEntity>
  implements PublicEntityStore<Entity>
{
  public id: string = uuidv4();
  private state: EntityState<Entity> = INITIAL_STATE;
  private listObservable: Observable<readonly Entity[]> | undefined;
  private listIdsObservable: Observable<readonly EntityId[]> | undefined;
  private itemObservables: Map<EntityId, Observable<Entity | null>> = new Map();
  private entityAdapter: EntityAdapter<Entity>;
  private selectId: IdSelector<Entity>;
  private selectors: EntitySelectors<Entity, EntityState<Entity>>;
  private permanentlyRemoved = new Set<EntityId>();

  constructor(adapterOptions?: CreateEntityAdapterOptions<Entity>) {
    this.entityAdapter = createEntityAdapter<Entity>(adapterOptions);
    this.selectId = adapterOptions?.selectId ?? defaultIdSelector;
    this.selectors = this.entityAdapter.getSelectors();
  }

  selectAll(): readonly Entity[] {
    const { selectAll } = this.selectors;
    return selectAll(this.state);
  }

  selectAllIds(): readonly Entity['id'][] {
    return this.state.ids;
  }

  selectById(entityId: Entity['id']) {
    const { selectById } = this.selectors;
    return selectById(this.state, entityId);
  }

  /**
   * Sets entity to store, overwriting a previously added/upserted/set
   * entity with same id. Differently from upsert, this won't merge the
   * two objects, only replacing the old reference with the new reference.
   *
   * If the entity was permanently removed, this will be no-op.
   *
   * @param entity - entity to set on store
   */
  setOne(entity: Entity): void {
    if (this.isPermanentlyRemoved(entity)) return;

    this.state = this.entityAdapter.setOne(this.state, entity);

    this.itemObservables.get(entity.id)?.set(entity);
    this.listIdsObservable?.set(this.state.ids);
    this.listObservable?.set(this.selectAll());
  }

  /**
   * Insert or update multiples entities. After the operation, all involved
   * observables are notifies
   *
   * For those entities that were permanently removed, the operation will be
   * no-op.
   *
   * @param entities
   */
  upsertMany(entities: readonly Entity[]): void {
    entities = entities.filter((e) => !this.isPermanentlyRemoved(e));

    this.state = this.entityAdapter.upsertMany(this.state, entities);

    entities.forEach((entity) =>
      this.itemObservables.get(entity.id)?.set(entity),
    );
    this.listIdsObservable?.set(this.state.ids);
    this.listObservable?.set(this.selectAll());
  }

  /**
   * Insert multiple entities without overriding existing ones.
   * After the operation, all involved observables are notified
   *
   * For those entities that were permanently removed, the operation will be
   * no-op.
   *
   * @param entities
   */
  addMany(entities: readonly Entity[]): void {
    entities = entities.filter((e) => !this.isPermanentlyRemoved(e));

    this.state = this.entityAdapter.addMany(this.state, entities);

    entities.forEach((entity) =>
      this.itemObservables.get(entity.id)?.set(entity),
    );
    this.listIdsObservable?.set(this.state.ids);
    this.listObservable?.set(this.selectAll());
  }

  /**
   * Updates entity with partial fields and notify that item observable.
   *
   * @param entityId
   * @param entityUpdate Partial object with some of entity's fields
   * @returns void
   */
  updateOne(entityId: Entity['id'], entityUpdate: Partial<Entity>): void {
    const entity = this.selectById(entityId);
    if (entity == null) return;

    this.state = this.entityAdapter.updateOne(this.state, {
      id: entityId,
      changes: entityUpdate,
    });

    this.itemObservables.get(entityId)?.set(this.selectById(entityId) ?? null);
    this.listIdsObservable?.set(this.state.ids);
    this.listObservable?.set(this.selectAll());
  }

  /**
   * Remove item from store and notify list and listIds observables
   * @param entityId - id of the entity
   * @param options
   * @param options.permanent - whether to permanently remove this entity,
   * it won't be possible to add/set/upsert the entity again, if tried, these
   * operations will be no-op.
   */
  removeOne(
    entityId: Entity['id'],
    { permanent = false }: { permanent?: boolean } = {},
  ): void {
    this.state = this.entityAdapter.removeOne(this.state, entityId);
    this.listIdsObservable?.set(this.state.ids);
    this.listObservable?.set(this.selectAll());
    this.itemObservables.get(entityId)?.set(null);

    if (permanent) {
      this.permanentlyRemoved.add(entityId);
    }
  }

  /**
   * Clear store, and reset values
   */
  clear() {
    this.state = INITIAL_STATE;
    this.listIdsObservable?.set([]);
    this.listObservable?.set([]);
    this.itemObservables.forEach((entityObservable) =>
      entityObservable.set(null),
    );
    this.permanentlyRemoved.clear();
  }

  /**
   * Observes changes to all list items. The preferred method should be to use observeListIds
   * and use observeItem for single updates for each entity.
   *
   * @param handler When the entity id list changes, notify handler with new list
   * @returns [CleanSubscription] - a function to clear observable subscription
   *
   * @deprecated @see list
   */
  subscribeList(handler: EventHandler<readonly Entity[]>) {
    return this.list().observe(handler);
  }

  /**
   * Prefer to use `listIds` instead, and then use `item` for
   * single entity updates.
   *
   * @returns observable to the list of items stored
   * @see listIds
   * @see item
   */
  list(): ReadonlyObservable<readonly Entity[]> {
    if (this.listObservable == null) {
      this.listObservable = createObservable('entities-list', this.selectAll());
    }
    return this.listObservable;
  }

  /**
   * Observes changes to the entity list ids. Returns a function to unsubscribe to changes
   *
   * @param handler When the entity id list changes, notify handler with new list
   * @returns [CleanSubscription] - a function to clear observable subscription
   *
   * @deprecated @see listIds
   */
  subscribeListIds(handler: EventHandler<readonly Entity['id'][]>) {
    return this.listIds().observe(handler);
  }

  /**
   * @returns observable to the list of ids of items stored
   */
  listIds(): ReadonlyObservable<readonly Entity['id'][]> {
    if (this.listIdsObservable == null) {
      this.listIdsObservable = createObservable(
        'entities-ids-list',
        this.selectAllIds(),
      );
    }
    return this.listIdsObservable;
  }

  /**
   * Observes single item changes. Returns a function to unsubscribe to changes
   *
   * @param entityId
   * @param handler When the entity value update, this handle will be called with the new entity
   * @returns [CleanSubscription] - a function to clear observable subscription
   *
   * @deprecated @see item
   */
  subscribeItem(entityId: Entity['id'], handler: EventHandler<Entity | null>) {
    return this.item(entityId).observe(handler);
  }

  /**
   * @param entityId - id of the item to get
   * @returns observable to the item
   */
  item(entityId: EntityId): ReadonlyObservable<Entity | null> {
    const entity = this.selectById(entityId);
    let entityObservable = this.itemObservables.get(entityId);

    if (entityObservable == null) {
      entityObservable = createObservable<Entity | null>(
        entityId.toString(),
        entity ?? null,
      );
      this.itemObservables.set(entityId, entityObservable);
    }

    return entityObservable;
  }

  private isIdPermanentlyRemoved(entityId: EntityId) {
    return this.permanentlyRemoved.has(entityId);
  }

  private isPermanentlyRemoved(entity: Entity) {
    return this.isIdPermanentlyRemoved(this.selectId(entity));
  }
}

function defaultIdSelector(entity: BaseEntity): EntityId {
  return entity.id;
}
