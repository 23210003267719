import { Maybe } from '@paradigm/utils/types';

export const NO_DEFAULT_SELECT_OPTION = {
  value: 'no-default',
  label: 'No Default Account',
} as const;
export const TAB_VENUES = [
  {
    id: 'DBT',
    label: 'Deribit',
  },
  {
    id: 'BYB',
    label: 'Bybit',
  },
  {
    id: 'BIT',
    label: 'BIT',
  },
] as const;

export const VENUES = TAB_VENUES.map((venue) => venue.id);

export type VenueCode = typeof VENUES[number];

export type DefaultAccountsRecord = {
  [code in VenueCode]: {
    all: string | null;
    [productCode: string]: string | null;
  };
};

export const INITIAL_DEFAULT_ACCOUNTS: DefaultAccountsRecord = {
  DBT: {
    all: null,
  },
  BYB: {
    all: null,
  },
  BIT: {
    all: null,
  },
};

export const isValidVenue = (venue: string): venue is VenueCode =>
  (VENUES as string[]).includes(venue);

export const isEmptyAccount = (
  accountValue: Maybe<string>,
): accountValue is Maybe<'no-default'> =>
  isNoDefaultAccount(accountValue) || isUnsetAccount(accountValue);

export const isNoDefaultAccount = (
  accountValue: Maybe<string>,
): accountValue is 'no-default' =>
  accountValue === NO_DEFAULT_SELECT_OPTION.value;

export const isUnsetAccount = (
  accountValue: Maybe<string>,
): accountValue is null | undefined => accountValue == null;
