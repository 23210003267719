import { ComponentPropsWithoutRef } from 'react';

const NotificationIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="19px" height="19px" viewBox="0 0 19 19" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path
        d="M2.578 4.323H.937A.937.937 0 000 5.259v4.99c0 .517.42.936.937.936h1.64c.224 0 .44.079.608.223l5.15 3.874a.937.937 0 001.544-.712V.938A.936.936 0 008.335.226l-5.15 3.873a.936.936 0 01-.607.224zM13.318 3.118c1.368 0 2.477 2.076 2.477 4.636s-1.109 4.637-2.477 4.637"
        transform="translate(-558 -429) translate(520 184) translate(39.682 246.6)"
      />
    </g>
  </svg>
);

export default NotificationIcon;
