import { ComponentPropsWithoutRef } from 'react';

const BackIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="15px" height="14px" viewBox="0 0 15 14" {...props}>
    <g
      transform="translate(-550 -223) translate(520 186) translate(32 32) translate(0 6)"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M6.176592 11.914464L0.339312 6.077184 6.176592 0.239904" />
      <path d="M0.339216 6.077328L11.845776 6.077328" />
    </g>
  </svg>
);

export default BackIcon;
