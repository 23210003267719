import { lazy, ReactNode, Suspense } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import { selectIsAuthenticated } from '@paradigm/store/auth';
import { withProfiler } from '@paradigm/logging';
import { QuotePrefillProvider } from '@paradigm/features/src/unified-rfqs/ui/drfq/quote-builder/prefill-context';

import UserIdentifier from '@paradigm/features/src/logging/UserIdentifier';

import { ToastContainer } from '#/components/toasts/toast';
import useInitialRedirect from '#/hooks/useInitialRedirect';
import UnifiedRfqsNotificationsProvider from '#/components/UnifiedRfqs/UnifiedRfqsNotificationsProvider';
import { UnifiedRfqsPinnedRfqsProvider } from '#/components/UnifiedRfqs/UnifiedRfqsPinnedRfqsProvider';

import history from '#/history';
import store from '#/redux/store';
import ForgotPassword from '#/components/forgot-password';
import SetPassword from '#/components/SetPassword';
import SignIn from '#/components/sign-in';
import TwoFA from '#/components/sign-in/two-fa';
import SignUp from '#/components/sign-up';
import MmpNotificator from '#/components/MmpNotificator';
import TokenRefresher from '#/components/TokenRefresher';
import LayoutContainer from '#/containers/layout';

import {
  VRFQS_ROUTE,
  FS_ROUTE,
  UNIFIED_RFQS_ROUTE,
  LEADERBOARD_ROUTE,
  PARADEX_ROUTE,
} from '#/routes';

import 'react-toastify/dist/ReactToastify.css';
import '#/styles/styles.scss';
import PageLoader from '#/containers/layout/loaders/PageLoader';
import TitleBar from '#/components/electron-title-bar/TitleBar';
import PagePlaceholder from '#/containers/layout/placeholders/PagePlaceholder';
import ErrorBoundary from '#/ErrorBoundary';
import { SidebarProvider } from '#/contexts/sidebar';
import { BottomTabsToggleStateProvider } from '#/contexts/BottomTabsStateProvider';
import FeatureFlagLoader from '#/containers/layout/loaders/FeatureFlagLoader';
import PWACustomInstallerPrompt from '#/components/pwa/PWACustomInstallerPrompt';
import useIncludeDbtAltCoins from '#/hooks/useIncludeDbtAltCoins';
import ElectronBoundary from './components/electron-deprecation/ElectronBoundary';

const UnifiedRfqsPage = lazy(async () => import('#/components/UnifiedRfqs'));
const VrfqPage = lazy(async () => import('#/components/Vrfqs/Vrfqs'));
const FutureSpreads = lazy(async () => import('#/components/FutureSpreads'));
const LeaderboardPage = lazy(async () => import('#/components/Leaderboard'));
const ParadexPage = lazy(async () => import('#/components/dapps/paradex'));

const App = withProfiler(function App() {
  return (
    <ErrorBoundary>
      <TitleBar />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <FeatureFlagLoader>
            <SidebarProvider>
              <PageLoader>
                <UnifiedRfqsPinnedRfqsProvider>
                  <UnifiedRfqsNotificationsProvider>
                    <ElectronBoundary>
                      <LayoutContainer>
                        <BottomTabsToggleStateProvider>
                          <Routes />
                        </BottomTabsToggleStateProvider>
                      </LayoutContainer>
                    </ElectronBoundary>
                  </UnifiedRfqsNotificationsProvider>
                </UnifiedRfqsPinnedRfqsProvider>
              </PageLoader>
            </SidebarProvider>
          </FeatureFlagLoader>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  );
});

export default App;

function Routes() {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/twoFA/" exact component={TwoFA} />
        <Route path="/register/" component={SignUp} />
        <Route path="/forgot-password/" component={ForgotPassword} />
        <Route path="/set-password/" component={SetPassword} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return <PrivateRoutes />;
}

function PrivateRoutes() {
  useIncludeDbtAltCoins();
  const initialRedirect = useInitialRedirect();
  return renderWithProviders(
    <>
      <Switch>
        <Route path={VRFQS_ROUTE} exact component={withSuspense(VrfqPage)} />
        <Route
          path={UNIFIED_RFQS_ROUTE}
          exact
          component={withSuspense(UnifiedRfqsPage)}
        />
        <Route path={FS_ROUTE} exact component={withSuspense(FutureSpreads)} />
        <Route
          path={PARADEX_ROUTE}
          exact
          component={withSuspense(ParadexPage)}
        />
        <Route
          path={LEADERBOARD_ROUTE}
          exact
          component={withSuspense(LeaderboardPage)}
        />
        <Redirect to={initialRedirect ?? UNIFIED_RFQS_ROUTE} />
      </Switch>
      <UserIdentifier />
      <ToastContainer />
      <MmpNotificator />
      <TokenRefresher />
      <PWACustomInstallerPrompt />
    </>,
  );
}

function withSuspense(Component: React.FC) {
  return function SuspenseComponent() {
    return (
      <Suspense fallback={<PagePlaceholder />}>
        <Component />
      </Suspense>
    );
  };
}

function renderWithProviders(children: ReactNode) {
  return <QuotePrefillProvider>{children}</QuotePrefillProvider>;
}
