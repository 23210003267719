import formatDecimal, {
  decimalRoundUp,
  isValidDecimal,
  roundUpToMultiplier,
  unformatDecimal,
} from '@paradigm/utils/formatDecimal';
import enhanceNegativeSign, {
  NEGATIVE_SIGN,
} from '@paradigm/utils/src/enhanceNegativeSign';
import {
  GrfqHedgeLeg,
  Grfq,
  GrfqLeg,
  GrfqRegularLeg,
} from '#/unified-rfqs/entities/grfq/domain';
import { BaseRfq } from '#/unified-rfqs/entities/shared';
import { getProduct } from '#/products/domain';
import { getVenue } from '#/unified-rfqs/entities/products/venue';
import { isStandardProduct, StandardProduct } from '#/products/types';

interface ExecConstraint {
  readonly minBlockSize: number;
  readonly minContractIncrement: number;
}

export function normalizedMinBlockSize(
  productCode: string,
  legs: readonly GrfqLeg[],
): ExecConstraint {
  const product = getProduct(productCode);
  const { minBlockSizeCalculation } = getVenue(product.venue);

  if (!isStandardProduct(product)) {
    return {
      minBlockSize: 0,
      minContractIncrement: product.minContractIncrement,
    };
  }

  switch (minBlockSizeCalculation) {
    case 'leg':
      return normalizedMinBlockSizeByLeg(legs, product);
    case 'strategy':
      return normalizedMinBlockSizeByStrategy(legs, product.code);
    // no default
  }
}

function normalizedMinBlockSizeByStrategy(
  legs: readonly GrfqLeg[],
  productCode: string,
): ExecConstraint {
  const product = getProduct(productCode);
  const venue = getVenue(product.venue);
  const hedgeConstraint = getHedgeExecConstraint(
    legs.filter((leg) => leg.isHedge) as GrfqHedgeLeg[],
  );
  const nonHedgeConstraint = _getNonHedgeExecConstraint(
    legs.filter((leg) => !leg.isHedge) as GrfqRegularLeg[],
  );
  const smallestConstraint = minExecConstraint(
    nonHedgeConstraint,
    hedgeConstraint,
  );
  const boundedMinBlockSize = Math.max(
    smallestConstraint.minBlockSize,
    venue.normalizedMinBlockSize,
  );
  const minContractIncrement =
    product.kind === 'OPTION'
      ? product.minContractIncrement
      : smallestConstraint.minContractIncrement;
  const minBlockSize = roundUpToMultiplier(
    boundedMinBlockSize,
    minContractIncrement,
  );
  return { minBlockSize, minContractIncrement };
}

function normalizedMinBlockSizeByLeg(
  legs: readonly GrfqLeg[],
  product: StandardProduct,
): ExecConstraint {
  const resultMinBlockSize = legs.reduce((currentMinBlockSize, leg) => {
    const legProduct = getProduct(leg.product_code);
    if (!isStandardProduct(legProduct)) return 0;
    if (!leg.isHedge)
      return Math.max(currentMinBlockSize, legProduct.minBlockSize);

    // If it's a hedge leg, calculate how many legs I need to buy to reach the min block size
    const parsedPrice = Number.parseFloat(unformatDecimal(leg.price!));
    const baseHedgeLegSize = parsedPrice / legProduct.contractSize;
    const unitsToReachMinBlockSize = Math.ceil(
      legProduct.minBlockSize / baseHedgeLegSize,
    );
    return Math.max(currentMinBlockSize, unitsToReachMinBlockSize);
  }, product.minBlockSize);
  return {
    minBlockSize: decimalRoundUp(resultMinBlockSize, product.quantityPrecision),
    minContractIncrement: product.minContractIncrement,
  };
}

// Can only be executed if leg products are standard (not mgp)
function _getNonHedgeExecConstraint(
  nonHedgeLegs: readonly GrfqRegularLeg[],
): ExecConstraint {
  const [firstLeg] = nonHedgeLegs;
  const product = getProduct(firstLeg!.product_code) as StandardProduct;
  const legsRatiosSum = nonHedgeLegs.reduce(
    (total, leg) => (total += Math.abs(Number.parseFloat(leg.ratio))),
    0,
  );
  return {
    minBlockSize: product.minBlockSize / legsRatiosSum,
    minContractIncrement: product.minContractIncrement,
  };
}

function getHedgeExecConstraint(
  hedgeLegs: readonly GrfqHedgeLeg[],
): ExecConstraint {
  return hedgeLegs.reduce<ExecConstraint>(_minHedgeExecConstraintReducer, {
    minBlockSize: Number.POSITIVE_INFINITY,
    minContractIncrement: Number.POSITIVE_INFINITY,
  });
}

// Can only be executed if leg products are standard (not mgp)
function _minHedgeExecConstraintReducer(
  current: ExecConstraint,
  leg: GrfqHedgeLeg,
): ExecConstraint {
  const legPrice = Number.parseFloat(leg.price);
  const legRatio = Number.parseFloat(leg.ratio);
  const product = getProduct(leg.product_code) as StandardProduct;
  const hedgeMinBlockSize =
    (product.minBlockSize * product.contractSize) / (legPrice * legRatio);

  const candidate: ExecConstraint = {
    minBlockSize: hedgeMinBlockSize,
    minContractIncrement: product.minContractIncrement,
  };
  return minExecConstraint(current, candidate);
}

function minExecConstraint(
  cnstrA: ExecConstraint,
  cnstrB: ExecConstraint,
): ExecConstraint {
  if (cnstrA.minBlockSize === cnstrB.minBlockSize) {
    return cnstrA.minContractIncrement <= cnstrB.minContractIncrement
      ? cnstrA
      : cnstrB;
  }

  return cnstrA.minBlockSize < cnstrB.minBlockSize ? cnstrA : cnstrB;
}

export const CUSTOM_DESCRIPTION_PREFIX = 'Cstm';

export function isCustomDescription(text: string): boolean {
  return text.startsWith(CUSTOM_DESCRIPTION_PREFIX);
}

export function isCustomStrategy(rfq: BaseRfq) {
  return isCustomDescription(rfq.description);
}

export function isHedged(rfq: Grfq) {
  return rfq.legs.some((leg) => leg.isHedge);
}

/**
 * @param text Custom strategy description
 * @returns List of leg descriptions
 */
export function parseCustomDescription(text: string): readonly string[] {
  const [, legsText] = text.split(CUSTOM_DESCRIPTION_PREFIX);

  const dirtyLegs = legsText?.split('\n');
  const cleanLegs = dirtyLegs?.map(sanitizeLegs);

  if (cleanLegs == null)
    throw new TypeError(`Could not parse custom description "${text}"`);

  return cleanLegs;
}

/**
 *
 * @param text Full strategy description
 * @returns Each line of the strategy description, the first line is the
 *          strategy name, the following lines are hedge legs.
 */
export function parseStrategyDescription(text: string): readonly string[] {
  const dirtyLegs = text.split('\n');
  const cleanLegs = dirtyLegs.map(sanitizeLegs);

  return cleanLegs;
}

export function formatProductMark(
  markPrice: string | null,
  productCode: string | undefined,
) {
  if (productCode == null) {
    return NEGATIVE_SIGN;
  }

  return formatProductPrice(markPrice, productCode);
}

export function formatProductPrice(
  price: string | null,
  productCode?: string | undefined,
  customPrecision?: number | null,
) {
  if (price == null || !isValidDecimal(price)) {
    return NEGATIVE_SIGN;
  }

  const numeric = Number.parseFloat(price);
  const fixed =
    productCode != null
      ? numeric.toFixed(
          customPrecision ?? getProduct(productCode).pricePrecision,
        )
      : numeric.toString();

  const formatted = formatDecimal(fixed);
  const ehnancedNegative = enhanceNegativeSign(formatted);

  return ehnancedNegative;
}

function sanitizeLegs(text: string): string {
  return text.trim();
}
