import { ComponentPropsWithoutRef } from 'react';

const LockIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="17px" height="20px" viewBox="0 0 17 20" {...props}>
    <g
      transform="translate(-559 -471) translate(520 184) translate(40.236 288.412)"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M13.503 17.566H1.22A1.22 1.22 0 010 16.346V6.476a1.22 1.22 0 011.22-1.221h12.283a1.22 1.22 0 011.22 1.221v9.868a1.22 1.22 0 01-1.22 1.221z"
        strokeLinecap="round"
      />
      <circle cx={7.36189772} cy={10.1652683} r={1.24572028} />
      <path d="M2.106 5.256a5.256 5.256 0 0110.512 0" strokeLinecap="round" />
    </g>
  </svg>
);

export default LockIcon;
