import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import { authReducer } from '@paradigm/store/auth';
import { errorReducer } from '@paradigm/store/error';

import app, { resetRequest } from '#/redux/app';
import registration from '#/redux/registration';

import electron from './electron';

export function createRootReducer(history) {
  const appReducers = combineReducers({
    router: connectRouter(history),
    form: formReducer,
    app,
    auth: authReducer,
    error: errorReducer,
    electron,
    registration,
  });

  return (state, action) => {
    // App-wide reset
    if (action.type === resetRequest.type) {
      state = {
        ...state,
        error: undefined,
        registration: undefined,
      };
    }
    return appReducers(state, action);
  };
}
