import { ComponentPropsWithoutRef } from 'react';

const HelpIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg viewBox="0 0 23 20" version="1.1" {...props}>
    <g
      id="um-/-icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="um-/-tab-/-2"
        transform="translate(-18.000000, -372.000000)"
        fillRule="nonzero"
        stroke="#7FABEC"
      >
        <g id="Group-21" transform="translate(18.009804, 373.419487)">
          <g id="icon-/-Help" transform="translate(0.500000, 0.000000)">
            <path
              d="M1.46238909,7.74888276 C7.17273163,5.2086674 10.9805211,3.53400196 12.8857573,2.72488644 C18.325594,0.414697208 19.4559455,0.0133926082 20.1926928,0 C20.3547335,-0.00277319175 20.7170444,0.0382297282 20.9517342,0.232668288 C21.1499016,0.396848408 21.2044254,0.618632407 21.2305168,0.774293167 C21.2566082,0.929953927 21.2890982,1.28455313 21.2632711,1.56162665 C20.9684845,4.72409944 19.6929473,12.398587 19.0440264,15.9406107 C18.7694436,17.4393737 18.2287816,17.9419013 17.7053582,17.9910802 C16.5678379,18.0979575 15.7040567,17.2235214 14.6023127,16.4861295 C12.8783009,15.3322566 11.9043439,14.6139672 10.2308919,13.4880005 C8.29692855,12.1867512 9.5506365,11.4715619 10.6527966,10.3027425 C10.9412371,9.99685692 15.9531774,5.34225916 16.0501837,4.9200142 C16.0623159,4.8672054 16.0735753,4.67035924 15.9590405,4.56641724 C15.8445058,4.46247524 15.6754625,4.49801936 15.5534761,4.52628792 C15.3805641,4.56635772 12.6264329,6.42501096 7.29108243,10.1022476 C6.50933145,10.6503449 5.80124569,10.9173963 5.16682514,10.9034018 C4.46742739,10.887974 3.1220667,10.4996371 2.12192931,10.1676963 C0.895220097,9.76055744 -0.0797439783,9.54530016 0.00515351744,8.85385236 C0.0493733668,8.49370356 0.535118543,8.12538036 1.46238909,7.74888276 Z"
              id="Path-3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HelpIcon;
