import { ProductCode } from '#/products/types';
import {
  BaseRfq,
  BaseRfqLeg,
  TradeDisplayValues,
  OptionKind,
  OrderType,
  QuoteStatus,
  Side,
} from '../shared';
import { Greeks } from '../greeks';
import { GrfqVenue } from '../products/venue';

export interface Grfq extends BaseRfq {
  type: 'grfq';
  id: number;
  legs: readonly GrfqLeg[];
  status: GrfqStatus;
  latest_activity: Date;
  strategy_code: string;
  last_trade: GrfqLastTrade | null;
  venue: GrfqVenue['code'];
  product_codes: readonly ProductCode[] | readonly string[];
  created: Date;
}

export interface GrfqLastTrade {
  readonly price: string;
  readonly quantity: string;
  readonly traded: Date;
}

export interface RawGrfqLeg extends BaseRfqLeg {
  readonly instrument: string;
}

export interface GrfqLeg extends RawGrfqLeg {
  readonly isHedge: boolean;
}

export interface GrfqRegularLeg extends RawGrfqLeg {
  readonly price?: null;
  readonly isHedge: false;
}

export interface GrfqHedgeLeg extends RawGrfqLeg {
  readonly price: string;
  readonly isHedge: true;
}

export type GrfqStatus = 'ACTIVE' | 'EXPIRED';

export type GrfqInstrument = GrfqFutureInstrument | GrfqOptionInstrument;

type InstrumentKind = 'OPTION' | 'FUTURE';

interface BaseInstrument {
  /** Unique identifier for the instrument */
  readonly id?: number;
  readonly name: string;
  readonly venue: GrfqVenue['code'];
  readonly kind: InstrumentKind;
  readonly greeks: Greeks;
  /** Unix time (ms) */
  readonly option_kind: OptionKind | null;
  readonly strike: string | null;
  /** Unix time (ms) */
  readonly expiration: number | null;
  readonly product_code: string;
  readonly mark_price: string;
  readonly base_currency: string;
  readonly margin_kind: string;
}

export interface GrfqFutureInstrument extends BaseInstrument {
  readonly kind: 'FUTURE';
  readonly option_kind: null;
  /** `null` expiration means it's perpetual */
  readonly expiration: number | null;
  readonly strike: null;
}

export interface GrfqOptionInstrument extends BaseInstrument {
  readonly kind: 'OPTION';
  readonly option_kind: OptionKind;
  readonly expiration: number;
  readonly strike: string;
}
export interface Order {
  readonly client_order_id: string;
  readonly filled_average_price: string;
  readonly filled_quantity: string;
  readonly id: number;
  readonly limit_price: string;
  readonly requested_quantity: string;
  readonly rfq_id: number;
  readonly side: Side;
  readonly status: string;
  readonly venue: string;
}

export interface OrderSummary extends Order {
  readonly quote: GrfqQuote;
}

export interface GrfqQuote {
  readonly rfqType: 'grfq';
  readonly id: string | number;
  readonly rfq_id: Grfq['id'];
  readonly side: Side;
  readonly created: Date;
  readonly last_activity: Date;
  readonly price: string;
  readonly status: QuoteStatus;
  readonly description: string;
  /**
   * Returned only by /grfqs/quotes API,
   * for RFQ quotes, we prefill this field using rfq.product_codes
   */
  readonly product_codes?: ProductCode[];
  readonly type: OrderType;
  /** Present only on crossed quotes */
  readonly order?: Order;
  readonly original_quantity: string;
  /** for the order book we have only remaining_quantity */
  readonly remaining_quantity: string;
  readonly canceled_quantity: string;
  readonly completed_quantity: string;
  readonly legs: Pick<GrfqLeg, 'price' | 'side' | 'isHedge'>[];
  /**
   * Maker's ticker. Defined only when it is your own desk and not
   * canceled, all GRFQ quotes are anonymous.
   */
  readonly maker?: string;
  readonly account: string;
}

export interface GrfqQuotes {
  asks: readonly GrfqQuote[];
  bids: readonly GrfqQuote[];
}

export interface GrfqTrade {
  readonly rfqType: 'grfq';
  readonly status: 'PENDING' | 'FILLED' | 'REJECTED';
  readonly id: number;
  readonly rfq_id: Grfq['id'];
  readonly quote_id: GrfqQuote['id'];
  readonly price: string;
  readonly quantity: string;
  readonly mark_price: string;
  readonly description: string;
  readonly quote_currency: string;
  readonly venue: GrfqVenue['code'];
  readonly product_codes: ProductCode[];
  readonly traded: Date;
  readonly instrument_kind: string;
  readonly rejected_reason?: string;
  readonly strategy_code: string;
  /** On the WS: Is only present if you're a participant of the trade  */
  readonly action?: Side;
  /** On the API: Only present if you're a participant of the trade  */
  readonly api_credential?: string;
  /** On the API/WS: Is only present if you're a participant of the trade  */
  readonly desk_role?: 'Maker' | 'Taker';
  readonly displayValues: TradeDisplayValues;
}

export interface GrfqTradeTape {
  readonly rfqType: 'grfq';
  readonly id: number;
  readonly rfq_id: Grfq['id'];
  readonly description: string;
  readonly venue: GrfqVenue['code'];
  readonly traded: Date;
  readonly price: string;
  readonly quantity: string;
  readonly strategy_code: string;
  readonly product_codes: ProductCode[];
  readonly mark_price: string;
  readonly quote_currency: string;
  readonly action: Side;
  readonly displayValues: TradeDisplayValues;
}

export interface GrfqTradeDetailsLeg extends GrfqLeg {
  readonly quantity: string;
  readonly exec_id: string;
  /** Only present if you're a participant of the trade  */
  readonly action?: Side;
}

export interface GrfqTradeDetails extends GrfqTrade {
  readonly api_credential: string;
  readonly legs: readonly GrfqTradeDetailsLeg[];
}

export interface GrfqBlotterBlock {
  /** Unix timestamp (ms) */
  readonly traded: number;
  readonly id: number;
  readonly description: string;
  readonly desk_role: string;
  readonly order_id: number;
  readonly price: string;
  readonly settlement_status: string;
  readonly strategy: string;
  readonly strategy_price: string;
  readonly legs: readonly GrfqBlotterLegs[];
  readonly venue: string;
}

export interface GrfqBlotterLegs {
  readonly id: number;
  readonly price: string;
  readonly side: string;
  readonly action: string;
  readonly ratio: string;
  readonly instrument: string;
  readonly quantity: string;
  readonly product_code: string;
  readonly exec_id: string;
  readonly index_price: string | null;
  readonly fee: string | null;
}

export function isGrfq(rfq: BaseRfq): rfq is Grfq {
  return rfq.type === 'grfq';
}

export function isOpenQuote(quote: GrfqQuote) {
  return quote.status === 'OPEN';
}

export function isMyQuote(quote: GrfqQuote) {
  return quote.maker != null;
}

export function isMyTrade(trade: GrfqTrade) {
  return trade.api_credential != null;
}
export function isFilledTrade(trade: GrfqTrade) {
  return trade.status === 'FILLED';
}

export function isQuoteNewer(current: GrfqQuote, next: GrfqQuote) {
  if (next.last_activity.getTime() > current.last_activity.getTime())
    return true;

  return (
    next.last_activity.getTime() === current.last_activity.getTime() &&
    Number.parseFloat(next.remaining_quantity) <=
      Number.parseFloat(current.remaining_quantity)
  );
}

export function isFutureInstrument(
  ins: GrfqInstrument,
): ins is GrfqFutureInstrument {
  return ins.kind === 'FUTURE';
}

export function isOptionInstrument(
  ins: GrfqInstrument,
): ins is GrfqOptionInstrument {
  return ins.kind === 'OPTION';
}

export function isTaker(rfq: Pick<GrfqTrade, 'desk_role'>) {
  return rfq.desk_role === 'Taker';
}

export function isMaker(rfq: Pick<GrfqTrade, 'desk_role'>) {
  return rfq.desk_role === 'Maker';
}
