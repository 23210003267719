import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsUserLoggingIn,
  getLoginError,
  loginFailure,
  loginRequest,
  loginSuccess,
  tokenStorage,
  twoFACodeRequired,
} from '@paradigm/store/auth';

import { useApi, Resp } from '@paradigm/api';
import { accountLogin, AccountLoginResp } from '@paradigm/api/auth';

import { USER_LOGIN } from '@paradigm/analytics/src/event-names';
import { identify, track } from '@paradigm/analytics';
import { logException } from '@paradigm/logging';
import { PWA_PROMPT_SOFT_KEY } from '@paradigm/utils/src/constants';
import localStorage from '@paradigm/utils/src/safeLocalStorage';

import rootPackageJson from '../../../package.json';
import { resetTabStorage } from './unified-rfqs/ui/bottom-tabs';

interface CredentialsLoginParams {
  readonly email: string;
  readonly password: string;
  readonly totp_token?: undefined;
  readonly onTwoFaCodeRequired: () => void;
}

interface TwoFaLoginParams {
  readonly email: string;
  readonly password: string;
  readonly totp_token: string;
  readonly onTwoFaCodeRequired?: undefined;
}

export function useLogin() {
  const dispatch = useDispatch();
  const [, exec] = useApi(accountLogin);

  // Legacy from global state, in the future this should be local state
  const error = useSelector(getLoginError);
  const isLoading = useSelector(getIsUserLoggingIn);

  const doLogin = useCallback(
    (params: CredentialsLoginParams | TwoFaLoginParams) => {
      const { email, password, totp_token, onTwoFaCodeRequired } = params;
      dispatch(loginRequest({ email, password, totp_token }));
      const apiCall = exec({
        body: {
          email,
          password,
          totp_token,
          app_version: rootPackageJson.version,
        },
      });

      // Login request timeout
      const timeoutHandle = setTimeout(() => {
        apiCall.abort();
      }, 60000);

      (async () => {
        const resp = await apiCall.promise;
        clearTimeout(timeoutHandle);
        const respAction = await getActionFromResponse(resp);
        localStorage.setItem(PWA_PROMPT_SOFT_KEY, 'false');

        if (respAction.type === loginSuccess.type) {
          resetTabStorage(respAction.payload.user.id);
          identify(respAction.payload.user, () => {
            track(USER_LOGIN);
          });
        } else if (respAction.type === twoFACodeRequired.type) {
          onTwoFaCodeRequired?.();
        }

        dispatch(respAction);
      })().catch((reason) => {
        logException('Unexpected error doing login:', reason);
      });
    },
    [dispatch, exec],
  );

  return { doLogin, error, isLoading };
}

/** Return the proper action to dispatch given the login response */
async function getActionFromResponse(resp: Resp<AccountLoginResp>) {
  let toDispatch;
  if (resp.ok) {
    await tokenStorage.setToken(resp.data.token);
    toDispatch = loginSuccess({ user: resp.data.user });
  } else {
    const firstNonFieldError = resp.data.non_field_errors?.[0];
    const emailFieldError = resp.data.email?.[0];
    if (firstNonFieldError === 'Two Factor Authentication code is required') {
      toDispatch = twoFACodeRequired();
    } else {
      let message;
      if (resp.error === 'AbortError' || resp.error === 'TypeError') {
        // AbortError is our timeout
        // TypeError is what fetch throws for networking issues
        message = 'Network error please check your Internet.';
      } else if (firstNonFieldError != null) {
        message = firstNonFieldError;
      } else if (emailFieldError != null) {
        message = emailFieldError;
      } else {
        message = `${resp.error}: ${resp.message}`;
      }
      toDispatch = loginFailure({ message });
    }
  }
  return toDispatch;
}
