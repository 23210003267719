/* eslint-disable @typescript-eslint/no-misused-promises */
import { Component, FormEvent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { UserDeactivateTFA, UserDeactivatePassword } from '@paradigm/api/auth';

import { getUserInfoRequestAction } from '@paradigm/store/auth';

import { renderInputField } from '#/utils/renderFormFields';

import { renderError } from '#/utils/renderErrors';
import { SettingsView } from '#/components/settings-modal/SettingsModal';
import { ErrorResp } from '#/components/user-preferences/components/two-factor-auth';

interface Props {
  setView: (view: SettingsView) => void;
  getUserUserInfo: (payload?: unknown) => unknown;
}

interface State {
  view: string;
  error: null | string;
}

class Deactivate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      view: 'password',
      error: null,
    };
  }
  handleSubmit(form: unknown) {
    UserDeactivatePassword(form)
      .then(() => {
        this.setState({
          view: 'code',
          error: null,
        });
      })
      .catch((res: ErrorResp) => {
        this.setState({
          error: res.response.data.non_field_errors,
        });
      });
  }
  handleSubmitCode(form: { totp_token: string }) {
    if (form.totp_token.length !== 6) {
      this.setState({
        error: 'Ensure this field has 6 characters.',
      });
    } else {
      UserDeactivateTFA(form)
        .then(() => {
          this.props.getUserUserInfo();
          setTimeout(() => {
            this.props.setView('Security');
          }, 1000);
        })
        .catch((res: ErrorResp) => {
          this.setState({
            error: res.response.data.non_field_errors,
          });
        });
    }
  }
  // eslint-disable-next-line class-methods-use-this
  focusOnClick(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.currentTarget.focus();
  }
  override render() {
    const { view, error } = this.state;
    if (view === 'password') {
      return (
        <div>
          <p className="smaller-font opacity-7">
            To deactivate two-factor authentication please enter your password.
          </p>
          <div className="divider-totp flex-shrink-0" />
          {renderError(error)}
          <Form
            onSubmit={(values) => this.handleSubmit(values)}
            render={({ handleSubmit, submitting, pristine }) => (
              // @ts-ignore
              <form onSubmit={handleSubmit}>
                <Field
                  onClick={this.focusOnClick}
                  component={renderInputField as unknown as 'input'}
                  type="password"
                  name="password"
                  containerClassName="form-group"
                  label="Password"
                  labelClassName="label-margin"
                  className="form-control form-input"
                  autoComplete="off"
                />
                <div className="d-flex justify-content-end mt-4">
                  <button
                    disabled={submitting || pristine}
                    type="submit"
                    className="btn btn-deactivate"
                  >
                    Deactivate
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      );
    }
    if (view === 'code') {
      return (
        <div>
          <p className="smaller-font opacity-7">
            To confirm deactivation please enter the code you received in the
            email.
          </p>
          <div className="divider-totp flex-shrink-0" />
          {renderError(error)}
          <Form
            onSubmit={(values: { totp_token: string }) =>
              this.handleSubmitCode(values)
            }
            render={({ handleSubmit, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  component={renderInputField as unknown as 'input'}
                  type="text"
                  name="totp_token"
                  containerClassName="form-group"
                  label="Code"
                  labelClassName="label-margin"
                  className="form-control form-input"
                  autoComplete="off"
                />
                <div className="d-flex justify-content-end mt-4">
                  <button
                    disabled={submitting || pristine}
                    type="submit"
                    className="btn btn-deactivate"
                  >
                    Deactivate
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = () => ({
  getUserUserInfo: PropTypes.func,
});

const mapActionsToProps = {
  getUserUserInfo: getUserInfoRequestAction,
};

const ConnectedDeactivate = connect(
  mapStateToProps,
  mapActionsToProps,
)(Deactivate);
export default ConnectedDeactivate;
