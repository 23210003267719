import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

export default function AnimatedStars(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <SvgContainer viewBox="0 0 51 52" {...props}>
      <linearGradient
        id="r4_00000089573410045876193720000016577632848450560907_"
        gradientUnits="userSpaceOnUse"
        x1="-237.9461"
        y1="397.0596"
        x2="-236.7633"
        y2="396.9409"
        gradientTransform="matrix(8 0 0 -8 1909 3182.6187)"
      >
        <stop offset="0" css="stop-color:#FF6769" />
        <stop offset="1" css="stop-color:#FF0035" />
      </linearGradient>
      <path
        id="r4"
        css="fill:url(#r4_00000089573410045876193720000016577632848450560907_);"
        d="M13,6.5c-2.2,0-4-1.8-4-4
	c0,2.2-1.8,4-4,4c2.2,0,4,1.8,4,4C9,8.3,10.8,6.5,13,6.5z"
      />
      <linearGradient
        id="r3_00000137128809200533203280000017367668268655799213_"
        gradientUnits="userSpaceOnUse"
        x1="-217.5461"
        y1="367.4346"
        x2="-216.3633"
        y2="367.3159"
        gradientTransform="matrix(5 0 0 -5 1128.7356 1865.4236)"
      >
        <stop offset="0" css="stop-color:#FF6769" />
        <stop offset="1" css="stop-color:#FF0035" />
      </linearGradient>
      <path
        id="r3"
        css="fill:url(#r3_00000137128809200533203280000017367668268655799213_);"
        d="M45.7,28.5c-1.4,0-2.5-1.1-2.5-2.5
	c0,1.4-1.1,2.5-2.5,2.5c1.4,0,2.5,1.1,2.5,2.5C43.2,29.6,44.4,28.5,45.7,28.5z"
      />
      <linearGradient
        id="r2_00000148632434618424866770000005777342116396088708_"
        gradientUnits="userSpaceOnUse"
        x1="-177.3976"
        y1="309.1308"
        x2="-176.2148"
        y2="309.012"
        gradientTransform="matrix(2.8768 0 0 -2.8768 556.2336 904.8375)"
      >
        <stop offset="0" css="stop-color:#FF6769" />
        <stop offset="1" css="stop-color:#FF0035" />
      </linearGradient>
      <path
        id="r2"
        css="fill:url(#r2_00000148632434618424866770000005777342116396088708_);"
        d="M48.6,15.6c-0.8,0-1.4-0.6-1.4-1.4
	c0,0.8-0.6,1.4-1.4,1.4c0.8,0,1.4,0.6,1.4,1.4C47.2,16.3,47.8,15.6,48.6,15.6z"
      />
      <linearGradient
        id="r1_00000124840897573490813730000000231445136214104982_"
        gradientUnits="userSpaceOnUse"
        x1="-177.3976"
        y1="309.1307"
        x2="-176.2148"
        y2="309.012"
        gradientTransform="matrix(2.8768 0 0 -2.8768 524.4669 904.1021)"
      >
        <stop offset="0" css="stop-color:#FF6769" />
        <stop offset="1" css="stop-color:#FF0035" />
      </linearGradient>
      <path
        id="r1"
        css="fill:url(#r1_00000124840897573490813730000000231445136214104982_);"
        d="M16.8,14.9c-0.8,0-1.4-0.6-1.4-1.4
	c0,0.8-0.6,1.4-1.4,1.4c0.8,0,1.4,0.6,1.4,1.4C15.4,15.6,16.1,14.9,16.8,14.9z"
      />
      <linearGradient
        id="g4_00000008871215861484662010000010816171046431963318_"
        gradientUnits="userSpaceOnUse"
        x1="-237.9461"
        y1="397.0596"
        x2="-236.7633"
        y2="396.9409"
        gradientTransform="matrix(8 0 0 -8 1943.8318 3180.1187)"
      >
        <stop offset="0" css="stop-color:#67FFF0" />
        <stop offset="0.9883" css="stop-color:#00FF65" />
      </linearGradient>
      <path
        id="g4"
        css="fill:url(#g4_00000008871215861484662010000010816171046431963318_);"
        d="M47.8,4c-2.2,0-4-1.8-4-4
	c0,2.2-1.8,4-4,4c2.2,0,4,1.8,4,4C43.8,5.8,45.6,4,47.8,4z"
      />
      <linearGradient
        id="g3_00000044875772484753881670000014750136779408133052_"
        gradientUnits="userSpaceOnUse"
        x1="-217.5461"
        y1="367.4346"
        x2="-216.3633"
        y2="367.3159"
        gradientTransform="matrix(5 0 0 -5 1114 1878.2485)"
      >
        <stop offset="0" css="stop-color:#67FFF0" />
        <stop offset="0.9883" css="stop-color:#00FF65" />
      </linearGradient>
      <path
        id="g3"
        css="fill:url(#g3_00000044875772484753881670000014750136779408133052_);"
        d="M31,41.3c-1.4,0-2.5-1.1-2.5-2.5
	c0,1.4-1.1,2.5-2.5,2.5c1.4,0,2.5,1.1,2.5,2.5C28.5,42.4,29.6,41.3,31,41.3z"
      />
      <linearGradient
        id="g2_00000153685223057344848410000003227327534282940545_"
        gradientUnits="userSpaceOnUse"
        x1="-217.5461"
        y1="367.4346"
        x2="-216.3772"
        y2="367.3173"
        gradientTransform="matrix(5 0 0 -5 1109.3324 1840.9492)"
      >
        <stop offset="0" css="stop-color:#67FFF0" />
        <stop offset="1" css="stop-color:#00FF65" />
      </linearGradient>
      <path
        id="g2"
        css="fill:url(#g2_00000153685223057344848410000003227327534282940545_);"
        d="M26.3,4c-1.4,0-2.5-1.1-2.5-2.5
	c0,1.4-1.1,2.5-2.5,2.5c1.4,0,2.5,1.1,2.5,2.5C23.8,5.1,25,4,26.3,4z"
      />
      <linearGradient
        id="g1_00000121264279580058315220000012607193614196102076_"
        gradientUnits="userSpaceOnUse"
        x1="-177.3976"
        y1="309.1308"
        x2="-176.2148"
        y2="309.012"
        gradientTransform="matrix(2.8768 0 0 -2.8768 511.0436 933.3643)"
      >
        <stop offset="0" css="stop-color:#67FFF0" />
        <stop offset="0.9883" css="stop-color:#00FF65" />
      </linearGradient>
      <path
        id="g1"
        css="fill:url(#g1_00000121264279580058315220000012607193614196102076_);"
        d="M3.4,44.2c-0.8,0-1.4-0.6-1.4-1.4
	c0,0.8-0.6,1.4-1.4,1.4c0.8,0,1.4,0.6,1.4,1.4C2,44.8,2.6,44.2,3.4,44.2z"
      />
      <linearGradient
        id="p4_00000163782488009730457900000014987994340029173141_"
        gradientUnits="userSpaceOnUse"
        x1="-237.9461"
        y1="397.0596"
        x2="-236.7653"
        y2="396.9411"
        gradientTransform="matrix(8 0 0 -8 1927 3195.1187)"
      >
        <stop offset="0" css="stop-color:#FE67FF" />
        <stop offset="1" css="stop-color:#FF00F9" />
      </linearGradient>
      <path
        id="p4"
        css="fill:url(#p4_00000163782488009730457900000014987994340029173141_);"
        d="M31,19c-2.2,0-4-1.8-4-4
	c0,2.2-1.8,4-4,4c2.2,0,4,1.8,4,4C27,20.8,28.8,19,31,19z"
      />
      <linearGradient
        id="p3_00000095335028040664292560000007862562868313458050_"
        gradientUnits="userSpaceOnUse"
        x1="-217.5461"
        y1="367.4346"
        x2="-216.3633"
        y2="367.3159"
        gradientTransform="matrix(5 0 0 -5 1133.3318 1885.9492)"
      >
        <stop offset="0" css="stop-color:#FE67FF" />
        <stop offset="0.9983" css="stop-color:#FF00F9" />
      </linearGradient>
      <path
        id="p3"
        css="fill:url(#p3_00000095335028040664292560000007862562868313458050_);"
        d="M50.3,49c-1.4,0-2.5-1.1-2.5-2.5
	c0,1.4-1.1,2.5-2.5,2.5c1.4,0,2.5,1.1,2.5,2.5C47.8,50.1,49,49,50.3,49z"
      />
      <linearGradient
        id="p2_00000145047096975289113140000004559274301485068424_"
        gradientUnits="userSpaceOnUse"
        x1="-237.9461"
        y1="397.0596"
        x2="-236.7633"
        y2="396.9409"
        gradientTransform="matrix(8 0 0 -8 1914.1682 3222.6187)"
      >
        <stop offset="0" css="stop-color:#FE67FF" />
        <stop offset="0.9983" css="stop-color:#FF00F9" />
      </linearGradient>
      <path
        id="p2"
        css="fill:url(#p2_00000145047096975289113140000004559274301485068424_);"
        d="M18.2,46.5c-2.2,0-4-1.8-4-4
	c0,2.2-1.8,4-4,4c2.2,0,4,1.8,4,4C14.2,48.3,16,46.5,18.2,46.5z"
      />
      <linearGradient
        id="p1_00000163054907856918755650000000943631359325953707_"
        gradientUnits="userSpaceOnUse"
        x1="-177.3976"
        y1="309.1308"
        x2="-176.2148"
        y2="309.012"
        gradientTransform="matrix(2.8768 0 0 -2.8768 511.8546 906.9794)"
      >
        <stop offset="0" css="stop-color:#FE67FF" />
        <stop offset="0.9983" css="stop-color:#FF00F9" />
      </linearGradient>
      <path
        id="p1"
        css="fill:url(#p1_00000163054907856918755650000000943631359325953707_);"
        d="M4.2,17.8c-0.8,0-1.4-0.6-1.4-1.4
	c0,0.8-0.6,1.4-1.4,1.4c0.8,0,1.4,0.6,1.4,1.4C2.8,18.4,3.4,17.8,4.2,17.8z"
      />
      <linearGradient
        id="y3_00000022539756958284764000000001577372341306165936_"
        gradientUnits="userSpaceOnUse"
        x1="-217.5461"
        y1="367.4346"
        x2="-216.3633"
        y2="367.3159"
        gradientTransform="matrix(5 0 0 -5 1093 1867.9236)"
      >
        <stop offset="0" css="stop-color:#FCFF67" />
        <stop offset="0.9785" css="stop-color:#FF8900" />
      </linearGradient>
      <path
        id="y3"
        css="fill:url(#y3_00000022539756958284764000000001577372341306165936_);"
        d="M10,31c-1.4,0-2.5-1.1-2.5-2.5
	C7.5,29.9,6.4,31,5,31c1.4,0,2.5,1.1,2.5,2.5C7.5,32.1,8.6,31,10,31z"
      />
      <linearGradient
        id="y2_00000146492513445685622400000017339379266366393993_"
        gradientUnits="userSpaceOnUse"
        x1="-177.3976"
        y1="309.1308"
        x2="-176.2402"
        y2="309.0146"
        gradientTransform="matrix(2.8768 0 0 -2.8768 552.2952 930.4875)"
      >
        <stop offset="0" css="stop-color:#FCFF67" />
        <stop offset="1" css="stop-color:#FF8900" />
      </linearGradient>
      <path
        id="y2"
        css="fill:url(#y2_00000146492513445685622400000017339379266366393993_);"
        d="M44.7,41.3c-0.8,0-1.4-0.6-1.4-1.4
	c0,0.8-0.6,1.4-1.4,1.4c0.8,0,1.4,0.6,1.4,1.4C43.2,41.9,43.9,41.3,44.7,41.3z"
      />
      <linearGradient
        id="y1_00000170257871306732818620000011839358812886082747_"
        gradientUnits="userSpaceOnUse"
        x1="-177.3976"
        y1="309.1308"
        x2="-176.2148"
        y2="309.012"
        gradientTransform="matrix(2.8768 0 0 -2.8768 543.4003 898.2497)"
      >
        <stop offset="0" css="stop-color:#FCFF67" />
        <stop offset="0.9785" css="stop-color:#FF8900" />
      </linearGradient>
      <path
        id="y1"
        css="fill:url(#y1_00000170257871306732818620000011839358812886082747_);"
        d="M35.8,9.1c-0.8,0-1.4-0.6-1.4-1.4
	c0,0.8-0.6,1.4-1.4,1.4c0.8,0,1.4,0.6,1.4,1.4C34.3,9.7,35,9.1,35.8,9.1z"
      />
    </SvgContainer>
  );
}

const SvgContainer = styled.svg`
  cursor: pointer;
  path {
    opacity: 0;
  }

  &:hover path {
    opacity: 0;
    animation: fadeIn 2s ease-in-out infinite;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  path#r1 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#r2 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#r3 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#r4 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p1 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p2 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p3 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p4 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g1 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g2 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g3 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g4 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y1 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y2 {
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y3 {
    animation-delay: ${getRandomDelay()}ms;
  }
`;
function getRandomDelay() {
  return Math.floor(Math.random() * 2000);
}
