import CloseIcon from '@paradigm/design-system/src/assets/CloseIcon';
import {
  Button,
  Container,
  Row,
  Header,
  Text,
  Title,
} from '#/components/pwa/shared';

interface Props {
  handleOnInstall: () => void;
  onDismiss: () => void;
  onSoftDismiss: () => void;
}

const ChromiumCustomInstallerPrompt = ({
  handleOnInstall,
  onSoftDismiss,
  onDismiss,
}: Props) => {
  return (
    <Container>
      <Header css="margin-bottom: 6px;">
        <Title>Install Paradigm</Title>
        <CloseIcon width={14} height={14} onClick={onSoftDismiss} />
      </Header>
      <Text css="margin-bottom: 16px;">Use Paradigm on your device</Text>
      <Row>
        <Button onClick={handleOnInstall} css="margin-right: 14px;" primary>
          Install
        </Button>
        <Button onClick={onDismiss} outlined>
          Don&apos;t Show
        </Button>
      </Row>
    </Container>
  );
};

export default ChromiumCustomInstallerPrompt;
