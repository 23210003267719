export const MMP_NOTIF_KINDS = ['TRIGGERED'] as const;

export type MmpNotificationKind = typeof MMP_NOTIF_KINDS[number];

export interface MmpNotificationData {
  readonly kind: MmpNotificationKind;
}

export const MMP_CHANNEL_TOPIC_ID = 'market_maker_protection';

export interface MmpNotification {
  readonly channel: typeof MMP_CHANNEL_TOPIC_ID;
  readonly data: MmpNotificationData;
}
