import { ComponentPropsWithoutRef } from 'react';

export default function StackIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="default-accounts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.699999988"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="default-accounts-/-1"
          transform="translate(-793, -345)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <g id="modal" transform="translate(755, 100)">
            <g id="Group-31" transform="translate(39.2722, 243.1077)">
              <g id="default-accounts" transform="translate(0, 3.0463)">
                <polygon
                  id="Vector-21"
                  points="8.30762111 6.64609689 0 3.32304844 8.30762111 0 16.6152422 3.32304844"
                />
                <path
                  d="M0,13.2921938 L8.30762111,16.6152422 L16.6152422,13.2921938 M0,8.30762111 L8.30762111,11.6306696 L16.6152422,8.30762111"
                  id="Vector-22"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
