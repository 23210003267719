import { useState } from 'react';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '#/containers/layout/constants';
import { AgreementDocument } from './types';

import AgreementBody from './AgreementBody';

interface AgreementProps {
  readonly reached?: boolean;
  readonly onAccept: () => void;
  readonly agreement: AgreementDocument;
}

export default function Agreement(props: AgreementProps) {
  const { reached = false, onAccept, agreement } = props;
  const [reachedBottom, setReachedBottom] = useState(reached);

  const firstContentId = agreement.content[0]?.id ?? '';
  const [activeAgreement, setActiveAgreement] = useState(firstContentId);
  const handleVisibilityChange = (scrolledBottom: boolean) => {
    if (!reachedBottom && scrolledBottom) {
      setReachedBottom(true);
    }
  };

  return (
    <>
      <Body>
        <SideNav>
          {agreement.content.map(({ id, label }) => (
            <a
              key={id}
              className={activeAgreement === id ? 'active' : ''}
              href={`#${id}`}
            >
              {label}
            </a>
          ))}
        </SideNav>
        <Divider />
        <AgreementBody
          setActiveAgreement={setActiveAgreement}
          handleVisibilityChange={handleVisibilityChange}
          agreement={agreement}
        />
      </Body>

      <Footer>
        <WebAgreementLink
          href={agreement.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          Open in website
        </WebAgreementLink>
        <button
          type="submit"
          disabled={!reachedBottom}
          className="btn primary-button accept-button w-auto"
          onClick={onAccept}
        >
          Accept and Get Started
        </button>
      </Footer>
    </>
  );
}

export const Body = styled.section`
  display: grid;
  grid-template-columns: 176px 2px 1fr;
  margin: 26px 0 0 0;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

export const SideNav = styled.nav`
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 12px 0 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
  a {
    display: block;
    color: white;
    opacity: 0.7;
    text-decoration: none;
    font-weight: 300;

    &.active {
      opacity: 1;
      font-weight: 400;
    }

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const Divider = styled.div`
  background-color: #1f2a37;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const WebAgreementLink = styled.a`
  font-size: 1rem;
  color: #0e69ee;
  margin-right: 16px;
`;
