import { useCallback } from 'react';
import {
  VENUES,
  VenueCode,
  isUnsetAccount,
} from '#/preferences/default-accounts/types';
import usePersistedAccounts from '#/preferences/default-accounts/usePersistedAccounts';
import { useAccounts } from '#/unified-rfqs/contexts/accounts';

export default function useSelectDefaultEmptyAccount() {
  const accountsView = useAccounts();
  const persistedAccountsStore = usePersistedAccounts();

  const selectEmptyDefaultAccountsByVenue = useCallback(
    (venue: VenueCode) => {
      if (!isUnsetAccount(persistedAccountsStore.get()[venue].all)) return;
      const accounts = accountsView.selectByVenue(venue);
      if (accounts.length !== 1) return;

      const account = accounts[0];
      if (account == null) return;
      const accountName = account.name;

      persistedAccountsStore.setVenueDefault(venue, accountName);
    },
    [accountsView, persistedAccountsStore],
  );

  return useCallback(() => {
    VENUES.forEach(selectEmptyDefaultAccountsByVenue);
  }, [selectEmptyDefaultAccountsByVenue]);
}
