import { addValidator } from 'redux-form-validators';

export const notEquals = addValidator({
  validator: (options, value, allValues) => {
    const { formName, field, fieldLabel } = options;
    let fieldValue =
      typeof allValues.getIn === 'function'
        ? allValues.getIn(field.split('.'))
        : allValues[field];
    fieldValue = `${fieldValue || ''}`;
    if (value === fieldValue) {
      return {
        id: `form.errors.${formName}`,
        defaultMessage: `Can't be the same as ${fieldLabel}`,
        values: { fieldLabel },
      };
    }
    return {};
  },
});

export const PASSWORD_VALIDATION_HINT =
  'Your password must be at least 10 characters and not resemble your name or email. It cannot be a common password or entirely numeric. Aim for uniqueness and strength.';
