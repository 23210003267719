/**
 * Segment Analytics Tracking
 * Following naming convention in the article published by Segment Analytics Academy.
 * https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 * We Will Stick to double underscores for event names like 'object__action' for example:
 * 'user__login', 'quote__create', 'websocket__disconnect', etc.
 * We will use snake case for properties like:
 * {
 *   user_name: 'John Doe',
 *   trading_desk: 'TIME0',
 * }
 *
 * Code Example:
 *
 * import { track } from '@paradigm/analytics';
 * import { MDRFQ_CREATED } from '@paradigm/analytics/event_names';
 * .....
 * track(MDRFQ_CREATED, {
 *   mdrfq_id: mdrfq.id,
 *   trading_desk: mdrfq?.user?.trading_desk?.ticker,
 *   .....
 * })
 */

export const API_ERROR = 'error__hit';

export const USER_LOGIN = 'user__login';
export const USER_LOGOUT = 'user__logout';

export const WEBSOCKET_CONNECT = 'websocket__connect';
export const WEBSOCKET_FAILURE = 'websocket__failure';
export const WEBSOCKET_DISCONNECT = 'websocket__disconnect';
export const WEBSOCKET_RFQ_MESSAGE = 'websocket__rfq_message';

export const INTERNET_CONNECT = 'internet__connect';
export const INTERNET_DISCONNECT = 'internet__disconnect';

export const MDRFQ_RFQ_SUBMIT = 'rfq__submit';
export const MDRFQ_QUOTE_SUBMIT = 'quote__submit';

export const MDRFQ_QUOTE_EXECUTE = 'quote__execute';

export const MDRFQ_RFQ_CANCEL = 'rfq__cancel';
export const MDRFQ_QUOTE_CANCEL = 'quote__cancel';

export const CHAT_PAGE = 'chat__page';
export const MDRFQ_PAGE = 'mdrfq__page';
export const TRADE_BLOTTER_PAGE = 'trade_blotter__page';
export const GRFQS_PAGE = 'grfqs__page';
export const UNIFIED_RFQS_PAGE = 'unified_rfqs__page';
export const LEADERBOARD_PAGE = 'leaderboard__page';
export const VRFQS_PAGE = 'vrfqs__page';
export const FS_PAGE = 'fs__page';
export const PARADEX_PAGE = 'paradex__page';

const ONE_CLICK_TRADING_PREFIX = 'grfqs__one_click_trading';
export const ONE_CLICK_TRADING = {
  ENABLED: `${ONE_CLICK_TRADING_PREFIX}__enabled`,
  DISABLED: `${ONE_CLICK_TRADING_PREFIX}__disabled`,
  NONACTIVE_CLICK: `${ONE_CLICK_TRADING_PREFIX}__nonactive_error`,
  QUOTE_SENT_SUCCESS: `${ONE_CLICK_TRADING_PREFIX}__quote_sent_success`,
  QUOTE_SENT_ERROR: `${ONE_CLICK_TRADING_PREFIX}__quote_sent_error`,
};

export const ORDER_CREATE_REQUEST = 'order__create_request';
export const ORDER_CREATE_SUCCESS = 'order__create_success';
export const ORDER_CREATE_ERROR = 'order__create_error';

const FS_ONE_CLICK_ORDER = 'fs_one_click_order';
export const FS_ONE_CLICK = {
  CREATE_REQUEST: `${FS_ONE_CLICK_ORDER}__create_request`,
  CREATE_SUCCESS: `${FS_ONE_CLICK_ORDER}__create_success`,
  CREATE_ERROR: `${FS_ONE_CLICK_ORDER}__create_error`,
};

export const ORDER_UPDATE_REQUEST = 'order__update_request';
export const ORDER_UPDATE_SUCCESS = 'order__update_success';
export const ORDER_UPDATE_ERROR = 'order__update_error';

export const ORDER_KILL_REQUEST = 'order__kill_request';
export const ORDER_KILL_SUCCESS = 'order__kill_success';
export const ORDER_KILL_ERROR = 'order__kill_error';

const ORDERS_KILL_PREFIX = 'orders__kill';
export const ORDERS_KILL = {
  ASKS: {
    REQUEST: `${ORDERS_KILL_PREFIX}__asks__request`,
    SUCCESS: `${ORDERS_KILL_PREFIX}__asks__success`,
    ERROR: `${ORDERS_KILL_PREFIX}__asks__error`,
  },
  BIDS: {
    REQUEST: `${ORDERS_KILL_PREFIX}__bids__request`,
    SUCCESS: `${ORDERS_KILL_PREFIX}__bids__success`,
    ERROR: `${ORDERS_KILL_PREFIX}__bids__error`,
  },
  ALL: {
    REQUEST: `${ORDERS_KILL_PREFIX}__all__request`,
    SUCCESS: `${ORDERS_KILL_PREFIX}__all__success`,
    ERROR: `${ORDERS_KILL_PREFIX}__all__error`,
  },
};

export const EXECUTE_ORDER_MODAL_OPEN = 'execute_order_modal__open';
export const EDIT_ORDER_MODAL_OPEN = 'edit_order_modal__open';

export const OPEN_ORDERS_UPDATE = 'open_orders__update';
export const ORDER_HISTORY_UPDATE = 'order_history__update';
export const TRADE_TAPE_UPDATE = 'trade_tape__update';
export const TRADE_HISTORY_UPDATE = 'trade_history__update';
export const STRATEGIES_UPDATE = 'strategies_update';

export const TRADE_FILLED = 'trade__notify_filled';
export const TRADE_FAILED = 'trade__notify_failed';
export const ORDER_FAILED = 'order__notify_failed';

export const STRATEGY_FILTER_UPDATE = 'strategy_filters__update';

export const OPEN_ORDERS_TAB_SELECT = 'open_orders_tab__select';
export const ORDER_HISTORY_TAB_SELECT = 'order_history_tab__select';
export const TRADE_TAPE_TAB_SELECT = 'trade_tape_tab__select';
export const TRADE_HISTORY_TAB_SELECT = 'trade_history_tab__select';

export const UM_GRFQ_LIST_RFQS = 'um_grfq_rfq__list';
