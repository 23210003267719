import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { userSetNewPasswordApi } from '@paradigm/api/auth';
import { errorAnnouncement } from '@paradigm/store/error';

import { ROOT_ROUTE } from '#/routes';

import SetPasswordForm from './SetPasswordForm';
import SetPasswordSuccess from './SetPasswordSuccess';

type Step = 'form' | 'success';

interface PasswordValues {
  readonly new_password1: string;
  readonly new_password2: string;
}

export default function SetPassword() {
  const [step, setStep] = useState<Step>('form');
  const { search } = useLocation();
  const { uid, token } = parseSearch(search);
  const dispatch = useDispatch();

  const handleSubmit = (passwordValues: PasswordValues) => {
    userSetNewPasswordApi({ uid, token, ...passwordValues })
      .then(() => {
        setStep('success');
      })
      .catch(() => {
        dispatch(
          errorAnnouncement('Password reset failed, try again later...'),
        );
      });
  };

  if (uid.length === 0 || token.length === 0) {
    return <Redirect to={ROOT_ROUTE} />;
  }

  switch (step) {
    case 'form':
      return (
        <div className="layout__container">
          <h5 className="text-center mb-5">Change your password!</h5>
          <SetPasswordForm onPasswordReset={handleSubmit} />
        </div>
      );
    case 'success':
      return <SetPasswordSuccess />;
    // no default
  }
}

function parseSearch(search: string) {
  const searchParams = new URLSearchParams(search);
  return {
    uid: searchParams.get('uid') ?? '',
    token: searchParams.get('token') ?? '',
  };
}
