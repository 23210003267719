import { log } from '@paradigm/logging/src/logging';

import {
  DrfqNotification,
  RawDrfqNotification,
  ProcessorsByTopic,
  getChannelTopic,
} from '#/unified-rfqs/repositories/drfq-ws-types';

export function createDrfqNotificationProcessor(processors: ProcessorsByTopic) {
  function processNotification(raw: RawDrfqNotification): DrfqNotification {
    const topic = getChannelTopic(raw.params.channel);
    const process = processors[topic] as (raw: unknown) => unknown;

    if (topic === 'unknown') {
      log('Received unknown drfq ws notification.', {
        data: raw,
        level: 'warning',
      });
    }

    return {
      ...raw.params,
      topic,
      data: process(raw.params.data),
    } as DrfqNotification;
  }

  return processNotification;
}
