import { logException } from '@paradigm/logging/src/logging';

export default function isAuthInvalidMessage(
  message: string | unknown,
): boolean {
  if (typeof message !== 'string') {
    const reason = `isNotAuthenticatedMessage: expected message='${String(
      message,
    )}' to be string`;
    logException(reason, new Error(reason));
    return false;
  }

  const lowercaseMsg = message.toLowerCase();
  return (
    lowercaseMsg.includes('invalid token') ||
    lowercaseMsg.includes('credentials were not provided')
  );
}
