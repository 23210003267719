export const MOBILE_BREAKPOINT = 900;

export const FONT_FAMILY = {
  sans: '"IBM Plex Sans", sans-serif',
};

export const FONT_WEIGHT = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const FONT_STYLES = {
  xxsmall: {
    fontSize: '0.625rem',
    fontSizePx: '10px',
    lineHeight: '1rem',
  },
  xsmall: {
    fontSize: '0.6875rem',
    fontSizePx: '11px',
    lineHeight: '1rem',
  },
  small: {
    fontSize: '0.75rem',
    fontSizePx: '12px',
    lineHeight: '1rem',
  },
  medium: {
    fontSize: '0.875rem',
    fontSizePx: '14px',
    lineHeight: '1.25rem',
  },
  large: {
    fontSize: '1rem',
    fontSizePx: '16px',
    lineHeight: '1.5rem',
  },
  xlarge: {
    fontSize: '1.125rem',
    fontSizePx: '18px',
    lineHeight: '1.75rem',
  },
  xxlarge: {
    fontSize: '1.25rem',
    fontSizePx: '20px',
    lineHeight: '1.75rem',
  },
};
