import { ComponentPropsWithoutRef } from 'react';

const DashboardIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="23px" height="19px" viewBox="0 0 23 19" {...props}>
    <g
      transform="translate(-556 -595) translate(520 184) translate(37.99 412.254)"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    >
      <path
        strokeLinecap="round"
        d="M6.75015599e-14 11.1265678L19.3876643 11.1265678 19.3876643 4.35207426e-14 6.75015599e-14 4.35207426e-14z"
      />
      <path
        strokeLinecap="round"
        d="M6.90952612 16.1372442L12.4781381 16.1372442 12.4781381 14.5569192 6.90952612 14.5569192z"
      />
      <path
        strokeLinecap="round"
        d="M8.82481323 12.707248L10.5624958 12.707248 10.5624958 11.126923 8.82481323 11.126923z"
      />
      <path d="M4.38380782 4.05106885L9.69383213 4.05106885" />
      <path d="M4.38380782 7.06519581L12.4781381 7.06519581" />
    </g>
  </svg>
);

export default DashboardIcon;
