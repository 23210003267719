import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { debounce, noop } from 'lodash';
import { trackAction } from '@paradigm/logging';
import { Action, ActionParams, TrackingContext } from './types';

interface TrackMethods {
  readonly track: <A extends Action>(
    action: A,
    params?: Partial<ActionParams[A]>,
  ) => void;
  readonly debouncedTrack: <A extends Action>(
    action: A,
    params?: Partial<ActionParams[A]>,
  ) => void;
}

const Ctx = createContext<TrackingContext>('um app');
Ctx.displayName = 'TrackingContext';

const MethodsCtx = createContext<TrackMethods>({
  track: noop,
  debouncedTrack: noop,
});
MethodsCtx.displayName = 'TrackMethods';

export const useTrackMethods = () => useContext(MethodsCtx);

export function TrackingCtxProvider({
  value,
  children,
}: PropsWithChildren<{ value: TrackingContext }>) {
  const track = useCallback(
    <A extends Action>(action: A, params?: Partial<ActionParams[A]>) =>
      trackAction(action, { context: value, ...params }),
    [value],
  );

  const debouncedTrack = debounce(track, 500) as typeof track;

  return (
    <Ctx.Provider value={value}>
      <MethodsCtx.Provider
        value={{
          track,
          debouncedTrack,
        }}
      >
        {children}
      </MethodsCtx.Provider>
    </Ctx.Provider>
  );
}

export function useTrackAction() {
  const { track } = useTrackMethods();
  return track;
}

export function useDebouncedTrackAction() {
  const { debouncedTrack } = useTrackMethods();
  return debouncedTrack;
}

export function useTrackedCallback<
  A extends Action,
  C extends (...args: any[]) => void,
>(
  action: A,
  callback: C,
  params?: Partial<ActionParams[A]>,
  isDebounced = false,
) {
  const { debouncedTrack, track } = useTrackMethods();
  const trackFn = isDebounced ? debouncedTrack : track;
  return useCallback(
    (...args: Parameters<C>) => {
      trackFn(action, params);
      callback(...args);
    },
    [trackFn, action, params, callback],
  );
}
