import { spawn, StrictEffect } from 'redux-saga/effects';

import { sagas as authSagas } from '@paradigm/store/auth';

import app from './app';
import forms from './forms';

import electron from './electron';
import registration from './registration';

const sagas: ReadonlyArray<() => Generator<StrictEffect>> = [
  ...app,
  authSagas.changePasswordListener,
  authSagas.getUserInfoListener,
  authSagas.logoutRequestListener,
  authSagas.authenticationRequestListener,
  authSagas.tokenExpirationListener,
  ...forms,
  ...electron,
  ...registration,
];

export default function* rootSaga() {
  for (const saga of sagas) {
    yield spawn(saga);
  }
}
