import { isNil } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '#/containers/layout/constants';

import UnstyledButton from '#/components/buttons/UnstyledButton';
import Agreement from './Agreement';
import CustomerTypeSelection from './CustomerTypeSelection';
import { AgreementDocument } from './types';
import {
  agreementFromCountryCode,
  agreementFromCustomerTypeQueryParam,
} from './getAgreement';

interface Props {
  readonly onAccept: (agreement: AgreementDocument['kind']) => void;
  readonly reached?: boolean;
  readonly selectedCountry?: string;
  readonly onBack?: () => void;
}

const AcceptAgreement = (props: Props) => {
  const { onAccept, reached, onBack, selectedCountry } = props;

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const customerQueryParam = params.get('customer') ?? '';

  const agreement = isNil(selectedCountry)
    ? agreementFromCustomerTypeQueryParam(customerQueryParam)
    : agreementFromCountryCode(selectedCountry);

  const isSelectingCustomerType = agreement == null;

  return (
    <Container className="accept-agreement" data-testid="agreement">
      <Header>
        {isSelectingCustomerType ? null : (
          <UnstyledButton
            onClick={() => {
              if (onBack) {
                onBack();
              } else {
                history.push({ search: '', hash: '' });
              }
            }}
            data-testid="back"
            title="go back"
          >
            <BackIcon />
          </UnstyledButton>
        )}
        <HeaderHeading>Agreement</HeaderHeading>
      </Header>
      {isSelectingCustomerType ? (
        <CustomerTypeSelection />
      ) : (
        <Agreement
          onAccept={() => {
            onAccept(agreement.kind);
          }}
          reached={reached}
          agreement={agreement}
        />
      )}
    </Container>
  );
};

export default AcceptAgreement;

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  padding: 32px 32px 24px;
  background: #091522;
  box-shadow: 0 7px 20px 0 rgb(0 0 0 / 37%);
  border-radius: 7px;
  width: 862px;
  max-height: 682px;
  min-height: 480px;
  overflow: hidden;
  margin: 0 0 0.75rem;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: auto;
    min-height: 750px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
`;

const HeaderHeading = styled.h1`
  font-size: 20px;
  line-height: 24px;
  margin: 0;
`;

const BackIcon = styled.i.attrs({
  className: 'material-icons',
  children: 'arrow_back',
})`
  display: block;
  font-size: 18px;
  font-weight: 700;
`;
