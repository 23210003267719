import { createAction } from '@reduxjs/toolkit';

import * as types from './types';

type AuthUser = types.AuthUser;

export const showAgreementOverlayAction = () => ({
  type: types.SHOW_AGREEMENT_OVERLAY,
});

export const acceptedAgreement = () => ({
  type: types.ACCEPTED_AGREEMENT,
});

export const getUserInfoRequestAction = (payload: unknown) => ({
  type: types.GET_USER_INFO_REQUEST,
  payload,
});

export const updateUserRequest = (userForm: Record<string, unknown>) => ({
  type: types.UPDATE_USER_REQUEST,
  userForm,
});

export const changeUserPasswordRequest = (
  passwordForm: Record<string, unknown>,
) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  passwordForm,
});

export const updateUserNotificationAction = (payload: unknown) => ({
  type: types.CHANGE_NOTIFICATIONS_SUCCESS,
  payload,
});

export const updateUserPermissionsAction = (payload: unknown) => ({
  type: types.UPDATE_USER_PERMISSIONS,
  payload,
});

export const toggleIpWarningAction = () => ({
  type: types.TOGGLE_IP_WARNING,
});

export const authenticationRequest = createAction(
  'auth/authenticationRequest',
  ({ user, token }: { user: AuthUser; token: string }) => ({
    payload: { user, token },
  }),
);

export const loginRequest = createAction(
  'auth/loginRequest',
  (loginForm: { email: string; password: string; totp_token?: string }) => ({
    payload: loginForm,
  }),
);
export const loginSuccess = createAction(
  'auth/loginSuccess',
  ({ user }: { user: AuthUser }) => ({
    payload: { user },
  }),
);
export const loginFailure = createAction(
  'auth/loginFailure',
  ({ message }: { message: string }) => ({
    payload: { message },
  }),
);

export interface LogoutRequestPayload {
  /**
   * Reason for the logout. May be used to show the user a message
   * explaining why he is seeing the login screen.
   */
  readonly reason: 'user-triggered' | 'token-expired';
}
export const logoutRequest =
  createAction<LogoutRequestPayload>('auth/logoutRequest');
export const logoutSuccess = createAction('auth/logoutSuccess');

export const sessionReuseAttemptStart = createAction(
  'auth/sessionReuseAttemptStart',
);
export const sessionReuseAttemptSuccess = createAction(
  'auth/sessionReuseAttemptSuccess',
);
export const sessionReuseAttemptFailure = createAction(
  'auth/sessionReuseAttemptFailure',
);

export const twoFACodeRequired = createAction('auth/twoFACodeRequired');

export const getUserInfoSuccess = createAction(
  'auth/getUserInfoSuccess',
  (data: unknown) => ({ payload: data }),
);
