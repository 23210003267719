import BigNumber from 'bignumber.js';
import { Maybe } from '@paradigm/utils/types';
import {
  isValidDecimal,
  parseFormattedFloat,
} from '@paradigm/utils/formatDecimal';
import FixedDecimalNumber from '@paradigm/utils/src/FixedDecimalNumber';
import { NEGATIVE_SIGN } from '@paradigm/utils/src/enhanceNegativeSign';
import { Side, TradeDisplayValues } from '../entities/shared';
import { getFixedDecimalPriceWithCustomRules } from './rfq-price-functions';

function getPriceBigNumberFromPriceString(
  price: Maybe<string>,
): BigNumber | undefined {
  if (price == null || !isValidDecimal(price)) return undefined;
  return BigNumber(parseFormattedFloat(price).toString());
}

/**
 * Returns a formatted string representing the spread from Mark price.
 * @param fixedDecimalTradePrice - The fixed decimal trade price.
 * @param fixedDecimalMarkPrice - The fixed decimal mark price.
 * @example +0.00010, –0.00100
 */
function getMarkPriceFormattedString(
  fixedDecimalTradePrice: FixedDecimalNumber,
  fixedDecimalMarkPrice: FixedDecimalNumber,
): string {
  const fixedDecimalMarkOffset = new FixedDecimalNumber(
    fixedDecimalTradePrice.round().minus(fixedDecimalMarkPrice.round()),
    fixedDecimalTradePrice.decimalPlaces,
  );
  const markOffsetPrefix = fixedDecimalMarkOffset.value.isGreaterThan(0)
    ? '+'
    : '';
  return `${markOffsetPrefix}${fixedDecimalMarkOffset.format()}`;
}

export function processDisplayValues(
  priceString: string,
  markPriceString: string,
  makerSide: Side | undefined,
  description: string,
  productCode: string | undefined,
): TradeDisplayValues {
  const price = getPriceBigNumberFromPriceString(priceString);
  const markPrice = getPriceBigNumberFromPriceString(markPriceString);
  if (
    price === undefined ||
    makerSide === undefined ||
    markPrice === undefined ||
    productCode === undefined
  ) {
    return {
      tradePrice: NEGATIVE_SIGN,
      markPrice: NEGATIVE_SIGN,
      markOffset: NEGATIVE_SIGN,
    };
  }

  const fixedDecimalTradePrice = getFixedDecimalPriceWithCustomRules(
    price,
    productCode,
    description,
    makerSide,
  );

  const fixedDecimalMarkPrice = getFixedDecimalPriceWithCustomRules(
    markPrice,
    productCode,
    description,
  );

  return {
    tradePrice: fixedDecimalTradePrice.format(),
    markPrice: fixedDecimalMarkPrice.format(),
    markOffset: getMarkPriceFormattedString(
      fixedDecimalTradePrice,
      fixedDecimalMarkPrice,
    ),
  };
}
