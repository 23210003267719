import isEqual from 'lodash/isEqual';
import { exceptionLogger } from '@paradigm/logging';

import createTopic, { ClearSubscription, EventHandler } from './createTopic';

export type { ClearSubscription, EventHandler } from './createTopic';

export interface Observable<T> {
  readonly name: string;
  readonly set: (value: T) => void;
  readonly get: () => T;
  readonly observe: (handler: EventHandler<T>) => ClearSubscription;
  readonly unobserve: (handler: EventHandler<T>) => void;
}

export interface ReadonlyObservable<T> extends Omit<Observable<T>, 'set'> {}

export default function createObservable<T>(
  name: string,
  initial: T,
): Observable<T> {
  const topic = createTopic<T>(`observable-topic:${name}`);
  let currentValue = initial;

  const handlerErrorLogger = exceptionLogger(
    `Observe handler threw on observable '${name}'`,
  );

  return {
    name,

    set(value) {
      if (isEqual(value, currentValue)) return;
      currentValue = value;
      topic.publish(value);
    },

    get() {
      return currentValue;
    },

    observe(handler) {
      const clearSubscription = topic.subscribe(handler);
      (async () => handler(currentValue))().catch(handlerErrorLogger);
      return clearSubscription;
    },

    unobserve(handler) {
      topic.unsubscribe(handler);
    },
  };
}

export function createReadonlyObservable<T>(
  name: string,
  initial: T,
): [ob: ReadonlyObservable<T>, set: (value: T) => void] {
  const { set, ...roObservable } = createObservable(name, initial);
  return [roObservable, set];
}

export function mapObservable<T, U>(
  observable: ReadonlyObservable<U>,
  fn: (arg: U) => T,
): [clearSubscription: ClearSubscription, ob: ReadonlyObservable<T>] {
  const [ob, setValue] = createReadonlyObservable(
    `${fn.name || 'anonymous'}(${observable.name})`,
    fn(observable.get()),
  );

  const clearSubscription = observable.observe((value) => setValue(fn(value)));

  return [clearSubscription, ob];
}
