import { ComponentPropsWithoutRef } from 'react';
import useIsViewerPrime from '@paradigm/features/src/prime/hooks/useIsViewerPrime';
import PrimeBadge from '@paradigm/design-system/src/assets/PrimeBadge';
import ProBadge from '@paradigm/design-system/src/assets/ProBadge';

export default function UserAccountTierBadge(
  props: ComponentPropsWithoutRef<'svg'>,
) {
  const isViewerPrime = useIsViewerPrime();

  if (isViewerPrime) return <PrimeBadge {...props} />;
  // WIP: When available use account info to display if user is pro
  return <ProBadge {...props} />;
}
