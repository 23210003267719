import { AgreementDocument, PARADIGM_TERMS_LINK } from './types';
import SgUserAgreementMarkdown from './sg/UserAgreementMarkdown';

import UsUserAgreement from './us/UserAgreement';
import UsAnnexAgreement from './us/UsAnnexAgreement';
import processAgreementMarkdownData from './processAgreementData';

const COUNTRIES_WITHIN_US_AGREEMENT_SCOPE = new Set([
  'US',
  'GU',
  'AS',
  'MP',
  'PR',
  'VI',
]);

const US_AGREEMENT: AgreementDocument = {
  kind: 'us',
  url: 'https://www.paradigm.co/help/terms/paradigm-us/terms-and-agreements',
  content: [
    {
      id: 'user',
      label: 'User Agreement',
      Component: UsUserAgreement,
    },
    {
      id: 'us-annex',
      label: 'US Cleared Products Annex',
      Component: UsAnnexAgreement,
    },
  ],
};

const NON_US_AGREEMENT: AgreementDocument = {
  kind: 'non-us',
  url: PARADIGM_TERMS_LINK,
  content: processAgreementMarkdownData([{ content: SgUserAgreementMarkdown }]),
};

export function agreementFromCustomerTypeQueryParam(customerType: string) {
  switch (customerType) {
    case 'non-us':
      return NON_US_AGREEMENT;
    case 'us':
      return US_AGREEMENT;
    default:
      return null;
  }
}

export function agreementFromCountryCode(countryCode: string) {
  if (COUNTRIES_WITHIN_US_AGREEMENT_SCOPE.has(countryCode)) {
    return US_AGREEMENT;
  }
  return NON_US_AGREEMENT;
}
