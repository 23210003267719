import { isFunction } from 'lodash';
import { cloneElement, isValidElement } from 'react';
import { ToastContentProps } from 'react-toastify';

type IconParams = Pick<
  ToastContentProps['toastProps'],
  'theme' | 'icon' | 'type' | 'isLoading'
>;

// Re-implementation of react-toastify icon resolution
export function getIcon({ theme, type, isLoading, icon }: IconParams) {
  let Icon: React.ReactNode = null;
  const iconProps = { theme, type };
  if (icon === false) {
    // hide
  } else if (isFunction(icon)) {
    Icon = icon({ ...iconProps, isLoading });
  } else if (isValidElement(icon)) {
    Icon = cloneElement(icon, iconProps);
  }
  return Icon;
}
