import { ComponentPropsWithoutRef } from 'react';

const LogoutIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="18px" height="20px" viewBox="0 0 18 20" {...props}>
    <g
      transform="translate(-559 -516) translate(520 184) translate(40.99 333.635)"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M7.5 16.552H2.2a2.2 2.2 0 01-2.2-2.2V2.2A2.2 2.2 0 012.2 0h5.3" />
      <path d="M9.43461739 3.78209348L13.9289 8.27637609 9.43461739 12.7706587" />
      <path d="M13.9289739 8.27641304L5.07012609 8.27641304" />
    </g>
  </svg>
);

export default LogoutIcon;
