import isElectron from 'is-electron';
import styled from 'styled-components';
import ParadigmLogoVertical from '@paradigm/design-system/src/assets/ParadigmLogoVertical';
import {
  blueMana,
  nepal,
  paradigmLightBlue,
} from '@paradigm/design-system/src/colors';
import Button from '@paradigm/design-system/src/components/Button';
import Text from '@paradigm/design-system/src/components/Text';
import { useTrackAction } from '@paradigm/features/src/analytics';
import UserIdentifier from '@paradigm/features/src/logging/UserIdentifier';

const DOWNLOAD_LINK = 'https://www.paradigm.co/download';
// Note: Explicit use of `http` link to bypass electron redirection handler.
// Routing to https is handled by url configuration.
const BROWSER_LINK = 'http://app.paradigm.trade';

/* 
  This component is used to display a deprecation message to users who are using 
  the Electron app. We use a boundary inorder for track to be called with user 
  identifier.
*/
const ElectronBoundary = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  if (isElectron()) {
    return (
      <>
        <UserIdentifier />
        <ElectronDeprecation />
      </>
    );
  }
  return children;
};

const ElectronDeprecation = () => {
  const track = useTrackAction();

  return (
    <div className="d-flex flex-column align-items-center w-100 layout__gradient">
      <div className="layout__logo d-flex flex-column align-items-center flex-shrink-0">
        <ParadigmLogoVertical />
      </div>
      <StyledContainer>
        <Text size="xxlarge" weight="semibold" css={{ marginTop: 16 }}>
          App Deprecated
        </Text>
        <Text color={nepal} css={{ marginTop: 10 }}>
          We regret to inform you that this application is no longer accessible.
          We are committed to providing a superior user experience and encourage
          you to switch to our{' '}
          <Link
            href={BROWSER_LINK}
            target="_blank"
            onClick={(_e) => {
              track('electron deprecation toast', { action: 'click-browser' });
            }}
          >
            browser version
          </Link>{' '}
          or{' '}
          <Link
            href={DOWNLOAD_LINK}
            target="_blank"
            onClick={(_e) => {
              track('electron deprecation toast', { action: 'click-pwa' });
            }}
          >
            install our Progressive Web App
          </Link>{' '}
          (PWA) for continuous access to our services. Both alternatives offer
          full functionality and an enhanced user experience. Thank you for your
          understanding and continued support.
        </Text>
        <Link
          href={DOWNLOAD_LINK}
          target="_blank"
          onClick={(_e) => {
            track('electron deprecation toast', { action: 'click-pwa' });
          }}
        >
          <Button ariaLabel="Install PWA" css={{ marginTop: 30, width: 150 }}>
            Install PWA
          </Button>
        </Link>
        <Text
          size="small"
          color={blueMana}
          weight="semibold"
          css={{ marginTop: 12 }}
        >
          <Link
            href={BROWSER_LINK}
            target="_blank"
            onClick={(_e) => {
              track('electron deprecation toast', { action: 'click-browser' });
            }}
          >
            Launch Paradigm
          </Link>{' '}
        </Text>
      </StyledContainer>
    </div>
  );
};

export default ElectronBoundary;

const Link = styled.a`
  color: ${paradigmLightBlue};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const StyledContainer = styled.div`
  width: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
