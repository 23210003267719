import { push } from 'connected-react-router';
import isElectron from 'is-electron';
import { takeEvery, call, put, fork, select, take } from 'redux-saga/effects';
import { log, logException } from '@paradigm/logging';

import {
  getUser,
  getHasAcceptedAgreement,
  getHasRfqIpRestriction,
  acceptedAgreement,
  showAgreementOverlayAction,
  toggleIpWarningAction,
  authenticationRequest,
  loginSuccess,
  logoutSuccess,
} from '@paradigm/store/auth';

import localStorage from '@paradigm/utils/src/safeLocalStorage';

import {
  recoveryFailure,
  initialDataFetchStart,
  initialDataFetchFailure,
  initialDataFetchSuccess,
  resetRequest,
} from '#/redux/app';

import { registrationSuccess } from '#/redux/registration';

import { ROOT_ROUTE } from '#/routes';

export function* resetAppSaga() {
  yield put(push(ROOT_ROUTE));
  if (isElectron()) {
    window.require('electron').ipcRenderer.sendSync('update-badge', null);
  }
  localStorage.setItem('messagesQueue', JSON.stringify({}));
}

/**
 * Fetches all the initial data required by the app.
 */
export function* fetchInitialDataSaga() {
  yield put(initialDataFetchStart());

  yield select(getUser);

  yield put(initialDataFetchSuccess());
}

function* agreementAcceptanceSaga() {
  const hasAcceptedAgreement = yield select(getHasAcceptedAgreement);
  const mustAcceptAgreement = !hasAcceptedAgreement;
  if (mustAcceptAgreement) {
    yield put(showAgreementOverlayAction());
    yield take(acceptedAgreement().type);
  }
}

function* ipWarningSaga() {
  const hasRfqIpRestriction = yield select(getHasRfqIpRestriction);
  if (hasRfqIpRestriction) {
    yield put(toggleIpWarningAction());
  }
}

export function* startAppSaga() {
  log('Start app saga started');
  yield call(agreementAcceptanceSaga);
  yield fork(fetchInitialDataSaga);
  yield call(ipWarningSaga);
  localStorage.setItem('messagesQueue', JSON.stringify({}));
  log('Start app saga ended');
}

export function* resetRequestListener() {
  yield takeEvery(resetRequest, resetAppSaga);
}

export function* loginSuccessListener() {
  yield takeEvery(loginSuccess, startAppSaga);
}

export function* logoutSuccessListener() {
  yield takeEvery(logoutSuccess, function* logoutSuccessSaga() {
    yield put(resetRequest());
  });
}

/**
 * Log failed recovery to help debugging
 */
export function* recoveryFailureListener() {
  yield takeEvery(recoveryFailure, function recoveryFailureSaga({ payload }) {
    const error = new Error(`Recovery failed: ${payload.message}`);
    logException(error.message, error);
  });
}

export function* initialDataFetchFailureListener() {
  while (true) {
    yield take(initialDataFetchFailure);
    const error = new Error('Initial data fetch failed');
    logException(error.message, error);
  }
}

/**
 * Requests authentication after a successful registration
 */
export function* registrationSuccessListener() {
  while (true) {
    const {
      payload: { user, token },
    } = yield take(registrationSuccess);
    yield put(authenticationRequest({ user, token }));
  }
}

const authSagas = [
  loginSuccessListener,
  logoutSuccessListener,
  resetRequestListener,
  recoveryFailureListener,
  initialDataFetchFailureListener,
  registrationSuccessListener,
];

export default authSagas;
