import styled from 'styled-components';

import UserAvatar from '#/components/user-avatar/user-avatar';
import NotificationIcon from '@paradigm/design-system/src/assets/NotificationIcon';
import LockIcon from '@paradigm/design-system/src/assets/LockIcon';
import LogoutIcon from '@paradigm/design-system/src/assets/LogoutIcon';
import DashboardIcon from '@paradigm/design-system/src/assets/DashboardIcon';
import FaqIcon from '@paradigm/design-system/src/assets/FaqIcon';
import StackIcon from '@paradigm/design-system/src/assets/StackIcon';
import { paradigmLightBlue } from '@paradigm/design-system/src/colors';
import useIsViewerPrime from '@paradigm/features/src/prime/hooks/useIsViewerPrime';
import AnimatedStarsSettings from '@paradigm/design-system/src/assets/AnimatedStarsSettingsIcon';
import { SettingsView } from '#/components/settings-modal/SettingsModal';
import { AuthUser } from '@paradigm/store/src/auth/types';
import { useDefaultAccountsFeatureFlag } from '@paradigm/features/src/feature-flags/context';
import UserAccountTierBadge from '#/components/user-preferences/components/UserAccountTierBadge';
import {
  useTrackAction,
  useTrackedCallback,
} from '@paradigm/features/src/analytics';

interface Props {
  setView: (view: SettingsView) => void;
  onClose: () => void;
  onLogout: () => void;
  isMobile: boolean;
  user: AuthUser;
}

function SettingsMain({
  user,
  setView,
  onLogout: onLogoutBase,
  isMobile,
}: Props) {
  const isViewerPrime = useIsViewerPrime();
  const isDefaultAccountsEnabled = useDefaultAccountsFeatureFlag();
  const track = useTrackAction();
  const onLogout = useTrackedCallback('logout', onLogoutBase);
  return (
    <>
      {isViewerPrime && (
        <div className="stary-skies">
          <AnimatedStarsSettings />
        </div>
      )}
      <div className="d-flex align-items-center px-4-5 pt-4 flex-shrink-0">
        <div style={{ fontSize: 21 }}>
          <UserAvatar
            firstName={user.first_name}
            lastName={user.last_name}
            color={user.color}
          />
        </div>
        <div className="ml-3">
          <UserName>
            <UserAccountTierBadge />
            {user.first_name} {user.last_name}
          </UserName>
          <UserFirm>
            {user.firm}
            {user.trading_desk ? ` (${user.trading_desk.ticker})` : ''}
          </UserFirm>
        </div>
      </div>
      <div className="divider flex-shrink-0" />
      <div className="px-4-5 flex-shrink-0">
        {!isMobile && (
          <>
            <button
              className="settings-link"
              onClick={() => setView('Notifications')}
            >
              <div className="link-icon">
                <NotificationIcon />
              </div>
              <div className="link-label">Notifications</div>
            </button>
            {isDefaultAccountsEnabled && (
              <button
                className="settings-link"
                onClick={() => setView('Default Accounts')}
              >
                <div className="link-icon">
                  <StackIcon />
                </div>
                <div className="link-label">Default Accounts</div>
              </button>
            )}
            <button
              className="settings-link"
              onClick={() => setView('Security')}
            >
              <div className="link-icon">
                <LockIcon />
              </div>
              <div className="link-label">Security</div>
            </button>
          </>
        )}
        <button className="settings-link" onClick={onLogout}>
          <div className="link-icon">
            <LogoutIcon />
          </div>
          <div className="link-label">Logout</div>
        </button>
      </div>
      <div className="divider flex-shrink-0" />
      <div className="px-4-5 flex-shrink-0">
        <a
          className="settings-link"
          href={ADMIN_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => track('view admin dashboard')}
        >
          <div className="link-icon">
            <DashboardIcon />
          </div>
          <div className="link-label">Dashboard</div>
        </a>
        <a
          className="settings-link"
          href="https://www.paradigm.co/help-center/faq"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => track('view faq')}
        >
          <div className="link-icon">
            <FaqIcon />
          </div>
          <div className="link-label">FAQ</div>
        </a>
      </div>
    </>
  );
}

const UserName = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
`;
const UserFirm = styled.div`
  font-size: 12px;
  align-items: center;
  color: ${paradigmLightBlue};
  display: flex;
  gap: 8px;
`;

export default SettingsMain;
