import { Wrapper } from './helpers';

/**
 * Helper component to keep error messages as DRY as possible.
 * @param {string | null} error - Top level error message.
 * @return {JSX} component displaying error message.
 */
export const renderError = (error) => (
  <Wrapper>
    {error != null && error.length > 0 && (
      <div
        id="error"
        className="error alert alert-danger text-center"
        role="alert"
      >
        <i className="error__icon-container fa fa-exclamation-triangle icon-alert" />
        {error}
      </div>
    )}
  </Wrapper>
);
