import { usePreferencesStore } from '#/preferences/store';

/**
 * Persist to local storage and sync with API.
 */
export default function usePersistedMatchingDetection() {
  const preferencesStore = usePreferencesStore((state) => ({
    toggleMatchingDetection: state.toggleMatchingDetection,
    isMatchingDetectionEnabled: state.isMatchingDetectionEnabled,
  }));

  return preferencesStore;
}
