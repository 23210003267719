import { isValidDecimal } from '@paradigm/utils/formatDecimal';

export const isValueEmpty = (
  value: null | undefined | string,
): value is null | undefined => {
  if (value == null) return true;
  if (value === '') return true;
  if (Number(value) === 0 || !isValidDecimal(value)) return true;

  return false;
};

export const getValidValueOrNull = (value: null | string | undefined) =>
  isValueEmpty(value) ? null : value;
