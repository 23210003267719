import styled from 'styled-components';
import { paradigmLightBlue } from '@paradigm/design-system/src/colors';

const UnstyledButton = styled.button.attrs({ type: 'button' })`
  border: 0;
  outline: 0;
  background: none;
  padding: 0;
  color: inherit;
  transition: box-shadow 0.1s ease;

  :focus-visible {
    box-shadow: 0 0 0 1px ${paradigmLightBlue};
  }
`;

export default UnstyledButton;
