import { InputProps } from './Input';

export const INPUT_SIZES: Record<
  Required<InputProps>['size'],
  { fontSize: string; helperFontSize: string }
> = {
  small: {
    fontSize: '0.75rem',
    helperFontSize: '0.6rem',
  },
  medium: {
    fontSize: '0.875rem',
    helperFontSize: '0.75rem',
  },
  large: {
    fontSize: '1rem',
    helperFontSize: '0.875rem',
  },
};
