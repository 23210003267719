import { ReactNode, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import createSafeContext from '@paradigm/utils/createSafeContext';
import { getUser } from '@paradigm/store/auth';
import localStorage from '@paradigm/utils/src/safeLocalStorage';

export const BOTTOM_TABS = [
  { id: 'positions', label: 'Positions' },
  { id: 'rfqs-history', label: 'RFQ History' },
  { id: 'open-orders', label: 'Open Orders' },
  { id: 'trade-tape', label: 'Trade Tape' },
  { id: 'order-history', label: 'Order History' },
  { id: 'trade-history', label: 'Trade History' },
] as const;

export type Tab = typeof BOTTOM_TABS[number]['id'];

export const INITIAL_STATE: Tab = 'trade-tape';

export const [useBottomTabsState, BottomTabsStateCtx] =
  createSafeContext<Tab>('BottomTabsState');

interface BottomTabsMethods {
  readonly setTab: (content: Tab) => void;
  readonly clear: () => void;
}

export const [useBottomTabsMethods, BottomTabsMethodsCtx] =
  createSafeContext<BottomTabsMethods>('BottomTabsMethods');

interface BottomTabsProviderProps {
  readonly children: ReactNode;
}

const SUFFIX = 'bottom-tab';

function createBottomTabKey(userId: number) {
  return `${userId}:${SUFFIX}`;
}

function isValidTab(tab: string | null): tab is Tab {
  return tab !== null && BOTTOM_TABS.some((tabObj) => tabObj.id === tab);
}
function getSavedBottomTab(key: string) {
  const initialTab = localStorage.getItem(key);
  return isValidTab(initialTab) ? initialTab : null;
}

export function BottomTabsProvider({ children }: BottomTabsProviderProps) {
  const user = useSelector(getUser);
  const userId = user.id;
  const bottomTabKey = createBottomTabKey(userId);

  const initialTab = getSavedBottomTab(bottomTabKey);
  const [state, setState] = useState<Tab>(initialTab ?? INITIAL_STATE);

  const methods = useMemo(
    () => ({
      setTab: (newContent: Tab) => {
        if (!isValidTab(newContent))
          throw new TypeError('Invalid bottom tab name provided');
        localStorage.setItem(bottomTabKey, newContent);
        setState(newContent);
      },
      clear: () => {
        localStorage.removeItem(bottomTabKey);
        setState(INITIAL_STATE);
      },
    }),
    [bottomTabKey],
  );

  return (
    <BottomTabsMethodsCtx value={methods}>
      <BottomTabsStateCtx value={state}>{children}</BottomTabsStateCtx>
    </BottomTabsMethodsCtx>
  );
}

export function resetTabStorage(userId: number) {
  const key = createBottomTabKey(userId);
  localStorage.removeItem(key);
}
