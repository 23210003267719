import { FormEventHandler } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { length, numericality, required } from 'redux-form-validators';
import { Spinner } from 'reactstrap';

import { useLogin } from '@paradigm/features/src/login';

import { ROOT_ROUTE } from '#/routes';

import { renderInputField } from '#/utils/renderFormFields';
import { renderError } from '#/utils/renderErrors';
import BackIcon from '@paradigm/design-system/src/assets/BackIcon';

/**
 * This must reflect the `name`s of the form fields and also the
 * validation they pass through.
 */
interface OtpForm {
  readonly totp_token: string;
}

export interface TwoFaLocationState {
  readonly email: string;
  readonly password: string;
}

interface Props extends InjectedFormProps<OtpForm> {}

function RawTwoFA(props: Props) {
  const { handleSubmit: createSubmitHandler } = props;

  const { state } = useLocation<TwoFaLocationState | undefined>();
  const { doLogin, error, isLoading } = useLogin();

  // This may happen if the user accesses this route manually
  if (state == null) {
    return <Redirect to={ROOT_ROUTE} />;
  }

  const handleSubmit = createSubmitHandler((data) => {
    doLogin({
      email: state.email,
      password: state.password,
      totp_token: data.totp_token,
    });
  }) as FormEventHandler<HTMLFormElement>;

  return (
    <div className="flex-column b-primary layout__container sign-in">
      <form onSubmit={handleSubmit}>
        <div className="row no-gutters align-items-center mb-4 flex-shrink-0">
          <div className="mr-3">
            <Link to="/" role="link" style={{ color: 'white' }}>
              <BackIcon />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <h2 className="sign-up__title">Enter your authentication code</h2>
          </div>
        </div>
        {renderError(error)}
        <label htmlFor="sign-in-otp-code-input" className="sign-in__code mb-2">
          Check your authentication app for a sign-in code.
        </label>
        <div className="">
          <Field
            id="sign-in-otp-code-input"
            component={renderInputField}
            type="text"
            name="totp_token"
            label="Code"
            placeholder="Enter Code"
            className="form-control sign-in__input"
            validate={[
              length({ is: 6 }),
              numericality({ int: true }),
              required(),
            ]}
            autoComplete="off"
          />
        </div>
        <div className="text-right">
          <button type="submit" className="btn primary-button mt-4">
            {isLoading ? (
              <Spinner size="sm" className="align-middle" />
            ) : (
              'Sign In'
            )}
          </button>
        </div>
      </form>
      <div className="divider-totp flex-shrink-0" />
      <p className="opacity-5 m-0">
        Unable to submit 2FA code? Please contact us at
        <a href="mailto:support@paradigm.co" rel="noopener noreferrer">
          {' '}
          support@paradigm.co
        </a>
      </p>
    </div>
  );
}

const TwoFA = reduxForm<OtpForm>({ form: 'TwoFA' })(RawTwoFA);

export default TwoFA;
