import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { IconContainer, StyledButton, StyledLoadingSpinner } from './styles';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  /**
   * Button content
   */
  children: ReactNode;
  /**
   * Accessibility: Short sentence of what the button does
   */
  ariaLabel: string;
  /**
   * Control which type of button is going to be displayed.
   */
  variant?: 'pill' | 'squared' | 'link';
  /**
   * Control color scheme themes applied to button.
   */
  theme?:
    | 'default'
    | 'primary-green'
    | 'primary-red'
    | 'primary-dark'
    | 'secondary-dark-blue'
    | 'secondary-light-blue'
    | 'secondary-green'
    | 'secondary-red'
    | 'quick-action';
  /**
   * Control if the button is transparent with a border
   */
  outline?: boolean;
  /**
   * Icon to be displayed alongside button
   */
  icon?: ReactNode;
  /**
   * Icon to be displayed alongside button
   */
  iconPlacement?: 'right' | 'left';
  /**
   * Default presets of size for the button
   */
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
  /**
   * if true, the button takes 100% of width
   */
  fluid?: boolean;
  /**
   * whether the font is bold or not
   */
  bold?: boolean;
  /**
   * disables the button and displays a Loading spinner
   */
  isLoading?: boolean;
  /**
   * className to customize button
   */
  className?: string;
  /**
   * Disables the button
   */
  disabled?: boolean;
  /**
   * Apply invalid styles if false
   */
  isValid?: boolean | undefined;
}

/**
 * Base generic button that can be configured by a different set of parameters
 */
export default function Button({
  children,
  icon,
  className,
  ariaLabel,
  isValid,
  isLoading = false,
  outline = false,
  fluid = false,
  bold = false,
  disabled = false,
  iconPlacement = 'right',
  size = 'medium',
  variant = 'pill',
  theme = 'default',
  type = 'button',
  ...props
}: ButtonProps) {
  const iconComponent = (
    <IconContainer placement={iconPlacement}>{icon}</IconContainer>
  );

  return (
    <StyledButton
      {...props}
      aria-label={ariaLabel}
      outline={outline}
      icon={icon}
      fluid={fluid}
      bold={bold}
      disabled={disabled || isLoading}
      className={className}
      iconPlacement={iconPlacement}
      size={size}
      variant={variant}
      type={type}
      isValid={isValid}
      theme={theme}
    >
      {icon != null && iconPlacement === 'left' && iconComponent}
      {children}
      {icon != null && iconPlacement === 'right' && iconComponent}
      {isLoading && <StyledLoadingSpinner iconSize={size} />}
    </StyledButton>
  );
}
