// Paradigm Blues
export const paradigmBlue = '#0E69EE';
export const paradigmLightBlue = '#7FABEC';
export const blueMana = '#72ADF2';
export const lightBlue = '#aed7e8';
export const blueBubble = '#A3D9EA';
export const prussianBlue = '#00325A';
export const strongBlue = '#0054D0';
export const brilliantAzure = '#388BFF';

// Dark shades
export const obsidian = '#020408';
export const blackTangaroa = '#020C17';
export const tangaroa = '#041d39';
export const blackPearl = '#09213A';
export const blackPearl_2 = '#03111E';
export const blackPearl_3 = '#04192E';
export const blackPearl_4 = '#010407';
export const blackPearl_5 = '#071A2E';
export const blackPearl_6 = '#070528';
export const blueCharcoal = '#020C1A';
export const blueCharcoal_2 = '#010319';
export const sanJuan = '#2D4B70';
export const firefly = '#091523';
export const firefly_2 = '#0F2038';
export const mirage = '#182332';
export const pickledBluewood = '#27364C';
export const pickledBluewood_2 = '#263347';
export const ebony = '#020408';
export const cloudBurst = '#23374E';
export const bigStone = '#122035';
export const darkerBlackPearl = '#051524';
export const darkCornflowerBlue = '#0d2440';
export const richBlack = '#0A0A18';
export const richBlack_2 = '#0C0928';
export const mineShaft = '#323232';
export const cetaceanBlue = '#0e0837';
export const americanBronze = '#0b1b38';
export const darkGunmetal = '#172333';

// Light shades
export const portage0 = 'rgba(127, 171, 236, 0)';
export const portage5 = 'rgba(127, 171, 236, 0.05)';
export const portage10 = 'rgba(127, 171, 236, 0.1)';
export const portage15 = 'rgba(127, 171, 236, 0.15)';
export const portage20 = 'rgba(127, 171, 236, 0.2)';
export const portage30 = 'rgba(127, 171, 236, 0.3)';
export const portage70 = 'rgba(127, 171, 236, 0.7)';

// Warning color
export const ripeLemon = '#F1E20D';

// Text colors
export const nepal = '#97B2BD';
export const bombay = '#B3B6BA';
export const osloGray = '#828A92';
export const raven = '#76818b';
export const linkWater = '#cdd0d3';
export const alto = '#D8D8D8';

// Eye catching colors
export const mountainMeadow = '#1AA369';

// Action colors (Sell/Buy)
export const razzmatazz = '#EA135E';
export const lima = '#7ED321';

// Inner notifications
export const wildWatermelon = '#FE5C83';
export const neonLemon = '#32B511';
export const grapefruit = '#F1930D';
export const scarlet = '#FF2B00';

// Profile picture colors
export const midnightBlue = '#003366';
export const seaGreen = '#339966';
export const fountainBlue = '#68A2BF';
export const maroonFlush = '#BD264A';
export const studio = '#664BAD';
export const fireBush = '#E08F39';
export const mauve = '#CC99FF';
export const frenchRose = '#F6597F';

export const amaranth = '#dc3545';

export const firstGold = '#fdd735';
export const secondSilver = '#E7FFFE';
export const thirdBronze = '#FF7425';
