import { ComponentPropsWithoutRef } from 'react';

export default function PrimeBadge(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="64px"
      height="16px"
      viewBox="0 0 64 16"
      version="1.1"
      data-testid="prime-badge-icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          x1="9.95673432%"
          y1="3.10803324%"
          x2="128.24389%"
          y2="14.243744%"
          id="linearGradient-1"
        >
          <stop stopColor="#67A4FF" offset="0%" />
          <stop stopColor="#0E00FF" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="prime-icon-path"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="um-/-prime-/-2" transform="translate(-861, -305)">
          <g id="modal" transform="translate(126, 18.5)">
            <g transform="translate(663, 0)" id="contact-/-trade">
              <g transform="translate(0, 256)">
                <g id="prime-tag" transform="translate(72, 31.1214)">
                  <rect
                    id="Rectangle"
                    fill="#000000"
                    x="0"
                    y="0"
                    width="64"
                    height="14.8571429"
                    rx="2.1"
                  />
                  <g id="Group-14" transform="translate(2.4318, 1.5207)">
                    <path
                      d="M6.1131249,0 L6.113,0.255 L6.116816,0.284673868 C6.5318697,3.93974576 8.47015136,5.8129701 11.931661,5.90434689 L12.2009354,5.90786139 C8.4797923,5.90786139 6.44211157,7.87714852 6.08789318,11.8157228 L6.113,11.56 L6.1131249,11.8157228 L6.1,11.688 L6.08789318,11.8157228 L6.087,11.56 L6.08420209,11.5310489 C5.65850598,7.78225723 3.63046619,5.90786139 8.27126599e-05,5.90786139 L0.269357079,5.90434689 C3.81962337,5.8106271 5.76754598,3.84251147 6.1131249,0 L6.087,0.255 L6.08789318,0 L6.1,0.127 L6.1131249,0 Z"
                      id="Combined-Shape"
                      fill="url(#linearGradient-1)"
                      fillRule="nonzero"
                    />
                    <g
                      id="Group"
                      transform="translate(12.201, 2.4441)"
                      fill="#FFFFFF"
                    >
                      <path
                        d="M5.18777829,1.13873649 C4.9662304,0.77530995 4.65088851,0.494260093 4.24013549,0.295586919 C3.82938248,0.0985289728 3.3523268,0 2.81058562,0 L0,0 L0,1.20334565 L1.48453256,1.20334565 L1.48453256,1.20173042 L2.84616265,1.20173042 C3.21163581,1.20819134 3.49948635,1.31964214 3.70809714,1.53769807 C3.91509078,1.75575399 4.02020474,2.04003431 4.02020474,2.39215424 C4.02020474,2.73619803 3.91670792,2.99948037 3.70971427,3.18361648 C3.50433776,3.36936783 3.20355012,3.46143588 2.81058562,3.46143588 L0,3.46143588 L0,7.19907593 L1.48453256,7.19907593 L1.48453256,4.6631663 L2.78632855,4.6631663 C3.64179448,4.6631663 4.31128956,4.45964744 4.79481378,4.05260972 C5.27833801,3.645572 5.51929155,3.08831797 5.51929155,2.38246287 C5.51929155,1.91889213 5.40932617,1.50377826 5.18777829,1.13873649"
                        id="Fill-1"
                      />
                      <path
                        d="M14.5254613,4.18828896 C14.979274,3.99284624 15.3162469,3.73279436 15.5397666,3.40328764 C15.761593,3.07539614 15.8733528,2.66674318 15.8733528,2.17571355 C15.8733528,1.48278028 15.6312065,0.946524231 15.1486072,0.56856063 C14.6643145,0.1889818 13.9768221,0 13.0844366,0 L10.2802804,0 L10.2802804,1.20334565 L11.8347583,1.20334565 L11.8347583,1.20173042 L13.0844366,1.20173042 C13.5026894,1.20173042 13.812569,1.29864417 14.0157687,1.49247165 C14.2172751,1.68791437 14.3188749,1.95442717 14.3188749,2.29362527 C14.3188749,2.62636246 14.2121951,2.88802956 13.997142,3.07701136 C13.8311956,3.22399721 13.6127559,3.31445004 13.3401296,3.34513939 C13.2605431,3.35644599 13.1775698,3.36129168 13.0895166,3.36129168 L10.2802804,3.36129168 L10.2802804,7.19907593 L11.8347583,7.19907593 L11.8347583,4.5630221 L13.0742766,4.5630221 L14.4899014,7.19907593 L16.1595258,7.19907593 L16.1595258,7.12962108 L14.5254613,4.18828896 Z"
                        id="Fill-3"
                      />
                      <polygon
                        id="Fill-7"
                        points="20.9205147 7.19907593 22.4803144 7.19907593 22.4803144 0 20.9205147 0"
                      />
                      <polygon
                        id="Fill-12"
                        points="35.0403023 0 35.0403023 7.19907593 33.5134349 7.19907593 33.5134349 1.77029105 31.4327039 7.19907593 30.0172746 7.19907593 27.2413033 0 28.8663027 0 29.3802482 1.48278028 29.3819115 1.48278028 29.7960618 2.6845107 29.7927353 2.6845107 30.7241576 5.38032801 31.6456004 2.6845107 32.0564242 1.48278028 32.0580875 1.48278028 32.5687065 0"
                      />
                      <path
                        d="M39.8055214,7.14148333 L44.6496376,7.14148333 L44.6496376,5.82692932 L39.8055214,5.82692932 L39.8055214,7.14148333 Z M39.8055214,1.31455401 L44.6397999,1.31455401 L44.6397999,0 L39.8055214,0 L39.8055214,1.31455401 Z M39.8012911,4.22801867 L42.8073191,4.22801867 L42.8073191,2.91346466 L39.8012911,2.91346466 L39.8012911,4.22801867 Z"
                        id="Fill-3"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
