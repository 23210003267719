import { useCallback } from 'react';
import {
  VENUES,
  VenueCode,
  isUnsetAccount,
} from '#/preferences/default-accounts/types';
import usePersistedAccounts from '#/preferences/default-accounts/usePersistedAccounts';
import { useAccounts } from '#/unified-rfqs/contexts/accounts';

export default function useMigrateLegacyApiDefaults() {
  const accountsView = useAccounts();
  const persistedAccountsStore = usePersistedAccounts();

  const migrateLegacyDefaultByVenue = useCallback(
    (venue: VenueCode) => {
      if (!isUnsetAccount(persistedAccountsStore.get()[venue].all)) return;

      const legacyDefaultAccount = accountsView.selectDefaultByVenue(venue);
      persistedAccountsStore.setVenueDefault(
        venue,
        legacyDefaultAccount?.name ?? null,
      );
    },
    [accountsView, persistedAccountsStore],
  );

  return useCallback(() => {
    if (persistedAccountsStore.defaultAccountsMigrated === true) return;
    VENUES.forEach(migrateLegacyDefaultByVenue);
    persistedAccountsStore.setHasMigratedAccounts();
  }, [migrateLegacyDefaultByVenue, persistedAccountsStore]);
}
