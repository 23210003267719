import { BigNumber } from 'bignumber.js';
import enhanceNegativeSign from './enhanceNegativeSign';

/*
 * This class is used to represent a number with a fixed number of decimal place
 * and a rounding mode.
 *
 * BigNumber has been proven safe to use for financial calculations. However it
 * does not retain its own decimal places and rounding mode. This class is used
 * to retain this information while being passed around in the codebase prior to
 * the final formatting step.
 */

class FixedDecimalNumber {
  public readonly value: BigNumber;
  public readonly decimalPlaces: number;
  public readonly roundMode?: BigNumber.RoundingMode;

  constructor(
    value: BigNumber,
    decimalPlaces: number,
    roundMode?: BigNumber.RoundingMode,
  ) {
    this.value = value;
    this.decimalPlaces = decimalPlaces;
    this.roundMode = roundMode;
  }

  round(): BigNumber {
    return this.value.decimalPlaces(this.decimalPlaces, this.roundMode);
  }

  /*
   * Fulfills some of the same responsibilities formatting responsibilities as
   * formatDecimal.ts but through the BigNumber API. This is used to format
   * numbers for display purposes.
   */
  format(): string {
    return enhanceNegativeSign(
      this.value.toFormat(this.decimalPlaces, this.roundMode),
    );
  }
}

export default FixedDecimalNumber;
