/* eslint-disable react/jsx-no-target-blank */

const UsAnnexAgreement = () => (
  <>
    <h1>US Cleared Products Annex</h1>
    <p>
      This US Cleared Product Annex (as amended from time to time, this “Annex”)
      is an annex to the User Agreement (the “User Agreement”) that the related
      user has executed with respect to their usage of the communications
      software provided by Paradigm Connect, Inc. DBA Paradigm (“Paradigm”) and
      relates to US Cleared Products (as defined below). This Annex supplements
      and forms an integral part of the User Agreement with respect to US
      Cleared Products. Capitalized terms used but not defined in this Annex
      have the meanings given to such terms in the User Agreement. Usage of the
      System to negotiate eligible US Cleared Products that are then submitted
      for clearing on a Clearing Organization for US Cleared Products
      constitutes agreement by the User to the terms of this Annex, as amended
      from time to time.
    </p>
    <h2>1. Eligible Products and Transactions</h2>
    <p>
      “Clearing Organization” means, with respect to any US Cleared Product, the
      relevant clearing organization for such US Cleared Product.
    </p>
    <p>
      “US Cleared Product” means a future and option of a given venue as labeled
      “US Cleared” that Paradigm supports, as listed on{' '}
      <a
        href="https://www.paradigm.co/help/products/derivatives/venues"
        target="_blank"
      >
        https://www.paradigm.co/help/products/derivatives/venues
      </a>
    </p>
    <p>Notes:</p>
    <p>
      a. No transactions in a US Cleared Product may be negotiated through the
      System unless submitted to the relevant Clearing Organization through the
      System for clearance by such Clearing Organization.
    </p>
    <p>
      b. A “futures contract” has the meaning given to such term under US law.
      As a general matter, a “futures contract” is a contract to buy or sell a
      given digital asset (including BTC and ETC, for example), in the future at
      a pre-agreed price or based on a reference point, where actual delivery
      does not occur. If a User has questions regarding whether a given
      transaction may constitute a “futures contract” or an option, they should
      contact their lawyer.
    </p>
    <p>
      c. Paradigm does not list any of its own tradable contracts or securities.
      Paradigm simply provides a communication platform for the bilateral
      negotiation of terms for contracts specified in the above table.
    </p>
    <p>
      d. All contract and other relevant specifications including but not
      limited to any exchange fees, minimum tick sizes, minimum quote sizes and
      corresponding margin requirements are set by and subject to agreements,
      rules and regulations set by the relevant Clearing Organization.
    </p>
    <h2>2. Submission and Clearing Venue</h2>
    <p>
      As listed with the “US Cleared” label at:{' '}
      <a
        href="https://www.paradigm.co/help/products/derivatives/venues"
        target="_blank"
      >
        https://www.paradigm.co/help/products/derivatives/venues
      </a>
    </p>
    <h2>3. Eligible Persons and Entities</h2>
    <p>
      a. Only Users that have signed and agreed to the Terms of Service or
      similar agreement with the relevant Clearing Organization, and have all
      other necessary contractual arrangements (such as give-up agreements and
      agreements with their futures commission merchant (“FCM”)) and that have
      an active account at the relevant Clearing Organization are eligible to
      negotiate transactions in US Cleared Products utilizing the System.
    </p>
    <p>
      b. The following Persons are not eligible to utilize the System to
      negotiate transactions in US Cleared Products:
    </p>
    <p>
      i. Any person or entity that is located in or organized under the laws of
      a US embargoed country, province, or region;
    </p>
    <p>
      ii. Any person or entity listed on the US Treasury Department’s list of
      Specially Designated Nationals or the US Department of Commerce Denied
      Person’s List or Entity List;
    </p>
    <p>
      iii. Any person or entity not permitted under applicable law to utilize
      the System to negotiate futures or options transactions in the
      jurisdiction(s) in which they are located; and
    </p>
    <p>
      iv. Any person or entity who is a resident of or located in a country,
      province, or region that is disallowed from accessing or utilizing
      services offered by the relevant Clearing Organization.
    </p>
    <p>
      c. In order to utilize the System, a User must (i) have a minimum
      financial instrument portfolio, including both cash deposits and financial
      instruments, in excess of US$500,000; (ii) the sophistication to
      understand the transactions that the User (or its Authorized Users) are
      negotiating themselves through the System; and (iii) otherwise satisfy all
      legal requirements to use the System (including the legal ability to enter
      into transactions) in the jurisdiction(s) in which they are located.
    </p>
    <p>
      By usage of the System to negotiate transactions in US Cleared Products, a
      User represents and warrants, on an ongoing basis, that it satisfies the
      eligibility criteria set forth above in the preceding paragraphs of this
      Section 3.
    </p>
    <h2>4. No Improper Actions</h2>
    <p>
      Each User agrees (i) not to use the System to engage in abusive trading
      practices, including in connection with any transactions that the User
      communicates about on the System (abusive trading practices include, but
      are not limited to, the following: wash trading, market manipulation,
      front-running, accommodation trading, or fraudulent trading); (ii) to only
      use the System for communication purposes and (iii) not to use the System
      in any way that would violate Paradigm’s agreements with the applicable
      Clearing Organization. For all purposes of the User Agreement and this
      Annex, references to “Software” include the API, in the case of US Cleared
      Products being submitted for clearing on the Chicago Mercantile Exchange
      Inc. or its subsidiaries (“CME”), that is utilized by Paradigm to submit
      transactions in such Products to CME for clearing.
    </p>
    <h2>5. Process</h2>
    <p>
      a. Request For Quote (RFQ) and Bilateral Price Negotiation/Agreement
      Process
    </p>
    <p>
      i. To initiate bilateral negotiations between two Users, each User must
      first “approve” each other in their web-dashboard. Each User is
      responsible for their own KYC, AML or any other verification processes
      deemed necessary to be compliant with the User’s regulatory
      jurisdiction(s).
    </p>
    <p>
      ii. Once approved, direct communication channels can be opened between
      Users wherein both Users (or their Authorized User) can exchange text
      based RFQs and come to a price agreement for the product/strategy
      requested. All communication for RFQs, responses, and price agreements can
      be automated through Paradigm buttons and “/” commands.
    </p>
    <p>
      iii. All Users are solely responsible for all risks (including market,
      credit and process risks) related to or arising out of the transactions
      negotiated by themselves or their Authorized Users, and Paradigm is not
      responsible for any such risks.
    </p>
    <p>b. Confirmation Process</p>
    <p>
      An agreement is reached when the initiating counterparty (“Taker”)
      confirms, via the Trade Recap popup screen, the terms put forth in the RFQ
      by the responding counterparty (“Maker”). After an agreement is reached,
      all agreement details are (i) displayed within the conversation via a
      Trade Recap; (ii) displayed in each user’s in-app blotter; and (iii)
      summarized in a Trade Recap email to each user in the “Trade Channel.”
    </p>
    <p>c. Settlement and Clearing Process</p>
    <p>
      The System will submit affirmed (matched, dual-sided) price-agreement
      details to the relevant Clearing Organization along with complete account
      and party information (including FCM Firm Number, Account Name and
      Entering Trader ID(s)) for both the buy side and sell side of the trade to
      be cleared by the relevant Clearing Organization. The relevant Clearing
      Organization will validate these trades for party, account, product and
      limits and acknowledges the submitter. If the trade fails any validations,
      the trade is rejected and a negative acknowledgement is sent to the
      submitter. The submitter should contact the relevant Clearing Organization
      if they have any questions regarding a rejected trade (in the case of CME,
      to the CME’s Global Command Center, which Users can access via their CME
      Group Login).
    </p>
    <p>d. Disputes</p>
    <p>
      Disputes regarding transactions (including with respect to whether an
      agreement has been reached, the terms of that agreement, or the breach of
      that agreement) are solely subject to, and resolved in accordance with,
      the rules of the relevant Clearing Organization. To the extent a
      transaction is not subject to dispute resolution under the relevant
      Clearing Organization’s rules, such dispute must be directly resolved
      between the applicable Users. Paradigm will not be involved in any dispute
      resolution, and each User agrees to not hold Paradigm liable for any
      dispute with the other applicable User or clearing venue.
    </p>
  </>
);

export default UsAnnexAgreement;
