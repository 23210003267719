import { Draft } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import {
  isValidDecimal,
  parseFormattedFloat,
} from '@paradigm/utils/formatDecimal';
import { VIEW_RATIO_PRECISION } from '@paradigm/features/src/unified-rfqs/ui/ratio-precision';
import { getProduct } from '#/products/domain';
import { TradeLeg, TradeTapeItem, TradeTapeLeg } from '../entities/drfq/trades';
import { DrfqLeg } from '../entities/drfq/domain';

interface LegItem {
  quantity: string | undefined;
  price?: string | null;
  isHedge: boolean;
}

/**
 * This function finds the base leg in a custom strategy, that is:
 * The leg that have the minimum quantity
 *
 * @param state - Current form state
 * @returns base leg
 */
export function findBaseLegIndex<T extends Draft<LegItem>>(legs: T[]): number {
  let minQty = Infinity;
  let minQtyIndex = -1;

  legs.forEach((leg, index) => {
    const { quantity } = leg;
    if (quantity == null || !isValidDecimal(quantity)) return;

    // Base leg can't be a hedge leg
    if (leg.isHedge) return;
    const numQty = parseFormattedFloat(quantity);

    if (numQty < minQty) {
      minQty = numQty;
      minQtyIndex = index;
    }
  });

  return minQtyIndex;
}

function getNonHedgeLegIndexForOptionsStrategies(
  legs: readonly TradeLeg[] | readonly TradeTapeLeg[] | readonly DrfqLeg[],
): number {
  return legs.findIndex(({ product_code }) => {
    const product = getProduct(product_code);
    return product.kind !== 'FUTURE';
  });
}

export function findDrfqBaseLegIndex(
  kind: TradeTapeItem['kind'],
  legs: readonly TradeLeg[] | readonly TradeTapeLeg[] | readonly DrfqLeg[],
): number {
  switch (kind) {
    case 'OPTION':
    case 'OPTION_FUTURE':
      return getNonHedgeLegIndexForOptionsStrategies(legs);
    case 'FUTURE':
      // future spreads pick first leg
      return 0;
    default:
      throw new TypeError('Not known Trade kind');
  }
}

export function findDrfqBaseLeg(
  kind: TradeTapeItem['kind'],
  legs: readonly TradeLeg[] | readonly TradeTapeLeg[] | readonly DrfqLeg[],
): TradeLeg | TradeTapeLeg | DrfqLeg | undefined {
  try {
    return legs[findDrfqBaseLegIndex(kind, legs)];
  } catch (error) {
    if (error instanceof TypeError) {
      console.error(error.message);
      return undefined;
    }
    throw error;
  }
}

export function roundedLegRatio(ratio: string) {
  const bnRatio = BigNumber(ratio);
  const fullPrecision = bnRatio.dp();

  if (fullPrecision == null || !bnRatio.isGreaterThan(0)) {
    return { roundedRatio: '', isRounded: false };
  }

  const isRounded = fullPrecision > VIEW_RATIO_PRECISION;
  const roundedValue = bnRatio.toFixed(
    VIEW_RATIO_PRECISION,
    BigNumber.ROUND_DOWN,
  );
  const postfix = isRounded ? '…' : '';

  const roundedRatio = `${roundedValue}${postfix}`;

  return { roundedRatio, isRounded };
}
