import localStorage from '@paradigm/utils/src/safeLocalStorage';

export interface Migration<T> {
  (value: string): T | null;
}

export interface StoredEntry<T> {
  get: () => T | string | null;
  set: (item: string) => void;
  remove: () => void;
}

/**
 * Creates an object to manipulate an entry in the local storage.
 * A migration function can be passed to test whether the read value remains
 * valid and if not, transform it to a valid value. It's also an opportunity
 * to make any kind of parsing.
 *
 * @param key Key to store the value at and read the value from
 * @param migrate Function to transform the read value
 */
export function createStoredEntry<T>(
  key: string,
  migrate?: Migration<T> | undefined,
) {
  const set = (item: string) => localStorage.setItem(key, item);

  /**
   * Reads the value from the storage and runs
   * it through the migration function.
   *
   * @returns Either `null` when the value is not found or the migrated value.
   */
  const get = (): T | string | null => {
    const value = localStorage.getItem(key);
    if (value === null) return null;
    return migrate != null ? migrate(value) : value;
  };

  const remove = () => localStorage.removeItem(key);

  return { set, get, remove };
}
