import { ComponentPropsWithoutRef } from 'react';
import { Input, Label, Slider, Text } from './styles';

export interface ToggleProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'size'> {
  /**
   * checked state
   */
  checked: boolean;
  /**
   * Accessibility: aria label for finding element
   */
  ariaLabel: string;
  /**
   * input name
   */
  name?: string;
  /**
   * displayed label next to the toggle
   */
  label?: string;
  /**
   * Position of the label
   */
  labelPlacement?: 'right' | 'left';
  /**
   * Whether the field is disabled
   */
  disabled?: boolean;
  /**
   * toggle component size
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * fluid: whether it takes 100$ width
   */
  fluid?: boolean;
  /**
   * className to customize the container
   */
  className?: string;
}

export default function Toggle({
  label,
  ariaLabel,
  disabled,
  name,
  className,
  checked,
  labelPlacement = 'right',
  size = 'medium',
  fluid = false,
  onChange,
  ...rest
}: ToggleProps) {
  return (
    <Label
      aria-label={ariaLabel}
      labelPlacement={labelPlacement}
      fluid={fluid}
      size={size}
      disabled={disabled}
      className={className}
      htmlFor={name}
    >
      <Input
        {...rest}
        onChange={onChange}
        type="checkbox"
        inputSize={size}
        checked={checked}
        aria-label={checked ? 'checked' : 'unchecked'}
        id={name}
        disabled={disabled}
      />
      <Slider id={`${name}-slider`} size={size} />
      {label != null && (
        <Text labelPlacement={labelPlacement} size={size}>
          {label}
        </Text>
      )}
    </Label>
  );
}
