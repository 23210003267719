/* eslint-disable no-irregular-whitespace, no-useless-escape */

const UserAgreementMarkdown = `
### User Agreement ###

Last updated: 29 February 2024



This USER AGREEMENT (this “Agreement”) sets forth the terms and conditions upon which the user set forth in the associated user registration form (“User”) desires to utilize the communication platform that automates price negotiation and settlement workflows for OTC digital asset traders (the “System”) offered by Paradigm Connect Asia Pte. Ltd. (“Paradigm”). Capitalized terms used but not defined in the text of this Agreement have the meanings given to such terms in Section 8.10.

NOW, THEREFORE, in consideration of the agreements and obligations set forth herein and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Paradigm and the undersigned User hereby agree as follows:



# Agreement

**Article I**

**No Services to Restricted Persons**

PARADIGM DOES NOT PROVIDE THE SYSTEM OR SOFTWARE TO, AND THE SYSTEM AND SOFTWARE MAY NOT BE ACCESSED OR USED BY, ANY PERSON OR ENTITY THAT IS:

●	A “US PERSON” (AS DEFINED UNDER US COMMODITY FUTURES AND SECURITIES LAWS AND AS LISTED BELOW IN THE NON-US PRODUCTS ANNEX);  

●	A “CANADIAN PERSON” (AS DEFINED UNDER CANADIAN SECURITIES LAWS);

●	A “SINGAPORE PERSON” (AS DEFINED UNDER SINGAPORE SECURITIES LAWS) THAT IS NOT AN INSTITUTIONAL INVESTOR OR ACCREDITED INVESTOR (EACH AS DEFINED UNDER THE SECURITIES AND FUTURES ACT 2001 OF SINGAPORE (“SFA”)) OR A RELEVANT PERSON DESCRIBED IN SECTION 275 OF THE SFA;
THE SUBJECT OF ECONOMIC SANCTIONS OR OTHER TRADE OR EXPORT RESTRICTIONS IMPOSED OR ADMINISTERED BY THE UNITED STATES, THE EUROPEAN UNION OR ANY OF ITS MEMBER STATES, THE UNITED KINGDOM OR SINGAPORE; OR

●	A CITIZEN, RESIDENT, ORGANIZED OR HAS A BRANCH IN A JURISDICTION OR TERRITORY THAT IS THE SUBJECT OF COMPREHENSIVE COUNTRY-WIDE, TERRITORY-WIDE, OR REGIONAL ECONOMIC SANCTIONS BY THE UNITED STATES, THE EUROPEAN UNION OR ANY OF ITS MEMBER STATES, THE UNITED KINGDOM OR SINGAPORE (INCLUDING AS LISTED ON PARADIGM’S WEBSITE AT: https://www.paradigm.co/help/miscellaneous/restricted-jurisdictions) 

(COLLECTIVELY, “RESTRICTED PERSONS”).

ADDITIONAL RESTRICTIONS AND REQUIRED QUALIFICATIONS TO ACCESS AND USE OF THE SYSTEM AND SOFTWARE ARE FOUND IN THE NON-US PRODUCT ANNEX.  IF YOU ARE A RESTRICTED PERSON, OR DO NOT SATISFY THE ADDITIONAL RESTRICTIONS AND QUALIFICATIONS LISTED IN THE NON-US PRODUCT ANNEX, DO NOT ATTEMPT TO ACCESS OR USE THE SYSTEM OR SOFTWARE.

ACCESS AND USAGE OF THE SYSTEM AND SOFTWARE IS SUBJECT TO PARADIGM’S ONBOARDING AND PERIODIC RE-VERIFICATION REVIEW.  PARADIGM RESERVES THE RIGHT TO DENY ACCESS AND USAGE OF THE SYSTEM AND SOFTWARE TO ANY PERSON OR ANY ENTITY AT ANY TIME, INCLUDING AFTER REGISTRATION, IN PARADIGM’S SOLE DISCRETION.



**Article II**

**License grant and limitations**

2.1 Grant of License.   Subject to the terms and conditions of this Agreement, Paradigm hereby grants User a personal, limited, royalty-free, non-exclusive, revocable, non-transferable and non-sublicenseable right to, during the Term, access and use the System.

2.2 Authorized Users, Desk Administrator and View Only Access.

(a) User may allow one or more of its Representatives to access the System by providing such specified Representatives with a unique password and user identification combination (each, an “Access Method”) provided or otherwise specified by Paradigm (such users, “Authorized Users”).  User is solely responsible for ensuring that only Authorized Users have access to the System through User or User’s Access Methods and that Authorized Users access the System using only the Access Method assigned to such user.  Paradigm may terminate, revoke, suspend, modify, or change any or all Access Methods assigned to User at any time with or without prior notice.  User shall, and shall cause each of its Authorized Users to, keep the Access Methods strictly confidential using at least the same degree of care that User uses to protect its own Confidential Information. User is solely responsible for ensuring the security of its and its Authorized Users’ connection to the System.  If any Access Method assigned to User is lost, stolen or compromised, User agrees to notify Paradigm as soon as practicable.

(b) At Paradigm’s request, User agrees to provide Paradigm with a complete list of User’s current Authorized Users and their countries of domicile.  User acknowledges that, in Paradigm’s discretion, Paradigm may deny access to the System to any of User’s Authorized Users, at any time and for any reason.  User will ensure all Authorized Users comply with all relevant terms of this Agreement and any failure to do so will constitute a breach by User. User is and will be solely responsible for all acts or omissions of its Authorized Users. Without limitation of the foregoing or any other provision of this Agreement, User agrees to be bound by the terms of all Message Data entered and orders placed on or through the System by its Authorized Users or using its Access Methods.  All transmissions generated through use of its Access Methods will be deemed to be authorized by User and made by an Authorized User whether or not Paradigm acknowledges receipt of such transmission.

(c)  Each User as part of its sign-up and ongoing account administration will designate a “desk administrator,” who among other things is (1) the initial point of contact for legal notices from Paradigm (such as updates to the User Agreement) and (2) adds and deletes Authorized Users and persons with view-only access to the User’s account.

(d) Each User (through their desk administrator) may also designate one or more persons who have view-only access (and not trading access) to the User’s account.

2.3 Appropriate Use.  User agrees to use the System and send and receive Message Data only for its own benefit and account(s), and not to use the System or any other software provided pursuant to this Agreement (“Software”) on behalf of third parties without Paradigm’s prior written consent.  User agrees not to, and shall cause each of its Authorized Users not to, do any of the following without Paradigm’s prior written consent: (a) use the System or the Software for any purposes other than as set forth herein; (b) copy any portion of the System or Software; (c) resell or otherwise distribute any of its Access Methods to any other party (other than Authorized Users); (d) reverse engineer, decompile, disassemble or otherwise attempt to discern the source code, underlying ideas, algorithms, software design and architecture or interface protocols of the System or Software; (e) modify, adapt, translate or reproduce the System or Software, merge any part of the System or Software with or into other software, or create derivative works based upon the System or Software; (f) use the System or Software or any Message Data to operate in or as a time-sharing, outsourcing, or service bureau environment, or as an application service provider, or in any way allow others to use the System, Software or Message Data for the benefit of any third party; or (g) take or authorize any action that could detrimentally interfere with the proper workings of the System or Software, use any robot, spider or other device or process to monitor or copy the System or Software, or knowingly transmit any virus, malware or other potentially harmful device in connection with use of the System or Software.

2.4 User Responsibilities.  User will be solely responsible for any losses, damages or costs that may be incurred as a result of errors made by, or the failure of, or delays caused by, the software or equipment that is used by User or any of its Authorized Users to access the System or Software, whether or not such software or equipment, including but not limited to any and all telecommunications circuits or other services, is provided by Paradigm, any Affiliate of Paradigm, its, theirs or other third party licensors or service providers.  User’s use of the System and Software is at the User's own discretion and risk and User is solely responsible for any damage, servicing, repair or corrections to its computer systems or other devices, as well as any interruptions in business operations or loss of data that results from such use or inability to use the System or Software.

2.5 Market Data; Product Annexes; Additional Terms of Use.  Market data, product-specific annexes or additional terms for use of the System or Software may be set forth in a separate supplement to this Agreement or on the System user interface, or otherwise made available to User pursuant to Section 2.12, and User agrees to be bound by such additional terms.

2.6 Applications.   Paradigm may, from time to time, make available applications created by or on behalf of third parties, which can be used on or with the System (the “Applications”).  User acknowledges and agrees that the Applications are provided entirely “as-is” and that Paradigm makes no representations or warranties or assumes any liability regarding any such Applications that User may choose to purchase, download or otherwise access and User further agrees to comply with any additional restrictions on usage of such Applications that Paradigm or such third party may communicate to User from time to time, including any such restrictions that may be contained in any license agreement by and between User and such third party licensors.

2.7 Limited Remote Support.  Paradigm agrees to provide reasonable technical support at the times and in the manner set forth on Paradigm’s website.

2.8 No Warranty.  Paradigm does not warrant or guarantee that the System or Software will be uninterrupted or error-free or that the System will meet the requirements of User or any Authorized User.

2.9 No Brokerage Relationship with Paradigm; No Responsibility for Message Data.  User acknowledges and agrees that: (a) Paradigm is not now, and shall not be by virtue of this Agreement, a party to, the executing broker-dealer for or exchange for, any orders or transactions that result from Message Data that User sends through the System; (b) although the System may transmit the Message Data that User sends through the System, Paradigm is not providing any service of dealing in (whether buying or selling) any products and is not accepting any orders for execution and is not responsible for the execution of any orders; (c) Paradigm is not responsible for any party to any transaction resulting from the transmission of Message Date; (d) Paradigm is not providing any advice (including investment related advice or the advisability of trading) to User or any Authorized User and (e) Paradigm has no obligation to accept or transmit all or any part of any Message Data that is sent through the System.  Without limitation of the foregoing, Paradigm has no responsibility for transmissions that are inaccurate, are not received or are delayed by the System. For the avoidance of doubt, User is not a brokerage customer of Paradigm.

2.10 Title to System.  All right, title and interest in and to the System and Software, including the concepts, specifications, interfaces and technology and other Intellectual Property inherent in the System and Software, are, and at all times shall remain, the sole and exclusive property of Paradigm or an Affiliate of Paradigm.  Except for the limited right to use the System and Software, as set forth herein, nothing contained in this Agreement shall directly or indirectly be construed to assign or grant to User or any Authorized Users any right, title or interest in and to the Intellectual Property of Paradigm or any Affiliate of Paradigm or any ownership rights in or to the System and Software.  User agrees not to remove, obscure, or alter any proprietary rights notices that may be affixed to or contained within the System, Software, or any software or documentation provided in connection with this Agreement.  User agrees to protect and not violate any proprietary rights of Paradigm or any Affiliate of Paradigm or those of its third party licensors or service providers and to honor and comply with Paradigm’s reasonable requests to protect its, and any of Paradigm’s Affiliates’ and third party licensors’ and service providers’ contractual, statutory and common law rights in the System, Software, and any related software and documentation.  If User becomes aware of any violation of the proprietary rights of Paradigm, any Affiliate of Paradigm, any third party licensors or service providers in the System or Software, User agrees to promptly notify Paradigm in writing.

2.11 Export Control.  Sanctions and Other Trade Restrictions.  The System and Software may be subject to the sanctions, export control, and other trade-related laws of Singapore, the United States and other countries.  In particular, but without limitation, the System may not be utilized by, and Software may not be exported, re-exported or provided to, any person (natural or legal) or territory that is the subject of economic sanctions or other trade or export restrictions imposed or administered by the (a) the government of the United States, including the U.S. Department of the Treasury and the U.S. Department of Commerce; (b) the European Union (“EU”) and any of its member states; (c) the United Kingdom; or (d) the Republic of Singapore (all such persons and territories described in (a)-(d) above, “Sanctioned Persons” and “Sanctioned Jurisdictions,” respectively).  User agrees to comply with all applicable sanctions, export control, and other trade-related laws, and User represents, warrants and covenants that none of User nor any of its Authorized Users, during the Term: (a) is or will be a Sanctioned Person; (b) will attempt to use the System or Software from any Sanctioned Jurisdiction; and (c) will provide, or otherwise make available, directly or indirectly, the System or Software to any Sanctioned Person or Sanctioned Jurisdiction.

2.12 Electronic Communications.  User hereby consents to the delivery of any required or optional communication under this Agreement or under any applicable law, including changes in the terms and conditions of this Agreement necessitated by regulatory or compliance with information security requirements (as provided below), via the System, by e-mail, website or other electronic means, subject to compliance with any applicable Law. Any such documents that are delivered electronically are deemed to be “in writing.” If a signature or acknowledgment is required or requested with respect to any such document and any Authorized User “clicks” in the appropriate space, or takes such other action as may be indicated on the System, or otherwise continues using the System thereafter, User will be deemed to have signed or acknowledged the document to the same extent and with the same effect as if User had signed the document manually.  User acknowledges that it has the right to withdraw its consent to the electronic delivery and signature of documents at any time by providing prior written notice to Paradigm.  However, if User revokes its consent, its access to the System may be restricted or terminated.   User acknowledges that Paradigm may, if required for regulatory reasons  or to comply with information security requirements, modify any of the terms set forth in this Agreement via any of the notification methods set forth above.  If User does not consent to any such modifications, User’s sole right will be to terminate this Agreement in accordance with Section 3.

2.13 Regulatory Cooperation.  User agrees to cooperate with any reasonable request that Paradigm may make in order to respond to any inquiries made by any exchanges or Governmental Authorities in connection with the System or User’s or an Authorized User’s use of the System.



**Article III**

**Fees and Expenses**

3.1 Fees and Expenses. User is responsible for, and agrees to pay, any and all fees, costs and expenses associated with or that arise from entitlement to use the System, including the execution and settlement of transactions (for example, commissions and other fees payable to broker-dealers, exchanges, data-providers or other Persons in connection with the execution of transactions, telecommunications, market data, hardware, and other connectivity costs, and costs of any third party software, equipment and any related maintenance services). Unless otherwise directed by Paradigm, User will pay such fees, costs and expenses to or on behalf of Paradigm in accordance with Paradigm’s payment instructions and Paradigm’s payment cycle, if any.



**Article IV**

**Term and Termination**

4.1 Term.  This Agreement shall be effective as of the date User or its Representative accepts this Agreement and will continue in effect until terminated in accordance with this Article 3 (the “Term”).

4.2 Mutual Termination Rights.  Either party may terminate this Agreement at any time, with or without cause, upon thirty (30) days’ written notice to the other party.

4.3 Paradigm Termination or Suspension Rights.  Regardless of any other provision of the Agreement, Paradigm has the right (a) to immediately suspend for any period of time as Paradigm believes is necessary or advisable or terminate (at any time, for any reason, with or without cause or prior notice) all or any part of the System or User’s or any or all Authorized Users’ access to the System or any component thereof, (b) to change the nature, composition or availability of the System or any component thereof, or (c) to change any controls, limits or parameters applicable to any Message Data that may be transmitted through the System.  Paradigm shall use commercially reasonable efforts to promptly notify User of any such suspension, termination or change.

4.4 Effect of Termination.  In the event of termination of this Agreement for any reason whatsoever, User shall, and shall cause each of its Authorized Users to, cease any use of the System and to delete or destroy any copies of the Software and any Confidential Information of Paradigm in its or their possession.  Notwithstanding any such termination, this Agreement will remain in effect with respect to Message Data transmitted by User or any Authorized User through the System prior to the time of the effective notice of termination.   Termination of this Agreement shall not relieve either party of liability for any act, omission, occurrence or liability occurring prior to the expiration or termination of this Agreement.  Sections 2.4, 2.8, 2.10, 2.13, 4.4 and 6.2 and Articles V, VII and VIII shall survive expiration or termination of this Agreement for any reason.



**Article V**

**Confidentiality**

5.1 Non-Disclosure.   Subject to the remainder of this Article IV, neither party shall disclose or make, in either case, publicly available, and shall cause its Representatives to not disclose or make, in either case, publicly available, any Confidential Information of the other party furnished or made available pursuant to this Agreement.  In any case, each party shall, and shall cause its Representatives to, exercise the same standard of care to protect such information as it uses to protect its own proprietary data, which in no event shall be less than reasonable care and further agrees to take reasonable precautions (including at least those measures that it takes to protect its own Confidential Information) to protect the secrecy of and prevent the unauthorized use of Confidential Information.

5.2 Exceptions from Confidentiality.  Notwithstanding anything to the contrary in this Agreement, each party may disclose, without any liability hereunder, that portion (and only that portion) of the Confidential Information (a) that is requested or required by any Governmental Authority having jurisdiction over the receiving party or its Representatives, or that the receiving party deems it advisable to provide to such a Governmental Authority, whether in connection with an audit, examination or otherwise, (b) that the receiving party reasonably determines to be necessary or appropriate to disclose to a Governmental Authority or (c) that is reasonably necessary to defend itself against any claim or proceeding brought against the receiving party or its Representatives.

5.3 Additional Exceptions from Confidentiality.

(a)  In addition to the provisions of Section 5.2, but subject to Section 5.3(b), a party may disclose Confidential Information of the other party (i) if and to the extent such Confidential Information is required by subpoena, court order or similar process to be disclosed and/or (ii) to the extent such disclosure is otherwise required by applicable Law or requirement of any stock exchange on which the shares or stock of the receiving party or an Affiliate thereof are listed, in which case the receiving party will provide the disclosing party with prompt written notice of such disclosure.

(b)   If the receiving party is requested or required (by subpoena, court order or similar process) to disclose any Confidential Information of the disclosing party as provided in Section 5.3(a), the receiving party shall (i) provide the disclosing party with prompt written notice of such request or requirement and (ii) make reasonable efforts to cooperate with the disclosing party so that the disclosing party may seek to set aside the subpoena or court order and/or seek other appropriate remedy or protection.  If such remedy or protection is not obtained, or the disclosing party waives compliance with the provisions of this Agreement, the receiving party shall use reasonable efforts to disclose only that portion of the Confidential Information that is legally requested or required to be disclosed.

5.4 Return or Destruction of Confidential Information Upon Termination.  Upon termination of this Agreement (or earlier at the request of Paradigm), User shall promptly return or destroy, at User’s sole discretion, all Confidential Information of Paradigm and any copies of documents, papers or other material which may contain or be derived from any Confidential Information of Paradigm that is in its possession.  At the request of Paradigm, User shall certify that it has satisfied its obligations under this Section 5.4.

5.5 Message Data.   Notwithstanding anything to the contrary in this Agreement, Paradigm may use and provide its Affiliates with Message Data in aggregated/anonymous form for any purpose, including for purposes of advertising trading volume or other statistical information, or in various marketing materials and publications.  Paradigm and its Affiliates may, in Paradigm’s  sole discretion, resell, distribute, market or license any or all aggregated/anonymous Message Data to any other Person (other than its Representatives), or otherwise use any or all such data as Paradigm or its Affiliates see fit and retain all compensation related thereto; provided, that neither Paradigm nor its Affiliates shall identify any User or Authorized User in connection with any Message Data, except: (a) as required pursuant to applicable Law; (b) for dispute resolution purposes with User or any other Person; or (c) to legal or accounting advisors on a confidential basis.  For the avoidance of doubt, this Section 5.5 shall not apply to any Message Data that a User or Authorized User transmits to one or more other parties through the System, as well as responses thereto.



**Article VI**

**Representations, Warranties and Covenants**

6.1 Representations, Warranties and Covenants.  User hereby represents and warrants to Paradigm, as of the Effective Date, and continuously throughout the Term, that:

(a) User has the legal capacity and authority to enter into this Agreement, to use the System and to transmit or receive Message Data through the System.  The execution, delivery and performance of this Agreement have been duly approved and authorized by all necessary action of User.  This Agreement constitutes the legal, valid and binding obligation of User, enforceable against such party in accordance with its terms, except as enforcement thereof may be limited by bankruptcy, insolvency, reorganization, moratorium, receivership, conservatorship, and other laws relating to or affecting creditors’ rights generally and by general principles of equity.

(b) User’s execution and delivery of this Agreement and its performance of this Agreement will not (i) conflict with, violate or result in any breach of any of the terms and provisions of, or constitute (with or without notice or lapse of time or both) a default under, any material indenture, contract, agreement, mortgage, deed of trust, or other instrument to which User is a party or by which it or any of its properties are bound; (ii) violate User’s constitution, memorandum and articles of association or any other constituent documents; (iii) require any consent of approval under any judgment, order, memorandum of understanding, writ, decree, permit or license to which User is a party or by which its assets are bound; or (iv) require the consent or approval of any other party to any material contract, instrument, or commitment to which User is a party or by which it is bound.

(c) User’s use of the System complies and will comply with (i) all applicable Laws; (ii) the policies and practices of any securities, futures and/or digital asset exchanges and clearing houses, alternative trading facilities, and self-regulatory organizations applicable to User and to Message Data entered by User or its Authorized Users on or through the System, and (iii) this Agreement and any other agreement (including any product annex) between User and Paradigm or any of its Affiliates, as may be amended from time to time.  User has all consents, rights, authority and has taken all actions necessary, to use the System and to transmit or receive Message Data through the System, as set forth herein.

6.2 Disclaimer of Warranties.  THE SYSTEM AND SOFTWARE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.  PARADIGM MAKES NO WARRANTIES REGARDING THE CORRECTNESS, QUALITY, ACCURACY, SECURITY, COMPLETENESS, RELIABILITY, PERFORMANCE, TIMELINESS, PRICING OR CONTINUED AVAILABILITY OF THE SYSTEM, SOFTWARE, OR FOR THE FAILURE OF ANY CONNECTION OR COMMUNICATION SERVICE TO PROVIDE OR MAINTAIN ACCESS TO THE SYSTEM, OR FOR ANY INTERRUPTION OR DISRUPTION OF ACCESS TO THE SYSTEM OR SOFTWARE.  PARADIGM SPECIFICALLY DISCLAIMS ALL WARRANTIES FOR THE SYSTEM AND SOFTWARE, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, OPERATION OF THE SYSTEM AND SOFTWARE AND ANY PARTICULAR APPLICATION OR USE OF THE SYSTEM AND SOFTWARE.  NEITHER PARADIGM NOR ANY OF ITS AFFILIATES WARRANTS THAT THE  SYSTEM OR SOFTWARE WILL MEET USER’S OR ITS AUTHORIZED USERS’ REQUIREMENTS, OR THAT THE OPERATION OF THE SYSTEM AND SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE FOREGOING CAN OR WILL BE CORRECTED.  NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY PARADIGM OR ITS AFFILIATES SHALL CREATE ANY WARRANTIES OR IN ANY WAY INCREASE THE SCOPE OF PARADIGM’S OBLIGATIONS HEREUNDER.



**Article VII**

**Indemnification and Limitation of Liability**

7.1 Indemnification.

(a) User hereby agrees, to the maximum extent permitted under applicable Law, to indemnify and hold harmless Paradigm and its Representatives and its and their respective successors and permitted assigns (collectively, the “Paradigm Indemnified Parties”) from and against any Losses of any Paradigm Indemnified Party arising out of or related to: (i) the use of the System and Software by User or the Authorized Users, including any breaches of the security of the System; (ii) if User or any of its Affiliates is a broker-dealer, investment manager, investment adviser or other party acting on behalf of customers, any claims that a trade was not suitable for or not authorized by a customer of such broker-dealer, investment manager or investment adviser; (iii) any breach by User of this Agreement, including the breach of any representation or warranty or failure by User to perform any covenant of User under this Agreement; (iv) any other acts or omissions of User or any of its Representatives or the Authorized Users; or (v) any violation of or noncompliance with applicable Law by User or any Authorized User.  Paradigm shall notify User reasonably promptly in writing of any such claim or suit; provided, that the failure to so notify User shall not limit User’s indemnification obligations under this Section 7.1(a), unless User is materially adversely prejudiced thereby.  Paradigm shall be entitled to defend any such claim or suit as it determines in its sole discretion.  User agrees to provide Paradigm with full information and assistance (including of or relating to its Authorized Users) in settling and/or defending such claim or suit.

(b) EXCEPT IN THE CASE OF A BREACH OF A PARTY’S  OBLIGATIONS OF CONFIDENTIALITY IN ARTICLE IV OR USER’S OBLIGATIONS PURSUANT TO SECTION 7.1(A), THE PARTIES EXPRESSLY AGREE THAT IN NO EVENT SHALL PARADIGM BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING FROM OR RELATED IN ANY WAY TO THIS AGREEMENT, THE SYSTEM OR THE SOFTWARE, INCLUDING BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, OR ANY OTHER LEGAL THEORY, WHETHER IN TORT OR CONTRACT, EVEN IF A PARTY IS AWARE OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING, INCLUDING COMPENSATION, REIMBURSEMENT OR DAMAGES ON ACCOUNT OF THE LOSS OF PRESENT OR PROSPECTIVE PROFITS, EXPENDITURES, TRADING LOSSES, INVESTMENTS OR COMMITMENTS, WHETHER MADE IN THE ESTABLISHMENT, DEVELOPMENT OR MAINTENANCE OF BUSINESS REPUTATION OR GOODWILL, FOR LOSS OF DATA, OR FOR ANY OTHER REASON WHATSOEVER.  NEITHER PARADIGM NOR ANY OF THE PARADIGM INDEMNIFIED PARTIES SHALL BE RESPONSIBLE FOR ANY LOSSES RESULTING FROM ALTERATION OR UNAUTHORIZED USE OF THE SYSTEM OR THE SOFTWARE, OR FROM THE UNINTENDED AND UNFORESEEN RESULTS OBTAINED BY USER OR ANY AUTHORIZED USERS RESULTING FROM SUCH USE.  TERMINATION OF THIS AGREEMENT PURSUANT TO SECTION 4 SHALL NOT RESULT IN ANY LIABILITY OF PARADIGM OR ANY OF THE PARADIGM INDEMNIFIED PARTIES TO USER OR ANY AUTHORIZED USER.  EXCEPT IN THE CASE OF A BREACH OF A PARTY’S  OBLIGATIONS OF CONFIDENTIALITY UNDER ARTICLE IV OR USER’S OBLIGATIONS PURSUANT TO SECTION 7.1(A), TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EACH PARTY’S  TOTAL, AGGREGATE LIABILITY TO THE OTHER PARTY SHALL NOT EXCEED US$10,000 FOR ALL DAMAGES OF EVERY KIND AND TYPE (WHETHER IN CONTRACT OR TORT).

7.2 User Acknowledgments.  User and its Authorized Users are solely responsible for any losses, damages or costs that may be incurred as a result of errors made by, or the failure of, or delays caused by, the software or equipment used by User or its Authorized Users, as applicable, to connect to the System.  User acknowledges that its and each of its Authorized Users’ connection to the System is at its and their discretion and risk, and User or its Authorized Users, as applicable, shall be solely responsible for any damage, servicing, repair or corrections to its computer systems or other devices, as well as any interruptions in business operations or loss of data that results from such connection to or inability to connect to the System.  User acknowledges and agrees that Paradigm shall have no responsibility for transmissions that are inaccurate, not received or are delayed by the System or by User’s or its Authorized Users’ connection to the System, and may transmit any message on the terms actually received by the System.  Additionally, Paradigm shall have no liability or responsibility for any regulatory or other reporting or record-keeping requirements of User arising in connection with any order or instruction transmitted to or by User or any Authorized User.

7.3 Third Party Systems.  Message Data transmitted by User or Authorized Users through the System will be transmitted through or routed to third party systems (each, a “Third Party System”).  None of Paradigm or any Paradigm Indemnified Party will be responsible for any Losses that may result from errors made by any Third Party System in reading, processing, responding to or executing such Message Data, or if any Third Party System otherwise fails to properly read, process, respond to or execute the transactions described in such Message Data.



**Article VIII**

**Miscellaneous**

8.1 Governing Law; Dispute Resolution.

(a) This Agreement, and all matters or disputes arising out of or in connection with this Agreement, the subject matter hereof or the activities of the parties in connection with or contemplated by this Agreement, including any question regarding the interpretation, existence, validity or termination of this Agreement (“Dispute”), shall be governed by, construed under and enforced in accordance with the laws of Singapore.

(b) The parties shall cooperate in good faith to resolve any Dispute. If any such Dispute cannot be resolved within sixty (60) days of the relevant parties commencing discussions to resolve such Dispute, each of the parties hereby irrevocably agrees that any Dispute shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the SIAC for the time being in force (“Rules”), which Rules are hereby incorporated by reference. The seat of the arbitration shall be Singapore. The tribunal shall consist of three (3) arbitrators, and the arbitration shall be conducted in English. The International Arbitration Act, Chapter 143A of Singapore (“IAA”) shall apply to the arbitration. The decision and award of such tribunal duly constituted pursuant to this Section 8.1(b) and the Rules (which may include specific performance and injunctive relief) shall be final and valid, non-appealable, binding upon all of the parties to the Dispute, and enforceable in accordance with applicable law by any party to such Dispute in any court of competent jurisdiction.

(c) Notwithstanding the provisions of Section 8.1(b), a party to a Dispute may, insofar as it is entitled to under the IAA, elect to seek temporary injunctive relief from any court of competent jurisdiction at any time to preserve its rights while a Dispute is being resolved by arbitration. The parties acknowledge that damages may be an inadequate remedy for any breach of this Agreement and, therefore, any Person to whom performance is owed under any provision of this Agreement shall be entitled to an injunction to be issued, or specific enforcement to be required, to require any other Person to perform its obligations under this Agreement and prevent the other party from breaching, or continuing to breach, any provision of this Agreement (in each case without posting any bond or other security). An election by a party to a court of competent jurisdiction for interim measures necessary to preserve the parties’ rights shall not be deemed incompatible with, or a waiver of, this agreement to arbitrate.

8.2 Notices.  Except as provided in Section 2.12, all notices and demands pertaining to this Agreement shall be in writing and may be given in any manner described below to the address or number set out below, and will be deemed effective as follows: (a) if delivered in person or by overnight courier service, on the date it is delivered; and (b) if sent via electronic mail to the correct electronic mail address, at the time of transmission; unless the date that delivery would be deemed effective is not a working day or the communication is given after the close of business on a working day in the place where the notice is delivered, in which case that communication will be deemed given and effective the first following day that is a working day.   The initial addresses and contact details of the parties are as follows:



**Paradigm:**

Paradigm Connect Asia Pte. Ltd.  
1 Irving Place  
\#08-11 The Commerze@Irving  
Singapore 369546  
Attn: General Counsel  

User: As set forth in User’s onboarding form with Paradigm

Each party may change its address or contact details from time to time by giving notice to that effect as provided in this Agreement.

8.3 Waiver.  Except as otherwise expressly provided herein, no delay, failure or waiver by either party to exercise any right or remedy under this Agreement and no partial or single exercise of any such right or remedy, will operate to limit, preclude, cancel, waive or otherwise affect such right or remedy, nor will any single or partial exercise of such right or remedy limit, preclude, impair or waive any further exercise of such right or remedy or the exercise of any other right or remedy.  For purposes of this Agreement, no course of dealing among any or all of the parties hereto shall operate as a waiver of the rights hereof.  Except as otherwise expressly provided herein, the rights and remedies herein provided shall be cumulative and not exclusive of any rights or remedies provided by applicable Law.  No provision hereof may be waived otherwise than by a written instrument signed by the party so waiving such provision as contemplated herein.

8.4 Entire Agreement and Modification.  This Agreement (including any annexures and/or supplement to this Agreement referred to herein or as may be contemplated by this Agreement) constitutes the entire agreement between the parties with respect to the matters contemplated hereby and supersedes all prior and contemporaneous oral or written agreements or understandings of the parties. Except as otherwise provided in this Agreement (including Section 2.12), all subsequent changes and modifications, to be valid, shall be by written instrument executed by each party.

8.5 Assignment; Successors.  The provisions of this Agreement shall be binding upon and inure to the benefit of the parties and their successors and permitted assigns.  User may not and shall not assign this Agreement or any of its rights hereunder without the prior written consent of Paradigm.  Paradigm may assign this Agreement or any of its rights hereunder without the consent of User to any successor in interest of Paradigm. Any purported assignment or delegation made in violation of this Section 8.5 shall be void and of no force or effect.

8.6 No Third Party Rights.  This Agreement shall be binding upon and inure solely to the benefit of the parties hereto, their successors and permitted assigns, and nothing in this Agreement, express or implied, other than Article VII (in the case of indemnitees), is intended to or shall confer upon any other Person any right under the Contracts (Rights of Third Parties) Act, Chapter 53B of Singapore.

8.7 Severability.  If any provision of this Agreement is held to be illegal, invalid, or unenforceable under any present or future applicable laws, such provision will to the extent necessary be severable; this Agreement will be construed and enforced as if such illegal, invalid, or unenforceable provision had never comprised a part of this Agreement; and the remaining provisions of this Agreement will remain in full force and effect and will not be affected by the illegal, invalid, or unenforceable provision or by its severance from this Agreement.

8.8 Expenses.  Except as otherwise provided herein, each party hereto shall bear its own expenses incident to the preparation, negotiation, execution and delivery of this Agreement and the performance of its obligations hereunder or thereunder.

8.9 Counterparts; Interpretation.  This Agreement may be executed in one or more counterparts, each of which will be deemed to be an original copy of this Agreement and all of which, when taken together, will be deemed to constitute one and the same agreement. In this Agreement, unless the context otherwise requires: (a) reference herein to the singular shall include the plural and vice versa and reference to any gender shall include all genders; (b) the terms “Article”, “Section”, and “Exhibit” followed by a number, letter, or combination of numbers and letters refer, unless expressly contemplated otherwise, to the specified article or section of, or exhibit to, this Agreement, as amended, restated or replaced from time to time; reference herein to another agreement shall be construed as a reference to such other agreement as the same may have been, or may from time to time be, amended, supplemented or novated; the division of this Agreement into Articles, Sections, Schedules and Exhibits, the provision of a table of contents and the use of headings are for convenience of reference only and shall not affect the construction or interpretation of this Agreement; (c) use of the words “includes” and “including” and similar terms of inclusion will not, unless expressly modified by the words “only” or “solely”, be construed as terms of limitation, but rather will mean “includes but is not limited to” and “including but not limited to”, such that references to included matters will be regarded as illustrative and not defining or exhaustive; and (d) if an ambiguity or question of intent or interpretation arises as to any aspect of this Agreement, then it will be construed as if drafted jointly by the parties and no presumption or burden of proof will arise favoring or disfavoring either party by virtue of the authorship of any provision of this Agreement.

8.10 Definitions.  Capitalized terms used but not defined in the body of this Agreement have the following meanings:

“Affiliate” means, with respect to a Person, any other Person, directly or indirectly, controlling, controlled by, or under common control with, such Person.

“Business Day” means any day which is not a Saturday, Sunday or any public or legal holiday in the United States or Singapore on which banks in Singapore and the United States are authorized or required to close for retail banking operations.

“Confidential Information” means non-public, proprietary or confidential information that is labeled as such or should reasonably be understood by the recipient to be confidential; provided, that Confidential Information shall not include any information that is (a) generally known to the industry or the public other than as a result of the recipient’s breach of this Agreement, (b) made legitimately available to a recipient by a third party without breach of any confidentiality obligation or (c) developed by such recipient without use of Confidential Information.

“Governmental Authority” means any Singapore or foreign regulatory, self-regulatory, judicial, arbitral, or governmental or quasi-governmental authority, or any agency, department, instrumentality, or sub-division thereof; or self-regulatory organization; or other Person exercising, or entitled to exercise, any administrative, executive, judicial, legislative, police, regulatory or taxing authority or power.

“Intellectual Property” means all intellectual property rights, comprising, arising from or associated with the following:  (a) Internet domain names; (b) trademarks, service marks, certification marks, logos, symbols and any other indicia of origin and any registrations or applications therefor, together with any goodwill symbolized by the foregoing; (c) patents and patent applications, inventions and discoveries (whether or not patentable); (d) works of authorship and copyrights (including rights in computer software) whether registered or unregistered and any applications therefor; (e) trade secret rights in information and know-how, including trade secret rights in any formula, pattern, compilation, program, device, method, technique, or process, that (i) derives independent economic value, actual or potential, from not being generally known to, and not being readily ascertainable by proper means by, other Persons who can obtain economic value from its disclosure or use, and (ii) is the subject of efforts that are reasonable under the circumstances to maintain its secrecy; and (f) all other intellectual property or proprietary rights that may be protectable under applicable Law (including with respect to data and databases).

“Law” means any federal, state, county, local or foreign statute, law, ordinance, regulation, rule, constitution, treaty, code, order or rule of common law.

“Losses” means any and all losses, penalties, fines, costs, damages (and any interest due thereon), liabilities, amounts paid in settlements and offsets and any reasonable out-of-pocket costs, expenses and attorneys’ fees and penalties and interest.

“Message Data” means instructions or messages entered into, transmitted through or made available on the System (including pre-trade, post-trade and other message related data).

“Person” means any individual, partnership, limited partnership, foreign limited partnership, corporation, limited liability company, trust, estate, Governmental Authority, association, joint venture, pool, syndicate, sole proprietorship, joint stock company, unincorporated organization, custodian, trustee, executor, administrator, nominee and any other form of entity in its own or representative capacity.

“System” means Paradigm’s communication platform that automates price negotiation and settlement workflows for OTC digital asset traders.

“Representatives” means, with respect to either party, its Affiliates, and its and their managers, officers, directors, employees, consultants, independent contractors and agents.



### Non-US Products Annex ###

Last updated: 29 February 2024

This Non-US Products Annex (as amended from time to time, this “Annex”) is an annex to the User Agreement (the “User Agreement”) that the related User has executed with respect to their usage of the communications software provided by Paradigm Connect Asia Pte. Ltd. (“Paradigm”) and relates to Non-US Products (as defined below). This Annex supplements and forms an integral part of the User Agreement with respect to Non-US Products. Capitalized terms used but not defined in this Annex have the meanings given to such terms in the User Agreement.

Continued usage of the System to negotiate transactions in Non-US Products after an updated version of this Annex is posted on Paradigm’s website constitutes agreement by the User to the terms of this Annex, as in effect from time to time.



**1\. Eligible Products and Transactions**

“DOV” means Decentralized Options Vault, and includes the User that accepted the User Agreement with Paradigm on behalf of itself and its related DOV.

“DOV Auction” means an auction involving a DOV.

“Non-US Product” means:

●   a future or an option on a digital asset,

●   a basis trade on a digital asset or

●   any other digital asset or product relating to a digital asset

in each case (1) for which Paradigm supports trading and (2) that is settled and/or cleared on Settlement Venue supported by Paradigm. Non-US Products do not include, and Paradigm does not support, privacy coins or tokenized securities (as defined under the federal securities laws of the United States). The list of Non-US Products supported by Paradigm can be found at [https://paradigm.co/help/products/derivatives/venues](https://paradigm.co/help/products/derivatives/venues)

“Settlement Venue” means, with respect to any Non-US Product, the relevant settlement and/or clearing venue or protocol for such Non-US Product. The “Settlement Venue” for a DOV is its related smart contract.

Notes:

a. No transactions in a Non-US Product may be negotiated through the System unless submitted to the relevant Settlement Venue through the System for settlement or clearance by such Settlement Venue.

b. A “futures contract” has the meaning given to such term under US law. As a general matter, a “futures contract” is a contract to buy or sell a given digital asset (including BTC and ETC, for example), in the future at a pre-agreed price or based on a reference point, where actual delivery of the digital asset is not expected to occur. If a User has questions regarding whether a given transaction may constitute a “futures contract” or an option, they should contact their lawyer.

c. Paradigm does not list any of its own tradable contracts or securities. Paradigm simply provides a communication platform for the negotiation of Non-US Products.

d. All contract and other relevant specifications including but not limited to any exchange fees, minimum tick sizes, minimum quote sizes and corresponding margin requirements are set by and subject to agreements, rules and regulations set by the relevant Settlement Venue.

e. In the case of a DOV Auction, the rules of the Auction, together with the smart contract address and details of the DOV, are determined by the DOV.



**2\. Supported Settlement Venues**

See [https://paradigm.co/help/products/derivatives/venues](https://paradigm.co/help/products/derivatives/venues)



**3\. Eligible Persons and Entities**

None of the following persons, entities, wallet addresses or protocols are eligible to utilize the System for Non-US Products:

i. “U.S. Persons,” which are defined under U.S. law as

(A)	any natural person who is a resident of the United States, including its territories and possessions;

(B)	any estate of a decedent who was a resident of the United States at the time of death;

(C)	any corporation, partnership, limited liability company, business, or other trust, association, joint stock company, fund or any form of enterprise similar to any of the foregoing that

(1)	is organized, incorporated or established under the laws of a state or other jurisdiction in the United States or

(2)	has its principal place of business in the United States, including its territories and possessions (for purposes of this Annex, principal place of business means the location from which the officers, partners, or managers of the legal person primarily direct, control, and coordinate the activities of the legal person. With respect to an externally managed investment vehicle, this location is the office from which the manager of the vehicle primarily directs, controls, and coordinates the investment activities of the vehicle);

(D)	any pension plan for the employees, officers, or principals of any corporation, partnership, limited liability company, business, or other trust, association, joint stock company, fund or any form of enterprise similar to any of the foregoing, unless the pension plan is primarily for foreign employees of such entity;

(E)	any trust governed by the laws of a state or other jurisdiction in the United States if a court within the United States is able to exercise primary supervision over the trust;

(F)	any commodity pool, pooled account, investment fund or other collective investment vehicle that is majority-owned by one or more persons or entities described above, except for those investment vehicles that are publicly offered only to non-U.S. persons and not offered to U.S. persons;

(G)	any legal entity (other than a limited liability company, limited liability partnership, or similar entity where all of the owners have limited liability) that is directly or indirectly majority-owned by one or more persons described above and in which such person(s) bears unlimited responsibility for the obligations and liabilities of the legal entity; and

(H)	any individual or joint account (discretionary or not) where the beneficial owner (or one of the beneficial owners in the case of a joint account) is a person described above; or

ii.   Any person or entity that is:

●   A “Canadian person” for purposes of Canadian securities law; or

●   A “Singapore Person” (as defined under Singapore securities laws) that is not an institutional investor or accredited investor (each as defined under the Securities and Futures Act 2001 of Singapore (“SFA”)) or a relevant person described in Section 275 of the SFA; or

iii.	Any person, entity, wallet address or protocol that is:

●   The subject of economic sanctions or other trade or export restrictions imposed or administered by the United States, the European Union or any of its member states, the United Kingdom or Singapore; 

●   A citizen, resident, organized or has a branch in a jurisdiction or territory that is the subject of comprehensive country-wide, territory-wide, or regional economic sanctions by the United States, the European Union or any of its member states, the United Kingdom or Singapore (including as listed on Paradigm’s website at: [https://www.paradigm.co/help/miscellaneous/restricted-jurisdictions](https://www.paradigm.co/help/miscellaneous/restricted-jurisdictions)); or  

iv.  Any person or entity that is:

●   Not permitted under applicable law to utilize the System in the jurisdiction(s) in which they are located; and/or

●   Not allowed to access or utilize services by the relevant Settlement Venue, including as a result of the jurisdiction in which they are located or resident.  



**4\. User Requirements**

a. Settlement Venue Onboarding and Requirements (All Settlement Venues other than DOVs)

i.	Prior to utilizing any Services, Users must first satisfy all applicable requirements with the relevant Settlement Venue(s), including: (1) agreeing to the Terms of Service or similar agreement with those Settlement Venue(s) and (2) having all other necessary contractual arrangements (such as give-up agreements and agreements with any applicable intermediary), in place with those Settlement Venue(s).

ii. In addition, each User must be KYC-verified by the relevant Settlement Venue(s). Each User is responsible for any additional KYC, AML or any other verification processes deemed necessary to be compliant with their counterparty User’s regulatory jurisdiction(s).

iii. If a User ceases to have a valid arrangement in place with a particular Settlement Venue, a User will not be able to negotiate in Non-US Products that are settled or cleared on that Settlement Venue.

Compliance with Settlement Venue requirements is in addition to, and does not replace, a User’s obligations to Paradigm.

b. DOVs

All matters relating to DOVs and DOV Auctions are determined by the relevant DOV. Users wishing to participate in a DOV Auction are responsible on their own for any diligence or verification on the DOV, its related smart contract and such DOV Auction.

c. Financial and Sophistication Requirements

**Users other than Residents of the United Kingdom or the European Economic Area:**

In order to utilize the System, a User must

●   have a minimum financial instrument portfolio, including both cash deposits and financial instruments, in excess of 500,000 EUR (or equivalent in non-Euro currencies)

●   the sophistication to understand the transactions that the User (or its Authorized Users) are negotiating themselves through the System and

●   otherwise satisfy all legal requirements to use the System (including the legal ability to enter into transactions) in the jurisdiction(s) in which they are located.

**Residents of the United Kingdom or the European Economic Area:**

Residents of the United Kingdom or the European Economic Area are only eligible to use the System if they elect to be treated as "Professional Clients". “Professional Clients” are defined under the following regulations: (i) Natural Persons: Section II of Annex II of Directive 2014/65/EU on markets in financial instruments ("MiFID II") entitled "Clients Who May Be Treated As Professionals On Request"; and under rule COBS 3.5.3 R of the rules of the UK Financial Conduct Authority (the “FCA”); and (ii) Legal Entities: Section I of Annex II of Directive 2014/65/EU (Markets in Financial Instruments Directive (MiFID)) entitled "CATEGORIES OF CLIENT WHO ARE CONSIDERED TO BE PROFESSIONALS" and under rule 3.5.2 of the rules of the FCA.

By entering into this User Agreement with Paradigm, a User that is a resident of the United Kingdom or the European Economic Area elects to be treated as a “Professional Client” with respect to the System, and makes the following representations and warranties on an ongoing basis to Paradigm for so long as they remain a User:

i. **Natural Persons**: The User (i) has a minimum financial instrument portfolio, including both cash deposits and financial instruments, in excess of 500,000 EUR (or equivalent in non-Euro currencies); (ii) has the sophistication to understand the transactions that the User (or its Authorized Users) are negotiating themselves through the System; (iii) either (A) has carried out transactions in significant size at an average frequency of 10 per quarter over the previous four quarters in digital assets markets OR (B) currently works or has worked in the financial sector for at least one year in a professional position which requires knowledge of digital assets transactions or services and (iv) otherwise satisfies all legal requirements to use the System (including the legal ability to enter into transactions) in the jurisdiction(s) in which they are located.

ii. **Legal Entities:**

(A) The User is one of the following types of entities: (1) an entity required to be authorised or regulated to operate in the financial markets, including a credit institution, an investment firm, any other authorised or regulated financial institution, an insurance company, a collective investment scheme or the management company of such a scheme, a pension fund or the management company of a pension fund, a commodity or commodity derivatives dealer or any other institutional investor; (2) a large undertaking meeting two of the following size requirements on a company basis: a balance sheet total of EUR 20,000,000, net turnover of EUR 40,000,000 or having funds of EUR 2,000,000; or (3) another institutional investor whose main activity is to invest in financial instruments and with a minimum financial instrument portfolio, including both cash deposits and financial instruments, in excess of 500,000 EUR (or equivalent in non-Euro currencies), AND

(B) The User (i) has the sophistication to understand the transactions that the User (or its Authorized Users) are negotiating themselves through the System; and (ii) otherwise satisfies all legal requirements to use the System (including the legal ability to enter into transactions) in the jurisdiction(s) in which they are located.

By usage of the System to negotiate transactions in or trade Non-US Products, a User represents and warrants, on an ongoing basis, that it satisfies the eligibility criteria set forth above in the preceding paragraphs of this Section 4.

**Singapore Persons**

“Singapore persons” (as defined under Singapore securities laws) are only eligible to use the System if they are (1) an institutional investor or accredited investor (each as defined under the SFA) or a relevant person described in Section 275 of the SFA.  To be an “accredited investor,” a User must elect to be treated as an accredited investor for all purposes of Singapore law, which, by entering into this User Agreement with Paradigm, that User does so elect.

In addition, in the case of an individual, that individual represents and warrants to Paradigm on an ongoing basis to Paradigm for so long as they remain a User that they satisfy at least 1 of the 3 following criteria:

●   The User’s income in the preceding 12 months has exceeded SGD300,000 (or its equivalent in a foreign currency);

●   The User’s net personal assets exceed SGD2 million (or its equivalent in a foreign currency) in value, of which the net value of their primary place of residence can only contribute up to SGD1 million; OR

●   The User’s net financial assets (stocks, bonds, crypto, etc.) exceed SGD1 million (or its equivalent in a foreign currency) in value.



**5\. No Improper Actions**

Each User agrees:

●   Not to use the System to engage in abusive trading practices (abusive trading practices include, but are not limited to, the following: wash trading, market manipulation, front-running, accommodation trading, or fraudulent trading) and

●   Not to use the System in any way that would violate Paradigm’s agreements with the applicable Settlement Venue.



**6\. Basis Trades and Other Package Transactions**

In the case of a basis trade or other package transaction, the User represents and warrants to Paradigm that it is entering into the transaction with the bona fide intent that all legs of such transaction be treated as a single, integrated transaction, and not with the intent of entering into only one leg of the transaction.



**7\. User Responsibility for Transactions**

Users are solely responsible for all risks (including market, credit and process risks) related to or arising out of the transactions negotiated by themselves or their Authorized Users, and Paradigm is not responsible for any such risks.

Users participating in a DOV Auction are responsible for all risks associated with transacting with the DOV, including verifying the smart contracts to which any assets are sent. Responsibility for performance or non-performance by a User and the relevant DOV is a matter solely between such User and the DOV. Paradigm is not a party to any contract or transaction between a User and a DOV.



**8\. Settlement and Clearing Process**

In the case of all transactions other than DOV Auctions, the System will submit affirmed (matched, dual-sided) price-agreement details to the relevant Settlement Venue along with complete account and party information (including Account Name and Entering Trader ID(s)) for both the buy side and sell side of the trade to be settled or cleared by the relevant Settlement Venue. The relevant Settlement Venue will validate these trades for party, account, product and limits and acknowledge the submitter. If the trade fails any validations, the trade is rejected and a negative acknowledgement is sent to the submitter. The submitter should contact the relevant Settlement Venue if they have any questions regarding a rejected trade.

DOV Auctions settle on-chain in accordance with the rules of the underlying blockchain protocol and the relevant DOV’s smart contracts.



**9\. Disputes**

In the case of all transactions other than DOV Auctions, disputes regarding transactions (including with respect to whether an agreement has been reached, the terms of that agreement, or the breach of that agreement) are solely subject to, and resolved in accordance with, the rules of the relevant Settlement Venue.

To the extent a transaction is not subject to dispute resolution under the relevant Settlement Venue’s rules (including in the case of a DOV Auction), such dispute must be directly resolved between the applicable Users. Paradigm will not be involved in any dispute resolution, and each User agrees to not hold Paradigm liable for any dispute with the other applicable User or Settlement Venue.
`;

export default UserAgreementMarkdown;
