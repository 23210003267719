import * as types from './types';

import {
  loginFailure,
  loginRequest,
  loginSuccess,
  logoutSuccess,
  sessionReuseAttemptStart,
  sessionReuseAttemptSuccess,
  sessionReuseAttemptFailure,
  twoFACodeRequired,
  getUserInfoSuccess,
} from './actions';

export interface AuthState {
  user: AuthUser;
  isAuthenticated: boolean;
  isAttemptingSessionReuse: boolean;
  loginError: string;
  updateUserError: string;
  updateUserSuccess: string;
  changePasswordError: string;
  changePasswordSuccess: string;
  changeNotificationsSuccess: string;
  isLoggingIn: boolean;
  restrictedIpWarning: boolean;
  agreementOverlay: boolean;
}

type AuthUser = types.AuthUser;
type TradingDesk = types.TradingDesk;

export const INITIAL_STATE: AuthState = {
  user: {
    id: 0,
    email: '',
    color: '',
    first_name: '',
    firm: '',
    last_name: '',
    has_totp_enabled: false,
    visual_mobile_notifications_enabled: false,
    audio_mobile_notifications_enabled: false,
    active_products: [],
    has_accepted_us_agreement: false,
    has_accepted_non_us_agreement: false,
    paradex_token: '',
    preferences: { data: {} },
  },
  isAuthenticated: false,
  // All apps must make a session reuse attempt on startup.
  // If you are stuck on an infinite loading because this is true,
  // ensure that the session reuse attempt is being made.
  isAttemptingSessionReuse: true,
  loginError: '',
  updateUserError: '',
  updateUserSuccess: '',
  changePasswordError: '',
  changePasswordSuccess: '',
  changeNotificationsSuccess: '',
  isLoggingIn: false,
  restrictedIpWarning: false,
  agreementOverlay: false,
};

export default function authReducer(
  state: AuthState = INITIAL_STATE,
  { type, payload }: { type?: string; payload?: Record<string, unknown> } = {},
): AuthState {
  switch (type) {
    case sessionReuseAttemptStart.type:
      return {
        ...state,
        isAttemptingSessionReuse: true,
      };
    case sessionReuseAttemptSuccess.type:
      return {
        ...state,
        isAttemptingSessionReuse: false,
      };
    case sessionReuseAttemptFailure.type:
      return {
        ...state,
        isAttemptingSessionReuse: false,
        isAuthenticated: false,
        user: { ...INITIAL_STATE.user },
      };
    case loginRequest.type:
      return {
        ...state,
        isLoggingIn: true,
        loginError: '',
      };
    case twoFACodeRequired.type:
      return {
        ...state,
        loginError: '',
        user: {
          ...state.user,
          has_totp_enabled: true,
        },
        isLoggingIn: false,
      };
    case loginSuccess.type:
      return {
        ...state,
        user: payload!.user as AuthUser,
        isAuthenticated: true,
        isLoggingIn: false,
        loginError: '',
      };
    case getUserInfoSuccess.type:
      return {
        ...state,
        user: payload as AuthUser,
      };
    case loginFailure.type:
      return {
        ...state,
        isAuthenticated: false,
        isLoggingIn: false,
        loginError: payload!.message as string,
      };
    case logoutSuccess.type:
      return {
        ...INITIAL_STATE,
        // Below are flags for independent tasks that are not affected
        isAttemptingSessionReuse: state.isAttemptingSessionReuse,
        isLoggingIn: state.isLoggingIn,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: payload as AuthUser,
        updateUserSuccess: 'Profile updated successfully!',
        updateUserError: '',
      };
    case types.UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
          trading_desk: mergeTradingDesks(
            state.user.trading_desk!,
            payload!.trading_desk as TradingDesk | null,
          ),
        },
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserError: payload as unknown as string,
        updateUserSuccess: '',
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordError: '',
        changePasswordSuccess: 'Your password has been changed successfully!',
      };
    case types.CHANGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          audio_notifications_enabled: payload!.audio_notifications_enabled,
          visual_notifications_enabled: payload!.visual_notifications_enabled,
          email_notifications_enabled: payload!.email_notifications_enabled,
          trading_notifications_enabled: payload!.trading_notifications_enabled,
          audio_mobile_notifications_enabled: payload!
            .audio_mobile_notifications_enabled as AuthUser['audio_mobile_notifications_enabled'],
          visual_mobile_notifications_enabled: payload!
            .visual_mobile_notifications_enabled as AuthUser['visual_mobile_notifications_enabled'],
        },
      };
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordError: payload as unknown as string,
        changePasswordSuccess: '',
      };
    case types.CLEAR_AUTH_MESSAGES:
      return {
        ...state,
        changePasswordSuccess: '',
        changeNotificationsSuccess: '',
        updateUserSuccess: '',
      };
    case types.TOGGLE_IP_WARNING:
      return {
        ...state,
        restrictedIpWarning: !state.restrictedIpWarning,
      };
    case types.SHOW_AGREEMENT_OVERLAY:
      return {
        ...state,
        agreementOverlay: true,
      };
    case types.ACCEPTED_AGREEMENT:
      return {
        ...state,
        agreementOverlay: false,
      };
    default:
      return state;
  }
}

function mergeTradingDesks(oldDesk: TradingDesk, newDesk: TradingDesk | null) {
  if (newDesk === null) return null;
  if (typeof newDesk === 'object') return { ...oldDesk, ...newDesk };
  return oldDesk;
}
