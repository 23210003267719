import { BaseReq } from '@paradigm/api/src/fetch-api';

export interface ListFeatureFlagReq extends BaseReq {}

interface RawFeatureFlag {
  readonly is_enabled: boolean;
  readonly default_value?: boolean | string | number;
}

export type RawFeatureFlags = Record<string, RawFeatureFlag>;

export interface FeatureFlag {
  readonly isEnabled: boolean;
  readonly value?: boolean | string | number | JSON;
}

export type FeatureFlags = Record<string, FeatureFlag>;

export function processRawFeatureFlag(data: RawFeatureFlags): FeatureFlags {
  return Object.fromEntries(
    Object.entries(data).map(([key, flag]): [string, FeatureFlag] => [
      key,
      {
        isEnabled: flag.is_enabled,
        value: flag.default_value,
      },
    ]),
  );
}
