import { exceptionLogger } from '@paradigm/logging/src/logging';

import notificationAudioWin from '#/assets/notification_win.ogg';
import notificationAudioOsx from '#/assets/notification_osx.ogg';

import logoSrc from '#/assets/logo.png';

let sound;
if (window.navigator.platform.includes('Mac')) {
  sound = new Audio(notificationAudioOsx);
} else if (window.navigator.platform.includes('Win')) {
  sound = new Audio(notificationAudioWin);
}

/**
 * Maximum length for notification message.
 * See https://electronjs.org/docs/tutorial/notifications
 */
const NOTIFICATION_MESSAGE_LIMIT = 195;

/**
 * Prepares message to be used in a notification
 * @param {string} text
 */
function sanitizeMessage(text) {
  return text.length > NOTIFICATION_MESSAGE_LIMIT
    ? `${text.slice(0, NOTIFICATION_MESSAGE_LIMIT)}...`
    : text;
}

/**
 * @param {object} param
 * @param {string} param.notificationId
 * @param {string} param.title
 * @param {string | null} param.message
 * @param {string | () => string} [param.icon]
 * @param {boolean} param.silent
 * @param {(() => void) | null} [param.onClick]
 */
export function fireNotification({
  notificationId,
  title,
  message,
  icon = logoSrc,
  silent,
  onClick = null,
}) {
  (async () => {
    if (typeof Notification === 'undefined') return;

    if (Notification.permission !== 'granted')
      await Notification.requestPermission();

    // Do not rely on the OS to play the notification sound.
    // Play sound regardless of the permission given to the platform.
    if (!silent) playNotificationSound();

    if (Notification.permission !== 'granted') return;

    const notification = new Notification(title, {
      body: message != null ? sanitizeMessage(message) : '',
      icon: typeof icon === 'function' ? icon() : icon,
      silent: true,
      tag: notificationId,
    });
    notification.onclick = onClick;
  })().catch(exceptionLogger('Error firing notification.'));
}

export function playNotificationSound() {
  sound?.play().catch(() => {
    /* do nothing */
  });
}
