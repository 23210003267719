import { Dispatch, SetStateAction, useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import { MOBILE_BREAKPOINT } from '#/containers/layout/constants';
import { AgreementDocument } from './types';

interface Props {
  readonly agreement: AgreementDocument;
  readonly setActiveAgreement: Dispatch<SetStateAction<string>>;
  readonly handleVisibilityChange: (scrolledBottom: boolean) => void;
}

export default function AgreementBody({
  handleVisibilityChange,
  agreement,
  setActiveAgreement,
}: Props) {
  const containerRef = useRef(null);

  const handleChange = (id: string) => (visible: boolean) => {
    if (visible) setActiveAgreement(id);
  };

  return (
    <Container ref={containerRef}>
      {agreement.content.map(({ Component, id }) => (
        <VisibilitySensor
          key={id}
          partialVisibility
          containment={containerRef.current}
          onChange={handleChange(id)}
        >
          <section id={id} className="mb-4">
            <Component />
          </section>
        </VisibilitySensor>
      ))}
      {/* always keep this as bottom element on page */}
      <VisibilitySensor
        active
        onChange={handleVisibilityChange}
        containment={containerRef.current}
      >
        <div style={{ visibility: 'hidden', height: 1 }}>agreement bottom</div>
      </VisibilitySensor>
    </Container>
  );
}

const Container = styled.article`
  margin: 0 0 0 23px;
  scroll-behavior: smooth;
  background-color: #0f2038;
  overflow: auto;
  padding: 0 1rem;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
  h1 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin: 0 0 10px;
    padding: 10px 0 0;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    margin: 20px 0 10px;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  h4 {
    font-size: 0.9rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    margin: 10px 0;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.25rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    margin: 0 0 10px;
  }
  ul {
    font-size: 0.9rem;
    line-height: 1.25rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
  }
`;
