import { useEffect, useState } from 'react';
import pRetry from 'p-retry';
import {
  ApiWebSocket,
  AsyncWSResp,
  SUBSCRIBE_TIMEOUT_MS,
  WSReqParams,
} from '@paradigm/api/src/internal/createWebSocket';
import { useEffectOnce } from '@paradigm/utils/src/hooks';
import { logException } from '@paradigm/logging/src/logging';
import createTopic from '@paradigm/utils/createTopic';
import { WSAbortError } from '@paradigm/logging/src/errors';

interface BaseWsRequest<
  C extends string,
  M extends string = 'subscribe' | 'unsubscribe',
> extends WSReqParams {
  readonly method: M;
  readonly params: {
    readonly channel: C;
    readonly data?: unknown;
  };
}

interface WebSocketRequest<C extends string> {
  (req: BaseWsRequest<C, 'subscribe'>): AsyncWSResp<readonly string[]>;
  (req: BaseWsRequest<C, 'unsubscribe'>): AsyncWSResp<readonly string[]>;
}

interface PartialApiWebSocket<Channel extends string>
  extends Pick<
    ApiWebSocket,
    'addEventListener' | 'removeEventListener' | 'close' | 'readyState'
  > {
  request: WebSocketRequest<Channel>;
}

export interface WsInstance<
  Channel extends string,
  WebSocket extends PartialApiWebSocket<Channel>,
> {
  readonly ws: WebSocket;
  /**
   * @param handler Function executed when subscription is
   * established or fails. When already subscribed (or failed)
   * the handler will be executed synchronously with the call.
   * @returns Function to clear handler
   */
  readonly onSubscribed: (handler: SubscribedHandler) => CleanupFn;
}

export interface WebSocketStatus {
  readonly error: string;
  readonly connected: boolean;
  readonly subscribed: boolean;
  readonly subscribing: boolean;
}

export type SubscribedHandler = (didSucceed: boolean) => void;

type CleanupFn = () => void;

const INITIAL_STATUS: WebSocketStatus = {
  error: '',
  connected: false,
  subscribed: false,
  subscribing: false,
};

export default function useWsInstance<
  Channel extends string,
  WebSocket extends PartialApiWebSocket<Channel>,
>(
  createWsInstance: () => WsInstance<Channel, WebSocket>,
): [ws: WebSocket, status: WebSocketStatus] {
  const [{ ws, onSubscribed }] = useState(createWsInstance);
  const [status, setStatus] = useState(INITIAL_STATUS);

  useEffect(() => {
    const handleOpen = () => {
      setStatus({
        error: '',
        connected: true,
        subscribing: true,
        subscribed: false,
      });
    };
    ws.addEventListener('open', handleOpen);

    const handleClose = () => {
      setStatus({
        error: 'Disconnected from Paradigm servers. Trying to reconnect...',
        connected: false,
        subscribing: false,
        subscribed: false,
      });
    };
    ws.addEventListener('close', handleClose);

    const handleError = () => {
      setStatus({
        error: 'Error connecting to Paradigm servers',
        connected: false,
        subscribing: false,
        subscribed: false,
      });
    };
    ws.addEventListener('error', handleError);

    const clearSubscribedHandler = onSubscribed((didSucceed) => {
      setStatus((currentStatus) => ({
        ...currentStatus,
        error: didSucceed ? '' : "Couldn't subscribe to Paradigm channels",
        subscribing: false,
        subscribed: didSucceed,
      }));
    });

    return () => {
      ws.removeEventListener('open', handleOpen);
      ws.removeEventListener('close', handleClose);
      ws.removeEventListener('error', handleError);
      clearSubscribedHandler();
    };
  }, [onSubscribed, ws]);

  // Close connection on unmount
  useEffectOnce(() => () => ws.close(1000));

  return [ws, status];
}

interface SubscribedWsInstanceArgs<WsChannel, WebSocket> {
  readonly ws: WebSocket;
  readonly channels: readonly WsChannel[];
  readonly topicName: string;
}

export function createSubscribedWsInstance<
  WsChannel extends string,
  WebSocket extends PartialApiWebSocket<WsChannel>,
>({
  ws,
  channels,
  topicName,
}: SubscribedWsInstanceArgs<WsChannel, WebSocket>): WsInstance<
  WsChannel,
  WebSocket
> {
  let isSubscribed: boolean | undefined;
  const subscribedTopic = createTopic<boolean>(topicName);

  ws.addEventListener('open', () => {
    let closed = false;

    subscribeToChannels<WsChannel, WebSocket>(ws, channels, topicName)
      .then((results) => {
        if (closed) return;
        const didSucceed = results.every(([, didSubscribe]) => didSubscribe);
        subscribedTopic.publish(didSucceed);
        isSubscribed = didSucceed;
      })
      .catch((error) => {
        if (closed) return; // Ignore error due to the disconnection
        logException(
          `Unexpected failure subscribind to ${topicName} channels`,
          error,
        );
      });

    const handleClose = () => {
      closed = true;
      ws.removeEventListener('close', handleClose);
    };
    ws.addEventListener('close', handleClose);
  });

  ws.addEventListener('close', () => {
    isSubscribed = undefined;
  });

  return {
    ws,
    onSubscribed(handler) {
      if (isSubscribed != null) handler(isSubscribed);
      const clearSubscription = subscribedTopic.subscribe(handler);
      return clearSubscription;
    },
  };
}

// 5 attempts with exponential backoff in 5 minutes total
const RETRY_OPTIONS = { factor: 3.863, retries: 5 };

type ChannelSubResult<Channel> = readonly [channel: Channel, result: boolean];

export async function subscribeToChannels<
  WsChannel extends string,
  WebSocket extends PartialApiWebSocket<WsChannel>,
>(
  ws: WebSocket,
  channels: readonly WsChannel[],
  topicName: string,
  signal?: AbortSignal,
): Promise<ChannelSubResult<WsChannel>[]> {
  const promises = channels.map(async (channel) =>
    pRetry<ChannelSubResult<WsChannel>>(async () => {
      try {
        const resp = await ws.request({
          method: 'subscribe',
          params: { channel },
          signal,
          timeout: SUBSCRIBE_TIMEOUT_MS,
        });
        if (resp.error != null) {
          throw new Error(
            `Error subscribing to ${topicName} ${channel} channel: ${resp.error.message}`,
          );
        }
        return [channel, true];
      } catch (error) {
        if (error instanceof WSAbortError) return [channel, false];
        throw error;
      }
    }, RETRY_OPTIONS).catch((error: Error): ChannelSubResult<WsChannel> => {
      const aborted = signal != null && signal.aborted;
      if (!aborted) {
        logException(
          `Failed to subscribe to ${topicName} ${channel} channel`,
          error,
        );
      }
      return [channel, false];
    }),
  );

  return Promise.all(promises);
}

export async function unsubscribeFromChannels<
  WsChannel extends string,
  WebSocket extends PartialApiWebSocket<WsChannel>,
>(
  ws: WebSocket,
  channels: readonly WsChannel[],
  signal: AbortSignal,
): Promise<ChannelSubResult<WsChannel>[]> {
  if (ws.readyState !== WebSocket.OPEN)
    throw new Error(
      `Tried to unsubscribe on a connection already closed for channels: ${channels.toString()}`,
    );
  const promises = channels.map(
    async (channel): Promise<ChannelSubResult<WsChannel>> => {
      try {
        const resp = await ws.request({
          method: 'unsubscribe',
          params: { channel },
          signal,
        });
        if (resp.error != null) {
          throw new Error(
            `Error unsubscribing from ${channel} channel: ${resp.error.message}`,
          );
        }
        return [channel, true];
      } catch (error) {
        /*
         * we check for ws.readyState === WebSocket.OPEN because if the component unsubscribes
         * before the ws connection is even able to start the signal will not be aborted
         * because it never triggers the 'close' ws event listener
         */
        if (!signal.aborted && ws.readyState === WebSocket.OPEN) {
          logException(`Failed to unsubscribe from ${channel} channel`, error);
        }
        return [channel, false];
      }
    },
  );

  return Promise.all(promises);
}

export interface SubscribeWebSocketParams {
  readonly ws: ApiWebSocket<unknown>;
  readonly channels: readonly string[];
  readonly name: string;
  readonly signal: AbortSignal;
}

export async function subscribeWebSocket(params: SubscribeWebSocketParams) {
  const { ws, channels, name, signal } = params;
  const subs = await subscribeToChannels(ws, channels, name, signal);
  const didSucceed = subs.every(([, subscribed]) => subscribed);
  return didSucceed;
}
