import {
  FsBbo,
  FsOrder,
  FsOrderBook,
  FsOrderBookDelta,
  FsStrategy,
  FsStrategyLeg,
  FsTapeTrade,
  FsTrade,
  OrderBookDeltaEntry,
  OrderBookEntry,
  RawFsBbo,
  RawFsOrder,
  RawFsOrderBook,
  RawFsOrderBookDelta,
  RawFsStrategy,
  RawFsStrategyLeg,
  RawFsTapeTrade,
  RawFsTrade,
  RawOrderBookDeltaEntry,
  RawOrderBookEntry,
} from '#/types/future-spreads';

export { processFsPlatformState } from '#/process/platform-state';

export function processStrategy(rawStrategy: RawFsStrategy): FsStrategy {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...rawStrategy,
    created_at: nsToDate(rawStrategy.created_at),
    expires_at: nsToDate(rawStrategy.expires_at),
    last_updated: nsToDate(rawStrategy.last_updated),
    min_block_size: parseDecimalValue(
      rawStrategy.min_block_size_decimal,
      rawStrategy.min_block_size,
    ),
    min_order_size: parseDecimalValue(
      rawStrategy.min_order_size_decimal,
      rawStrategy.min_order_size,
    ),
    legs: [
      processStrategyLeg(rawStrategy.legs[0]),
      processStrategyLeg(rawStrategy.legs[1]),
    ],
  };
}

function processStrategyLeg(rawStrategyLeg: RawFsStrategyLeg): FsStrategyLeg {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...rawStrategyLeg,
    created_at: nsToDate(rawStrategyLeg.created_at),
    expires_at:
      rawStrategyLeg.expires_at > 0
        ? nsToDate(rawStrategyLeg.expires_at)
        : null,
    min_order_size: parseDecimalValue(
      rawStrategyLeg.min_order_size_decimal,
      rawStrategyLeg.min_order_size,
    ),
    min_block_size: parseDecimalValue(
      rawStrategyLeg.min_block_size_decimal,
      rawStrategyLeg.min_block_size,
    ),
  };
}

const makeDecimalParser =
  () => (strValue: string | undefined, numValue: number) =>
    strValue != null ? strValue : String(numValue);

function processOrderBookDeltaEntry(
  orderBook: RawOrderBookDeltaEntry,
): OrderBookDeltaEntry {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...orderBook,
    amount: parseDecimalValue(orderBook.amount_decimal, orderBook.amount),
  };
}

export function processOrderBookDelta(
  rawOrderBook: RawFsOrderBookDelta,
): FsOrderBookDelta {
  return {
    ...rawOrderBook,
    bids: rawOrderBook.bids.map((orderBook) =>
      processOrderBookDeltaEntry(orderBook),
    ),
    asks: rawOrderBook.asks.map((orderBook) =>
      processOrderBookDeltaEntry(orderBook),
    ),
  };
}

function processOrderBookEntry(orderBook: RawOrderBookEntry): OrderBookEntry {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...orderBook,
    amount: parseDecimalValue(orderBook.amount_decimal, orderBook.amount),
  };
}

export function processOrderBook(rawOrderBook: RawFsOrderBook): FsOrderBook {
  return {
    ...rawOrderBook,
    bids: rawOrderBook.bids.map((orderBook) =>
      processOrderBookEntry(orderBook),
    ),
    asks: rawOrderBook.asks.map((orderBook) =>
      processOrderBookEntry(orderBook),
    ),
  };
}

export function processOrder(rawOrder: RawFsOrder): FsOrder {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...rawOrder,
    amount: parseDecimalValue(rawOrder.amount_decimal, rawOrder.amount),
    filled_amount: parseDecimalValue(
      rawOrder.filled_amount_decimal,
      rawOrder.filled_amount,
    ),
    pending_fill_amount: parseDecimalValue(
      rawOrder.pending_fill_amount_decimal,
      rawOrder.pending_fill_amount,
    ),
    canceled_amount: parseDecimalValue(
      rawOrder.canceled_amount_decimal,
      rawOrder.canceled_amount,
    ),
    created_at: nsToDate(rawOrder.created_at),
    expires_at: nsToDate(rawOrder.expires_at),
  };
}

export function processTrade(rawTrade: RawFsTrade): FsTrade {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...rawTrade,
    executed_at: nsToDate(rawTrade.executed_at),
    filled_at: rawTrade.filled_at > 0 ? nsToDate(rawTrade.filled_at) : null,
    last_updated: nsToDate(rawTrade.last_updated),
    amount: parseDecimalValue(rawTrade.amount_decimal, rawTrade.amount),
  };
}

export function processTapeTrade(rawTrade: RawFsTapeTrade): FsTapeTrade {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...rawTrade,
    executed_at: nsToDate(rawTrade.executed_at),
    filled_at: nsToDate(rawTrade.filled_at),
    amount: parseDecimalValue(rawTrade.amount_decimal, rawTrade.amount),
  };
}

function nsToDate(nanoseconds: number): Date {
  return new Date(nanoseconds / 10 ** 6);
}

export function processBbo(rawBbo: RawFsBbo): FsBbo {
  const parseDecimalValue = makeDecimalParser();
  return {
    ...rawBbo,
    best_ask_amount: parseDecimalValue(
      rawBbo.best_ask_amount_decimal,
      rawBbo.best_ask_amount,
    ),
    best_bid_amount: parseDecimalValue(
      rawBbo.best_bid_amount_decimal,
      rawBbo.best_bid_amount,
    ),
  };
}
