import { startSubmit, stopSubmit, reset, initialize } from 'redux-form';
import {
  takeEvery,
  call,
  put,
  spawn,
  take,
  delay,
  StrictEffect,
} from 'redux-saga/effects';

import { logException } from '@paradigm/logging';
import createTopicChannel from '@paradigm/utils/createTopicChannel';

import { tokenExpirationTopic } from '@paradigm/api';
import {
  UserLogout,
  UserInfoUpdate,
  UserPasswordChange,
  UserInfo,
} from '@paradigm/api/auth';

import {
  identify,
  track,
  reset as resetAnalyticsUser,
  USER_LOGOUT,
} from '@paradigm/analytics';

import * as types from './types';

import {
  loginSuccess,
  sessionReuseAttemptStart,
  sessionReuseAttemptSuccess,
  sessionReuseAttemptFailure,
  logoutSuccess,
  logoutRequest,
  authenticationRequest,
  getUserInfoSuccess,
} from './actions';

import tokenStorage from './tokenStorage';

import type { AsyncReturnType } from 'type-fest';

interface MaybeAuthError {
  readonly response?: {
    readonly status?: number;
    readonly data?: {
      readonly non_field_errors?: readonly string[];
    };
  };
}

function* logoutSaga({ payload }: ReturnType<typeof logoutRequest>) {
  const { reason } = payload;

  track(USER_LOGOUT, { reason }, () => {
    resetAnalyticsUser();
  });

  /* We must not call the logout endpoint when token is expired
   * as it could trigger an infinite loop of token expired logout
   * requests. */
  if (reason !== 'token-expired') {
    // Don't give a fork about the result of the logout.
    // It's assumed that the logout is always successful.
    yield spawn(UserLogout);
  }
  console.info({
    message: 'The user is logged out due to token expiration.',
    logoutReason: reason,
    payloadData: payload,
    timestamp: Date.now(),
  });
  yield call(tokenStorage.setToken, null);
  yield put(logoutSuccess());
}

type GetToken = typeof tokenStorage['getToken'];

/**
 * Tries to reuse the stored session token. With that token it tries to get
 * the info about that user, which tells wether the token is still valid or
 * not and provides the user data to initialize the app.
 */
export function* sessionReuseSaga(): Generator<StrictEffect> {
  const token = (yield call(
    tokenStorage.getToken,
  )) as AsyncReturnType<GetToken>;

  yield put(sessionReuseAttemptStart());

  if (token == null) {
    yield put(sessionReuseAttemptFailure());
    return;
  }

  try {
    const { data: user } = (yield call(UserInfo)) as {
      data: types.AuthUser;
    };
    yield call(authenticationSaga, { user, token });
    yield put(sessionReuseAttemptSuccess());
  } catch (_error) {
    const error = _error as MaybeAuthError | undefined;
    const isTokenInvalid = error?.response?.status === 403;

    // Let the app handle authentication if token is not valid anymore
    if (isTokenInvalid) {
      yield put(sessionReuseAttemptFailure());
      return;
    }

    // Keep trying to log the user in if the error is not token related
    yield delay(3000);
    yield call(sessionReuseSaga);
  }
}

function* authenticationSaga({
  user,
  token,
}: {
  user: types.AuthUser;
  token: string;
}) {
  yield call(identify, user);
  yield call(tokenStorage.setToken, token);
  yield put(loginSuccess({ user }));
}

function* updateUser({
  userForm,
}: {
  type: string;
  userForm: Record<string, unknown>;
}): Generator<StrictEffect> {
  try {
    yield put(startSubmit('userInfoUpdate'));
    const { data } = (yield call(UserInfoUpdate, userForm)) as AsyncReturnType<
      typeof UserInfoUpdate
    >;
    yield put({ type: types.UPDATE_USER_SUCCESS, payload: data });
    yield put(stopSubmit('userInfoUpdate'));
    yield put(initialize('userInfoUpdate', data));
    yield delay(3000);
    yield put({ type: types.CLEAR_AUTH_MESSAGES });
  } catch (error) {
    const data = (error as MaybeAuthError).response?.data;
    const nonFieldErrors = data?.non_field_errors;
    yield nonFieldErrors != null
      ? put({
          type: types.UPDATE_USER_FAILURE,
          payload: nonFieldErrors[0],
        })
      : put(stopSubmit('userInfoUpdate', data));
  }
}

function* changePassword({
  passwordForm,
}: {
  type: string;
  passwordForm: Record<string, unknown>;
}): Generator<StrictEffect> {
  try {
    yield put(startSubmit('userPasswordChange'));
    const { data } = (yield call(
      UserPasswordChange,
      passwordForm,
    )) as AsyncReturnType<typeof UserPasswordChange>;
    yield put({ type: types.CHANGE_PASSWORD_SUCCESS, payload: data });
    yield put(stopSubmit('userPasswordChange'));
    yield put(reset('userPasswordChange'));
    yield delay(3000);
    yield put({ type: types.CLEAR_AUTH_MESSAGES });
  } catch (error) {
    const data = (error as MaybeAuthError).response?.data;
    const nonFieldErrors = data?.non_field_errors;
    yield nonFieldErrors != null
      ? put({
          type: types.CHANGE_PASSWORD_FAILURE,
          payload: nonFieldErrors[0],
        })
      : put(stopSubmit('userPasswordChange', data));
  }
}

/**
 * Validate the user's API token. When the token is invalid there
 * will be an event published on the `tokenExpirationTopic`.
 */
export function* validateTokenSaga() {
  // We don't care about any returned information or error thrown.
  // We rely on an event being sent by the `UserInfo` function when
  // the token is not valid anymore, which is currently handled
  // by the application.
  yield spawn(UserInfo);
}

function* getUserInfoSaga(): Generator<StrictEffect> {
  try {
    const { data } = (yield call(UserInfo)) as AsyncReturnType<typeof UserInfo>;
    yield put(getUserInfoSuccess(data));
  } catch (error) {
    logException('Error on get user info saga', error);
  }
}

export function* changePasswordListener() {
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, changePassword);
}

export function* updateUserListener() {
  yield takeEvery(types.UPDATE_USER_REQUEST, updateUser);
}

export function* getUserInfoListener() {
  yield takeEvery(types.GET_USER_INFO_REQUEST, getUserInfoSaga);
}

export function* logoutRequestListener() {
  yield takeEvery(logoutRequest, logoutSaga);
}

/**
 * Listen for requests to set the current user as authenticated by saving the
 * token in the token storage.
 */
export function* authenticationRequestListener() {
  yield takeEvery(
    authenticationRequest,
    function* authenticationRequestSaga({ payload: { user, token } }) {
      yield call(authenticationSaga, { user, token });
    },
  );
}

/**
 * Listen for token expiration events from the api topic and
 * dispatches it's own token expiration event to the store.
 */
export function* tokenExpirationListener() {
  const tokenExpirationChannel = createTopicChannel(tokenExpirationTopic);
  while (true) {
    yield take(tokenExpirationChannel);
    console.info({
      message: 'The user is logged out due to token expiration.',
      timestamp: Date.now(),
    });
    yield put(logoutRequest({ reason: 'token-expired' }));
  }
}
