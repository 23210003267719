import { ComponentPropsWithoutRef } from 'react';

export default function StatsIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
      <g
        stroke="#7FABEC"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.7080096,20.0004408 C16.0867558,20.0153513 15.5712462,19.4993976 15.5556542,18.8470941 L15.5556542,10.3853328 C15.5712462,9.73302963 16.0867558,9.21707667 16.7080096,9.23198651 L18.8476447,9.23198651 C19.4688985,9.21707667 19.984408,9.73302963 20,10.3853328 L20,18.8470941 C19.984408,19.4993976 19.4688985,20.0153513 18.8476447,20.0004408 L16.7080096,20.0004408 Z" />
        <path d="M8.9301825,20.0005422 C8.30892874,20.0154516 7.79341906,19.4994985 7.7778271,18.8471956 L7.7778271,1.15366157 C7.79341906,0.501358303 8.30892874,-0.0145946794 8.9301825,0.000315170485 L11.0698176,0.000315170485 C11.6910714,-0.0145946794 12.2065809,0.501358303 12.2221729,1.15366157 L12.2221729,18.8471956 C12.2065809,19.4994985 11.6910714,20.0154516 11.0698176,20.0005422 L8.9301825,20.0005422 Z" />
        <path d="M1.1523554,20.0005725 C0.531101634,20.0154828 0.0155919588,19.4995296 0,18.8472264 L0,5.77041267 C0.0155919588,5.11810943 0.531101634,4.6021565 1.1523554,4.61706635 L3.29199048,4.61706635 C3.91324425,4.6021565 4.42875383,5.11810943 4.4443458,5.77041267 L4.4443458,18.8472264 C4.42875383,19.4995296 3.91324425,20.0154828 3.29199048,20.0005725 L1.1523554,20.0005725 Z" />
      </g>
    </svg>
  );
}
