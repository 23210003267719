import { eventChannel } from 'redux-saga';
import { Topic } from './createTopic';

/**
 * Creates an event channel that will receive any message
 * published to `topic`.
 */

export default function createTopicChannel<T extends Record<string, unknown>>(
  topic: Topic<T>,
) {
  const channel = eventChannel<T>((emit) => {
    const unsubscribe = topic.subscribe(emit);
    return unsubscribe;
  });
  return channel;
}
