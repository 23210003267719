import { track } from '@paradigm/analytics';

import {
  INTERNET_DISCONNECT,
  INTERNET_CONNECT,
} from '@paradigm/analytics/src/event-names';

import * as electronTypes from './types/electron';
import { internetOffline, internetOnline, recoveryFailure } from './app';

export const GET_DEFAULT_MIDDLEWARE_CONFIG = {
  thunk: false,
  immutableCheck: {
    warnAfter: 100,
  },
  serializableCheck: {
    ignoredPaths: ['auth'],
    warnAfter: 100,
  },
};

export function electronLink(store) {
  const { ipcRenderer } = window.require('electron');
  ipcRenderer.send('app-ready', true);
  ipcRenderer.on('update-available', () =>
    store.dispatch({
      type: electronTypes.UPDATE_AVAILABLE,
      payload: true,
    }),
  );
  ipcRenderer.on('update-progress', (event, progress) =>
    store.dispatch({
      type: electronTypes.UPDATE_PROGRESS,
      payload: progress,
    }),
  );
  ipcRenderer.on('update-ready', () =>
    store.dispatch({
      type: electronTypes.UPDATE_READY,
      payload: true,
    }),
  );
  ipcRenderer.on('deep-link-available', (event, deepLink) =>
    store.dispatch({
      type: electronTypes.DEEP_LINK_AVAILABLE,
      payload: deepLink,
    }),
  );
  ipcRenderer.on('recovery-error', (event, payload) =>
    store.dispatch(recoveryFailure(payload)),
  );
}

export function addListenersForWindowConnectivityState(store) {
  window.addEventListener('offline', () => {
    store.dispatch(internetOffline());
    track(INTERNET_DISCONNECT);
  });
  window.addEventListener('online', () => {
    store.dispatch(internetOnline());
    track(INTERNET_CONNECT);
  });
}
