import { StrategyCode } from '#/shared/strategies';
import { PRODUCT_CODES } from './config';

export type ContractType =
  // Options
  | 'EUROPEAN'
  // Futures
  | 'INVERSE'
  | 'LINEAR';

export type MarginType = 'LINEAR' | 'INVERSE';
export type VenueCode = 'BIT' | 'DBT' | 'BYB';

/**
 * Most of products follow a standard specification that contains a `minBlockSize`
 * for calculating minimum quantities
 */
export interface StandardProduct<Code extends string = string> {
  readonly code: Code;
  readonly venue: VenueCode;
  readonly kind: 'OPTION' | 'FUTURE';
  readonly currency: string;
  readonly contractType: ContractType;
  /**
   * For option legs only.
   * Used when hedge contract type is different from option contract type.
   * E.g: Bitcom BD is EUROPEAN, but Bitcom PE is LINEAR
   **/
  readonly marginType: MarginType;
  readonly shortName: string;
  readonly longName: string;
  /** Contract Multiplier */
  readonly contractSize: number;
  readonly contractCurrency: string;
  readonly minBlockSize: number;
  readonly strategies: 'all' | readonly StrategyCode[];
  readonly quantityPrecision: number;
  readonly pricePrecision: number;
  readonly ratioPrecision: number;
  readonly minContractIncrement: number;
  readonly isHedgeDisabled?: boolean;
  readonly showUSDApproximation?: boolean;
  readonly isDisabled?: boolean;
  readonly isDisabledForOb?: boolean;
  readonly isDisabledForRfq?: boolean;
  readonly quoteCurrency?: string;
}

/**
 * Min Gross Premium Products does not have an specification
 * on `minBlockSize`. Alternatively, the min quantity is dynamically calculated
 * with `minGrossPremium`.
 *
 * minQuantity = minGrossPremium / (price * contractSize)
 */
export interface MgpProduct<Code extends string = string>
  extends Omit<StandardProduct<Code>, 'minBlockSize'> {
  readonly minGrossPremium: number;
}

/**
 * Can be a Standard Product or a Minimum Gross Premium Product.
 *
 * Product were extracted from the spreadsheet bellow
 *   https://docs.google.com/spreadsheets/d/1wI7t38ES0f-CQTwX9MKm-r9VAPGXsov28EjzFNMGxIQ/edit#gid=1365784445
 */
export type Product<Code extends string = string> =
  | StandardProduct<Code>
  | MgpProduct<Code>;

export type ProductCode = typeof PRODUCT_CODES[number];
export interface StandardProductWithSpot<Code extends string = string>
  extends Omit<StandardProduct<Code>, 'kind'> {
  readonly kind: 'OPTION' | 'FUTURE' | 'SPOT';
}

export type ProductWithSpot<Code extends string = string> =
  | StandardProductWithSpot<Code>
  | MgpProduct<Code>;

export const isStandardProduct = (
  product: Product,
): product is StandardProduct => 'minBlockSize' in product;

export const isMgpProduct = (product: Product): product is MgpProduct =>
  !isStandardProduct(product);

export const isProductDisabledForType = (
  product: ProductWithSpot,
  type: 'OB' | 'RFQ',
) => {
  if (type === 'OB') return product.isDisabledForOb === true;
  return product.isDisabledForRfq === true;
};
