import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import {
  paradigmBlue,
  paradigmLightBlue,
} from '@paradigm/design-system/src/colors';

export default function AnimatedStarsSettings(
  props: ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <SvgContainer viewBox="0 0 390 134" {...props}>
      <path
        id="r1"
        d="M35.9,21.8c-2.7,0-5-2.2-5-5c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C30.9,24,33.2,21.8,35.9,21.8z"
      />
      <path
        id="r2"
        d="M168.9,72.8c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C163.9,75,166.2,72.8,168.9,72.8z"
      />
      <path
        id="r3"
        d="M108.4,22c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C103.4,24.3,105.7,22,108.4,22z"
      />
      <path
        id="r4"
        d="M103.4,82.7c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C98.5,84.9,100.7,82.7,103.4,82.7z"
      />
      <path
        id="p1"
        d="M256.9,19.9c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C252,22.1,254.2,19.9,256.9,19.9z"
      />
      <path
        id="p2"
        d="M345.9,79.9c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C341,82.1,343.2,79.9,345.9,79.9z"
      />
      <path
        id="p3"
        d="M213.3,98.4c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C208.3,100.6,210.5,98.4,213.3,98.4z"
      />
      <path
        id="p4"
        d="M74,82.7c-2.7,0-5-2.2-5-5c0,2.7-2.2,5-5,5
	c2.7,0,5,2.2,5,5C69,84.9,71.3,82.7,74,82.7z"
      />
      <path
        id="g1"
        d="M389.9,20.3c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C385,22.5,387.2,20.3,389.9,20.3z"
      />
      <path
        id="g2"
        d="M124.9,51.3c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C119.9,53.5,122.2,51.3,124.9,51.3z"
      />
      <path
        id="g3"
        d="M204.9,9.4c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C199.9,11.6,202.2,9.4,204.9,9.4z"
      />
      <path
        id="g4"
        d="M314.9,42.7c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C309.9,44.9,312.2,42.7,314.9,42.7z"
      />
      <path
        id="y1"
        d="M382.9,113.7c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C377.9,115.9,380.2,113.7,382.9,113.7z"
      />
      <path
        id="y2"
        d="M228.9,65.9c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C223.9,68.1,226.2,65.9,228.9,65.9z"
      />
      <path
        id="y3"
        d="M129.9,128.4c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C124.9,130.6,127.1,128.4,129.9,128.4z"
      />
      <path
        id="y4"
        d="M20,55.9c-2.7,0-5-2.2-5-5c0,2.7-2.2,5-5,5
	c2.7,0,5,2.2,5,5C15,58.2,17.2,55.9,20,55.9z"
      />
      <path
        id="r1"
        d="M331.8,24.4c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C326.8,26.6,329.1,24.4,331.8,24.4z"
      />
      <path
        id="r2"
        d="M256.9,82.7c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C252,84.9,254.2,82.7,256.9,82.7z"
      />
      <path
        id="r3"
        d="M10,98.4c-2.7,0-5-2.2-5-5c0,2.7-2.2,5-5,5
	c2.7,0,5,2.2,5,5C5,100.6,7.3,98.4,10,98.4z"
      />
      <path
        id="r4"
        d="M172,21.6c-2.7,0-5-2.2-5-5
	c0,2.7-2.2,5-5,5c2.7,0,5,2.2,5,5C167,23.9,169.3,21.6,172,21.6z"
      />
      <path
        id="p1"
        d="M54.2,33.5c-1.7,0-3.1-1.4-3.1-3.1
	c0,1.7-1.4,3.1-3.1,3.1c1.7,0,3.1,1.4,3.1,3.1C51.1,34.9,52.5,33.5,54.2,33.5z"
      />
      <path
        id="p2"
        d="M30.1,3.1C28.4,3.1,27,1.7,27,0
	c0,1.7-1.4,3.1-3.1,3.1c1.7,0,3.1,1.4,3.1,3.1C27,4.5,28.4,3.1,30.1,3.1z"
      />
      <path
        id="p3"
        d="M9.8,36.6c-1.7,0-3.1-1.4-3.1-3.1
	c0,1.7-1.4,3.1-3.1,3.1c1.7,0,3.1,1.4,3.1,3.1C6.7,38,8.1,36.6,9.8,36.6z"
      />
      <path
        id="p4"
        d="M35.9,49.5c-1.7,0-3.1-1.4-3.1-3.1
	c0,1.7-1.4,3.1-3.1,3.1c1.7,0,3.1,1.4,3.1,3.1C32.8,50.9,34.2,49.5,35.9,49.5z"
      />
      <path
        id="g1"
        d="M59.9,59c-1.7,0-3.1-1.4-3.1-3.1
	c0,1.7-1.4,3.1-3.1,3.1c1.7,0,3.1,1.4,3.1,3.1C56.8,60.4,58.2,59,59.9,59z"
      />
      <path
        id="g2"
        d="M52.9,49.5c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C51.1,50.3,51.9,49.5,52.9,49.5z"
      />
      <path
        id="g3"
        d="M57.8,17.6c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C56,18.4,56.8,17.6,57.8,17.6z"
      />
      <path
        id="g4"
        d="M87.3,8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C85.6,8.8,86.4,8,87.3,8z"
      />
      <path
        id="y1"
        d="M269.8,60.9c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C268.1,61.7,268.9,60.9,269.8,60.9z"
      />
      <path
        id="y2"
        d="M250.6,111.9c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C248.8,112.7,249.6,111.9,250.6,111.9z"
      />
      <path
        id="y3"
        d="M305,93.4c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C303.2,94.2,304,93.4,305,93.4z"
      />
      <path
        id="y4"
        d="M191.5,49.5c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C189.7,50.3,190.5,49.5,191.5,49.5z"
      />
      <path
        id="r1"
        d="M154.3,9.8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C152.5,10.6,153.3,9.8,154.3,9.8z"
      />
      <path
        id="r2"
        d="M154.3,47.7c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C152.5,48.5,153.3,47.7,154.3,47.7z"
      />
      <path
        id="r3"
        d="M192,76.7c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C190.2,77.5,191,76.7,192,76.7z"
      />
      <path
        id="r4"
        d="M301.4,11.6c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C299.6,12.4,300.4,11.6,301.4,11.6z"
      />
      <path
        id="p1"
        d="M290.3,70.8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C288.5,71.6,289.3,70.8,290.3,70.8z"
      />
      <path
        id="p2"
        d="M389.9,9.8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C388.1,10.6,388.9,9.8,389.9,9.8z"
      />
      <path
        id="p3"
        d="M308.5,128.4c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C306.7,129.2,307.5,128.4,308.5,128.4z"
      />
      <path
        id="p4"
        d="M137.4,21.2c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C135.6,22,136.4,21.2,137.4,21.2z"
      />
      <path
        id="g1"
        d="M282.4,35.3c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C280.6,36.1,281.4,35.3,282.4,35.3z"
      />
      <path
        id="g2"
        d="M357.3,67.7c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C355.5,68.5,356.3,67.7,357.3,67.7z"
      />
      <path
        id="g3"
        d="M35.9,89.8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C34.1,90.6,34.9,89.8,35.9,89.8z"
      />
      <path
        id="g4"
        d="M16.8,121.6c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C15,122.4,15.8,121.6,16.8,121.6z"
      />
      <path
        id="y1"
        d="M135.6,75.9c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C133.8,76.7,134.6,75.9,135.6,75.9z"
      />
      <path
        id="y2"
        d="M230.7,29.3c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C228.9,30.1,229.7,29.3,230.7,29.3z"
      />
      <path
        id="y3"
        d="M353.7,9.8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C351.9,10.6,352.7,9.8,353.7,9.8z"
      />
      <path
        id="y4"
        d="M378.1,84.8c-1,0-1.8-0.8-1.8-1.8
	c0,1-0.8,1.8-1.8,1.8c1,0,1.8,0.8,1.8,1.8C376.3,85.6,377.1,84.8,378.1,84.8z"
      />
    </SvgContainer>
  );
}

const SvgContainer = styled.svg`
  cursor: pointer;
  path {
    opacity: 0;
    animation: fadeIn 2s ease-in-out infinite;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  path#r1 {
    fill: ${paradigmBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#r2 {
    fill: ${paradigmBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#r3 {
    fill: ${paradigmBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#r4 {
    fill: ${paradigmBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p1 {
    fill: #ffffff;
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p2 {
    fill: #ffffff;
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p3 {
    fill: #ffffff;
    animation-delay: ${getRandomDelay()}ms;
  }
  path#p4 {
    fill: #ffffff;
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g1 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g2 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g3 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#g4 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y1 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y2 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y3 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
  path#y4 {
    fill: ${paradigmLightBlue};
    animation-delay: ${getRandomDelay()}ms;
  }
`;
function getRandomDelay() {
  return Math.floor(Math.random() * 2000);
}
