import isElectron from 'is-electron';
import styled from 'styled-components';
import logo from '#/assets/logo.png';

export default function TitleBar() {
  return (
    <TitleBarContainer hidden={!isElectron()}>
      <span>
        <img height={16} width={16} alt="logo" src={logo} />
        Paradigm
      </span>
    </TitleBarContainer>
  );
}

const TITLE_BAR_COLOR = '#142334';

const TitleBarContainer = styled.div`
  background-color: ${TITLE_BAR_COLOR};
  color: seashell;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.33rem 0;
  -webkit-app-region: drag;
  height: 32px; // must be fixed

  & span {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    user-select: none;
  }
`;
