/**
 * Memoizes a function that receives one object with a weak map.
 *
 * Memoizing with a weak map means the result will be freed from
 * memory together with the input object. So this method allows
 * us to have calculated values bound to an instance. But without
 * preventing the instance from being freed from memory.
 */
export default function memoWeak<O extends object, R>(fn: (object: O) => R) {
  if (fn.length !== 1)
    throw new TypeError('Function must accept exactly one argument');

  const cache = new WeakMap<O, R>();

  return (object: O) => {
    let result = cache.get(object);
    if (result == null) {
      result = fn(object);
      cache.set(object, result);
    }
    return result;
  };
}
