import { routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { call, spawn } from 'redux-saga/effects';
import isElectron from 'is-electron';

import { sagas as authSagas, setupTokenStorage } from '@paradigm/store/auth';
import { createReduxEnhancer } from '@paradigm/logging';

import { getToken, setToken } from '#/utils/tokenStorage';

import history from '#/history';
import { createRootReducer } from './reducers/root';
import rootSaga from './sagas/rootSaga';
import {
  addListenersForWindowConnectivityState,
  electronLink,
  GET_DEFAULT_MIDDLEWARE_CONFIG,
} from './storeConfigUtils';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) => [
    routerMiddleware(history),
    sagaMiddleware,
    ...getDefaultMiddleware(GET_DEFAULT_MIDDLEWARE_CONFIG),
  ],
  enhancers: [createReduxEnhancer()],
});

/* eslint-disable no-undef */
if (NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/root', () => {
    const nextCreateRootReducer = require('./reducers/root').createRootReducer;
    store.replaceReducer(nextCreateRootReducer(history));
  });
}
/* eslint-enable no-undef */

const spawnFunc = function* () {
  yield call(setupTokenStorage, { getToken, setToken });
  yield spawn(authSagas.sessionReuseSaga);
  yield* rootSaga();
};

sagaMiddleware.run(spawnFunc);

if (isElectron()) electronLink(store);

addListenersForWindowConnectivityState(store);

export default store;
