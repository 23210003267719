import { FormEventHandler } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { required, email, length } from 'redux-form-validators';
import { Spinner } from 'reactstrap';

import { useLogin } from '@paradigm/features/src/login';

import { TWO_FA_ROUTE } from '#/routes';

import { renderInputField } from '#/utils/renderFormFields';
import { renderError } from '#/utils/renderErrors';
import { TwoFaLocationState } from './two-fa';

/**
 * This must reflect the `name`s of the form fields and also the
 * validation they pass through.
 */
interface LoginForm {
  readonly email: string;
  readonly password: string;
}

interface Props extends InjectedFormProps<LoginForm> {}

/**
 * Displays a sign in form, handles validation for email and password fields,
 * validations only handle that the fields are required in order to submit the form,
 * on credentials validation fail, server returns an error message.
 * @param props.error - Error message from the server
 */
function RawSignIn(props: Props) {
  const { handleSubmit: createSubmitHandler } = props;

  const history = useHistory<TwoFaLocationState>();
  const { doLogin, error, isLoading } = useLogin();

  const handleSubmit = createSubmitHandler((data) => {
    doLogin({
      ...data,
      onTwoFaCodeRequired: () => {
        history.push(TWO_FA_ROUTE, data);
      },
    });
  }) as FormEventHandler<HTMLFormElement>;

  return (
    <div className="flex-column text-no-code b-primary layout__container sign-in">
      <p className="align-self-center sign-in__welcome">Welcome</p>
      <div className="d-flex flex-column align-items-center">
        <p className="mb-0">
          Don’t have an account yet?
          <a
            href={SIGNUP_URL}
            role="button"
            className="sign-in__sign-up-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Paradigm
          </a>
        </p>
      </div>
      <form className="mt-2" onSubmit={handleSubmit}>
        {renderError(error)}
        <Field
          component={renderInputField}
          type="text"
          name="email"
          label="Email"
          placeholder="Enter Email"
          className="form-control sign-in__input"
          validate={[required(), email()]}
          autoComplete="off"
        />
        <div className="mt-2">
          <label htmlFor="password">Password</label>
          <Field
            component={renderInputField}
            type="password"
            name="password"
            placeholder="Enter Password"
            className="form-control sign-in__input"
            validate={[required(), length({ min: 1 })]}
            autoComplete="off"
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Link to="/forgot-password/" role="button" className="sign-in__link">
            Forgot Password
          </Link>
          <button type="submit" className="btn primary-button">
            {isLoading ? (
              <Spinner size="sm" className="align-middle" />
            ) : (
              'Sign In'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

const SignIn = reduxForm<LoginForm>({
  form: 'login',
})(RawSignIn);

export default SignIn;
