import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '@paradigm/store/auth';

import { createStoredEntry, Migration } from '../utils/stored-entry';

/**
 * Sets up a stored entry scoped to the user id.
 *
 * @param key Key to be appended to the user id
 * @param migrate Optional function to transform the read value
 */
export function useStoredEntry<T>(
  key: string,
  migrate?: Migration<T> | undefined,
) {
  const user = useSelector(getUser);
  const finalKey = `${user.id}:${key}`;

  return useMemo(
    () => createStoredEntry(finalKey, migrate),
    [finalKey, migrate],
  );
}
