import { takeEvery, put } from 'redux-saga/effects';
import { submit } from 'redux-form';
import * as types from '#/redux/types/forms';

function* submitForm({ formName }) {
  yield put(submit(formName));
}

function* submitFormsSaga() {
  yield takeEvery(types.FORM_SUBMIT, submitForm);
}

const formsSagas = [submitFormsSaga];

export default formsSagas;
