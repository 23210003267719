import styled from 'styled-components';
import {
  blueMana,
  firefly,
  firefly_2,
  nepal,
  paradigmLightBlue,
} from '@paradigm/design-system/src/colors';

export const Title = styled.span`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  color: white;
`;

export const Text = styled.span`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  color: ${nepal};
`;

export const Button = styled.button<{ primary?: boolean; outlined?: boolean }>`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  background: none;
  border: none;
  border-radius: 15px;
  height: 30px;
  padding: 0;

  ${(props) =>
    props.primary != null &&
    props.primary &&
    `
    background: ${blueMana};
    color: #006BF7;
    padding: 0px 20px;
    font-weight: bold;
  `}

  ${(props) =>
    props.outlined != null &&
    props.outlined &&
    `
    color: ${paradigmLightBlue};
  `}
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 324px;
  color: white;
  background-color: ${firefly_2};
  padding: 16px;
  flex-direction: column;
  justtify-content: flex-start;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  align-items: flex-start;
`;

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${firefly};
  border-radius: 8px;
  margin-right: 8px;
`;
