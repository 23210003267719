import { css } from 'styled-components';
import { NumberFormatProps } from 'react-number-format';
import {
  ComponentPropsWithoutRef,
  ReactNode,
  ChangeEvent,
  useCallback,
} from 'react';
import {
  Container,
  HelperContent,
  InputWrapper,
  Label,
  Prefix,
  StyledFormattedNumber,
  StyledInput,
  StyledReadonlyNumber,
  Suffix,
} from './styles';

interface BaseProps {
  /**
   * Input string value
   */
  readonly value?: string | null;
  /**
   * input rendering format
   */
  readonly variant?: 'default' | 'transparent';
  /**
   * Controls input size
   */
  readonly size?: 'small' | 'medium' | 'large';
  /**
   * Customize container
   */
  readonly className?: string;
  /**
   * Label placed on top of input
   */
  readonly label?: ReactNode;
  /**
   * Input id
   */
  readonly id?: string;
  /**
   * preffix element placed inside input box
   */
  readonly inputPrefix?: ReactNode;
  /**
   * test id used in suffix component
   */
  readonly inputPrefixTestId?: string;
  /**
   * suffix element placed inside input box
   */
  readonly suffix?: ReactNode;

  /**
   * test id used in suffix component
   */
  readonly suffixTestId?: string;
  /**
   * Description displayed below input
   */
  readonly helperContent?: ReactNode;
  readonly disabled?: boolean;
  /**
   * whether it is in a invalid state and should display a yellow border
   */
  readonly warning?: boolean;
  /**
   * onChange function
   * @param value updated value
   * @param ev original event
   * @returns
   */
  readonly onChange?: (
    value: string,
    ev: ChangeEvent<HTMLInputElement>,
  ) => void;
  /**
   * Customize label
   */
  readonly labelCss?: ReturnType<typeof css> | string;
  /**
   * Customize helper
   */
  readonly helperCss?: ReturnType<typeof css> | string;
  /**
   * Customize input wrapper
   */
  readonly wrapperCss?: ReturnType<typeof css> | string;
}

type OmitOverrideProps<T> = Omit<T, 'size' | 'onChange' | 'value'>;
export type NumberInputProps = BaseProps &
  OmitOverrideProps<NumberFormatProps> & {
    inputType: 'numeric';
    readonly value?: string | number | null;
  };

type TextInputProps = { inputType: 'text' | 'tel' | 'password' } & BaseProps &
  OmitOverrideProps<ComponentPropsWithoutRef<'input'>>;

export type InputProps = NumberInputProps | TextInputProps;

const isNumeric = (props: InputProps): props is NumberInputProps => {
  return props.inputType === 'numeric';
};

export default function Input(props: InputProps) {
  const {
    className,
    label,
    id: inputId,
    suffix,
    suffixTestId,
    inputPrefix,
    inputPrefixTestId,
    helperContent,
    size,
    labelCss,
    helperCss,
    wrapperCss,
    onChange,
    value,
    variant = 'default',
    disabled = false,
    warning = false,
    ...restProps
  } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value, e);
    },
    [onChange],
  );

  return (
    <Container variant={variant} size={size} className={className}>
      {Boolean(label) && (
        <Label size={size} htmlFor={inputId} css={labelCss}>
          {label}
        </Label>
      )}
      <InputWrapper
        warning={warning}
        disabled={disabled}
        variant={variant}
        size={size}
        css={wrapperCss}
      >
        {inputPrefix != null && (
          <Prefix data-testid={inputPrefixTestId}>{inputPrefix}</Prefix>
        )}
        {isNumeric(props) ? (
          <StyledFormattedNumber
            {...(restProps as NumberFormatProps)}
            value={value}
            inputSize={size}
            id={inputId}
            onChange={handleChange}
            warning={warning}
            disabled={disabled}
            customInput={undefined}
          />
        ) : (
          <StyledInput
            {...restProps}
            value={value ?? undefined}
            inputSize={size}
            id={inputId}
            warning={warning}
            disabled={disabled}
            onChange={handleChange}
          />
        )}
        {suffix != null && (
          <Suffix data-testid={suffixTestId} size={size} className="suffix">
            {suffix}
          </Suffix>
        )}
      </InputWrapper>
      {helperContent != null && (
        <HelperContent size={size} css={helperCss}>
          {helperContent}
        </HelperContent>
      )}
    </Container>
  );
}

export function ReadonlyTransparentNumberField({
  value,
  size,
  ...props
}: Pick<InputProps, 'value' | 'size'>) {
  return (
    <StyledReadonlyNumber size={size} {...props}>
      {value}
    </StyledReadonlyNumber>
  );
}
