import { EffectCallback, useEffect, useRef } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import { ReadonlyObservable } from './createObservable';

export function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useObservable<T>(ob: ReadonlyObservable<T>): T {
  return useSyncExternalStore(ob.observe, ob.get);
}

export function useEscapeListener(onEsc: () => void) {
  useEffect(() => {
    const handleOnEsc = (event: KeyboardEvent) => {
      const { key } = event;

      if (key === 'Escape' || key === 'Esc') {
        onEsc();
      }
    };

    document.addEventListener('keydown', handleOnEsc);

    return () => document.removeEventListener('keydown', handleOnEsc);
  }, [onEsc]);
}
