import * as PropTypes from 'prop-types';
import {
  midnightBlue,
  seaGreen,
  fountainBlue,
  maroonFlush,
  studio,
  fireBush,
  mauve,
  frenchRose,
} from '@paradigm/design-system/src/colors';

export const COLORS = {
  NV: midnightBlue,
  GE: seaGreen,
  TL: fountainBlue,
  RD: maroonFlush,
  GR: studio,
  OG: fireBush,
  LC: mauve,
  YL: frenchRose,
};

const UserAvatar = (props) => {
  const { color } = props;
  const firstName = props.firstName || ' ';
  const lastName = props.lastName || ' ';

  return (
    <div
      style={{
        background: COLORS[color],
        width: '2.9em',
        height: '2.9em',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 800,
      }}
    >
      {`${firstName[0]}${lastName[0]}`.toUpperCase()}
    </div>
  );
};

UserAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  color: PropTypes.string,
};

export default UserAvatar;
