import { useEffect, useState } from 'react';
import { useAccounts } from '#/unified-rfqs/contexts/accounts';
import { useDefaultAccountsFeatureFlag } from '#/feature-flags/context';
import useMigrateLegacyDefaultByVenue from '#/preferences/default-accounts/migration/useMigrateLegacyApiDefaults';
import useCleanInvalidAccounts from '#/preferences/default-accounts/migration/useCleanInvalidAccounts';
import useSelectDefaultEmptyAccount from '#/preferences/default-accounts/migration/useSelectDefaultEmptyAccount';

type Status = 'loading' | 'complete';
/**
 *
 * There are 3 steps to migrating API accounts to user preferences:
 *
 * 1. Migrate legacy venue defaults:
 *   For the first time the user opens the app, after the feature has launched.
 *   We will grab the legacy api defaults and set user preferences with it.
 *   We use a flag `defaultAccountsMigrated` to make sure this is executed only once.
 *
 * 2. Clean up invalid accounts:
 *   The user can deactivate or remove accounts in
 *   the admin portal or via API. So we should make sure every account stored
 *   is valid, when starting a new session.
 *
 * 3. Initialize empty accounts:
 *   If the user deleted/deactivated an account and there is only one valid account left,
 *   we set it as default.
 */
export default function useAccountsMigration() {
  const accountsView = useAccounts();
  const [status, setStatus] = useState<Status>('loading');
  const isDefaultAccountEnabled = useDefaultAccountsFeatureFlag();
  const migrateLegacyDefaults = useMigrateLegacyDefaultByVenue();
  const initializeDefaultAccounts = useSelectDefaultEmptyAccount();
  const cleanInvalidAccounts = useCleanInvalidAccounts();

  useEffect(() => {
    if (!isDefaultAccountEnabled) return;
    if (accountsView.isLoading) {
      setStatus('loading');
      return;
    }

    if (status !== 'loading') return;

    migrateLegacyDefaults();
    cleanInvalidAccounts();
    initializeDefaultAccounts();

    setStatus('complete');
  }, [
    accountsView.isLoading,
    cleanInvalidAccounts,
    initializeDefaultAccounts,
    isDefaultAccountEnabled,
    migrateLegacyDefaults,
    status,
  ]);

  return { status };
}
