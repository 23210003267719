import { exceptionLogger } from '@paradigm/logging/src/logging';

import { echo } from '#/shared';

import createWebSocket, {
  ApiWebSocket,
  CreateWebSocketOptions,
  WSNotification,
} from './createWebSocket';

/**
 * Same as `createWebSocket` but with extra handlers especificallly
 * for PAPI web sockets.
 *
 * @see {@link createWebSocket}
 */
export default function createPapiWebSocket<
  RawNotification extends WSNotification = WSNotification,
  Notification = WSNotification,
>(
  options: CreateWebSocketOptions<RawNotification, Notification>,
): ApiWebSocket<Notification> {
  const ws = createWebSocket(options);

  ws.addEventListener('error', testTokenValidity);

  return ws;
}

function testTokenValidity() {
  async function test() {
    /*
     * We don't need to do handle the response, if the http request
     * fails with 401 or 403 (invalid token), there'll be a message
     * published on the token expiration topic. The application
     * listening to the token expiration topic will handle it,
     * probably by signing out the user.
     */
    await echo({});
  }

  test().catch(exceptionLogger('Failed testing PAPI token validity'));
}
