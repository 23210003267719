import BigNumber from 'bignumber.js';
import { Maybe } from '@paradigm/utils/types';
import { formatProductPrice } from '#/unified-rfqs/ui/rfqs/rfqs-functions';
import { NEGATIVE_SIGN } from '@paradigm/utils/src/enhanceNegativeSign';
import { Side } from '#/unified-rfqs/entities/shared';
import { getProduct } from '#/products/domain';
import {
  isValidDecimal,
  parseFormattedFloat,
} from '@paradigm/utils/formatDecimal';
import FixedDecimalNumber from '@paradigm/utils/src/FixedDecimalNumber';

/**
 * Apply custom non integer rounding rules from the Maker's perspective
 * We round down for BIDS and UP for ASKS. We do this intentionally so
 * the raw underlying price is equal or better than the one we show.
 *
 * @param price - The price to determine the rounding mode for.
 * @param side - Maker's perspective, invert for Taker's perspective
 */
function getCustomNonIntegerRoundModeBySide(
  price: BigNumber,
  side: Maybe<Side>,
): BigNumber.RoundingMode {
  if (side == null) return BigNumber.ROUND_HALF_EVEN;
  if (price.isLessThan(0)) {
    return side === 'BUY' ? BigNumber.ROUND_UP : BigNumber.ROUND_DOWN;
  }
  return side === 'BUY' ? BigNumber.ROUND_DOWN : BigNumber.ROUND_UP;
}

export function roundPrecisionBySide(
  price: string,
  side: Maybe<Side>,
  precision: number,
): string {
  const priceBN = BigNumber(price);
  const roundMode = getCustomNonIntegerRoundModeBySide(priceBN, side);
  return BigNumber(price).decimalPlaces(precision, roundMode).toString();
}

function getDecimalPlacesByProductCode(
  productCode: string,
  isCustomNonIntegerPrice: boolean,
): number {
  const { pricePrecision } = getProduct(productCode);
  return isCustomNonIntegerPrice ? pricePrecision + 1 : pricePrecision;
}

export function formatCustomNonIntegerPrice(
  price: Maybe<string>,
  productCode: string,
  side: Maybe<Side>,
): string {
  if (price == null || !isValidDecimal(price)) return NEGATIVE_SIGN;
  const unformattedPrice = parseFormattedFloat(price).toString();
  const precision = getDecimalPlacesByProductCode(productCode, true);
  const rounded = roundPrecisionBySide(unformattedPrice, side, precision);
  return formatProductPrice(rounded, productCode, precision);
}

/** Match first float number preceded by + or -*/
const RATIO_PARSE_RE = /^([+-]\d+\.?\d*)/u;

/**
 * Check if the ob/rfq description is Cstm with at least one non integer ratio (Eg: 1.11).
 * We should revisit this when the complete legs information are available in all
 * entities such as trades and quotes.
 */
export function isCustomNonIntegerRatio(description: Maybe<string>): boolean {
  if (description == null || !description.includes('Cstm')) return false;
  const cleanedDescs = description.replace('Cstm', '').split('\n');
  const ratios = cleanedDescs.map(
    (legDesc) => RATIO_PARSE_RE.exec(legDesc.trim())?.[0] ?? '0',
  );

  return ratios.some((ratio) => !BigNumber(ratio).isInteger());
}

function getCustomNonIntegerFixedDecimalPrice(
  price: BigNumber,
  productCode: string,
  side?: Side, // If side is not provided, use default round mode
): FixedDecimalNumber {
  const decimalPlaces = getDecimalPlacesByProductCode(productCode, true);
  const roundMode =
    side === undefined
      ? undefined
      : getCustomNonIntegerRoundModeBySide(price, side);
  return new FixedDecimalNumber(price, decimalPlaces, roundMode);
}

function getFixedDecimalPrice(
  price: BigNumber,
  productCode: string,
): FixedDecimalNumber {
  const decimalPlaces = getDecimalPlacesByProductCode(productCode, false);
  return new FixedDecimalNumber(price, decimalPlaces);
}

export function getFixedDecimalPriceWithCustomRules(
  price: BigNumber,
  productCode: string,
  description: string,
  side?: Side,
) {
  const shouldApplyCustomNonIntegerRounding =
    isCustomNonIntegerRatio(description);

  return shouldApplyCustomNonIntegerRounding
    ? getCustomNonIntegerFixedDecimalPrice(price, productCode, side)
    : getFixedDecimalPrice(price, productCode);
}
