import { useEffect } from 'react';

import { echo } from '@paradigm/api/src/shared';
import { exceptionLogger } from '@paradigm/logging';

const REFRESH_TOKEN_INTERVAL_MS = 5 * 60 * 1000;

export default function TokenRefresher() {
  useEffect(() => {
    const interval = setInterval(() => {
      /*
       * Echo endpoint response will have a refresh-token header
       * if a refreshed token is available. When this header is
       * available our API library already handles updating the
       * token.
       *
       * This is necessary as not all endpoints respond with a
       * refresh-token (see PTP-5364).
       */
      echo({}).catch(exceptionLogger('Failure on token refresher'));
    }, REFRESH_TOKEN_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
}
