import { omitBy, isArray, isEmpty } from 'lodash';

export function trimParams<T>(params: Record<string, T> | null | undefined) {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const trimmed = omitBy(params, (p) => !p || (isArray(p) && isEmpty(p)));
  return isEmpty(trimmed) ? null : trimmed;
}

const MAX_I32 = 2147483647;

export function createCounter(resetAfter: number = MAX_I32) {
  let value = 0;
  return () => {
    if (value === resetAfter) {
      const nextValue = value;
      value = 0;
      return nextValue;
    }
    return value++;
  };
}

type QueryStringValue = string[] | string | number | boolean | null | undefined;

/**
 * @param params Parameters names and their respective values. The
 * parameters with values of `null` or `undefined` are omitted.
 * @returns Query string (with a leading `?` if not empty)
 */
export function getQueryString(
  params: ReadonlyArray<readonly [string, QueryStringValue]>,
) {
  const searchParams = new URLSearchParams();
  for (const [name, value] of params) {
    if (value == null) continue;
    if (Array.isArray(value) && value.length === 0) continue;

    if (Array.isArray(value)) {
      searchParams.append(name, value.join(','));
    } else {
      searchParams.append(name, `${value}`);
    }
  }

  const query = searchParams.toString();
  return `${query.length > 0 ? '?' : ''}${query}`;
}
