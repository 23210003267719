import { useCallback, useEffect, useMemo } from 'react';
import { useApi } from '@paradigm/api/src';
import { useEffectOnce } from '@paradigm/utils/src/hooks';
import { listFeatureFlags } from './repository';
import { useFeatureFlagStore } from './store';
import { FeatureFlags } from './adapters';

/**
 * This hook is used to load feature flags that are later accessible via
 * `useIsFeatureFlagEnabled()` hook. For usage outside React, you can use
 * `isFeatureFlagEnabled` function from `utils.ts`
 **/
const useFeatureFlagLoader = (
  loadedState: FeatureFlags = {},
  overrideFlags: FeatureFlags = {},
  onChange?: (state: FeatureFlags) => void,
) => {
  const flags = useFeatureFlagStore((state) => state.flags);
  const setFlags = useFeatureFlagStore((state) => state.setFlags);
  const setHasFetched = useFeatureFlagStore((state) => state.setHasFetched);

  const featureFlags = useMemo(() => {
    return Object.keys(flags).length > 0
      ? flags
      : { ...loadedState, ...overrideFlags };
  }, [flags, loadedState, overrideFlags]);

  const loadFlags = useCallback(async () => {
    const res = await listFeatureFlags({});
    setHasFetched(true);
    if (res.ok) {
      setFlags({
        ...res.data,
        ...overrideFlags,
      });
    }
    return res;
  }, [setHasFetched, setFlags, overrideFlags]);

  const [_, fetchFeatureFlags] = useApi(loadFlags);

  useEffect(() => {
    onChange?.(featureFlags);
  }, [onChange, featureFlags]);

  useEffectOnce(() => {
    fetchFeatureFlags({});
  });
};

export default useFeatureFlagLoader;
