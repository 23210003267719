import { RefObject } from 'react';
import { useEscapeListener } from '@paradigm/utils/src/hooks';

export default function useModalFocus<T extends HTMLElement>(
  ref: RefObject<T>,
) {
  // Toasts are driving the focus away from the Modal.
  // If user use Esc to close the toast, drive focus back
  // to the modal, so next Esc will close the modal.
  useEscapeListener(() => {
    if (ref.current == null) return;
    ref.current.focus();
  });
}
