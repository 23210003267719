import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';
import { AgreementsResponseData } from '@paradigm/api/src';
import { AgreementContent } from './types';

export default function processAgreementMarkdownData(
  content: AgreementsResponseData[],
) {
  const markdownTextContent = content[0]?.content;
  const argumentId = content[0]?.id;

  // Here we split the text by ### which is the Header that we want in the sidebar of the agreement
  const markdownTextSplited =
    content[0]?.content !== undefined
      ? markdownTextContent?.split('###').slice(1)
      : [];

  const processContent = (header: string, index: number) => {
    //  We map the data and every second item is header so we create
    //  Markdown with header and following by the text content
    if (index % 2 !== 0) return;
    const text =
      markdownTextSplited !== undefined ? markdownTextSplited[index + 1] : '';
    const markdownComponent = () => (
      <Markdown>{`###${header}${`###${text}`}`}</Markdown>
    );
    return {
      argumentId,
      id: header.trim(),
      label: header.trim(),
      Component: markdownComponent,
    };
  };

  const processedAgreementData = markdownTextContent
    ?.split('###')
    .slice(1)
    .map(processContent);

  // This is default Markdown and will be use in case we upload markdown agreement with out any ### Header ###
  const defaultMarkdown = () => <Markdown>{`${markdownTextContent}`}</Markdown>;
  const defaultAgreement = {
    argumentId,
    id: 'User Agreement',
    label: 'User Agreement',
    Component: defaultMarkdown,
  };

  // At the end we remove the undefined items from the processed Agreement Data
  return !isEmpty(processedAgreementData?.filter(Boolean))
    ? (processedAgreementData?.filter(Boolean) as AgreementContent[])
    : [defaultAgreement];
}
