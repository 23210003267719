import { FocusEventHandler } from 'react';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';

import { StyledDatePicker, Error, DateLabel } from './styles';
import { getDatePickerConfig } from './config';

export interface DatePickerProps {
  readonly onChange: (value?: Date | null, dateString?: string) => void;
  readonly value?: Date;
  readonly error?: string;
  readonly label?: string;
  readonly htmlFor?: string;
  readonly hasError?: boolean;
  readonly isFocused?: boolean;
  readonly placeholder?: string;
  readonly onBlur?: FocusEventHandler<HTMLInputElement>;
  readonly onFocus?: FocusEventHandler<HTMLInputElement>;
}

const DatePicker = ({
  label,
  error,
  value,
  onBlur,
  htmlFor,
  onFocus,
  onChange,
  placeholder,
  hasError = false,
  isFocused = false,
}: DatePickerProps) => (
  <StyleProvider hashPriority="high">
    <ConfigProvider theme={{ token: getDatePickerConfig({ hasError }) }}>
      <DateLabel htmlFor={htmlFor}>{label}</DateLabel>
      <StyledDatePicker
        hasError={hasError}
        isFocused={isFocused}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        popupStyle={{ paddingTop: '0.5rem' }}
        onChange={onChange}
        placeholder={placeholder}
      />
      {hasError && <Error>{error}</Error>}
    </ConfigProvider>
  </StyleProvider>
);

export default DatePicker;
