import { setApiToken, refreshedTokenTopic } from '@paradigm/api';

type Token = string | null;

type TokenStorage = {
  getToken(): Promise<Token>;
  setToken(token: Token): Promise<void>;
};

let internalTokenStorage: TokenStorage | undefined;

const tokenStorage: TokenStorage = {
  async getToken() {
    if (internalTokenStorage == null) {
      throw createMissingSetupError();
    }
    return internalTokenStorage.getToken();
  },
  async setToken(token) {
    if (internalTokenStorage == null) {
      throw createMissingSetupError();
    }
    return internalTokenStorage.setToken(token);
  },
};

export default tokenStorage;

let clearRefreshedTokenSubscription = noop;

export async function setupTokenStorage({ getToken, setToken }: TokenStorage) {
  const initialToken = await getToken();
  setApiToken(initialToken);

  const thisTokenStorage: TokenStorage = {
    getToken,
    setToken: async (newToken) => {
      setApiToken(newToken);
      await setToken(newToken);
    },
  };

  internalTokenStorage = thisTokenStorage;

  clearRefreshedTokenSubscription();
  clearRefreshedTokenSubscription = refreshedTokenTopic.subscribe(
    async function handleRefreshedToken({ token }) {
      await thisTokenStorage.setToken(token);
    },
  );
}

function createMissingSetupError() {
  return new TypeError(
    'Token Storage is not set up. Use `setupTokenStorage` to set it up.',
  );
}

function noop() {
  /* noop */
}
