import styled from 'styled-components';
import ChromiumCustomInstallerPrompt from '#/components/pwa/ChromiumCustomInstallerPrompt';
import useChromiumPWACustomPrompt from '#/components/pwa/useChromiumPWACustomPrompt';
import { MOBILE_BREAKPOINT } from '#/containers/layout/constants';
import useLocalStorage from '#/hooks/local-storage';
import {
  PWA_PROMPT_PERMANENT_KEY,
  PWA_PROMPT_SOFT_KEY,
} from '@paradigm/utils/src/constants';
import useIsMobile from '@paradigm/design-system/src/hooks/useIsMobile';

declare global {
  interface Navigator {
    // only available on Safari
    standalone: boolean;
  }
}

const PWACustomInstallerPrompt = () => {
  const isMobile = useIsMobile();
  const { handleOnInstall, installStatus } = useChromiumPWACustomPrompt();
  const [softDismiss, setSoftDissmiss] = useLocalStorage(
    PWA_PROMPT_SOFT_KEY,
    false,
  );
  const [permanentDismiss, setPermanentDismiss] = useLocalStorage(
    PWA_PROMPT_PERMANENT_KEY,
    false,
  );

  const onDismiss = () => setPermanentDismiss(true);
  const onSoftDismiss = () => setSoftDissmiss(true);

  // Checks if should display install popup notification:
  if (
    isMobile ||
    softDismiss ||
    permanentDismiss ||
    !installStatus.isInstallAllowed
  )
    return null;

  return (
    <Container>
      <ChromiumCustomInstallerPrompt
        onDismiss={onDismiss}
        onSoftDismiss={onSoftDismiss}
        handleOnInstall={handleOnInstall}
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 11;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    top: unset;
    bottom: 8px;
    left: 0;
    right: 0;
  }
`;

export default PWACustomInstallerPrompt;
