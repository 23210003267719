import { ComponentPropsWithoutRef } from 'react';

export default function PrimeLogo(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 34 23" {...props}>
      <linearGradient
        id="Star_00000086650565237713158450000006413114412365155486_"
        gradientUnits="userSpaceOnUse"
        x1="-267.8603"
        y1="414.2102"
        x2="-266.6775"
        y2="414.0988"
        gradientTransform="matrix(23.1781 0 0 -22.4465 6210.0459 9308.2129)"
      >
        <stop offset="0" css="stop-color:#67A4FF" />
        <stop offset="1" css="stop-color:#0E00FF" />
      </linearGradient>
      <path
        id="Star"
        css="fill:url(#Star_00000086650565237713158450000006413114412365155486_);"
        d="M11.8,0.4l0,0.5
        c0.8,7,4.5,10.6,11.2,10.7l0.3,0c-6.9,0-10.8,3.6-11.6,10.7l0,0.5l0-0.2l0,0.2l0-0.5c-0.8-7.2-4.6-10.8-11.6-10.8l0.3,0
        C7.2,11.5,11,7.9,11.7,0.8L11.8,0.4l0,0.2L11.8,0.4z"
      />
      <path
        id="P"
        css="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;"
        d="M33.2,7.2c-0.4-0.7-1-1.2-1.8-1.6C30.6,5.2,29.7,5,28.7,5h-5.3v2.3h2.8v0h2.6c0.7,0,1.2,0.2,1.6,0.6
        c0.4,0.4,0.6,1,0.6,1.6c0,0.7-0.2,1.2-0.6,1.5c-0.4,0.4-1,0.5-1.7,0.5h-5.3v7.1h2.8v-4.8h2.5c1.6,0,2.9-0.4,3.8-1.2
        c0.9-0.8,1.4-1.8,1.4-3.2C33.8,8.6,33.6,7.9,33.2,7.2"
      />
    </svg>
  );
}
