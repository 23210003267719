import styled from 'styled-components';
import { blackPearl_2, blackPearl_3, firefly, tangaroa } from '#/colors';

export const Nav = styled.nav`
  align-self: stretch;
  display: flex;
  padding: 8px 0 0;
  align-items: stretch;
  gap: 8px;
`;

export const Container = styled.section`
  height: 42px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0 16px;
  background: ${tangaroa};
`;

export const BaseTab = styled.button`
  border: none;
  display: flex;
  align-items: center;
  background: ${blackPearl_3};
  color: #ffffffb2;
  padding: 0 29px;
  border-radius: 3px 3px 0 0;
  font-weight: 600;
  position: relative;

  &:hover {
    background: ${firefly}99;
    color: #ffffffd8;
    cursor: pointer;
  }

  &.active {
    color: white;
    background: ${blackPearl_2};
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;
