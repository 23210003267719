import { ReactNode, useState } from 'react';
import { connect } from 'react-redux';

import { logoutRequest } from '@paradigm/store/auth';

import { AuthUser } from '@paradigm/store/src/auth/types';
import { useTrackedCallback } from '@paradigm/features/src/analytics';
import SettingsModal from './SettingsModal';

interface Props {
  user: AuthUser;
  handleLogout: () => void;
  children: ReactNode;
}
const SettingsContainerPure = ({ user, handleLogout, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useTrackedCallback('view settings', () => setIsOpen(true));
  const onClose = useTrackedCallback('close modal', () => setIsOpen(false));
  return (
    <>
      <button
        className="settings-trigger"
        onClick={onOpen}
        onMouseDown={(e) => {
          e.preventDefault(); // prevent chat input from losing focus
        }}
      >
        {children}
      </button>
      {isOpen && (
        <SettingsModal onClose={onClose} onLogout={handleLogout} user={user} />
      )}
    </>
  );
};

const mapStateToProps = (state: { auth: { user: AuthUser } }) => ({
  user: state.auth.user,
});

const SettingsContainer = connect(mapStateToProps, {
  handleLogout: () => logoutRequest({ reason: 'user-triggered' }),
})(SettingsContainerPure);
export default SettingsContainer;
