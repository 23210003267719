import { ComponentPropsWithoutRef } from 'react';

export default function ProBadge(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="43px"
      height="18px"
      viewBox="0 0 43 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <radialGradient
          cx="100%"
          cy="4.31946146e-14%"
          fx="100%"
          fy="4.31946146e-14%"
          r="178.084148%"
          gradientTransform="translate(1, 0), scale(0.4148, 1), rotate(90), translate(-1, -0)"
          id="radialGradient-1"
        >
          <stop stopColor="#FFFFFF" stopOpacity="0.238690997" offset="0%" />
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%" />
        </radialGradient>
        <linearGradient
          x1="101.718256%"
          y1="40.0180112%"
          x2="50%"
          y2="58.6035665%"
          id="linearGradient-2"
        >
          <stop stopColor="#919191" offset="0%" />
          <stop stopColor="#272727" offset="100%" />
        </linearGradient>
        <rect
          id="path-3"
          x="0"
          y="0"
          width="40.9821429"
          height="17"
          rx="4.55357143"
        />
      </defs>
      <g
        id="default-accounts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="default-accounts-/-1" transform="translate(-858, -196)">
          <g id="modal" transform="translate(755, 100)">
            <g id="pro-icon" transform="translate(104, 96.5)">
              <g id="Rectangle" fillRule="nonzero">
                <use fill="#000000" xlinkHref="#path-3" />
                <use
                  stroke="url(#linearGradient-2)"
                  strokeWidth="0.6375"
                  fill="url(#radialGradient-1)"
                  xlinkHref="#path-3"
                />
              </g>
              <g id="Group" transform="translate(4.9024, 3.0658)">
                <path
                  d="M7.87531707,1.72866118 C7.53899586,1.1769608 7.06029049,0.75031251 6.43674605,0.448716305 C5.81320162,0.149572102 5.08900632,0 4.26661504,0 L0,0 L0,1.82674124 L2.25359759,1.82674124 L2.25359759,1.82428924 L4.32062283,1.82428924 C4.87543008,1.83409725 5.31240216,2.00328536 5.62908417,2.33430559 C5.94331129,2.66532581 6.10287975,3.09687811 6.10287975,3.63141447 C6.10287975,4.15369082 5.94576619,4.5533671 5.63153907,4.83289529 C5.31976686,5.11487548 4.86315558,5.25463957 4.26661504,5.25463957 L0,5.25463957 L0,10.9285714 L2.25359759,10.9285714 L2.25359759,7.07892881 L4.22979155,7.07892881 C5.5284333,7.07892881 6.54476163,6.7699766 7.27877653,6.15207218 C8.01279143,5.53416776 8.37857143,4.68822719 8.37857143,3.61670246 C8.37857143,2.91297798 8.21163827,2.28281355 7.87531707,1.72866118"
                  id="Fill-1"
                  fill="#FFFFFF"
                />
                <path
                  d="M16.6444052,6.35804032 C17.3333165,6.06134812 17.8448589,5.66657585 18.1841734,5.16636751 C18.5209173,4.66861117 18.6905746,4.04825475 18.6905746,3.30284625 C18.6905746,2.25093753 18.3229839,1.43687298 17.590373,0.863104587 C16.8551915,0.286884195 15.8115423,0 14.4568548,0 L10.2,0 L10.2,1.82674124 L12.5597782,1.82674124 L12.5597782,1.82428924 L14.4568548,1.82428924 C15.0917843,1.82428924 15.5621976,1.97140934 15.8706653,2.26564954 C16.1765625,2.56234174 16.3307964,2.96692202 16.3307964,3.48184237 C16.3307964,3.98695471 16.1688508,4.38417898 15.8423891,4.67106318 C15.5904738,4.89419533 15.258871,5.03150742 14.8450101,5.07809545 C14.7241935,5.09525946 14.5982359,5.10261547 14.4645665,5.10261547 L10.2,5.10261547 L10.2,10.9285714 L12.5597782,10.9285714 L12.5597782,6.92690471 L14.4414315,6.92690471 L16.5904234,10.9285714 L19.125,10.9285714 L19.125,10.8231354 L16.6444052,6.35804032 Z"
                  id="Fill-3"
                  fill="#FFFFFF"
                />
                <circle
                  id="Oval"
                  stroke="#FFFFFF"
                  strokeWidth="2.125"
                  cx="26.3806046"
                  cy="5.43417605"
                  r="4.37167605"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
