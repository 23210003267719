import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';

import { toggleIpWarningAction } from '@paradigm/store/auth';

import { warningYellowIcon } from '#/assets';

export default function RestrictedIp() {
  const dispatch = useDispatch();
  const toggle = () => dispatch(toggleIpWarningAction());

  return (
    <Modal
      isOpen
      contentClassName="restricted-ip__container text-center"
      fade={false}
    >
      <div className="relative restricted-ip__header d-flex flex-center align-items-center">
        <div className="d-flex flex-center align-items-center">
          <img src={warningYellowIcon} alt="warning" />
        </div>
      </div>
      <div className="p-3">
        <p className="restricted-ip__container__title mt-2">
          Restricted IP Detected
        </p>
        <p className="restricted-ip__container__text opacity-7">
          We’ve detected a login from a restricted IP address. The creation of
          RFQs is disabled.
        </p>
      </div>
      <div className="d-flex flex-center align-items-center">
        <button
          onClick={toggle}
          type="button"
          className="btn restricted-ip__btn"
        >
          Proceed Anyway
        </button>
      </div>
    </Modal>
  );
}
