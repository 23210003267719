import { RetryOptions, retryExecutor } from '@paradigm/utils/src/retry';

import { AsyncWSResp, WSResponse } from '#/internal/createWebSocket';

/**
 * Execute a request and retry if it is a retrieable failure.
 *
 * By deafult we assume it is retrieable if a timeout error occurs
 * or if the request gets a response with any error. In the future we
 * could support receiving a function to assert if the response error
 * is retriable or not. On another function we could do the same to
 * test if the captured exception is retriable.
 *
 * @param executor Function that executes the request
 * @param options Parameters to configure retry strategy
 * @returns Response
 */
export async function retryWsRequest<T>(
  executor: (currentAttempt: number) => AsyncWSResp<T>,
  options?: RetryOptions,
) {
  const { value } = await retryExecutor<WSResponse<T>, WSResponse<T> | Error>(
    async (currentAttempt) => {
      try {
        const resp = await executor(currentAttempt);

        if (resp.error != null)
          return { ok: false, retriable: true, value: resp };

        return { ok: true, value: resp };
      } catch (error) {
        if (!(error instanceof Error)) throw error;

        if (error.name === 'TimeoutError')
          return { ok: false, retriable: true, value: error };

        return { ok: false, retriable: false, value: error };
      }
    },
    options,
  );

  if (value instanceof Error) throw value;
  return value;
}
