/**
 * User Preferences API provides an authenticated untyped storage for UI
 * clients. We integrate Zustand store w/ Persist middleware to interact with
 * this API with sensible defaults. (ie: State, Versioning, Migration)
 *
 * @see https://docs.pmnd.rs/zustand/integrations/persisting-store-data
 */

import { createStore, useStore } from 'zustand';
import { persist, PersistStorage, StorageValue } from 'zustand/middleware';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import { PreferencesResp } from '@paradigm/store/src/auth/types';
import {
  DefaultAccountsRecord,
  INITIAL_DEFAULT_ACCOUNTS,
} from '#/preferences/default-accounts/types';
import { setPreferences, getPreferences } from './api';
import { usePreferencesContext } from './context';
import { removeEmpty } from './utils';

const CONFIG = {
  name: 'preferences',
  /**
   * If the stored state's version mismatch the one specified here, the storage
   * will not be used. This is useful when adding a breaking change.
   */
  version: 1,
  /**
   * Prevent the persist middleware from triggering hydration on initialization,
   * This allows you to call `rehydrate()` at a later point.
   */
  skipHydration: true, // We will be initializing through props
  persistedPreferenceKeys: [
    'defaultAccounts',
    'defaultAccountsMigrated',
    'isMatchingDetectionEnabled',
  ], // Allowlist for preferences that wil get pushed to the server
};

export const DEFAULT_PROPS: Preferences = {
  defaultAccounts: INITIAL_DEFAULT_ACCOUNTS,
  isMatchingDetectionEnabled: true,
};

export interface Preferences {
  defaultAccounts: DefaultAccountsRecord;
  defaultAccountsMigrated?: boolean;
  isMatchingDetectionEnabled: boolean;
}
export interface PreferencesState extends Preferences {
  setDefaultAccounts: (value: Preferences['defaultAccounts']) => void;
  mergeDefaultAccounts: (
    value: Partial<Preferences['defaultAccounts']>,
  ) => void;
  setHasMigratedAccounts: () => void;
  resetHasMigrated: () => void;
  toggleMatchingDetection: () => void;
}
export type PersistedPreferences = Partial<Preferences>;

export type PreferencesStore = ReturnType<typeof createPreferencesStore>;

const storage: PersistStorage<PersistedPreferences> = {
  getItem: async () => {
    return getPreferences();
  },
  setItem: debounce(
    async (_name: string, newValue: StorageValue<PersistedPreferences>) => {
      await setPreferences(newValue);
    },
    500,
  ),
  removeItem: async () => {
    await setPreferences({ state: {}, version: CONFIG.version });
  },
};

/**
 * @remarks If you want to introduce a breaking change to preferences
 * (e.g. renaming a field), you can specify a new version number. By default,
 * if the version in the storage does not match the version in the code, the
 * stored value won't be used. You can use the migrate function to handle
 * breaking changes in order to persist previously stored data.
 */
const migrate = (
  persistedState: unknown,
  _version: number,
): PreferencesState => {
  /**
   * Example of a migration:
   *
   * if (version === 0) {
   *   persistedState.newField = persistedState.oldField
   *   delete persistedState.oldField
   * }
   *
   */
  return persistedState as PreferencesState;
};

/**
 * @remarks Persist can do this on hydration, but we implement this manually so
 * that the store is initialized with first call to get all UserInfo.
 */
const processStoredPreferences = (
  storedPreferences: PreferencesResp['data'],
): PersistedPreferences => {
  if (
    typeof storedPreferences.version !== 'number' ||
    storedPreferences.version !== CONFIG.version
  ) {
    const migratedState = migrate(
      storedPreferences.state,
      storedPreferences.version,
    );
    return migratedState;
  }
  return storedPreferences.state as PersistedPreferences;
};

/**
 * @remarks Minify the storage value before calling API
 */
const partialize = (state: PreferencesState): PersistedPreferences => {
  const preferencesWithoutDefaults: PersistedPreferences = {};
  for (const k of CONFIG.persistedPreferenceKeys) {
    const key = k as keyof Preferences;
    if (isEqual(state[key], DEFAULT_PROPS[key])) continue;
    // @ts-ignore: we are sure about this type casting
    preferencesWithoutDefaults[key] = state[key];
  }

  const preferencesWithoutEmptyValues = removeEmpty(
    preferencesWithoutDefaults,
  ) as PersistedPreferences;

  return preferencesWithoutEmptyValues;
};

export const createPreferencesStore = (
  storedPreferences: PreferencesResp['data'],
) => {
  const initProps = processStoredPreferences(storedPreferences);
  return createStore<PreferencesState>()(
    persist(
      (set, get) => ({
        ...DEFAULT_PROPS,
        ...initProps,
        mergeDefaultAccounts: (partialValue) => {
          const prevValue = structuredClone(
            get().defaultAccounts,
          ) as DefaultAccountsRecord;
          const newValue = merge(
            prevValue,
            partialValue,
          ) as DefaultAccountsRecord;
          if (isEqual(get().defaultAccounts, newValue)) return;

          set({ defaultAccounts: newValue });
        },
        setDefaultAccounts: (value) => {
          if (!isEqual(get().defaultAccounts, value)) {
            set({ defaultAccounts: value });
          }
        },
        resetHasMigrated: () => {
          if (get().defaultAccountsMigrated === false) return;
          set({ ...get(), defaultAccountsMigrated: false });
        },
        setHasMigratedAccounts: () => {
          if (get().defaultAccountsMigrated === true) return;
          set({ ...get(), defaultAccountsMigrated: true });
        },
        toggleMatchingDetection: () => {
          set({
            ...get(),
            isMatchingDetectionEnabled: !get().isMatchingDetectionEnabled,
          });
        },
      }),
      {
        ...CONFIG,
        storage,
        // Perform deep merge
        merge: (persistedState, currentState) =>
          merge(persistedState, currentState),
        migrate,
        partialize,
      },
    ),
  );
};

type ExtractState<S> = S extends { getState: () => infer X } ? X : never;

/**
 * usePreferencesStore() is the main hook to accessing the preferences store.
 * @example: const x = usePreferencesStore((state) => state.x); (React Hook)
 */
export function usePreferencesStore<T>(
  selector: (state: ExtractState<PreferencesStore>) => T,
): T {
  const store = usePreferencesContext();
  return useStore(store, selector);
}
