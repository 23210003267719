import { useSelector } from 'react-redux';

import UnifiedMarketsNotificationsProvider from '@paradigm/features/src/unified-rfqs/ui/notifications/notifications-context';
import { selectIsAuthenticated } from '@paradigm/store/auth';

import { playNotificationSound, fireNotification } from '#/notifications';
import { UNIFIED_RFQS_ROUTE } from '#/routes';
import { usePinnedRfqsAction } from '@paradigm/features/src/unified-rfqs/ui/rfqs/pinned-rfqs-context';

interface Props {
  children: JSX.Element;
}

export default function UnifiedRfqsNotificationsProvider({
  children,
}: Props): JSX.Element {
  const pathname = useSelector((state) => state.router.location.pathname);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { pinRfq } = usePinnedRfqsAction();

  if (!isAuthenticated) return children;

  return (
    <UnifiedMarketsNotificationsProvider
      playNotificationSound={playNotificationSound}
      fireNotification={fireNotification}
      pinRfq={pinRfq}
      isViewingUmPage={pathname.includes(UNIFIED_RFQS_ROUTE)}
    >
      {children}
    </UnifiedMarketsNotificationsProvider>
  );
}
