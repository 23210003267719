import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { getUser } from '@paradigm/store/auth';
import DrfqNotificationsService, {
  FireNotificationFn,
} from '#/unified-rfqs/services/drfq-notifications';
import { DrfqOrderbooksStore } from '#/unified-rfqs/store/drfq-orderbooks';
import { RfqId } from '../../entities/rfq';

interface Notifications {
  readonly fireNotification: FireNotificationFn;
  readonly hasNewNotifications: boolean;
}

export const [useUnifiedMarketsNotifications, NotificationsCtxProvider] =
  createSafeContext<Notifications>('Notifications');

interface Props {
  readonly children: ReactNode;
  readonly playNotificationSound: () => void;
  readonly pinRfq: (rfqId: RfqId) => void;
  readonly fireNotification: (opts: {
    title: string;
    message: string | null;
    silent: boolean;
    icon?: string | (() => string);
    notificationId: string;
    onClick?: (() => void) | null;
  }) => void;
  readonly isViewingUmPage: boolean;
}

export default function UnifiedMarketsNotificationsProvider({
  children,
  playNotificationSound,
  fireNotification,
  pinRfq,
  isViewingUmPage,
}: Props) {
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [notificationsService, setNotificationsService] =
    useState<DrfqNotificationsService | null>(null);
  const user = useSelector(getUser);

  const playSound = useMemo(
    () =>
      debounce(() => {
        if (user.audio_notifications_enabled === true) {
          playNotificationSound();
        }
        // Dispatch notification sound at most every second
      }, 1000),
    [playNotificationSound, user],
  );

  const value: Notifications = useMemo(
    () => ({
      hasNewNotifications,
      fireNotification: (options) => {
        if (!isViewingUmPage) setHasNewNotifications(true);
        if ('onlyBadge' in options) return;
        if ('onlySound' in options) {
          playSound();
          return;
        }

        if (options.silent !== true) playSound();
        if (user.visual_notifications_enabled === false) {
          return;
        }

        fireNotification({ ...options, silent: true });
      },
    }),
    [hasNewNotifications, isViewingUmPage, user, playSound, fireNotification],
  );

  useEffect(() => {
    const orderbooksStore = new DrfqOrderbooksStore();
    const service = new DrfqNotificationsService({
      fireNotification: value.fireNotification,
      pinRfq,
      orderbooksStore,
    });
    setNotificationsService(service);

    return service.teardown;
  }, [pinRfq, value.fireNotification]);

  // Mark notifications as seen, when user goes to UM page
  useEffect(() => {
    if (isViewingUmPage) {
      setHasNewNotifications(false);
    }
  }, [isViewingUmPage]);

  /**
   * Start notifications service websocket when user is not at the UM
   * page. When the user goes to /rfqs, we shutdown the websocket connection
   * and use the one that was open by services/drfq-ws.
   */
  useEffect(() => {
    if (isViewingUmPage) {
      return;
    }
    notificationsService?.startWebsocket();
    return () => {
      notificationsService?.teardown();
    };
  }, [isViewingUmPage, notificationsService, value.fireNotification]);

  return (
    <NotificationsCtxProvider value={value}>
      {children}
    </NotificationsCtxProvider>
  );
}
