import { useLocation } from 'react-router';

const ALL_PRIVATE_DEVELOPMENT_ENVS = [
  'local',
  'ondemand',
  'stage',
  'nightly',
  'dev',
  'development',
];

const useDebugFeatureFlags = () => {
  const { search } = useLocation();

  if (!new Set(ALL_PRIVATE_DEVELOPMENT_ENVS).has(ENVIRONMENT)) return;
  const query = new URLSearchParams(search);
  return Object.fromEntries(
    Array.from(query.entries())
      .filter(([key]) => key.startsWith('feature_'))
      .map(([key, value]) => [
        key,
        {
          isEnabled: !(value === '0' || value === 'false'),
        },
      ]),
  );
};

export default useDebugFeatureFlags;
