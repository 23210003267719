// TO DO: Remove this duplication with api grfq type
export type Source = 'BIT' | 'DBT' | 'BYB';

export interface GrfqVenue {
  /** Venue acronym used in most API req/resp bodies */
  readonly code: Source;
  /**
   * Venue acronym used for display purposes. Usually follows `code`.
   * `null` means that the code is not to be displayed.
   */
  readonly displayCode: string | null;
  /** Human-readable name */
  readonly name: string;
  readonly shortName: string;
  readonly custom: boolean;
  readonly minBlockSizeCalculation: 'leg' | 'strategy';
  /** Normalization value for min block size calculation. Use 0 for no normalization. */
  readonly normalizedMinBlockSize: number;
}

export const VENUES: readonly GrfqVenue[] = [
  {
    code: 'DBT',
    displayCode: 'DBT',
    name: 'Deribit (DBT)',
    shortName: 'Deribit',
    custom: true,
    minBlockSizeCalculation: 'strategy',
    normalizedMinBlockSize: 0,
  },
  {
    code: 'BIT',
    displayCode: 'BIT',
    name: 'Bit.com (BIT)',
    shortName: 'Bit.com',
    custom: true,
    minBlockSizeCalculation: 'leg',
    normalizedMinBlockSize: 0,
  },
  {
    code: 'BYB',
    displayCode: 'BYB',
    name: 'Bybit (BYB)',
    shortName: 'Bybit',
    custom: true,
    minBlockSizeCalculation: 'strategy',
    normalizedMinBlockSize: 1,
  },
];

export const getVenues = () => VENUES;

export const venueSupportsCustomStrategy = (code: GrfqVenue['code']) => {
  return getVenue(code).custom;
};

export const getVenue = (code: string) => {
  const venue = getVenues().find((vn) => vn.code === code);
  if (venue === undefined)
    throw new Error(`Venue with code "${code}" doesn't exist.`);
  return venue;
};
