import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';

import { errorDismiss, selectError } from '@paradigm/store/error';

const PopupError = () => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={error.length > 0}
      toggle={() => dispatch(errorDismiss())}
      contentClassName="error-popup__container"
      centered
      fade={false}
    >
      <div className="d-flex flex-center align-items-center">
        <div className="flex-center align-items-center flex-column">
          <i className="material-icons error-popup__icon mb-3">highlight_off</i>
          <p>{error}</p>
        </div>
      </div>
      <div className="d-flex flex-center align-items-center mt-4">
        <button
          onClick={() => dispatch(errorDismiss())}
          type="button"
          className="btn error-popup__btn"
        >
          Okay
        </button>
      </div>
    </Modal>
  );
};

export default PopupError;
