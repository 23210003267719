interface Props {
  readonly width?: number;
}

export default function CheckIcon(props: Props) {
  const { width = 16 } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 9.13169188 6.5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="um-/-positions"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="um-/-positions-/-2.1"
          transform="translate(-66.434154, -588.241502)"
          fillRule="nonzero"
          stroke="#7FABEC"
          strokeWidth="1.5"
        >
          <g id="Group-22" transform="translate(49.000000, 300.000000)">
            <g id="Group-27" transform="translate(0.000000, 143.500000)">
              <path
                d="M25.8158459,145.491502 L20.8157477,150.491502 L18.1841541,147.849003"
                id="icon-/-selected"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
