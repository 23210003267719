import { BaseReq, request } from './fetch-api';
import { getQueryString } from './internal/utils';

type ValidationStatus = 'ok' | 'warn' | 'disabled';

interface AccountBalance {
  readonly currency: string;
  readonly has_balance: boolean;
  readonly currency_kind: string;
}
export interface AccountResponse {
  readonly id: number;
  readonly name: string;
  readonly is_default: boolean;
  readonly is_penalized: boolean;
  readonly validation_status?: ValidationStatus;
  readonly has_balance_permission?: boolean;
  readonly has_position_readable_permission: boolean;
  readonly account_balance?: AccountBalance[];
  /* DeFi venues only */
  readonly wallet_address?: string;
  readonly delegated_wallet_address?: string;
}

interface ListAccountsReq extends BaseReq {
  readonly venue: string;
  /* DeFi venues only */
  readonly swapAddress?: string;
  readonly biddingToken?: string;
}

const VENUE_ACCOUNTS_LIST_URL_MAP: Record<string, string> = {
  BIT: '/bitcom/api-credentials/',
  CME: '/cme/clearing-accounts/',
  DBT: '/deribit/api-credentials/',
  BYB: '/bybit/api-credentials/',
  RBN: '/ribbon/api-credentials/',
  FKN: '/friktion/api-credentials/',
  FTX: '/ftx/api-credentials/',
  TTN: '/thetanuts/api-credentials/',
};

export async function listAccounts(req: ListAccountsReq) {
  const url = VENUE_ACCOUNTS_LIST_URL_MAP[req.venue];
  if (url == null) throw new TypeError(`Venue "${req.venue}" not supported`);

  const { swapAddress, biddingToken } = req;

  const query = getQueryString([
    ['swap_address', swapAddress],
    ['bidding_token', biddingToken],
  ]);

  return request<AccountResponse[]>({
    ...req,
    method: 'GET',
    url: `${url}${query}`,
  });
}

interface UpdateDefaultAccountReq extends BaseReq {
  readonly id: number | null;
  readonly venue: string;
}

const VENUE_ACCOUNT_ID_FIELD_MAP: Record<string, string> = {
  DBT: 'default_deribit_api_credential_id',
  CME: 'default_cme_clearing_account_id',
  BIT: 'default_bitcom_api_credential_id',
  BYB: 'default_bybit_api_credential_id',
  RBN: 'default_ribbon_api_credential_id',
  FKN: 'default_friktion_api_credential_id',
  FTX: 'default_ftx_api_credential_id',
};

export const updateDefaultAccount = async (params: UpdateDefaultAccountReq) => {
  const { venue, id, ...req } = params;
  const field = VENUE_ACCOUNT_ID_FIELD_MAP[venue];
  if (field == null) throw new TypeError(`Venue "${venue}" not supported`);
  return request({
    ...req,
    method: 'PATCH',
    url: '/trading-desk/member/trading-state/update/',
    body: { [field]: id },
  });
};

interface UpdateDefaultTraderReq extends BaseReq {
  readonly id: number;
  readonly venue: string;
}

const VENUE_TRADER_ID_FIELD_MAP: Record<string, string> = {
  CME: 'default_cme_trader_id',
};

export const updateDefaultTrader = async (params: UpdateDefaultTraderReq) => {
  const { venue, id, ...req } = params;
  const field = VENUE_TRADER_ID_FIELD_MAP[venue];
  if (field == null) throw new TypeError(`Venue "${venue}" not supported`);
  return request({
    ...req,
    method: 'PATCH',
    url: '/trading-desk/member/trading-state/update/',
    body: { [field]: id },
  });
};
