import localStorage from '@paradigm/utils/src/safeLocalStorage';
/**
 * Persist the route that the application should be when reopened.
 */

const KEY = 'last-route';

export const getReopenRoute = () => localStorage.getItem(KEY);

export const setReopenRoute = (route: string) =>
  localStorage.setItem(KEY, route);

export const removeReopenRoute = () => localStorage.removeItem(KEY);
