import { DatePicker as AntDatePicker } from 'antd';
import styled from 'styled-components';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { PickerComponentClass } from 'antd/es/date-picker/generatePicker/interface';
import { PickerProps } from 'antd/es/date-picker/generatePicker';
import {
  amaranth,
  americanBronze,
  blackPearl_6,
  mineShaft,
  paradigmLightBlue,
  pickledBluewood,
  portage70,
} from '#/colors';
import { MOBILE_BREAKPOINT } from '#/units';

type BaseDatePicker = PickerComponentClass<
  PickerProps<Date> & {
    status?: '' | 'warning' | 'error' | undefined;
    hashId?: string | undefined;
    popupClassName?: string | undefined;
    rootClassName?: string | undefined;
  }
>;

const BaseDatePicker: BaseDatePicker = AntDatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
);

export const StyledDatePicker = styled(BaseDatePicker)<{
  hasError: boolean;
  isFocused: boolean;
}>`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  line-height: 1.375rem;
  height: 40px;
  border-color: ${(props) =>
    props.hasError
      ? amaranth
      : props.isFocused
      ? pickledBluewood
      : 'transparent'} !important;

  box-shadow: ${(props) =>
    props.hasError
      ? `0 0 10px ${amaranth}`
      : props.isFocused
      ? `0 0 0 0.2rem rgba(0, 123, 255, 0.25)`
      : 'none'} !important;

  input {
    color: #ffffff !important;
  }
`;

export const Error = styled.div`
  padding-top: 5px;
  color: $invalid-color;
  font-size: 12px;
`;

export const DateLabel = styled.div<{ htmlFor?: string }>`
  margin-top: 1em;
  opacity: 0.5;
  color: #ffffff;
  font-size: 0.75rem;
  margin-bottom: 7px;
`;

export const RangeDateLabel = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.66rem;
  line-height: 1rem;
  position: absolute;
  top: -8px;
  left: 8px;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
  background: #07071d;
`;

const fontFamily = "'IBM Plex Sans', 'Roboto', sans-serif";

export const Container = styled.div<{ isActive: boolean }>`
  position: relative;

  .ant-picker {
    font-family: ${fontFamily};
    padding: 4px 4px 4px 11px;
    width: 100%;
    height: 36px;
    color: white;
    font-size: 0.875rem;
    line-height: 1rem;
    border: 1px solid ${mineShaft};
    border-color: ${(props) => (props.isActive ? portage70 : mineShaft)};
    border-radius: 3px;
    background-color: #07071d;
    &:hover {
      border: 1px solid ${portage70};
    }
    &.ant-picker-focused {
      border: 1px solid ${portage70};
    }
    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
      visibility: visible !important;
    }
    .ant-picker-input {
      input {
        color: white;
        ::placeholder {
          color: white;
        }
        @media (max-width: 1200px) {
          font-size: 0.8rem;
        }
      }
    }
    .ant-picker-month-btn,
    .ant-picker-year-btn {
      cursor: not-allowed;
      pointer-events: none;
    }
    .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
      visibility: visible !important;
    }
    .ant-picker-panels > *:last-child {
      display: none !important;
    }
    .ant-picker-panel-container,
    .ant-picker-footer {
      width: 430px !important;
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 280px !important;
      }
    }
    .ant-picker-footer-extra > div {
      flex-wrap: wrap !important;
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-range-arrow::before,
    .ant-picker-range-arrow::after {
      background: ${paradigmLightBlue};
    }
    .ant-picker-range-separator {
      span {
        margin: 0;
        margin-right: 6px;
      }
      padding: 0;
    }
    .ant-picker-clear,
    .ant-picker-clear:hover {
      background: transparent;
      top: 55%;
      right: 35px;
    }
    .ant-picker-suffix {
      margin: 0;
      color: ${paradigmLightBlue};
      @media (max-width: 1200px) {
        display: none;
      }
    }
    .date-picker-pop-up {
      .ant-picker-cell-in-range::before {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 0;
        opacity: 0.4;
      }
      .ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
      .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background-color: rgba(255, 255, 255, 0.4);
        opacity: 0.4;
      }
      .ant-picker-panel-container {
        border-radius: 8px;
        background-color: ${blackPearl_6};
        box-shadow: 0 0 20px 0 rgba(74, 28, 186, 0.3);
      }
      .ant-picker-presets {
        li {
          border-radius: 4px;
          background-color: ${americanBronze};
          opacity: 0.4;
          color: rgba(255, 255, 255, 0.88);
          font-size: 0.8rem;
          letter-spacing: 0;
          line-height: 1.3rem;
        }
        li:hover {
          opacity: 1;
          background-color: ${americanBronze};
        }
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          display: none;
        }
      }
      .ant-picker-header {
        color: white;
        opacity: 0.7;
        .ant-picker-header-view button:hover {
          color: ${paradigmLightBlue};
        }
      }
      .ant-picker-super-prev-icon,
      .ant-picker-super-next-icon,
      .ant-picker-next-icon,
      .ant-picker-prev-icon {
        color: white;
        opacity: 0.5;
      }
      .ant-picker-content {
        th {
          color: white;
          opacity: 0.3;
        }
        .ant-picker-cell-inner {
          color: white;
          opacity: 0.7;
        }
        .ant-picker-cell-inner:hover {
          color: white;
          opacity: 0.7;
        }
        .ant-picker-cell-range-start,
        .ant-picker-cell-range-end {
          .ant-picker-cell-inner::before {
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            bottom: 0;
            inset-inline-start: 0;
            z-index: 1;
            border: 1px solid ${paradigmLightBlue};
            border-radius: 4px;
            content: '';
          }
          .ant-picker-cell-inner {
            opacity: 1;
            background: ${blackPearl_6};
            color: ${paradigmLightBlue};
            border-color: ${paradigmLightBlue};
          }
        }
        .ant-picker-cell-range-end {
          .ant-picker-cell-inner::before {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            content: '';
          }
        }
        .ant-picker-cell-range-start {
          .ant-picker-cell-inner::before {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
        .ant-picker-cell-disabled {
          .ant-picker-cell-inner {
            opacity: 0.5;
          }
        }
        .ant-picker-cell-today {
          .ant-picker-cell-inner {
            opacity: 1;
          }
        }
      }
    }
  }

  .ant-picker-range-wrapper {
    font-family: ${fontFamily};
  }
`;
