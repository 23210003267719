import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { blueMana } from '#/colors';
import { MOBILE_BREAKPOINT } from '#/units';
import BaseCloseIcon from '#/assets/CloseIcon';

export function CloseButton(props: ComponentPropsWithoutRef<'button'>) {
  return (
    <BaseButton type="button" {...props}>
      <CloseIcon />
    </BaseButton>
  );
}

const BaseButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  z-index: 1;
`;

const CloseIcon = styled(BaseCloseIcon)`
  display: block;
  color: ${blueMana};
  cursor: pointer;
  width: 0.88rem;
  height: 0.88rem;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
