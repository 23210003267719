import {
  DataProcessor,
  DataProcessorsByChannel,
  GrfqNotification,
  NotificationErrorMap,
  RawGrfqNotification,
  RawGrfqNotificationResp,
} from '#/unified-rfqs/repositories/grfq-ws';
import {
  processGrfq,
  processGrfqOwnQuote,
  processGrfqQuote,
  processGrfqTrade,
  processGrfqTradeTape,
} from '#/unified-rfqs/repositories/adapters/grfq-api';

const dataProcessors: DataProcessorsByChannel = {
  rfq(notif) {
    return { ...notif, rfq: processGrfq(notif.rfq) };
  },
  quote(notif) {
    return { ...notif, quote: processGrfqOwnQuote(notif.quote) };
  },
  quote_book(notif) {
    return { ...notif, quote: processGrfqQuote(notif.quote) };
  },
  quote_status(notif) {
    return { ...notif };
  },
  trade(notif) {
    return { ...notif, trade: processGrfqTrade(notif.trade) };
  },
  trade_tape(notif) {
    return { ...notif, trade: processGrfqTradeTape(notif.trade) };
  },
  order(notif) {
    const { quote, ...restOrder } = notif.order;
    return {
      ...notif,
      order: {
        ...restOrder,
        // add order inside quote to calculate TAKER quantities
        quote: processGrfqOwnQuote({ ...quote, order: restOrder }),
      },
    };
  },
  market_maker_protection(notif) {
    return notif;
  },
};

export function processNotification(
  raw: RawGrfqNotificationResp,
): GrfqNotification {
  const { channel, data } = raw.params;
  const processor = dataProcessors[channel] as DataProcessor;
  return {
    channel,
    data: processor(data),
    errors: processErrors(raw.params),
  } as GrfqNotification;
}

export function processErrors(raw: RawGrfqNotification): NotificationErrorMap {
  return {
    venue_error: raw.venue_error?.message,
  };
}
