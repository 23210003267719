import {
  ReactNode,
  useMemo,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';
import { useSelector } from 'react-redux';

import { isBefore, subHours } from 'date-fns';
import { getUserID } from '@paradigm/store/auth';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { Rfq, RfqId } from '#/unified-rfqs/entities/rfq';
import { Nullable } from '@paradigm/utils/types';

interface PinnedRfqsActions {
  readonly pinRfq: (rfqId: RfqId) => void;
  readonly unpinRfq: (rfqId: RfqId) => void;
  readonly togglePinRfq: (rfqId: RfqId) => void;
}
interface PinnedRfqsView {
  readonly splitPinnedRfqs: (
    rfqs: readonly Nullable<Rfq>[],
  ) => [pinnedRfqs: readonly Rfq[], unpinnedRfqs: readonly Rfq[]];
  readonly isPinned: (rfqId: RfqId) => boolean;
}

export const [usePinnedRfqsView, PinnedItemsViewProvider] =
  createSafeContext<PinnedRfqsView>('PinnedRfqsView');

export const [usePinnedRfqsAction, PinnedItemsActionProvider] =
  createSafeContext<PinnedRfqsActions>('PinnedRfqsActions');

export interface StoredPinnedItem {
  [rfqId: string | number]: number;
}
export interface StoredPinnedItems {
  readonly [userId: number]: StoredPinnedItem;
}

interface PinnedRfqsViewProviderProps {
  readonly children: ReactNode;
  readonly pinnedRfqs: StoredPinnedItems;
  readonly setPinnedRfqs: Dispatch<SetStateAction<StoredPinnedItems>>;
}

export function PinnedRfqsProvider({
  children,
  pinnedRfqs,
  setPinnedRfqs,
}: PinnedRfqsViewProviderProps) {
  const createView = useCallback(
    (userId: number) => {
      const userPinnedRfqs = pinnedRfqs[userId] || {};

      const sortByPinnedTime = (rfqA: Rfq, rfqB: Rfq) => {
        const timestampA = userPinnedRfqs[rfqA.id]!;
        const timestampB = userPinnedRfqs[rfqB.id]!;

        return timestampB - timestampA;
      };

      const view: PinnedRfqsView = {
        splitPinnedRfqs: (rfqs: readonly Nullable<Rfq>[]) => {
          const pinnedItems: Rfq[] = [];
          const unpinnedItems: Rfq[] = [];

          rfqs.forEach((rfq) => {
            if (rfq == null) return;

            if (userPinnedRfqs[rfq.id] != null) pinnedItems.push(rfq);
            else unpinnedItems.push(rfq);
          });

          return [pinnedItems.sort(sortByPinnedTime), unpinnedItems];
        },
        isPinned: (rfqId) => userPinnedRfqs[rfqId] != null,
      };
      return view;
    },
    [pinnedRfqs],
  );

  const createActions = useCallback(
    (userId: number) => {
      const actions: PinnedRfqsActions = {
        pinRfq: (rfqId) => {
          setPinnedRfqs((currentValue: StoredPinnedItems) => {
            const currentUserValue = currentValue[userId];
            return {
              ...currentValue,
              [userId]: cleanOldPinnedRfqs({
                ...currentUserValue,
                [rfqId]: Date.now(),
              }),
            };
          });
        },
        unpinRfq: (rfqId) => {
          setPinnedRfqs((currentValue: StoredPinnedItems) => {
            const { [userId]: userPinned, ...restPinnedRfqs } = currentValue;
            const { [rfqId]: _, ...restUserPinned } = userPinned ?? {};
            return {
              ...restPinnedRfqs,
              [userId]: cleanOldPinnedRfqs(restUserPinned),
            };
          });
        },
        togglePinRfq: (rfqId) => {
          setPinnedRfqs((currentValue: StoredPinnedItems) => {
            const { [userId]: userPinned, ...restPinnedRfqs } = currentValue;
            const { [rfqId]: rfqPinned, ...restUserPinned } = userPinned ?? {};

            const pinnedValue = {
              ...currentValue,
              [userId]: cleanOldPinnedRfqs({
                ...userPinned,
                [rfqId]: Date.now(),
              }),
            };

            const unpinnedValue = {
              ...restPinnedRfqs,
              [userId]: cleanOldPinnedRfqs(restUserPinned),
            };

            return rfqPinned == null ? pinnedValue : unpinnedValue;
          });
        },
      };
      return actions;
    },
    [setPinnedRfqs],
  );

  const userId = useSelector(getUserID);
  const view = useMemo(() => createView(userId), [createView, userId]);
  const actions = useMemo(() => createActions(userId), [createActions, userId]);

  return (
    <PinnedItemsViewProvider value={view}>
      <PinnedItemsActionProvider value={actions}>
        {children}
      </PinnedItemsActionProvider>
    </PinnedItemsViewProvider>
  );
}
const MAX_PIN_RFQ_DURATION_HOURS = 24;
function cleanOldPinnedRfqs(pinnedRfqs: StoredPinnedItem | undefined) {
  if (pinnedRfqs === undefined) return {};
  const cleanPinnedRfqs: StoredPinnedItem = {};
  Object.entries(pinnedRfqs).forEach(([rfqIdKey, timestamp]) => {
    if (isBefore(timestamp, subHours(Date.now(), MAX_PIN_RFQ_DURATION_HOURS)))
      return;

    cleanPinnedRfqs[rfqIdKey] = timestamp;
  });

  return cleanPinnedRfqs;
}
