import { ComponentPropsWithoutRef } from 'react';

export default function FsIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="-3.5 -1.5 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#7FABEC" fill="none" fillRule="evenodd">
        <rect width="7" height="12" rx="2" />
        <rect x="10" y="8" width="7" height="12" rx="2" />
        <rect y="15" width="7" height="5" rx="2" />
        <rect x="10" width="7" height="5" rx="2" />
      </g>
    </svg>
  );
}
