import { useEffect } from 'react';

import { setUser } from '@paradigm/logging';
import useUserMetadata from './useUserMetadata';

export default function UserIdentifier() {
  const { id, email, name } = useUserMetadata();

  useEffect(() => {
    setUser({ id, email, name });
    return () => {
      setUser(null);
    };
  }, [id, email, name]);

  return null;
}
