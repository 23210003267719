export const VIEW_RATIO_PRECISION = 2;
export const RATIO_PRECISION = {
  OB: {
    OPTION: 1,
    FUTURE: 2,
  },
  RFQ: {
    OPTION: 12,
    FUTURE: 17,
  },
};
