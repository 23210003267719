/* eslint-disable react/forbid-prop-types */
import * as PropTypes from 'prop-types';

/**
 * Helper for returning a wrapper given a className else returns just the
 * children JSX.
 * @param {JSX} children - JSX syntax passed down to the component
 * @param {String} className - classNames generated by css module
 * @return {JSX} children wrapped in div with given className else just the children
 */
export const Wrapper = ({ children, className }) => {
  if (className) {
    return <div className={className}>{children}</div>;
  }
  return children;
};

Wrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
