import {
  AsyncResp,
  FailedResp,
  RequestParams,
  requestPapi,
} from '@paradigm/api/src/fetch-api';
import { getQueryString } from '@paradigm/api/src/internal/utils';
import { track, UM_GRFQ_LIST_RFQS } from '@paradigm/analytics/src/analytics';
import { isFeatureFlagEnabled, isOBV1APIEnabled } from '#/feature-flags/utils';
import { Grfq, GrfqQuote } from '#/unified-rfqs/entities/grfq/domain';
import {
  processGrfq,
  processGrfqTradeDetails,
  processGrfqQuote,
  processGrfqTrade,
  processGrfqOwnQuote,
  processGrfqTradeTape,
  processGrfqQuoteAPIV2,
} from '#/unified-rfqs/repositories/adapters/grfq-api';
import {
  CancelQuoteReq,
  CancelQuotesBatchReq,
  CancelQuotesBatchResp,
  CancelQuotesBatchRespV2,
  CancelRfqQuotesBatchReq,
  CreateQuoteReq,
  CreateRfqReq,
  CreateRfqResp,
  GetBboReq,
  GetBboResp,
  GetBlotterReq,
  GetBlotterResp,
  GetLastOwnQuoteReq,
  GetLastOwnQuoteResp,
  GetRfqReq,
  GetTradeDetailsReq,
  GetTradeDetailsResp,
  GrfqQuoteV2CreateResp,
  ListedRfqQuote,
  ListInstrumentsReq,
  ListInstrumentsResp,
  ListOwnQuotesReq,
  ListOwnQuotesResp,
  ListQuotesReq,
  ListRfqQuotesResp,
  ListRfqsReq,
  ListRfqsResp,
  ListTradesReq,
  ListTradesResp,
  ListTradeTapeReq,
  ListTradeTapeResp,
  PricingReq,
  PricingResp,
  RawCreateRfqResp,
  RawGrfq,
  RawGrfqQuote,
  RawGrfqQuoteV2CreateResp,
  RawGrfqTradeDetails,
  RawListOwnQuotesResp,
  RawListRfqQuotesResp,
  RawListRfqsResp,
  RawListTradesResp,
  RawListTradeTapeResp,
} from '#/unified-rfqs/repositories/grfq-types';
import { GrfqServiceRequestError } from '@paradigm/logging/src/errors';
import type { Breadcrumb } from '@sentry/types';

export const getGrfqBaseUrl = () =>
  isOBV1APIEnabled() ? '/v1/ob' : '/v1/grfq';

export async function listRfqs(req: ListRfqsReq): AsyncResp<ListRfqsResp> {
  const isAdditionalGrfqLogsEnabled = isFeatureFlagEnabled(
    'logs_grfq_message_recieved',
  );

  if (isAdditionalGrfqLogsEnabled) {
    track(UM_GRFQ_LIST_RFQS, { req });
    console.info(`[GET]${getGrfqBaseUrl()}/rfqs - req:`, req);
  }

  const { cursor, pageSize, productCodes, strategyCodes, rfqId, ...rest } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['page_size', pageSize],
    ['product_codes', productCodes],
    ['strategies', strategyCodes],
    ['rfq_id', rfqId],
  ]);

  const resp = await requestPapi<RawListRfqsResp>({
    ...rest,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/rfqs${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processGrfq),
    },
  };
}

export async function createRfq(req: CreateRfqReq): AsyncResp<CreateRfqResp> {
  const { venue, legs, ...rest } = req;
  const resp = await requestPapi<RawCreateRfqResp>({
    ...rest,
    method: 'POST',
    url: `${getGrfqBaseUrl()}/rfqs`,
    body: { venue, legs },
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processGrfq(resp.data),
  };
}

export async function getLegPricing(req: PricingReq): AsyncResp<PricingResp> {
  const { signal, ...body } = req;
  const resp = await requestPapi<PricingResp>({
    body,
    method: 'POST',
    url: `${getGrfqBaseUrl()}/pricing`,
    signal,
  });
  return resp;
}

export async function createOrReplaceQuote({
  rfqId,
  quote,
  ...req
}: CreateQuoteReq): AsyncResp<GrfqQuote | GrfqQuoteV2CreateResp> {
  const requestParams = {
    ...req,
    body: {
      ...quote,
      ioc: quote.ioc ?? false,
    },
    method: 'POST',
    url: `${getGrfqBaseUrl()}/rfqs/${rfqId}/quotes`,
  } as RequestParams;

  if (isOBV1APIEnabled()) {
    const resp = await requestPapi<RawGrfqQuoteV2CreateResp>(requestParams);
    if (!resp.ok) return resp;
    return { ...resp, data: processGrfqQuoteAPIV2(resp.data) };
  }

  const resp = await requestPapi<RawGrfqQuote>(requestParams);
  if (!resp.ok) return resp;
  return { ...resp, data: processGrfqQuote(resp.data) };
}

export async function listRfqQuotes(
  req: ListQuotesReq,
): AsyncResp<ListRfqQuotesResp> {
  const { rfqId, ...rest } = req;
  const resp = await requestPapi<RawListRfqQuotesResp>({
    ...rest,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/rfqs/${rfqId}/quotes`,
  });

  if (!resp.ok) return resp;

  const addRfqIdAndProcess = (quote: ListedRfqQuote) =>
    processGrfqQuote({ ...quote, rfq_id: resp.data.id });

  return {
    ...resp,
    data: {
      ...resp.data,
      asks: resp.data.asks.map(addRfqIdAndProcess),
      bids: resp.data.bids.map(addRfqIdAndProcess),
    },
  };
}

export async function listOwnQuotes(
  req: ListOwnQuotesReq,
): AsyncResp<ListOwnQuotesResp> {
  const { rfqId, side, cursor, status, pageSize, signal } = req;

  const query = getQueryString([
    ['rfq_id', rfqId],
    ['side', side],
    ['cursor', cursor],
    ['page_size', pageSize],
    ['status', status],
  ]);

  const resp = await requestPapi<RawListOwnQuotesResp>({
    method: 'GET',
    url: `${getGrfqBaseUrl()}/quotes${query}`,
    signal,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: { ...resp.data, results: resp.data.results.map(processGrfqOwnQuote) },
  };
}

export async function getLastOwnQuote(
  req: GetLastOwnQuoteReq,
): AsyncResp<GetLastOwnQuoteResp> {
  const resp = await listOwnQuotes({
    ...req,
    pageSize: 1,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: resp.data.results[0],
  };
}

export async function cancelQuote(req: CancelQuoteReq): AsyncResp<null> {
  const { quoteId, ...rest } = req;
  return requestPapi<null>({
    ...rest,
    method: 'DELETE',
    url: `${getGrfqBaseUrl()}/quotes/${quoteId}`,
  });
}

export async function cancelRfqQuotesBatch(
  req: CancelRfqQuotesBatchReq,
): AsyncResp<CancelQuotesBatchResp | CancelQuotesBatchRespV2> {
  const { side, price, rfqId, ...rest } = req;

  const query = getQueryString([
    ['side', side],
    ['price', price],
    ['rfq_id', rfqId],
  ]);

  return requestPapi<CancelQuotesBatchResp | CancelQuotesBatchRespV2>({
    ...rest,
    method: 'DELETE',
    url: `${getGrfqBaseUrl()}/quotes${query}`,
  });
}

export async function cancelQuotesBatch(
  req: CancelQuotesBatchReq,
): AsyncResp<CancelQuotesBatchResp | CancelQuotesBatchRespV2> {
  const { side, ...rest } = req;

  const query = getQueryString([['side', side]]);

  const requestParams = {
    ...rest,
    method: 'DELETE',
    url: `${getGrfqBaseUrl()}/quotes${query}`,
  } as RequestParams;

  if (isOBV1APIEnabled()) {
    return requestPapi<CancelQuotesBatchRespV2>(requestParams);
  }

  return requestPapi<CancelQuotesBatchResp>(requestParams);
}

export async function listInstruments(
  req: ListInstrumentsReq,
): AsyncResp<ListInstrumentsResp> {
  const { asset, type, venue, cursor, ...rest } = req;

  const query = getQueryString([
    ['ordering', 'default'],
    ['page_size', 1000],
    ['asset', asset],
    ['type', type],
    ['venue', venue],
    ['cursor', cursor],
  ]);

  return requestPapi<ListInstrumentsResp>({
    ...rest,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/instruments${query}`,
  });
}

export async function listTrades(
  req: ListTradesReq,
): AsyncResp<ListTradesResp> {
  const {
    cursor,
    status,
    pageSize,
    productCodes,
    strategyCodes,
    hidePublic,
    ...rest
  } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['hide_public', hidePublic],
    ['page_size', pageSize],
    ['status', status != null && status.length > 0 ? status.join(',') : null],
    ['product_codes', productCodes],
    ['strategies', strategyCodes],
  ]);

  const resp = await requestPapi<RawListTradesResp>({
    ...rest,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/trades${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processGrfqTrade),
    },
  };
}

export async function listTradeTape(
  req: ListTradeTapeReq,
): AsyncResp<ListTradeTapeResp> {
  const { cursor, status, pageSize, productCodes, strategyCodes, ...rest } =
    req;

  const query = getQueryString([
    ['cursor', cursor],
    ['page_size', pageSize],
    ['status', status != null && status.length > 0 ? status.join(',') : null],
    ['product_codes', productCodes],
    ['strategies', strategyCodes],
  ]);

  const resp = await requestPapi<RawListTradeTapeResp>({
    ...rest,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/trade_tape${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(processGrfqTradeTape),
    },
  };
}

export async function getRfq({ rfqId, ...req }: GetRfqReq): AsyncResp<Grfq> {
  const resp = await requestPapi<RawGrfq>({
    ...req,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/rfqs/${rfqId}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processGrfq(resp.data),
  };
}

export async function getBlotter({
  cursor,
  ...req
}: GetBlotterReq): AsyncResp<GetBlotterResp> {
  const query = getQueryString([['cursor', cursor]]);
  return requestPapi<GetBlotterResp>({
    ...req,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/blotter${query}`,
  });
}

export async function getTrade({
  tradeId: quoteId,
  ...req
}: GetTradeDetailsReq): AsyncResp<GetTradeDetailsResp> {
  const resp = await requestPapi<RawGrfqTradeDetails>({
    ...req,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/trades/${quoteId}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processGrfqTradeDetails(resp.data),
  };
}

export function processGrfqException(
  resp: FailedResp,
): [Breadcrumb, GrfqServiceRequestError] {
  const { data, message, status } = resp;
  const grfqRequestError = new GrfqServiceRequestError(message, status);
  return [data, grfqRequestError];
}

export function humanizeGrfqErrorMessage(resp: FailedResp): string {
  let errorMessage = resp.message.trim().replace(/\.+$/u, '');
  if (typeof resp.data.data === 'string') {
    const dataString = resp.data.data as string;
    const errorReason = dataString.trim().replace(/\.+$/u, '');
    errorMessage = `${errorMessage}. ${errorReason}.`;
  }

  return errorMessage;
}

export async function getRfqBbo({
  rfqId,
  ...req
}: GetBboReq): AsyncResp<GetBboResp> {
  const resp = await requestPapi<GetBboResp>({
    ...req,
    method: 'GET',
    url: `${getGrfqBaseUrl()}/rfqs/${rfqId}/bbo`,
  });
  return resp;
}
