import { ReactNode, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '@paradigm/store/auth';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { createPreferencesStore, PreferencesStore } from './store';

interface ProviderProps {
  readonly children: ReactNode;
}

export const [usePreferencesContext, PreferencesCtxProvider] =
  createSafeContext<PreferencesStore>('PreferencesStore');

/**
 * Provider for preferences to the rest of the application.
 *
 * @remarks This provider must be rendered within an authenticated context. In
 * most cases you can use the state hook instead of this provider when
 * interacting with preferences. @see usePreferencesStore()
 *
 * You can trigger more advanced workflows on the Persist config like
 * rehydrate which lets you resync with the server.
 *
 * @example
 * const preferencesContext = usePreferencesContext();
 * preferencesContext.persist.rehydrate();
 *
 */
export const PreferencesProvider = ({ children }: ProviderProps) => {
  const { preferences } = useSelector(getUser);
  const storeRef = useRef<PreferencesStore>();

  if (!storeRef.current) {
    storeRef.current = createPreferencesStore(preferences?.data ?? {});
  }

  return (
    <PreferencesCtxProvider value={storeRef.current}>
      {children}
    </PreferencesCtxProvider>
  );
};
