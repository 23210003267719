import styled, { css } from 'styled-components';
import { lima, pickledBluewood, razzmatazz } from '#/colors';
import { ToggleProps } from './Toggle';

type StyledProps = Pick<
  ToggleProps,
  'size' | 'fluid' | 'labelPlacement' | 'disabled'
>;

const withLargeStyles =
  (styles: ReturnType<typeof css> | string) => (props: StyledProps) =>
    props.size === 'large' && styles;

const withSmallStyles =
  (styles: ReturnType<typeof css> | string) => (props: StyledProps) =>
    props.size === 'small' && styles;

export const Slider = styled.div<StyledProps>`
  width: 40px;
  height: 20px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${razzmatazz};
  transition: 0.2s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 0;
    bottom: 0;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
  }

  ${withLargeStyles(css`
    height: 25px;
    width: 50px;

    &:before {
      height: 25px;
      width: 25px;
    }
  `)}

  ${withSmallStyles(css`
    height: 15px;
    width: 30px;

    ::before {
      height: 15px;
      width: 15px;
    }
  `)}
`;

export const Label = styled.label<StyledProps>`
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disabled === true &&
    css`
      &&& {
        opacity: 0.4;
        cursor: not-allowed;

        ${Slider} {
          background-color: ${pickledBluewood} !important;
        }
      }
    `}

  ${(props) =>
    props.labelPlacement === 'left' && 'flex-direction: row-reverse;'}

  &:focus {
    box-shadow: 0 0 1px ${lima};

    ${Slider} {
      box-shadow: 0 0 1px ${lima};
    }
  }

  ${(props) =>
    props.fluid === true &&
    css`
      width: 100%;
      justify-content: space-between;
    `}
`;

export const Text = styled.span<StyledProps>`
  ${(props) =>
    props.labelPlacement === 'right'
      ? 'padding-left: 10px;'
      : 'padding-right: 10px;'}

  ${withLargeStyles(
    css`
      font-size: 1.0625rem;
      font-weight: 300;
    `,
  )}
`;

export const Input = styled.input<{ inputSize: ToggleProps['size'] }>`
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 0;
  height: 0;

  &:checked {
    & + ${Slider} {
      background-color: ${lima};

      &:before {
        transform: translateX(20px);
      }
      ${(props) =>
        props.inputSize === 'large' &&
        css`
          &:before {
            transform: translateX(25px);
          }
        `}
      ${(props) =>
        props.inputSize === 'small' &&
        css`
          &:before {
            transform: translateX(15px);
          }
        `}
    }
  }

  &:focus {
    & + ${Slider} {
      box-shadow: 0 0 1px ${lima};
    }
  }
`;
