import { MouseEventHandler, TouchEventHandler, useMemo, useState } from 'react';
import {
  GroupBase,
  ControlProps,
  components,
  ValueContainerProps,
  OptionProps,
  MultiValueRemoveProps,
  MultiValueProps,
  MenuListProps,
} from 'react-select';

import SearchIcon from '#/assets/SearchIcon';
import {
  ContainerProps,
  IndicatorProps,
  SelectProps,
  OptionTypeBase,
} from './types';
import { ClearButton } from './styles';

export function Control<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: ControlProps<OptionType, IsMulti, GroupType>) {
  if (props.isMulti) {
    const { children, ...otherProps } = props;
    return (
      <components.Control {...otherProps}>
        <SearchIcon /> {children}
      </components.Control>
    );
  }
  const innerProps = {
    ...props.innerProps,
    className: 'select-control',
    'data-testid': 'select-control',
  };
  return <components.Control {...props} innerProps={innerProps} />;
}

export function MultiValueRemove<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: MultiValueRemoveProps<OptionType, IsMulti, GroupType> & {
    className?: string;
  },
) {
  return (
    <components.MultiValueRemove
      {...props}
      data-testid="select-multi-value-remove"
    />
  );
}

export function MultiValue<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: MultiValueProps<OptionType, IsMulti, GroupType>) {
  return <components.MultiValue {...props} data-testid="select-multi-value" />;
}

export function ValueContainer<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: ValueContainerProps<OptionType, IsMulti, GroupType>) {
  return (
    <components.ValueContainer
      {...props}
      className="select-value-container"
      data-testid="select-value-container"
    />
  );
}

export function SelectContainer<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: ContainerProps<OptionType, IsMulti, GroupType>) {
  const [shouldStopEscKeyUp, setShouldStopEscKeyUp] = useState(false);

  const innerProps = useMemo(() => {
    const className =
      props.selectProps.variant === 'transparent'
        ? 'transparent-select-container'
        : 'select-container';

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (props.selectProps.menuIsOpen && event.key === 'Escape') {
        setShouldStopEscKeyUp(true);
      }
      if (props.innerProps.onKeyDown) {
        props.innerProps.onKeyDown(event);
      }
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (shouldStopEscKeyUp && event.key === 'Escape') {
        event.stopPropagation();
        setShouldStopEscKeyUp(false);
      }
    };

    return {
      ...props.innerProps,
      onKeyDown,
      onKeyUp,
      className,
      'data-testid': 'select-container',
    } as const;
  }, [props.selectProps, props.innerProps, shouldStopEscKeyUp]);

  return <components.SelectContainer {...props} innerProps={innerProps} />;
}

export function Option<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: OptionProps<OptionType, IsMulti, GroupType>) {
  const innerProps = {
    ...props.innerProps,
    className: 'select-option',
    'data-testid': 'select-option',
  };

  return <components.Option {...props} innerProps={innerProps} />;
}

export function DropdownIndicator<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: IndicatorProps<OptionType, IsMulti, GroupType>) {
  if (props.selectProps.variant === 'transparent') {
    return <TransparentDropdownIndicator {...props} />;
  }
  return (
    <components.DropdownIndicator {...props}>
      <>
        {props.selectProps.showDefaultLabel === true && (
          <p
            className="pt-1"
            style={{
              fontSize: 12,
              color: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            Default
          </p>
        )}
        <i className="material-icons white-icon">arrow_drop_down</i>
      </>
    </components.DropdownIndicator>
  );
}

const TransparentDropdownIndicator = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType>,
>(
  props: IndicatorProps<OptionType, IsMulti, GroupType>,
) => {
  if (props.selectProps.noOpacity === true && props.isDisabled) return null;
  return (
    <components.DropdownIndicator
      {...props}
      className="select-dropdown-indicator"
    >
      <i className="material-icons white-icon" style={{ fontSize: '1rem' }}>
        arrow_drop_down
      </i>
    </components.DropdownIndicator>
  );
};

const MenuList = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType>,
>(
  props: MenuListProps<OptionType, IsMulti, GroupType>,
) => <components.MenuList {...props} className="select-menu-list" />;

function ClearIndicator(props: ClearIndicatorProps) {
  const { innerProps, isMulti } = props;
  if (!isMulti) return null;
  return (
    <ClearButton {...innerProps} ariaLabel="clear all multi-select">
      Clear All
    </ClearButton>
  );
}

type ClearIndicatorProps = Omit<
  IndicatorProps<OptionTypeBase, true, GroupBase<OptionTypeBase>>,
  'innerProps'
> & {
  innerProps: {
    onMouseDown: MouseEventHandler<HTMLButtonElement>;
    onTouchEnd: TouchEventHandler<HTMLButtonElement>;
  };
};

export const defaultComponents = {
  IndicatorSeparator: () => null,
  DropdownIndicator,
  SelectContainer,
  Option,
  ValueContainer,
  Control,
  MultiValue,
  MultiValueRemove,
  ClearIndicator,
};

export const transparentComponents = {
  IndicatorSeparator: () => null,
  DropdownIndicator,
  SelectContainer,
  Option,
  ValueContainer,
  MenuList,
};

export function makeComponents<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false,
>(props: SelectProps<OptionType, IsMulti>) {
  const variantComponents =
    props.variant === 'transparent' ? transparentComponents : defaultComponents;

  return { ...variantComponents, ...props.components };
}
