import { ReactNode, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  blackPearl,
  blackPearl_2,
  blueMana,
  firefly_2,
} from '@paradigm/design-system/src/colors';
import TabGroup from '@paradigm/design-system/src/components/TabGroup';
import Text from '@paradigm/design-system/src/components/Text';
import Input from '@paradigm/design-system/src/components/Input';
import SearchIcon from '@paradigm/design-system/src/assets/SearchIcon';
import iconWarning from '@paradigm/design-system/src/assets/icon-warning-yellow.svg';
import Select, {
  SelectOption,
  makeDefaultStyles,
  Props as SelectProps,
} from '@paradigm/design-system/src/components/Select';
import {
  useDefaultAccountsState,
  useDefaultAccountsActions,
} from '@paradigm/features/src/preferences/default-accounts/context';
import { Maybe } from '@paradigm/utils/types';
import {
  TAB_VENUES,
  NO_DEFAULT_SELECT_OPTION,
  isEmptyAccount,
} from '@paradigm/features/src/preferences/default-accounts/types';

const getOptionValue = (value: Maybe<string>) =>
  !isEmptyAccount(value) ? { value, label: value } : null;

const getPlaceholder = (value: Maybe<string>) =>
  !isEmptyAccount(value) ? value : NO_DEFAULT_SELECT_OPTION.label;

const shouldShowAllProducts = (searchValue: string) =>
  'all products'.includes(searchValue.toLowerCase()) ||
  'primary'.includes(searchValue.toLowerCase());

function DefaultAccounts() {
  const {
    activeVenue,
    searchValue,
    venueAccountsOptions,
    venueProducts,
    defaultAccounts,
  } = useDefaultAccountsState();
  const {
    setVenue,
    setSearchValue,
    setAllProductsDefault,
    venueHasEmptyProduct,
    setProductDefault,
    getDefaultAccount,
  } = useDefaultAccountsActions();

  const tabs = useMemo(() => {
    return TAB_VENUES.map((tab) => ({
      id: tab.id,
      label: (
        <TabLabel>
          {tab.label}
          {venueHasEmptyProduct(tab.id) && <WarningIcon />}
        </TabLabel>
      ),
    }));
  }, [venueHasEmptyProduct]);

  const accountOptions = useMemo(
    () => [NO_DEFAULT_SELECT_OPTION, ...venueAccountsOptions],
    [venueAccountsOptions],
  );

  const isAccountInvalid = useCallback(
    (productCode?: Maybe<string>) => {
      return getDefaultAccount(activeVenue, productCode) == null;
    },
    [activeVenue, getDefaultAccount],
  );

  return (
    <Container>
      <TabGroup
        css={`
          background: ${firefly_2};
        `}
        activeTabId={activeVenue}
        onSetTab={setVenue}
        tabs={tabs}
      />
      <VenuesContainer>
        <SearchInput
          inputType="text"
          value={searchValue}
          onChange={setSearchValue}
        />
        <ScrollContainer>
          <Grid>
            {shouldShowAllProducts(searchValue) && (
              <LabeledSelect
                isInvalid={isAccountInvalid()}
                aria-label="default-accounts-product-all"
                label={
                  <div css="display: flex; columm-gap: 10px; place-items: center;">
                    <Text>All Products</Text> <PrimaryBadge />
                  </div>
                }
                options={accountOptions}
                placeholder={NO_DEFAULT_SELECT_OPTION.label}
                value={getOptionValue(defaultAccounts[activeVenue].all)}
                onChange={(option) => {
                  if (option == null) return;
                  setAllProductsDefault(activeVenue, option.value);
                }}
              />
            )}
            {venueProducts.map(({ productCode, label }) => (
              <LabeledSelect
                aria-label={`default-accounts-product-${productCode}`}
                isInvalid={isAccountInvalid(productCode)}
                key={productCode}
                label={label}
                placeholder={getPlaceholder(defaultAccounts[activeVenue].all)}
                options={accountOptions}
                value={getOptionValue(
                  defaultAccounts[activeVenue][productCode],
                )}
                onChange={(option) => {
                  if (option == null) return;
                  setProductDefault(activeVenue, productCode, option.value);
                }}
              />
            ))}
          </Grid>
        </ScrollContainer>

        {venueHasEmptyProduct(activeVenue) && (
          <OneClickWarning>
            <WarningIcon />
            <Text size="small" weight="bold">
              No Default Account
            </Text>
            <Text size="small" css="opacity: 0.7;">
              1-Click Trading has been disabled
            </Text>
          </OneClickWarning>
        )}
      </VenuesContainer>
    </Container>
  );
}

const LabeledSelect = ({
  label,
  isInvalid,
  ...props
}: SelectProps<SelectOption, false> & {
  label: ReactNode;
  isInvalid: boolean;
}) => {
  return (
    <>
      <Text css="position: relative;" as="div">
        {isInvalid && <SelectWarningIcon />}
        {label}
      </Text>
      <Select
        {...props}
        isMulti={false}
        styles={{
          ...makeDefaultStyles<SelectOption, false>(),
          placeholder: ({ ...placeholderProps }) => ({
            ...placeholderProps,
            color: 'white',
            opacity: 0.5,
          }),
        }}
      />
    </>
  );
};

const PrimaryBadge = styled(Text).attrs({
  as: 'span',
  children: 'Primary',
  size: 'small',
})`
  width: 56px;
  height: 18px;
  border: 1px solid ${blueMana};
  display: flex;
  place-items: center;
  justify-content: center;
  opacity: 0.7;
  margin-left: 10px;
  border-radius: 3px;
  /* we have a global style that sets font-size to 14px in .settings-modal */
  &&& {
    font-size: 12px;
  }
`;

const OneClickWarning = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 6px;
  padding-left: 12px;
  background-color: black;
  height: 32px;
  margin: 24px 32px 0 10px;
  border-radius: 15px;
`;

const WarningIcon = styled.img.attrs({
  src: iconWarning,
  width: 16,
  alt: 'warning',
})``;

const SelectWarningIcon = styled(WarningIcon)`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
`;

const VenuesContainer = styled.div`
  padding-bottom: 20px;
  background-color: ${blackPearl_2};
`;

const Container = styled.div`
  margin: 0 -2rem;
  margin-bottom: -2rem;
`;

const SearchInput = styled(Input).attrs({
  placeholder: 'Search',
  inputPrefix: <SearchIcon />,
  wrapperCss: css`
    background-color: ${blackPearl};
  `,
})`
  padding: 24px 32px;
  padding-bottom: 20px 32px;
`;

const ScrollContainer = styled.div`
  padding: 20px 32px;
  padding-top: 0;
  max-height: 500px;
  overflow-y: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
  gap: 16px;
`;

const TabLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`;

export default DefaultAccounts;
