import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { getUser } from '@paradigm/store/auth';

export default function useUserMetadata() {
  const { id, email, first_name, last_name, trading_desk, paradex_token } =
    useSelector(getUser);

  const desk = trading_desk != null ? ` [${trading_desk.ticker}]` : '';
  const name = `${first_name} ${last_name}${desk}`;

  return useMemo(
    () => ({
      id: String(id),
      email,
      name,
      paradexToken: paradex_token,
    }),
    [email, id, name, paradex_token],
  );
}
