import { AsyncResp, BaseReq, requestFs } from './fetch-api';
import { getQueryString } from './internal/utils';
import {
  processOrder,
  processStrategy,
  processTapeTrade,
  processTrade,
  processFsPlatformState,
  processOrderBook,
} from './process/future-spreads';

import type {
  RawFsStrategy,
  FsStrategy,
  RawFsOrderBook,
  RawFsOrder,
  FsOrderSide,
  FsOrderType,
  FsOrderTimeInForce,
  FsOrderState,
  FsOrder,
  RawFsTapeTrade,
  FsTapeTrade,
  RawFsTrade,
  FsTrade,
  RawPlatformState,
  PlatformState,
  FsOrderBook,
  RawFsInstrument,
} from './types/future-spreads';

export interface ListStrategiesReq extends BaseReq {
  readonly venue: string;
  readonly baseCurrency: string;
  readonly marginType: string;
}

export interface RawListStrategiesResp {
  readonly results: readonly RawFsStrategy[];
}

export interface ListStrategiesResp {
  readonly results: readonly FsStrategy[];
}

export async function listStrategies(
  req: ListStrategiesReq,
): AsyncResp<ListStrategiesResp> {
  const { venue, baseCurrency, marginType, ...rest } = req;
  const query = getQueryString([
    ['venue', venue],
    ['base_currency', baseCurrency],
    ['margin_type', marginType],
    ['state', 'ACTIVE'],
    ['page_size', 100],
  ]);

  const resp = await requestFs<RawListStrategiesResp>({
    ...rest,
    method: 'GET',
    url: `/v1/fs/strategies${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map((item) => processStrategy(item)),
    },
  };
}

interface GetStrategyReq extends BaseReq {
  readonly id: string;
}

export async function getStrategy(req: GetStrategyReq): AsyncResp<FsStrategy> {
  const { id, ...rest } = req;
  const resp = await requestFs<RawFsStrategy>({
    ...rest,
    method: 'GET',
    url: `/v1/fs/strategies/${id}`,
  });

  if (!resp.ok) return resp;

  return { ...resp, data: processStrategy(resp.data) };
}

export interface GetOrderBookReq extends BaseReq {
  readonly strategyId: string;
  readonly depth?: 'ALL' | 1 | 5 | 25;
  readonly level?: 'L2' | 'L3';
}

export async function getOrderBook(
  req: GetOrderBookReq,
): AsyncResp<FsOrderBook> {
  const { strategyId, depth, level, ...rest } = req;
  const query = getQueryString([
    ['depth', depth],
    ['level', level],
  ]);

  const resp = await requestFs<RawFsOrderBook>({
    ...rest,
    method: 'GET',
    url: `/v1/fs/strategies/${strategyId}/order-book${query}`,
  });

  if (!resp.ok) return resp;

  return { ...resp, data: processOrderBook(resp.data) };
}

interface CreateOrderIntReq extends BaseReq {
  readonly strategy_id: string;
  readonly side: FsOrderSide;
  readonly type: FsOrderType;
  /** Price in quote currency */
  readonly price?: string | null;
  /** Amount in clearing currency */
  readonly amount: number;
  readonly account_name: string;
  readonly time_in_force?: FsOrderTimeInForce;
  readonly expires_in?: string;
  readonly label?: string;
  readonly post_only?: boolean;
}

interface CreateOrderDecimalReq extends Omit<CreateOrderIntReq, 'amount'> {
  readonly amount_decimal: string;
}

export type CreateOrderReq = CreateOrderIntReq | CreateOrderDecimalReq;

export type CreateOrderResp = Pick<
  RawFsOrder,
  | 'id'
  | 'strategy_id'
  | 'side'
  | 'type'
  | 'state'
  | 'price'
  | 'amount'
  | 'time_in_force'
  | 'label'
  | 'account_name'
  | 'created_at'
  | 'expires_at'
>;

export async function createOrder(
  req: CreateOrderReq,
): AsyncResp<CreateOrderResp> {
  const { signal, ...body } = req;

  return requestFs<CreateOrderResp>({
    signal,
    method: 'POST',
    url: `/v1/fs/orders`,
    body,
  });
}

interface ReplaceOrderIntReq extends BaseReq {
  readonly orderId: string;
  readonly type: FsOrderType;
  /** Price in quote currency */
  readonly price?: string | null;
  /** Amount in clearing currency */
  readonly amount: number;
  readonly account_name: string;
  readonly time_in_force?: FsOrderTimeInForce;
  readonly expires_in?: string;
  readonly label?: string;
  readonly post_only: boolean;
}

interface ReplaceOrderDecimalReq extends Omit<ReplaceOrderIntReq, 'amount'> {
  readonly amount_decimal: string;
}

export type ReplaceOrderReq = ReplaceOrderIntReq | ReplaceOrderDecimalReq;

export type ReplaceOrderResp = Pick<
  RawFsOrder,
  | 'id'
  | 'side'
  | 'type'
  | 'state'
  | 'price'
  | 'amount'
  | 'time_in_force'
  | 'label'
  | 'account_name'
  | 'created_at'
  | 'expires_at'
>;

export async function replaceOrder(
  req: ReplaceOrderReq,
): AsyncResp<ReplaceOrderResp> {
  const { orderId, signal, ...body } = req;

  return requestFs<ReplaceOrderResp>({
    signal,
    method: 'POST',
    url: `/v1/fs/orders/${orderId}/replace`,
    body,
  });
}

interface KillOrderReq extends BaseReq {
  readonly id: string;
}

export async function killOrder(req: KillOrderReq): AsyncResp<undefined> {
  const { id, ...rest } = req;
  return requestFs<undefined>({
    ...rest,
    method: 'DELETE',
    url: `/v1/fs/orders/${id}`,
  });
}

interface KillOrdersReq extends BaseReq {
  readonly side?: FsOrderSide;
  readonly label?: string;
}

interface KillOrdersResp {
  readonly successes: readonly {
    readonly count: number;
    /** List of order ids canceled with success */
    readonly details: readonly string[];
  }[];
  readonly failures: readonly {
    readonly count: number;
    /** List of order ids that failed to cancel */
    readonly details: readonly string[];
  }[];
}

export async function killOrders(
  req: KillOrdersReq,
): AsyncResp<KillOrdersResp> {
  const { side, label, ...rest } = req;

  const query = getQueryString([
    ['side', side],
    ['label', label],
  ]);

  return requestFs<KillOrdersResp>({
    ...rest,
    method: 'DELETE',
    url: `/v1/fs/orders${query}`,
  });
}

export interface ListOrdersReq extends BaseReq {
  readonly cursor?: string | null;
  readonly venue?: string;
  readonly kind?: 'ANY' | 'FUTURE';
  readonly type?: FsOrderType;
  readonly settlementCurrency?: string;
  readonly strategyId?: string;
  readonly state?: FsOrderState;
  readonly pageSize?: number;
}

export interface RawListOrdersResp {
  /** Cursor for previous page */
  readonly prev: string | null;
  /** Cursor for next page */
  readonly next: string | null;
  readonly results: readonly RawFsOrder[];
}

export interface ListOrdersResp extends Omit<RawListOrdersResp, 'results'> {
  readonly results: readonly FsOrder[];
}

export async function listOrders(
  req: ListOrdersReq,
): AsyncResp<ListOrdersResp> {
  const {
    cursor,
    venue,
    kind,
    type,
    settlementCurrency,
    strategyId,
    state,
    pageSize,
    ...rest
  } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['venue', venue],
    ['kind', kind],
    ['type', type],
    ['settlement_currency', settlementCurrency],
    ['strategy_id', strategyId],
    ['state', state],
    ['page_size', pageSize],
  ]);

  const resp = await requestFs<RawListOrdersResp>({
    ...rest,
    method: 'GET',
    url: `/v1/fs/orders${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map((rawOrder) => processOrder(rawOrder)),
    },
  };
}

export interface TradeTapeReq extends BaseReq {
  readonly cursor?: string | null;
  readonly venue?: string;
  readonly kind?: 'ANY' | 'FUTURE';
  readonly settlementCurrency?: string;
  readonly strategyId?: string;
  readonly pageSize?: number;
}

export interface RawTradeTapeResp {
  /** Cursor for previous page */
  readonly prev: string | null;
  /** Cursor for next page */
  readonly next: string | null;
  readonly results: readonly RawFsTapeTrade[];
}

export interface TradeTapeResp extends Omit<RawTradeTapeResp, 'results'> {
  readonly results: readonly FsTapeTrade[];
}

export async function getTradeTape(
  req: TradeTapeReq,
): AsyncResp<TradeTapeResp> {
  const {
    cursor,
    venue,
    kind,
    settlementCurrency,
    strategyId,
    pageSize,
    ...rest
  } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['venue', venue],
    ['kind', kind],
    ['settlement_currency', settlementCurrency],
    ['strategy_id', strategyId],
    ['page_size', pageSize],
  ]);

  const resp = await requestFs<RawTradeTapeResp>({
    ...rest,
    method: 'GET',
    url: `/v1/fs/trade_tape${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map((item) => processTapeTrade(item)),
    },
  };
}

export interface GetTradesReq extends BaseReq {
  readonly cursor?: string | null;
  readonly state?: 'FILLED' | 'REJECTED';
  readonly venue?: string;
  readonly kind?: 'ANY' | 'FUTURE';
  readonly settlementCurrency?: string;
  readonly role?: 'MAKER' | 'TAKER';
  readonly strategyId?: string;
  readonly pageSize?: number;
}

export interface RawGetTradesResp {
  /** Cursor for previous page */
  readonly prev: string | null;
  /** Cursor for next page */
  readonly next: string | null;
  readonly results: readonly RawFsTrade[];
}

export interface GetTradesResp extends Omit<RawGetTradesResp, 'results'> {
  readonly results: readonly FsTrade[];
}

export async function getTrades(req: GetTradesReq): AsyncResp<GetTradesResp> {
  const {
    cursor,
    state,
    venue,
    kind,
    settlementCurrency,
    strategyId,
    role,
    pageSize,
    ...rest
  } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['state', state],
    ['venue', venue],
    ['kind', kind],
    ['settlement_currency', settlementCurrency],
    ['role', role],
    ['strategy_id', strategyId],
    ['page_size', pageSize],
  ]);

  const resp = await requestFs<RawGetTradesResp>({
    ...rest,
    method: 'GET',
    url: `/v1/fs/trades${query}`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map((item) => processTrade(item)),
    },
  };
}

export async function getPlatformState(): AsyncResp<PlatformState> {
  const resp = await requestFs<RawPlatformState>({
    method: 'GET',
    url: `/v1/fs/platform_state`,
  });

  if (!resp.ok) return resp;

  return { ...resp, data: processFsPlatformState(resp.data) };
}

export interface ListInstrumentsReq extends BaseReq {
  readonly cursor?: string | null;
  readonly state?: 'ACTIVE' | 'SETTLED' | 'EXPIRED';
  readonly venue?: string;
  readonly kind?: 'ANY' | 'FUTURE';
  readonly settlementCurrency?: string;
  readonly clearingCurrency?: string;
  readonly pageSize?: number;
}

export interface RawListInstrumentsResp {
  /** Cursor for previous page */
  readonly prev: string | null;
  /** Cursor for next page */
  readonly next: string | null;
  readonly results: RawFsInstrument[];
}

export async function listInstruments(
  req: ListInstrumentsReq,
): AsyncResp<RawListInstrumentsResp> {
  const {
    cursor,
    venue,
    kind,
    settlementCurrency,
    clearingCurrency,
    state = 'ACTIVE',
    pageSize = 100,
  } = req;

  const query = getQueryString([
    ['cursor', cursor],
    ['state', state],
    ['venue', venue],
    ['kind', kind],
    ['settlement_currency', settlementCurrency],
    ['clearing_currency', clearingCurrency],
    ['page_size', pageSize],
  ]);

  return requestFs<RawListInstrumentsResp>({
    method: 'GET',
    url: `/v1/fs/instruments${query}`,
  });
}
