import { uniqBy, capitalize } from 'lodash';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useProductsStore } from '#/products/store';
import { useApi } from '@paradigm/api/src';
import {
  PaginatedResp,
  fetchAll,
  AsyncResp,
} from '@paradigm/api/src/fetch-api';
import { RawFsInstrument } from '@paradigm/api/types';
import {
  ListInstrumentsReq,
  listInstruments,
} from '@paradigm/api/src/future-spreads';
import { ProductWithSpot, StandardProductWithSpot } from '#/products/types';
import { Source } from '#/unified-rfqs/entities/products/venue';
import { selectIsAuthenticated } from '@paradigm/store/auth';

type ListPaginatedInstruments = (
  req: ListInstrumentsReq,
) => AsyncResp<PaginatedResp<RawFsInstrument>>;

export default function useProductsLoader() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [initialized, setInitialized] = useState(false);
  const store = useProductsStore();
  const [_, fetchFspdInstruments] = useApi(async (req) =>
    fetchAll(req, listInstruments as ListPaginatedInstruments),
  );
  useEffect(() => {
    if (initialized || !isAuthenticated || store.isLoading) return;
    store.setIsLoading(true);
    fetchFspdInstruments({}, (resp) => {
      if (resp.ok) {
        store.addProducts(instrumentsToProducts(resp.data.results));
      }
      store.setIsLoading(false);
      setInitialized(true);
    });
  }, [
    isAuthenticated,
    store,
    fetchFspdInstruments,
    initialized,
    setInitialized,
  ]);
}

function instrumentsToProducts(
  instruments: readonly RawFsInstrument[],
): ProductWithSpot[] {
  const uniqueInstrumentsByProduct = uniqBy(instruments, 'product_code');

  return uniqueInstrumentsByProduct.map(toProduct);
}

function toProduct(instrument: RawFsInstrument): StandardProductWithSpot {
  const { product_code, base_currency, margin_type, kind, venue } = instrument;
  const longName = `${product_code} - ${base_currency} ${capitalize(
    kind,
  )} - ${capitalize(margin_type)} - ${venue}`;
  const shortName = `${base_currency} ${capitalize(kind)} - ${capitalize(
    margin_type,
  )} - ${venue}`;

  return {
    code: product_code,
    venue: venue as Source,
    longName,
    shortName,
    kind,
    quantityPrecision: BigNumber(instrument.min_order_size).dp()!,
    pricePrecision: BigNumber(instrument.min_tick_size).dp()!,
    ratioPrecision: 0,
    minContractIncrement: instrument.min_order_size,
    strategies: ['FSpd'],
    contractSize: instrument.contract_size,
    contractType: instrument.margin_type,
    minBlockSize: instrument.min_block_size,
    contractCurrency: instrument.quote_currency,
    currency: base_currency,
    marginType: instrument.margin_type,
    quoteCurrency: instrument.quote_currency,
  };
}
