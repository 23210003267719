import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  featureFlagsMigrator,
  saveWhitelistedFlags,
  FLAGS_TO_PERSIST,
} from '@paradigm/features/src/feature-flags/context';
import { useStoredEntry } from '#/hooks/useStoredEntry';
import { FeatureFlags } from '@paradigm/features/src/feature-flags/adapters';
import useFeatureFlagLoader from '@paradigm/features/src/feature-flags/loader';
import { selectIsAuthenticated } from '@paradigm/store/auth';
import useDebugFeatureFlags from './useDebugFeatureFlags';

interface Props {
  readonly children: JSX.Element;
}

const FeatureFlagWithStoredEntryLoader = ({ children }: Props) => {
  const overrideFlags = useDebugFeatureFlags();
  /**
   * Note: There are no current usage of the feature flags stored entries as
   * shown in FLAGS_TO_PERSIST. We could deprecate this path in the future.
   */
  const flagsInitialState = useStoredEntry(
    'feature-flags',
    featureFlagsMigrator,
  );
  const flagsStored = useMemo(
    () => (flagsInitialState.get() as FeatureFlags | null) ?? undefined,
    [flagsInitialState],
  );

  useFeatureFlagLoader(flagsStored, overrideFlags, (state) =>
    saveWhitelistedFlags(FLAGS_TO_PERSIST, state, flagsInitialState),
  );
  return children;
};

export default function FeatureFlagLoader({ children }: Props) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  if (!isAuthenticated) return children;
  return (
    <FeatureFlagWithStoredEntryLoader>
      {children}
    </FeatureFlagWithStoredEntryLoader>
  );
}
