import createPapiWebSocket from '@paradigm/api/src/internal/createPapiWebSocket';
import {
  ApiWebSocket,
  WSNotification,
} from '@paradigm/api/src/internal/createWebSocket';
import { availableToken } from '@paradigm/api/src/internal/token';
import {
  MmpNotificationData,
  MMP_CHANNEL_TOPIC_ID,
} from '@paradigm/api/src/types/mmp';
import {
  Grfq,
  GrfqQuote,
  GrfqTrade,
  GrfqTradeTape,
  OrderSummary,
} from '#/unified-rfqs/entities/grfq/domain';
import { processNotification } from '#/unified-rfqs/repositories/adapters/grfq-ws';
import {
  RawGrfq,
  RawGrfqQuote,
  RawGrfqTrade,
  RawGrfqTradeTape,
  RawOrderSummary,
  RawQuoteStatus,
} from '#/unified-rfqs/repositories/grfq-types';
import { isOBV1APIEnabled } from '#/feature-flags/utils';
import { getGrfqBaseUrl } from './grfq-api';

export const getGrfqChannels = () => [
  'quote',
  'quote_book',
  ...(isOBV1APIEnabled() ? ['quote_status'] : []),
  'rfq',
  'trade',
  'trade_tape',
  'order',
];

export const GRFQ_TOPIC_NAME = 'grfq-ws-subscribed';

export function createGrfqWebSocket(): GrfqWebSocket {
  return createPapiWebSocket({
    urlProvider,
    processNotification,
  });
}

async function urlProvider() {
  const token = await availableToken();
  return (
    `${PAPI_URL_WS}${getGrfqBaseUrl()}/` +
    '?cancel-on-disconnect=0' +
    `&token=${encodeURIComponent(token)}`
  );
}

export type GrfqWebSocket = ApiWebSocket<GrfqNotification>;

export type GrfqWSChannel =
  | 'rfq'
  | 'quote'
  | 'quote_book'
  | 'quote_status'
  | 'trade'
  | 'trade_tape'
  | 'order'
  | typeof MMP_CHANNEL_TOPIC_ID;

type RfqKind = 'ADDED' | 'REMOVED' | 'UPDATED';

type QuoteKind =
  | 'NEW'
  | 'CANCELED'
  | 'PARTIALLY_CANCELED'
  | 'PENDING_FILL'
  | 'PARTIALLY_FILLED'
  | 'FILLED';

type QuoteBookKind = 'ADDED' | 'REMOVED' | 'UPDATED';

type TradeTapeKind = 'COMPLETED';

type TradeKind = 'CONFIRMATION' | 'REJECTION';

type OrderKind = 'PENDING_FILL' | 'SUMMARY';

type QuoteStatusKind = 'CREATE' | 'REPLACE' | 'CANCEL' | 'BATCH_CANCEL';

/**
 * Each channel's raw notification data.
 */
export interface RawNotificationDataByChannel {
  readonly rfq: {
    readonly kind: RfqKind;
    readonly rfq: RawGrfq;
  };
  readonly quote: {
    readonly kind: QuoteKind;
    readonly quote: RawGrfqQuote;
  };
  readonly quote_book: {
    readonly kind: QuoteBookKind;
    readonly quote: RawGrfqQuote;
  };
  readonly quote_status: QuoteStatus;
  readonly trade: {
    readonly kind: TradeKind;
    readonly trade: RawGrfqTrade;
  };
  readonly trade_tape: {
    readonly kind: TradeTapeKind;
    readonly trade: RawGrfqTradeTape;
  };
  readonly order: {
    readonly kind: OrderKind;
    readonly order: RawOrderSummary;
  };
  readonly market_maker_protection: MmpNotificationData;
}

/**
 * Each channel's notification data.
 */
export interface NotificationDataByChannel {
  readonly rfq: {
    readonly kind: RfqKind;
    readonly rfq: Grfq;
  };
  readonly quote: {
    readonly kind: QuoteKind;
    readonly quote: GrfqQuote;
  };
  readonly quote_book: {
    readonly kind: QuoteBookKind;
    readonly quote: GrfqQuote;
  };
  readonly quote_status: QuoteStatus;
  readonly trade: {
    readonly kind: TradeKind;
    readonly trade: GrfqTrade;
  };
  readonly trade_tape: {
    readonly kind: TradeTapeKind;
    readonly trade: GrfqTradeTape;
  };
  readonly order: {
    readonly kind: OrderKind;
    readonly order: OrderSummary;
  };
  readonly market_maker_protection: {
    readonly kind: 'TRIGGERED';
  };
}

export type RawGrfqNotificationByChannel = {
  [Channel in GrfqWSChannel]: {
    readonly channel: Channel;
    readonly data: RawNotificationDataByChannel[Channel];
    readonly venue_error?: {
      readonly message: string;
    };
  };
};

export type NotificationErrorMap = {
  readonly venue_error: string | undefined;
};

export type GrfqNotificationByChannel = {
  [Channel in GrfqWSChannel]: {
    readonly channel: Channel;
    readonly data: NotificationDataByChannel[Channel];
    readonly errors: NotificationErrorMap;
  };
};

export type RawGrfqNotification = RawGrfqNotificationByChannel[GrfqWSChannel];
export type GrfqNotification = GrfqNotificationByChannel[GrfqWSChannel];

export type DataProcessor = (
  data: RawNotificationDataByChannel[GrfqWSChannel],
) => NotificationDataByChannel[GrfqWSChannel];

export type DataProcessorsByChannel = {
  readonly [Channel in GrfqWSChannel]: (
    raw: RawNotificationDataByChannel[Channel],
  ) => NotificationDataByChannel[Channel];
};

export interface RawGrfqNotificationResp extends WSNotification {
  readonly params: RawGrfqNotification;
}

interface QuoteStatus extends RawQuoteStatus {
  readonly kind: QuoteStatusKind;
}
