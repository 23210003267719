import styled from 'styled-components';
import { portage20 } from '@paradigm/design-system/src/colors';

const PagePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${portage20};
  flex: 1;
`;

export default PagePlaceholder;
