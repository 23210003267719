/* eslint-disable react/forbid-prop-types */
import * as PropTypes from 'prop-types';
import cn from 'classnames';
import PhoneInput from 'react-phone-number-input';
import { Wrapper } from './helpers';

const FeedbackInvalid = ({ feedback }) =>
  Array.isArray(feedback) ? (
    <div className="feedback-invalid">
      {feedback.map((label) => (
        <div key={label}>{label}</div>
      ))}
    </div>
  ) : (
    <div className="feedback-invalid">{feedback}</div>
  );

FeedbackInvalid.propTypes = {
  feedback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

/**
 * Renderer for Field component in redux-form for a straight forward field.
 * @param {string} containerClassName - className for field's outer component
 * @param {string} hint - Help text for field
 * @param {object} input - input level attributes
 * @param {object} inputProps - inputProps level attributes
 * @param {string} label - Label's text
 * @param {string} labelClassName - Label's className
 * @param {jsx} children - JSX passed down from the parent component
 * @param {string} type - Input's type
 * @param {boolean} touched - Boolean if input has been interacted with
 * @param {boolean} displayError - addition error condition
 * @param {string} error - Error message from redux-form-validations
 * @return {JSX} Field component for redux-form
 */
export const renderInputField = ({
  containerClassName,
  hint,
  input,
  label,
  labelClassName,
  displayError = true,
  children,
  type = 'text',
  meta: { touched, error } = {},
  ...inputProps
}) => {
  const allInputProps = { ...input, ...inputProps };
  const feedback = touched && displayError && error;
  if (!feedback) {
    return (
      <Wrapper className={containerClassName}>
        {label && (
          <label htmlFor={input.name} className={labelClassName}>
            {label}
          </label>
        )}
        <input type={type} {...allInputProps} id={input.name} />
        {children}
        {hint && <small className="text-muted">{hint}</small>}
      </Wrapper>
    );
  }
  /**
   * If there is feedback aka any type of error, concat the inputInvalid
   * css class to the input's className.
   */
  allInputProps.className = cn(allInputProps.className, 'input-invalid');
  return (
    <Wrapper className={containerClassName}>
      {label && (
        <label htmlFor={input.name} className={labelClassName}>
          {label}
        </label>
      )}
      <input type={type} {...allInputProps} id={input.name} />
      {children}
      <FeedbackInvalid feedback={feedback} />
      {hint && <small className="text-muted">{hint}</small>}
    </Wrapper>
  );
};

renderInputField.propTypes = {
  containerClassName: PropTypes.string,
  hint: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.any,
  displayError: PropTypes.bool,
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.any,
};

/**
 * Renderer for Field component with an icon attached to the field
 * in redux-form, while keeping same functionalities.
 * @param {string} containerClassName - className for field's outer component
 * @param {string} hint - Help text for field
 * @param {object} input - input level attributes
 * @param {string} label - Label's text
 * @param {string} labelClassName - Label's className
 * @param {jsx} children - JSX passed down from the parent component
 * @param {string} type - Input's type
 * @param {boolean} touched - Boolean if input has been interacted with
 * @param {string} error - Error message from redux-form-validations
 * @return {JSX} Field component for redux-form
 */
export const renderComplexInputField = ({
  containerClassName,
  hint,
  input,
  label,
  labelClassName,
  innerContainerClassName,
  type,
  meta: { touched, error } = {},
  children,
  ...inputProps
}) => {
  const allInputProps = { ...input, ...inputProps };
  const feedback = touched && error;
  if (!feedback) {
    return (
      <Wrapper className={containerClassName}>
        {label ? <label className={labelClassName}>{label}</label> : ''}
        <div className={innerContainerClassName}>
          <input type={type || 'text'} {...allInputProps} />
          {children}
        </div>
        {hint ? <small className="text-muted">{hint}</small> : ''}
      </Wrapper>
    );
  }
  allInputProps.className = cn(allInputProps.className, 'input-invalid');
  return (
    <Wrapper className={containerClassName}>
      {label ? <label className={labelClassName}>{label}</label> : ''}
      <div className={innerContainerClassName}>
        <input type={type || 'text'} {...allInputProps} />
        {children}
      </div>
      <FeedbackInvalid feedback={feedback} />
      {hint ? <small className="text-muted">{hint}</small> : ''}
    </Wrapper>
  );
};

renderComplexInputField.propTypes = {
  containerClassName: PropTypes.string.isRequired,
  innerContainerClassName: PropTypes.string.isRequired,
  hint: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.any,
  input: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string.isRequired,
  children: PropTypes.any,
};

/**
 * Renderer for Phone Field component in redux-form for a straight forward field.
 * @param {string} containerClassName - className for field's outer component
 * @param {string} hint - Help text for field
 * @param {object} input - input level attributes
 * @param {string} label - Label's text
 * @param {string} labelClassName - Label's className
 * @param {jsx} children - JSX passed down from the parent component
 * @param {string} type - Input's type
 * @param {boolean} touched - Boolean if input has been interacted with
 * @param {string} error - Error message from redux-form-validations
 * @return {JSX} Field component for redux-form
 */
export const renderPhoneInputField = ({
  containerClassName,
  hint,
  input,
  label,
  labelClassName,
  children,
  type,
  meta: { touched, error } = {},
  ...inputProps
}) => {
  const allInputProps = { ...input, ...inputProps };
  const feedback = touched && error;
  if (!feedback) {
    return (
      <Wrapper className={containerClassName}>
        {label ? <label className={labelClassName}>{label}</label> : ''}
        <PhoneInput
          defaultCountry="US"
          type={type || 'text'}
          {...allInputProps}
        />
        {children}
        {hint ? <small className="opacity-7">{hint}</small> : ''}
      </Wrapper>
    );
  }
  /**
   * If there is feedback aka any type of error, concat the inputInvalid
   * css class to the input's className.
   */
  allInputProps.className = cn(allInputProps.className, 'input-invalid');
  return (
    <Wrapper className={containerClassName}>
      {label ? <label className={labelClassName}>{label}</label> : ''}
      <PhoneInput
        name="phone_number"
        defaultCountry="US"
        type={type || 'text'}
        {...allInputProps}
      />
      {children}
      <FeedbackInvalid feedback={feedback} />
      {hint ? <small className="text-muted">{hint}</small> : ''}
    </Wrapper>
  );
};

renderPhoneInputField.propTypes = {
  containerClassName: PropTypes.string,
  hint: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.any,
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.any,
};

/**
 * Renderer for Check box input component in redux-form.
 * @param {string} containerClassName - className for field's outer component
 * @param {string} hint - Help text for field
 * @param {object} input - input level attributes
 * @param {string} label - Label's text
 * @param {string} labelClassName - Label's className
 * @param {jsx} children - JSX passed down from the parent component
 * @param {string} type - Input's type
 * @param {boolean} touched - Boolean if input has been interacted with
 * @return {JSX} Field component for redux-form
 */
export const renderCheckboxWithIconField = ({
  containerClassName,
  hint,
  input,
  label,
  labelClassName,
  children,
  type,
  ...inputProps
}) => {
  const allInputProps = { ...input, ...inputProps };
  return (
    <Wrapper className={containerClassName}>
      <input type="checkbox" {...allInputProps} id={input.name} />
      {children}
      {label && (
        <label htmlFor={input.name} className={cn('w-100', labelClassName)}>
          {label}
        </label>
      )}
      {hint && <small className="text-muted">{hint}</small>}
    </Wrapper>
  );
};

renderCheckboxWithIconField.propTypes = {
  containerClassName: PropTypes.string,
  hint: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.any,
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.any,
};
