import { create } from 'zustand';
import uniqBy from 'lodash/uniqBy';
import { PRODUCTS } from '#/products/config';
import { isProductDisabledForType, ProductWithSpot } from '#/products/types';
import { Maybe } from '@paradigm/utils/types';

export interface FilterActiveProductsArgs {
  venue?: string;
  kinds?: ProductWithSpot['kind'][];
  auctionType?: Maybe<'OB' | 'RFQ'>;
}
interface ProductsStoreState {
  products: ProductWithSpot[];
  isLoading: boolean;
  getProduct: (productCode: string) => ProductWithSpot | undefined;
  getActiveProducts: (args?: FilterActiveProductsArgs) => ProductWithSpot[];
  setIsLoading: (value: boolean) => void;
  addProducts: (value: ProductWithSpot[]) => void;
  updateProduct: (
    productCode: string,
    partialProduct: Partial<ProductWithSpot>,
  ) => void;
}

export const useProductsStore = create<ProductsStoreState>()((set, get) => ({
  products: Object.values(PRODUCTS),
  isLoading: false,
  addProducts: (products) => {
    set((state) => ({
      ...state,
      products: uniqBy([...get().products, ...products], 'code'),
    }));
  },
  setIsLoading: (isLoading) => set((state) => ({ ...state, isLoading })),
  updateProduct: (productCode, partialProduct) =>
    set((state) => ({
      ...state,
      products: get().products.map((product) => {
        if (product.code !== productCode) return product;
        return { ...product, ...partialProduct, code: productCode };
      }) as ProductWithSpot[],
    })),
  getProduct: (productCode) =>
    get().products.find((p) => p.code === productCode),
  getActiveProducts: ({
    venue,
    kinds = ['OPTION', 'FUTURE'],
    auctionType,
  } = {}) =>
    get().products.filter(
      (product) =>
        product.isDisabled !== true &&
        (venue == null || product.venue === venue) &&
        kinds.includes(product.kind) &&
        (auctionType == null ||
          !isProductDisabledForType(product, auctionType)),
    ),
}));
