import { Component } from 'react';
import { Button } from 'reactstrap';
import { UserTwoFACodes } from '@paradigm/api/auth';
import { renderError } from '#/utils/renderErrors';
import { SettingsView } from '#/components/settings-modal/SettingsModal';
import BackUpCodes from './BackUpCodes';

interface Props {
  setView: (view: SettingsView) => void;
}

interface State {
  view: string;
  codesData: null | string[];
  error: null | string;
}
class Active extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      codesData: null,
      view: 'active',
      error: null,
    };
  }
  override componentDidMount() {
    UserTwoFACodes()
      .then((res) => {
        this.setState({
          codesData: res.data as string[],
        });
      })
      .catch((error: { data: string }) => {
        this.setState({
          error: error.data,
        });
      });
  }
  getViewLink() {
    const nuberOfCodes =
      this.state.codesData != null ? this.state.codesData.length : 0;
    const codes = nuberOfCodes > 1 ? 'codes' : 'code';
    if (nuberOfCodes > 0) {
      return (
        <p className="smaller-font opacity-7">
          You have <strong>{nuberOfCodes} unused</strong> backup {codes}{' '}
          <Button
            size="sm"
            color="link"
            onClick={() => this.handleViewCodes()}
            className="mb-1"
          >
            View
          </Button>
        </p>
      );
    }
    return (
      <p className="smaller-font opacity-7">
        You have <strong>no</strong> backup codes{' '}
        <Button
          size="sm"
          color="link"
          onClick={() => this.handleGenerateNew()}
          className="mb-1"
        >
          Generate new
        </Button>
      </p>
    );
  }
  handleDeactivate() {
    this.props.setView('Deactivate');
  }
  handleViewCodes() {
    this.props.setView('Backup Codes');
  }
  handleGenerateNew() {
    this.props.setView('GenerateCodes');
  }
  override render() {
    const { view, error } = this.state;
    if (view === 'active') {
      return (
        <div>
          {renderError(error)}
          <p className="smaller-font opacity-7">
            Two-Factor authentication is <strong>active</strong> for your
            account.
          </p>
          <div className="divider-totp flex-shrink-0" />
          {this.getViewLink()}
          <div className="divider-totp flex-shrink-0" />
          <div className="d-flex justify-content-end mt-4">
            <button
              type="submit"
              className="btn primary-btn-lg"
              onClick={() => this.handleDeactivate()}
            >
              Deactivate Two-Factor Authentication
            </button>
          </div>
        </div>
      );
    }
    if (view === 'backup-codes') {
      return <BackUpCodes />;
    }
    return <div />;
  }
}

export default Active;
