import MaterialTextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';

import { Checkbox } from '@mui/material';
import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { GroupBase, OnChangeValue } from 'react-select';
import { AutocompleteValue } from '@mui/material/useAutocomplete';
import { isEqual } from 'lodash';
import {
  mineShaft,
  paradigmLightBlue,
  portage15,
  portage70,
} from '@paradigm/design-system/src/colors';
import CheckIcon from '@paradigm/design-system/src/assets/CheckIcon';
import Button from '@paradigm/design-system/src/components/Button';
import { Box } from '@paradigm/design-system/src/components/Checkbox';
import { OptionTypeBase, SelectOption, SelectProps } from './types';

interface Props<
  OptionType extends OptionTypeBase = SelectOption,
  IsMulti extends boolean = false,
> extends SelectProps<OptionType, IsMulti> {
  readonly size?: 'small' | 'medium';
  readonly onChange: (value: OnChangeValue<OptionType, IsMulti>) => void;
}

export default function MaterialSelect<
  DisableClearable extends true,
  OptionType extends OptionTypeBase = SelectOption,
  IsMulti extends boolean = false,
>({
  label,
  options,
  isMulti,
  value,
  onChange,
  size = 'small',
  ...props
}: Props<OptionType, IsMulti>) {
  type State = AutocompleteValue<
    OptionType | GroupBase<OptionType>,
    IsMulti,
    DisableClearable,
    false // freeSolo
  >;

  const noOptionsText = <Label>No options</Label>;
  const checkedIcon = (
    <Box variant="border">
      <CheckIcon width={9} />
    </Box>
  );
  const uncheckedIcon = <Box variant="border" />;
  const defaultValue = isMulti === true ? [] : '';

  const [selectedValue, setSelectedValue] = useState<State>(
    (value ?? defaultValue) as State,
  );

  const handleClose = useCallback(() => {
    onChange(selectedValue as OnChangeValue<OptionType, IsMulti>);
  }, [onChange, selectedValue]);

  const isActive = !isEqual(selectedValue, defaultValue);

  return (
    <Container isActive={isActive} data-testid={props['data-testid']}>
      <Autocomplete
        multiple={isMulti}
        size={size}
        disableCloseOnSelect={isMulti}
        getOptionLabel={(option) => option.label as string}
        ListboxComponent={CustomListbox}
        noOptionsText={noOptionsText}
        onClose={handleClose}
        options={options?.slice() ?? []}
        renderInput={(params) => (
          <MaterialTextField {...params} label={label} variant="outlined" />
        )}
        renderOption={(optionProps, option, { selected }) => (
          <li {...optionProps}>
            <CheckboxContainer>
              {isMulti === true && (
                <Checkbox
                  checked={selected}
                  icon={uncheckedIcon}
                  checkedIcon={checkedIcon}
                />
              )}
              <Label>{option.label}</Label>
            </CheckboxContainer>
          </li>
        )}
        renderTags={(tags) => <Selection value={tags} />}
        PaperComponent={PopupContainer}
        value={selectedValue}
        onChange={(_, v) => setSelectedValue(v)}
      />
    </Container>
  );
}

function Selection<
  OptionType extends OptionTypeBase,
  Multiple extends boolean,
>({ value }: { value: OnChangeValue<OptionType, Multiple> }) {
  if (value == null) return null;
  if (Array.isArray(value) && value.length > 0) {
    const label =
      value.length > 1
        ? `${value.length} Selections`
        : ((value[0] as OptionType).label as string);
    return <SelectionContainer>{label}</SelectionContainer>;
  }
  return (
    <SelectionContainer>
      {(value as OptionType).label as string}
    </SelectionContainer>
  );
}

const CustomListbox = forwardRef(
  (props: HTMLAttributes<HTMLElement>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <CustomListboxContainer ref={ref} role="listbox">
        <ul {...props} />
        <hr />
        <ActionButton ariaLabel="add filter" type="button">
          Add Filter
        </ActionButton>
      </CustomListboxContainer>
    );
  },
);
CustomListbox.displayName = 'CustomListbox';

const fontFamily = '"IBM Plex Sans", "Roboto", sans-serif';
const fontSize = '0.875rem';
const lineHeight = '1rem';

const Container = styled.div<{ isActive: boolean }>`
  label {
    font-size: ${fontSize};
    color: white;
    opacity: 0.7;
    font-family: ${fontFamily};

    &.Mui-focused {
      color: white;
    }
  }

  .MuiFormControl-root {
    background-color: #07071d;
    width: 100%;
    font-size: ${fontSize};
    line-height: ${lineHeight};

    fieldset {
      border-color: ${(props) => (props.isActive ? portage70 : mineShaft)};
    }

    &:hover {
      fieldset {
        border-color: ${portage70};
      }
    }
  }

  .MuiInputBase-root {
    font-size: ${fontSize};
    line-height: ${lineHeight};
    font-weight: normal;

    &.Mui-focused {
      fieldset {
        border-color: ${portage70};
        border-width: 1px;
      }
    }

    input {
      color: white;
      font-family: ${fontFamily};
    }
  }

  .MuiIconButton-root {
    color: ${paradigmLightBlue};
  }
`;

const PopupContainer = styled.div`
  border: 1px solid ${portage15};
  border-radius: 3px;
  background-color: #07071d;
  box-shadow: 0 0 20px 0 rgba(74, 28, 186, 0.3);
`;

const Label = styled.span`
  color: white;
  opacity: 0.6;
  font-weight: normal;
  font-size: ${fontSize};

  .MuiCheckbox-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    display: none;
  }
`;

const SelectionContainer = styled.div`
  color: white;
  font-size: ${fontSize};
  line-height: ${lineHeight};
  font-family: ${fontFamily};
  padding-left: 6px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .MuiIconButton-root {
    padding: 0;
  }
`;

const CustomListboxContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: ${fontFamily};

  ul {
    width: 100%;
  }

  hr {
    border-color: #191e45;
    width: 100%;
    margin: 34px 0 12.5px 0;
  }
  .MuiAutocomplete-listbox {
    padding: 0;

    .MuiAutocomplete-option {
      padding: 0;
    }
  }
`;

const ActionButton = styled(Button)`
  align-self: flex-end;
  background-color: #23374e;
  color: #7fabec;
  height: auto;
  font-size: 0.75rem;
  line-height: 1.375rem;
  border-radius: 1rem;
  padding: 0 12.5px;
`;
