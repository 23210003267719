import {
  CSSObjectWithLabel,
  GroupBase,
  StylesConfig,
  ThemeConfig,
} from 'react-select';
import styled from 'styled-components';
import { useMemo } from 'react';
import {
  pickledBluewood,
  firefly,
  razzmatazz,
  ripeLemon,
  blueMana,
  cloudBurst,
  bigStone,
} from '#/colors';
import { FONT_FAMILY, MOBILE_BREAKPOINT } from '#/units';
import Button from '#/components/Button';
import useIsMobile from '#/hooks/useIsMobile';
import { SelectProps, OptionTypeBase } from './types';

export const getDefaultTheme: ThemeConfig = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    menuGutter: 0,
    controlHeight: 32,
  },
  colors: {
    ...theme.colors,
    neutral50: '#2c3f56',
    neutral10: 'rgba(255, 255, 255, 0.2)',
    primary: 'rgba(53, 146, 255, 0.5)',
    primary25: 'rgba(117, 171, 242, 0.25)',
    primary50: 'rgba(117, 171, 242, 0.25)',
  },
});

export function addStyles<T>(stylesFn: (state: T) => CSSObjectWithLabel) {
  return (base: CSSObjectWithLabel, state: T): CSSObjectWithLabel => ({
    ...base,
    ...stylesFn(state),
  });
}

export function makeDefaultStyles<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
>(
  props: SelectProps<OptionType, IsMulti> = {},
): StylesConfig<OptionType, IsMulti, GroupBase<OptionType>> {
  return {
    menu: addStyles(({ theme }) => ({
      background: theme.colors.neutral50,
      color: 'white',
      fontSize: 14,
      borderRadius: '0 0 3px 3px',
      boxShadow: '0 -1px 0 0 #3c4a5c',
      zIndex: 3,
      ...(props.isBordered === true && {
        borderRadius: '0 3px 3px 3px',
        overflow: 'hidden',
      }),
    })),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    singleValue: addStyles(() => ({
      color: 'white',
    })),
    input: addStyles(() => ({ color: 'white' })),
    dropdownIndicator: addStyles(() => ({
      color: 'white',
      paddingTop: 0,
      paddingBottom: 0,
      height: 28,
    })),
    placeholder: addStyles(() => ({ color: 'white' })),
    control: addStyles(({ theme, isDisabled }) => ({
      background: theme.colors.neutral50,
      borderRadius: 3,
      border: 'none',
      ...(props.isBordered === true &&
        props.hasWarning !== true && {
          background: 'transparent',
          border: `2px solid ${pickledBluewood} !important`,
        }),
      boxShadow: 'none',
      minHeight: 32,
      height: 32,
      fontSize: 14,
      cursor: 'pointer',
      ...(isDisabled && { opacity: 0.7 }),
    })),
    valueContainer: (base) => ({ ...base, position: 'static' }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 150,
    }),
    container: (base) => ({
      ...base,
      borderRadius: 5,
      boxSizing: 'border-box',
    }),
  };
}

export const getTransparentTheme: ThemeConfig = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    menuGutter: 0,
    controlHeight: 32,
  },
  colors: {
    ...theme.colors,
    neutral50: '#2c3f56',
    neutral10: 'rgba(255, 255, 255, 0.2)',
    primary: 'rgba(53, 146, 255, 0.5)',
    primary25: 'rgba(117, 171, 242, 0.25)',
    primary50: 'rgba(117, 171, 242, 0.25)',
    primary75: 'blue',
  },
});

export function makeTransparentStyles<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
>(
  props: SelectProps<OptionType, IsMulti> = {},
): StylesConfig<OptionType, IsMulti, GroupBase<OptionType>> {
  return {
    menu: addStyles(() => ({
      background: firefly,
      color: 'white',
      fontSize: 12,
      borderRadius: 0,
      minWidth: props.menuWidth ?? 100,
    })),
    singleValue: addStyles(() => ({
      color: 'white',
      maxWidth: props.showDefaultLabel === true ? 'calc(100% - 85px)' : '100%',
      textOverflow: 'ellipsis',
      opacity: 0.7,
    })),
    multiValue: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      fontSize: 16,
      opacity: 0.7,
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      ...base,
      '&:hover': {
        color: razzmatazz,
        backgroundColor: 'transparent',
      },
    }),
    input: addStyles(() => ({ color: 'white', position: 'absolute', top: 2 })),
    dropdownIndicator: addStyles(() => ({
      color: 'white',
      opacity: 0.7,
      paddingTop: 6,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      height: 28,
    })),
    placeholder: addStyles(() => ({
      color: props.submitted === true ? ripeLemon : 'white',
      opacity: 0.7,
    })),
    control: addStyles(({ isDisabled }) => ({
      background: 'transparent',
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
      minHeight: 32,
      height: 32,
      fontSize: 13,
      cursor: 'pointer',
      ...(isDisabled && { opacity: 0.3 }),
      ...(props.noOpacity === true && { opacity: 1 }),
    })),
    valueContainer: addStyles(() => ({ height: '100%' })),
    option: (base) => ({ ...base, height: 32 }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 145,
      overflow: 'overlay',
      borderRadius: 3,
    }),
  };
}

function useMultiSelectStyles<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
>(): StylesConfig<OptionType, IsMulti, GroupBase<OptionType>> {
  const isMobile = useIsMobile();
  return useMemo(
    () => ({
      dropdownIndicator: (base) => ({ ...base, display: 'none' }),
      indicatorSeparator: (base) => ({ ...base, display: 'none' }),
      indicatorsContainer: (base) => ({ ...base, position: 'relative' }),
      menu: (base) => ({ ...base, backgroundColor: bigStone }),
      control: (styles) => ({
        ...styles,
        paddingLeft: '8px',
        backgroundColor: cloudBurst,
        border: 'none',
      }),
      input: (base) => ({ ...base, color: '#FFFFFF70' }),
      option: (base) => ({
        ...base,
        backgroundColor: bigStone,
        fontFamily: FONT_FAMILY.sans,
        fontWeight: 'normal',
        color: '#FFFFFF70',
        cursor: 'default',
      }),
      placeholder: (base) => ({
        ...base,
        color: '#FFFFFF',
        opacity: 0.5,
      }),
      multiValue(base) {
        return {
          ...base,
          display: 'flex',
          backgroundColor: 'transparent',
          border: '1px solid #ffffff4d',
          borderRadius: isMobile ? '1rem' : '15px',
          alignItems: 'center',
          height: isMobile ? '2rem' : 'inherit',
          fontSize: isMobile ? '1.125rem' : 'inherit',
          padding: isMobile ? '0 0.25rem' : 'inherit',
        };
      },
      multiValueLabel: (base) => ({
        ...base,
        color: '#FFFFFF',
        opacity: 0.8,
        fontFamily: FONT_FAMILY.sans,
        fontWeight: 'bold',
      }),
      multiValueRemove: (base) => ({
        ...base,
        color: blueMana,
        cursor: 'pointer',
        ':hover': {
          color: '#FFFFFF70',
        },
        width: isMobile ? '1.25rem' : '14px',
        height: isMobile ? '1.25rem' : '14px',
        lineHeight: isMobile ? 'inherit' : 0,
        padding: 0,
      }),
      valueContainer(base) {
        return {
          ...base,
          gap: '0.25rem',
          minHeight: isMobile ? '3rem' : 'inherit',
          fontSize: isMobile ? '1rem' : 'inherit',
          padding: isMobile ? '0.5rem 0.5rem' : 'inherit',
        };
      },
    }),
    [isMobile],
  );
}

export function useStyles<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
>(props: SelectProps<OptionType, IsMulti> = {}) {
  const multiSelectStyles = useMultiSelectStyles<OptionType, IsMulti>();
  if (props.isMulti === true) {
    return multiSelectStyles;
  }
  if (props.variant === 'transparent') {
    return makeTransparentStyles<OptionType, IsMulti>(props);
  }
  return makeDefaultStyles<OptionType, IsMulti>(props);
}

export function makeTheme<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false,
>({ variant, isMulti }: SelectProps<OptionType, IsMulti> = {}) {
  if (isMulti === true) return;
  if (variant === 'transparent') {
    return getTransparentTheme;
  }
  return getDefaultTheme;
}

export const ClearButton = styled(Button).attrs({
  variant: 'link',
  size: 'small',
})`
  position: absolute;
  right: 0px;
  top: -1.25rem;
  font-size: 0.75rem;
  text-align: center;
  white-space: nowrap;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 1rem;
    top: -1.75rem;
  }
`;
