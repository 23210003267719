import Analytics from 'analytics';
// @ts-expect-error no available type defs for `@analytics/segment` at this moment
import segmentPlugin from '@analytics/segment';

import isElectron from 'is-electron';
import { exceptionLogger } from '@paradigm/logging';
import codeBaseVersion from '@paradigm/utils/src/codeBaseVersion';

import { User } from './types';

const analytics = Analytics({
  app: 'desktop',
  version: codeBaseVersion,
  plugins: [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    segmentPlugin({
      writeKey: ANALYTICS_DESKTOP_API_KEY,
      syncAnonymousId: true,
    }),
  ],
});

let identified = false;

const identify = (user: User, cb?: () => void) => {
  const { id } = user;

  const traits: Record<string, unknown> = {
    name: [user.first_name, user.last_name].filter(Boolean).join(' '),
  };
  if (user.trading_desk != null) {
    traits.desk = user.trading_desk.ticker;
  }

  // Must be set before identify call to allow for tracking
  // methods called after to perform as desired. Methods called
  // after the identify call will be identified (non-anonymous),
  // even if this promise hasn't resolved yet.
  identified = true;

  analytics
    .identify(id.toString(), traits, undefined, cb)
    .catch(handleIdentifyError);
};

const reset = () => {
  if (!identified) return;
  analytics.reset().catch(handleResetError);
  identified = false;
};

/**
 * Attention: will be no-op if user not identified
 */
const track = (
  eventName: string,
  properties?: Record<string, unknown>,
  cb?: () => void,
) => {
  if (!identified) return;
  const [product] = window.location.pathname.replace('/', '').split('/');
  analytics
    .track(
      eventName,
      {
        product,
        app_version: codeBaseVersion,
        app_type: isElectron() ? 'desktop_app' : 'web_app',
        url: window.location.toString(),
        ...properties,
      },
      undefined,
      cb,
    )
    .catch(handleTrackError);
};

export * from '#/event-names';
export { identify, reset, track };

const handleIdentifyError = exceptionLogger('Web analytics identify failure');
const handleResetError = exceptionLogger('Web analytics reset failure');
const handleTrackError = exceptionLogger('Web analytics track failure');
