import {
  Drfq,
  RfqsEvent,
  RfqOrdersEvent,
  OrdersEvent,
  TradesEvent,
  TradeTapeEvent,
  BboEvent,
  MmpEvent,
} from '#/unified-rfqs/entities/drfq/domain';
import {
  MmpStatus,
  RawDrfq,
  RawOrder,
  RawTrade,
  RawTradeTapeItem,
  RawWsBbo,
} from '#/unified-rfqs/repositories/drfq-types';
import { Order } from '#/unified-rfqs/entities/drfq/orders';
import { Side } from '#/unified-rfqs/entities/shared';
import { Trade, TradeTapeItem } from '#/unified-rfqs/entities/drfq/trades';
import { Bbo } from '#/unified-rfqs/entities/drfq/bbo';

export type DrfqWSChannel =
  | 'rfqs'
  | 'rfq_orders'
  | 'orders'
  | 'trades'
  | 'trade_tape'
  | `bbo.${string}`
  | 'mmp';

const KNOWN_TOPICS = [
  'rfqs',
  'rfq_orders',
  'orders',
  'trades',
  'trade_tape',
  'bbo',
  'mmp',
] as const;

type KnownTopic = typeof KNOWN_TOPICS[number];

type DrfqTopic = KnownTopic | 'unknown';

function isKnownTopic(str: string): str is KnownTopic {
  return (KNOWN_TOPICS as readonly string[]).includes(str);
}

export function getChannelTopic(channel: string): DrfqTopic {
  const [topic] = channel.split('.');
  if (topic != null && isKnownTopic(topic)) return topic;
  return 'unknown';
}

interface DrfqDataByTopic {
  readonly rfqs: Drfq;
  readonly rfq_orders: RfqOrderNotificationData;
  readonly orders: Order;
  readonly trades: Trade;
  readonly trade_tape: TradeTapeItem;
  readonly bbo: Bbo;
  readonly mmp: MmpStatus;
  readonly unknown: unknown;
}

interface DrfqEventByTopic {
  readonly rfqs: RfqsEvent;
  readonly rfq_orders: RfqOrdersEvent;
  readonly orders: OrdersEvent;
  readonly trades: TradesEvent;
  readonly trade_tape: TradeTapeEvent;
  readonly bbo: BboEvent;
  readonly mmp: MmpEvent;
  readonly unknown: string;
}

export type DrfqNotification = DrfqNotificationByTopic[DrfqTopic];

export type DrfqNotificationByTopic = {
  readonly [Topic in DrfqTopic]: {
    readonly topic: Topic;
    readonly channel: string;
    readonly event: DrfqEventByTopic[Topic];
    readonly data: DrfqDataByTopic[Topic];
  };
};

export interface RawDrfqNotification {
  readonly jsonrpc: '2.0';
  readonly method: string;
  readonly params: {
    readonly channel: string;
    readonly event: string;
    readonly data: unknown;
  };
}

interface RawDrfqDataByTopic {
  readonly rfqs: RawDrfq;
  readonly rfq_orders: RfqOrderNotificationData;
  readonly orders: RawOrder;
  readonly trades: RawTrade;
  readonly trade_tape: RawTradeTapeItem;
  readonly bbo: RawWsBbo;
  readonly mmp: MmpStatus;
  readonly unknown: unknown;
}

export type ProcessorsByTopic = {
  readonly [Topic in DrfqTopic]: (
    raw: RawDrfqDataByTopic[Topic],
  ) => DrfqDataByTopic[Topic];
};

export interface RfqOrderNotificationData {
  readonly rfq_id: string;
  readonly side: Side;
  readonly price: string;
  readonly quantity: string;
  readonly desk: string;
  readonly id: string;
  isNew?: boolean;
}
