import { PlatformState, RawPlatformState } from '#/types/platform-state';

export function processFsPlatformState(
  rawPlatformState: RawPlatformState,
): PlatformState {
  return {
    publishedAt: rawPlatformState.published_at,
    messageKind: rawPlatformState.message_kind,
    platformState: rawPlatformState.platform_state,
    nextPlatformState: rawPlatformState.next_platform_state,
    nextPlatformStateAt: rawPlatformState.next_platform_state_at,
  };
}

const fromNanosecondToMicroSecond = (n?: number) =>
  n !== undefined ? n * 1000000 : n;

export function processUMPlatformState(
  rawPlatformState: RawPlatformState,
): PlatformState {
  return {
    messageKind: rawPlatformState.message_kind,
    platformState: rawPlatformState.platform_state,
    nextPlatformState: rawPlatformState.next_platform_state,
    publishedAt: fromNanosecondToMicroSecond(rawPlatformState.published_at),
    nextPlatformStateAt: fromNanosecondToMicroSecond(
      rawPlatformState.next_platform_state_at,
    ),
  };
}
