import { Context, SeverityLevel } from '@sentry/types';

export class ParadigmAppError extends Error {
  level: SeverityLevel;
  tag: string;
  constructor(message: string) {
    super(message);
    this.name = 'ParadigmAppError';
    this.tag = 'unknown_error';
    this.level = 'error';
  }

  /**
   * Static method to check if the error is a instance
   * of this custom paradigm app error.
   *
   * @param error - The error to check.
   * @returns A boolean indicating if the error is a instance of this class.
   */
  static matches(error: Error): error is ParadigmAppError {
    return error instanceof this;
  }

  /**
   * Method to get the context of the error.
   * @returns The context of the error.
   */
  getContext(): Context {
    return {
      level: this.level,
      tags: { error_tag: this.tag },
    };
  }
}

export class FailedToFetchError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'FailedToFetchError';
    this.level = 'warning';
    this.tag = 'failed_to_fetch';
  }

  static override matches(error: unknown): error is FailedToFetchError {
    return (
      error instanceof TypeError &&
      error.message === 'Failed parsing API response'
    );
  }
}

export class CannotReadUndefinedError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'CannotReadUndefinedError';
    this.tag = 'cannot_read_undefined';
    this.level = 'fatal';
  }

  static override matches(error: unknown): error is CannotReadUndefinedError {
    return (
      error instanceof TypeError &&
      error.message.includes('Cannot read properties of undefined')
    );
  }
}

export class ErrorBoundaryError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'ErrorBoundaryError';
    this.level = 'fatal';
    this.tag = 'error_boundary';
  }
}

export class WSAbortError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'WSAbortError';
    this.level = 'warning';
    this.tag = 'ws_aborted';
  }
}

export class WSTimeoutError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'WSTimeoutError';
    this.level = 'warning';
    this.tag = 'ws_timeout';
  }
}
export class TimeoutError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
    this.level = 'warning';
    this.tag = 'timeout';
  }

  static override matches(error: Error): error is TimeoutError {
    return error.name === this.name;
  }
}

export class AbortError extends ParadigmAppError {
  constructor(message: string) {
    super(message);
    this.name = 'AbortError';
    this.level = 'warning';
    this.tag = 'aborted';
  }
  static override matches(error: Error): error is AbortError {
    return error.name === this.name;
  }
}

export class RequestError extends ParadigmAppError {
  status: number | undefined;
  constructor(message: string, status?: number) {
    super(message);
    this.name = 'RequestError';
    this.tag = 'request_error';
    this.status = status;
    this.level = status === 400 ? 'fatal' : 'error';
  }
  static override matches(error: Error): error is RequestError {
    return error.name === this.name;
  }
}

export class GrfqServiceRequestError extends RequestError {
  constructor(message: string, status?: number) {
    super(message, status);
    this.name = 'GrfqServiceRequestError';
    this.tag = 'grfq_service_request_error';
  }
}

export class DrfqServiceRequestError extends RequestError {
  constructor(message: string, status?: number) {
    super(message, status);
    this.name = 'DrfqServiceRequestError';
    this.tag = 'drfq_service_request_error';
  }
}

export const ParadigmErrors: typeof ParadigmAppError[] = [
  FailedToFetchError,
  WSTimeoutError,
  ErrorBoundaryError,
  WSAbortError,
  TimeoutError,
  AbortError,
];
