import React from 'react';
import { StyledText } from './styles';

export type TextProps = {
  as?: 'p' | 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'pre';
  size?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';
  weight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  color?: string;
  children: React.ReactNode;
};

export function BaseText({
  as = 'span',
  size = 'medium',
  color = 'white',
  weight = 'normal',
  children,
  ...rest
}: TextProps) {
  return React.createElement(
    as,
    {
      size,
      color,
      weight,
      ...rest,
    },
    children,
  );
}

export default function Text({
  as = 'span',
  size = 'medium',
  color = 'white',
  weight = 'normal',
  children,
  ...rest
}: TextProps) {
  return <StyledText {...rest} {...{ as, size, color, weight, children }} />;
}
