import moment from 'moment';

import { razzmatazz } from '@paradigm/design-system/src/colors';

import CloseIcon from '@paradigm/design-system/src/assets/CloseIcon';

export interface SessionItem {
  last_login: string;
  app_version: string;
  app_type: string | null;
  id: number;
  device: string | null;
  ip_address: string;
  os_type: string;
  os_version: string;
  region: string;
}
interface SessionProps {
  session: SessionItem;
  handleSessionsTerminate: (id: number) => void;
  current?: boolean;
}
const Session = (props: SessionProps) => {
  const { session, handleSessionsTerminate } = props;
  const date = session.last_login
    ? moment(session.last_login).format('DD MMM YY')
    : 'Unknown IP and date';
  return (
    <div className="mt-4">
      <p className="m-0">
        {session.app_type ?? 'Paradigm'} {session.app_version}
        {props.current === true && (
          <CloseIcon
            width={9}
            height={9}
            className="close-icon float-right mt-1 hiden"
            style={{
              color: razzmatazz,
            }}
            onClick={() => handleSessionsTerminate(session.id)}
          />
        )}
      </p>
      <p className="m-0">
        {session.device ?? 'Unknown device'}, {session.os_type}{' '}
        {session.os_version}
      </p>
      <p className="m-0 opacity-5">
        {session.ip_address}-{session.region}-{date}
      </p>
    </div>
  );
};

export default Session;
