import { exceptionLogger } from '@paradigm/logging/src/logging';
import { isSilentNotification } from '#/unified-rfqs//utils/screenPrices';
import { DrfqOrderbooksStore } from '#/unified-rfqs/store/drfq-orderbooks';
import { subscribeWebSocket } from '../../websocket/useWsInstance';
import { isMaker } from '../entities/drfq/domain';
import { RfqId } from '../entities/rfq';
import { DrfqWebSocket, createDrfqWebSocket } from '../repositories/drfq-ws';
import {
  DrfqNotification,
  DrfqNotificationByTopic,
} from '../repositories/drfq-ws-types';

export type FireNotificationOptions =
  | {
      notificationId: string;
      onlySound: true;
    }
  | {
      notificationId: string;
      onlyBadge: true;
    }
  | {
      notificationId: string;
      title: string;
      message: string;
      silent?: boolean;
    };

export type FireNotificationFn = (options: FireNotificationOptions) => void;

interface NotificationServiceDeps {
  readonly fireNotification: FireNotificationFn;
  readonly pinRfq: (rfqId: RfqId) => void;
  readonly orderbooksStore: DrfqOrderbooksStore;
}

export default class DrfqNotificationsService {
  private ws: DrfqWebSocket | null = null;
  private abortController: AbortController | null = null;

  constructor(private deps: NotificationServiceDeps) {}

  startWebsocket = () => {
    const ws = createDrfqWebSocket();
    this.ws = ws;
    this.ws.addNotificationHandler(this.handleNotification);

    const subscribe = () => {
      this.abortController?.abort();
      this.abortController = new AbortController();
      subscribeWebSocket({
        ws,
        channels: ['rfqs', 'trades', 'rfq_orders'],
        name: 'drfq-ws',
        signal: this.abortController.signal,
      }).catch(exceptionLogger('Failed to connect to drfq-ws notifications.'));
    };

    const handleClose = () => {
      this.abortController?.abort();
    };

    this.ws.addEventListener('open', subscribe);
    this.ws.addEventListener('close', handleClose);
  };

  teardown = () => {
    this.ws?.removeNotificationHandler(this.handleNotification);
    this.ws?.close();
    this.abortController?.abort();
  };

  handleNotification = (notification: DrfqNotification) => {
    switch (notification.topic) {
      case 'rfqs':
        this.handleRfqNotification(notification);
        break;
      case 'rfq_orders':
        this.handleRfqOrdersNotification(notification);
        break;
      case 'trades':
        this.handleTradesNotification(notification);
        break;
      case 'trade_tape':
      case 'orders':
      case 'bbo':
      case 'mmp':
      case 'unknown':
        break;
      // no default
    }
  };

  private handleRfqNotification(notification: DrfqNotificationByTopic['rfqs']) {
    const { data, event } = notification;
    const { fireNotification, pinRfq } = this.deps;

    switch (event) {
      case 'ADDED':
        // Pin rfq beforehand, so when user goes to /rfqs, it would be already pinned.
        pinRfq(data.id);
        if (isMaker(data)) {
          const title = data.is_taker_anonymous
            ? 'RFQ Received (Anonymous)'
            : `RFQ Received (${data.taker_desk_name})`;
          const message = data.description;

          fireNotification({
            notificationId: `${data.id} ${data.type} ${title} ${message}`,
            title,
            message,
          });
        }
        break;
      case 'REMOVED':
        break;
      // no default
    }
  }

  private handleRfqOrdersNotification(
    notification: DrfqNotificationByTopic['rfq_orders'],
  ) {
    const { event, data } = notification;
    const { orderbooksStore } = this.deps;
    const byRfqOrderStore = orderbooksStore.byRfq(data.rfq_id);
    const asks = byRfqOrderStore.asks.selectAll();
    const bids = byRfqOrderStore.bids.selectAll();

    const silent = isSilentNotification(
      data,
      data.side === 'BUY' ? bids[0] : asks[0],
    );
    switch (event) {
      case 'ADDED':
        if (!silent) {
          this.deps.fireNotification({
            notificationId: `${data.id} ${data.rfq_id} ${data.price} ${data.quantity}`,
            onlyBadge: true,
          });
        }
        break;
      case 'REMOVED':
      case 'UPDATED':
        break;
      // no default
    }
  }

  private handleTradesNotification(
    notification: DrfqNotificationByTopic['trades'],
  ) {
    const { event, data } = notification;
    switch (event) {
      case 'FILLED':
        this.deps.fireNotification({
          notificationId: `${data.id} ${data.exec_id} ${data.order_id} ${data.description}`,
          title: 'RFQ trade filled',
          message: `Filled ${data.filled_quantity}x at ${data.price}`,
        });
        break;
      case 'REJECTED':
        this.deps.fireNotification({
          notificationId: `${data.id} ${data.exec_id} ${data.order_id} ${data.description}`,
          title: 'RFQ trade rejected',
          message: `Reject Party: ${data.rejected_party}, Reason: ${data.rejected_reason}`,
        });
        break;
      case 'PENDING_SETTLEMENT':
        break;
      // no default
    }
  }
}
