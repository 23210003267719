import { useCallback, useMemo, useState } from 'react';
import clx from 'classnames';
import * as PropTypes from 'prop-types';

import styled from 'styled-components';
import codeBaseVersion from '@paradigm/utils/src/codeBaseVersion';

import CloseIcon from '@paradigm/design-system/src/assets/CloseIcon';
import BackIcon from '@paradigm/design-system/src/assets/BackIcon';
import UserTwoFactorAuth from '#/components/user-preferences/components/two-factor-auth';
import BackUpCodes from '#/components/user-preferences/components/two-factor-auth/BackUpCodes';
import Deactivate from '#/components/user-preferences/components/two-factor-auth/Deactivate';
import GenerateCodes from '#/components/user-preferences/components/two-factor-auth/GenerateCodes';
import UserPasswordChange from '#/components/user-preferences/components/password-change-form';
import ActiveSessions from '#/components/user-preferences/components/ActiveSessions';
import DefaultAccounts from '#/components/user-preferences/components/default-accounts/DefaultAccounts';

import useIsMobile from '@paradigm/design-system/src/hooks/useIsMobile';
import { AuthUser } from '@paradigm/store/src/auth/types';
import Modal from '@paradigm/design-system/src/components/Modal';
import ProBadge from '@paradigm/design-system/src/assets/ProBadge';
import { useTrackAction } from '@paradigm/features/src/analytics';
import { Action } from '@paradigm/features/src/analytics/types';
import SettingsSecurity from './sub-views/SettingsSecurity';
import SettingsNotifications from './sub-views/SettingsNotifications';
import SettingsMain from './sub-views/SettingsMain';

export type SettingsView =
  | 'Change Password'
  | 'Two-Factor Authentication'
  | 'Active Sessions'
  | 'Security'
  | 'Backup Codes'
  | 'Notifications'
  | 'Deactivate'
  | 'GenerateCodes'
  | 'Default Accounts'
  | '';

const TRACKED_VIEWS: Partial<Record<SettingsView, Action>> = {
  'Default Accounts': 'edit default account',
  Notifications: 'view notification settings',
  Security: 'view security settings',
  'Active Sessions': 'view active sessions',
  'Change Password': 'view change password',
  'Two-Factor Authentication': 'view two-factor authentication',
};

const SECURITY_CHILD_VIEWS = new Set([
  'Change Password',
  'Two-Factor Authentication',
  'Active Sessions',
]);
const TWOFA_CHILD_VIEWS = new Set([
  'Backup Codes',
  'Deactivate',
  'GenerateCodes',
]);
interface Props {
  readonly onClose: () => void;
  readonly user: AuthUser;
  readonly onLogout: () => void;
  readonly initialView?: SettingsView;
}

const SettingsModal = (props: Props) => {
  const { onClose, user, onLogout, initialView } = props;

  const [view, setViewBase] = useState<SettingsView>(initialView ?? '');

  const track = useTrackAction();
  const setView = useCallback(
    (value: SettingsView) => {
      setViewBase(value);
      const viewEvent = TRACKED_VIEWS[value];
      if (viewEvent !== undefined) {
        track(viewEvent);
      }
    },
    [track, setViewBase],
  );

  const isMobile = useIsMobile();

  const backBtn: SettingsView = useMemo(() => {
    if (SECURITY_CHILD_VIEWS.has(view)) return 'Security';
    if (TWOFA_CHILD_VIEWS.has(view)) return 'Two-Factor Authentication';
    return '';
  }, [view]);

  const getTitle = () => {
    if (!view) {
      return 'Settings';
    }
    if (view === 'Default Accounts') {
      return (
        <TitleContainer>
          <ProBadge />
          <span>Default Accounts</span>
        </TitleContainer>
      );
    }
    if (view === 'GenerateCodes') {
      return 'Backup Codes';
    }
    return view;
  };

  return (
    <Modal
      isOpen
      width={500}
      toggle={onClose}
      keyboard
      fade={false}
      className={clx('custom-dialog settings-modal', { mobile: isMobile })}
    >
      <div
        className={clx('d-flex px-4-5 align-items-center', {
          'justify-content-between': !isMobile,
          'justify-content-center': isMobile,
        })}
      >
        <div
          className={clx('d-flex align-items-center', {
            pointer: Boolean(view),
            centered: isMobile,
          })}
          {...(Boolean(view) && {
            onClick: () => setView(backBtn),
          })}
        >
          {Boolean(view) && (
            <div className="pr-3">
              <BackIcon />
            </div>
          )}
          <div className="modal-title">{getTitle()}</div>
        </div>
        <CloseIcon
          width={15}
          height={15}
          css="z-index: 2;"
          className={clx('close-icon', { centered: isMobile })}
          onClick={onClose}
        />
      </div>
      {!view && (
        <SettingsMain
          isMobile={isMobile}
          onLogout={onLogout}
          user={user}
          setView={setView}
          onClose={onClose}
        />
      )}
      {view === 'Notifications' && (
        <div className="px-4-5 mt-4">
          <SettingsNotifications />
        </div>
      )}
      {view === 'Default Accounts' && (
        <div className="px-4-5 mt-4">
          <DefaultAccounts />
        </div>
      )}
      {view === 'Security' && (
        <div className="px-4-5 mt-4">
          <SettingsSecurity setView={setView} view={view} />
        </div>
      )}
      {view === 'Change Password' && (
        <div className="px-4-5 mt-4">
          <UserPasswordChange />
        </div>
      )}
      {view === 'Active Sessions' && (
        <div className="mt-4">
          <ActiveSessions />
        </div>
      )}
      {view === 'Two-Factor Authentication' && (
        <div className="px-4-5 mt-4">
          <UserTwoFactorAuth user={user} setView={setView} />
        </div>
      )}
      {view === 'Backup Codes' && (
        <div className="px-4-5 mt-4">
          <BackUpCodes />
        </div>
      )}
      {view === 'Deactivate' && (
        <div className="px-4-5 mt-4">
          <Deactivate setView={setView} />
        </div>
      )}
      {view === 'GenerateCodes' && (
        <div className="px-4-5 mt-4">
          <GenerateCodes setView={setView} />
        </div>
      )}
      {!view && (
        <div className="px-4-5 flex-shrink-0">
          <span style={{ opacity: 0.5, float: 'right', marginTop: 24 }}>
            Paradigm v {codeBaseVersion}
          </span>
        </div>
      )}
    </Modal>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`;

SettingsModal.propTypes = {
  onClose: PropTypes.func,
  onLogout: PropTypes.func,
  initialView: PropTypes.string,
  hasArchived: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

export default SettingsModal;
