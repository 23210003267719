import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from '#/containers/layout/loading';
import { getAgreementsApi } from '@paradigm/api/auth';
import { exceptionLogger } from '@paradigm/logging/src/logging';
import { Container } from './AcceptAgreement';

import Agreement from './Agreement';
import { AgreementDocument, PARADIGM_TERMS_LINK } from './types';
import processAgreementMarkdownData from './processAgreementData';

interface Props {
  readonly onAccept: (agreement: number) => Promise<void>;
  readonly reached?: boolean;
}

export default function NewAcceptAgreement(props: Props) {
  const { onAccept: onAcceptNewAgreement, reached } = props;
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [agreement, setAgreement] = useState<AgreementDocument>();
  useEffect(() => {
    getAgreements();
  }, [error]);

  const getAgreements = () => {
    getAgreementsApi()
      .then((response) => {
        if (response.ok) {
          setAgreement({
            kind: 'us',
            url: PARADIGM_TERMS_LINK,
            content: processAgreementMarkdownData(response.data),
          });
        } else {
          setError('Unable to load the Agreements. Please reload the page');
        }
        setLoading(false);
      })
      .catch(exceptionLogger('Unable to fetch the Agreements'));
  };

  const onAccept = () => {
    if (agreement === undefined) {
      return;
    }
    const argumentId = agreement.content[0]?.argumentId ?? 0;
    onAcceptNewAgreement(argumentId)
      .then(() => {
        getAgreements();
      })
      .catch(exceptionLogger('Unable to Accept the Agreement'));
  };

  return (
    <NewAgreementContainer className="accept-agreement" data-testid="agreement">
      <Header>
        <HeaderHeading>Agreements</HeaderHeading>
        <HeaderMessage>
          You are receiving this message in your capacity as the Desk
          Administrator for your related User. Paradigm periodically refreshes
          its User Agreement and annexes and requires all users to re-accept its
          User Agreement to continue to receive services from Paradigm.
        </HeaderMessage>
        <HeaderMessage>
          We are updating our User Agreement to remove the CME Product Annexe to
          reflect the deprecation of support for trading on CME.
        </HeaderMessage>
        <HeaderMessage>
          By re-accepting the User Agreement, you will confirm that you (or your
          related User) are not a “U.S. Person” (as defined in U.S. securities
          and commodity futures laws), a “Canadian person” (as defined in
          Canadian securities laws) or otherwise a sanctioned person or a
          resident of a sanctioned jurisdiction.
        </HeaderMessage>
      </Header>
      {!isLoading && agreement !== undefined ? (
        <Agreement
          onAccept={onAccept}
          reached={reached}
          agreement={agreement}
        />
      ) : error !== null ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <Loading />
      )}
    </NewAgreementContainer>
  );
}

const NewAgreementContainer = styled(Container)`
  max-height: 800px;
`;
const Header = styled.div`
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
`;

const HeaderHeading = styled.h1`
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const HeaderMessage = styled.p`
  opacity: 0.5;
`;
const ErrorMessage = styled.p`
  color: red;
`;
