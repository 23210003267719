import { ComponentType } from 'react';

export const PARADIGM_TERMS_LINK =
  'https://www.paradigm.co/help/terms/paradigm-singapore/terms-and-agreements';

export interface AgreementContent {
  readonly id: string;
  readonly label: string;
  readonly Component: ComponentType;
  readonly argumentId?: number;
}

export interface AgreementDocument {
  readonly kind: 'us' | 'non-us';
  readonly url: string;
  readonly content: readonly AgreementContent[];
}
