import { RfqOrder } from '#/unified-rfqs/entities/drfq/orders';
import { getPriceBigNum, Side } from '#/unified-rfqs/entities/shared';
import EntityStore from './entity';

export class DrfqOrderbooksStore {
  constructor(private readonly orderbooks = new Map<string, DrfqOrderbook>()) {}

  byRfq(rfqId: string) {
    let ob = this.orderbooks.get(rfqId);
    if (ob == null) {
      ob = new DrfqOrderbook(rfqId);
      this.orderbooks.set(rfqId, ob);
    }
    return ob;
  }

  clearByRfq(rfqId: string) {
    const ob = this.orderbooks.get(rfqId);
    ob?.clear();
    this.orderbooks.delete(rfqId);
  }

  clear() {
    for (const ob of this.orderbooks.values()) {
      ob.clear();
    }
    this.orderbooks.clear();
  }
}

export class DrfqOrderbook {
  readonly rfqId: string;
  readonly bids = new EntityStore({ sortComparer: compareBids });
  readonly asks = new EntityStore({ sortComparer: compareAsks });

  constructor(rfqId: string) {
    this.rfqId = rfqId;
  }

  bySide(side: Side) {
    switch (side) {
      case 'BUY':
        return this.bids;
      case 'SELL':
        return this.asks;
      // no default
    }
  }

  clear() {
    this.bids.clear();
    this.asks.clear();
  }
}

function compareBids(bidA: RfqOrder, bidB: RfqOrder): number {
  return getPriceBigNum(bidB).comparedTo(getPriceBigNum(bidA));
}

function compareAsks(askA: RfqOrder, askB: RfqOrder): number {
  return getPriceBigNum(askA).comparedTo(getPriceBigNum(askB));
}
