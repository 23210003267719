import { useEffect, useState, useCallback } from 'react';
import {
  UserActiveSessions,
  UserActiveSessionsTerminateAll,
  UserActiveSessionTerminate,
} from '@paradigm/api/auth';
import { exceptionLogger } from '@paradigm/logging/src/logging';
import { useTrackAction } from '@paradigm/features/src/analytics';
import Session from './Session';
import type { SessionItem } from './Session';

const ActiveSessions = () => {
  const [currentSession, setCurrentSession] = useState<SessionItem | null>(
    null,
  );
  const [sessions, setSessions] = useState<SessionItem[] | null>(null);
  const track = useTrackAction();

  useEffect(() => {
    UserActiveSessions()
      .then((res) => {
        const data = res.data as {
          active_sessions: SessionItem[];
          current_session_id: number;
        };
        setCurrentSession(
          data.active_sessions.find(
            (session) => session.id === data.current_session_id,
          ) ?? null,
        );
        setSessions(
          data.active_sessions.filter(
            (session) => session.id !== data.current_session_id,
          ),
        );
      })
      .catch(exceptionLogger('user active sessions'));
  }, []);

  const handleSessionsTerminate = useCallback(
    (id: number) => {
      UserActiveSessionTerminate(id)
        .then(() => {
          setSessions(sessions?.filter((session) => session.id !== id) ?? null);
        })
        .catch(exceptionLogger('terminate session'));
      track('terminate current session');
    },
    [sessions, track],
  );

  const handleTerminateAll = useCallback(() => {
    UserActiveSessionsTerminateAll()
      .then(() => {
        setSessions(null);
      })
      .catch(exceptionLogger('terminate all sessions'));
    track('terminate all sessions');
  }, [track]);

  return (
    <div>
      <h6 className="px-4-5">
        <div className="green-circle" />
        Current Session
      </h6>
      <div className="px-4-5">
        {currentSession && (
          <div key={currentSession.id} className="mt-2">
            <Session
              current
              session={currentSession}
              handleSessionsTerminate={handleSessionsTerminate}
            />
          </div>
        )}
      </div>
      <div className="divider flex-shrink-0" />
      <h6 className="px-4-5">
        Active Sessions
        {sessions != null && sessions.length > 0 && (
          <button
            className="terminate-all-btn float-right p-0"
            onClick={handleTerminateAll}
          >
            Terminate all
          </button>
        )}
      </h6>
      <div className="px-4-5">
        {sessions?.map((item) => (
          <div key={item.id} className="mt-2">
            <Session
              session={item}
              handleSessionsTerminate={handleSessionsTerminate}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveSessions;
