import styled, { css } from 'styled-components';
import {
  FONT_FAMILY,
  FONT_STYLES,
  FONT_WEIGHT,
} from '@paradigm/design-system/src/units';
import { TextProps, BaseText } from './Text';

const withSize = ({ size }: Required<TextProps>) => css`
  font-size: ${FONT_STYLES[size].fontSize};
  line-height: ${FONT_STYLES[size].lineHeight};
`;

const withFontWeight = ({ weight }: Required<TextProps>) => css`
  font-weight: ${FONT_WEIGHT[weight]};
`;

const withColor = ({ color }: TextProps) => css`
  color: ${color};
`;

export const StyledText = styled(BaseText).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    defaultValidatorFn(prop) && prop !== 'size',
})`
  font-family: ${FONT_FAMILY.sans};
  ${withSize}
  ${withFontWeight}
  ${withColor}
`;
