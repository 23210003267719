import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { blackPearl } from '@paradigm/design-system/src/colors';

export default function CustomerTypeSelection() {
  return (
    <Container>
      <Paragraph>
        We’re excited to announce the launch of Paradigm Singapore!
      </Paragraph>
      <Paragraph>
        Paradigm Singapore will be launching new non-U.S. products, including
        Global RFQ.
      </Paragraph>
      <CardsContainer>
        <Card>
          <CardHeading>Non-US Person Customers</CardHeading>
          <Paragraph>
            Paradigm US and Paradigm Singapore are affiliates, but legally
            separate entities. Accordingly, if you are a non-US person customer
            of Paradigm, to utilize these new products, including Global RFQ,
            you must become a customer of Paradigm Singapore. You will remain a
            customer of Paradigm US for existing Paradigm products.
          </Paragraph>
          <ButtonLink to="?customer=non-us">Review Agreement</ButtonLink>
        </Card>
        <Card>
          <CardHeading>US Person Customers</CardHeading>
          <Paragraph>
            If you are a US person customer of Paradigm, you will remain a
            customer of Paradigm US and are not eligible at this time to become
            a customer of Paradigm Singapore. We will update you as Paradigm US
            offer new products.
          </Paragraph>
          <ButtonLink to="?customer=us">Review Agreement</ButtonLink>
        </Card>
      </CardsContainer>
    </Container>
  );
}

const Container = styled.section`
  margin: 16px 0 0;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
  align-items: flex-start;
  margin: 30px 0 0;
`;

const Card = styled.div`
  background: ${blackPearl};
  border-radius: 7px;
  padding: 23px 21px 16px 16px;
`;

const CardHeading = styled.h2`
  font-size: 22px;
  line-height: 27px;
  font-weight: 500;
  margin: 0 0 16px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  opacity: 0.7;
  margin: 0 0 8px;
`;

const ButtonLink = styled(Link)`
  display: block;
  margin: 20px 0 0 auto;
  color: white;
  background-color: #72adf2;
  font-size: 12px;
  padding: 6px 25px;
  width: fit-content;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
  outline: none;

  :hover {
    background-color: #72aef2da;
    text-decoration: none;
    color: inherit;
  }
  :active {
    background-color: #72adf2;
  }
  :focus {
    box-shadow: 0 0 0 1.5px white;
  }
`;
