const UserAgreement = () => (
  <>
    <h1>User Agreement</h1>
    <p>Last updated: 12 AUG 2021</p>
    <p>
      This USER AGREEMENT (this “Agreement”) sets forth the terms and conditions
      upon which the user set forth in the associated user application form
      (“User”) desires to utilize the services of Paradigm Connect, Inc.
      (“Paradigm”). Capitalized terms used but not defined in the text of this
      Agreement have the meanings given to such terms in Section 7.10.
    </p>
    <h4>Witnesseth:</h4>
    <p>
      WHEREAS, Paradigm has developed a communication platform that automates
      price negotiation and settlement workflows for OTC digital asset traders
      (the “System”) that User desires to, and Paradigm agrees to permit User
      to, and to authorize its Authorized Users to, access and use the System on
      the terms and conditions of this Agreement.
    </p>
    <p>
      NOW, THEREFORE, in consideration of the agreements and obligations set
      forth herein and for other good and valuable consideration, the receipt
      and sufficiency of which are hereby acknowledged, the undersigned User
      hereby agree as follows:
    </p>
    <h2>Agreement</h2>
    <h4>
      Article I<br />
      License grant and limitations
    </h4>
    <p>
      1.1 Grant of License. Subject to the terms and conditions of this
      Agreement, Paradigm hereby grants User a personal, limited, royalty-free,
      non-exclusive, revocable, non-transferable and non-sublicenseable right
      to, during the Term, access and use the System.
    </p>
    <p>1.2 Authorized Users.</p>
    <p>
      (a) User may allow one or more of its Representatives to access the System
      by providing such specified Representatives with a unique password and
      user identification combination (each, an “Access Method”) provided or
      otherwise specified by Paradigm (such users, “Authorized Users”). User is
      solely responsible for ensuring that only Authorized Users have access to
      the System through User or User’s Access Methods and that Authorized Users
      access the System using only the Access Method assigned to such user.
      Paradigm may terminate, revoke, suspend, modify, or change any or all
      Access Methods assigned to User at any time with or without prior notice.
      User shall, and shall cause each of its Authorized Users to, keep the
      Access Methods strictly confidential using at least the same degree of
      care that User uses to protect its own Confidential Information. User is
      solely responsible for ensuring the security of its and its Authorized
      Users’ connection to the System. If any Access Method assigned to User is
      lost, stolen or compromised, User agrees to notify Paradigm as soon as
      practicable.
    </p>
    <p>
      (b) At Paradigm’s request, User agrees to provide Paradigm with a complete
      list of User’s current Authorized Users and their countries of domicile.
      User acknowledges that, in Paradigm’s discretion, Paradigm may deny access
      to the System to any of User’s Authorized Users, at any time and for any
      reason. User will ensure all Authorized Users comply with all relevant
      terms of this Agreement and any failure to do so will constitute a breach
      by User. User is and will be solely responsible for all acts or omissions
      of its Authorized Users. Without limitation of the foregoing or any other
      provision of this Agreement, User agrees to be bound by the terms of all
      Message Data entered and orders placed on or through the System by its
      Authorized Users or using its Access Methods. All transmissions generated
      through use of its Access Methods will be deemed to be authorized by User
      and made by an Authorized User whether or not Paradigm acknowledges
      receipt of such transmission.
    </p>
    <p>
      1.3 Appropriate Use. User agrees to use the System and send and receive
      Message Data only for its own benefit and account(s), and not to use the
      System or any other software provided pursuant to this Agreement
      (“Software”) on behalf of third parties without Paradigm’s prior written
      consent. User agrees not to, and shall cause each of its Authorized Users
      not to, do any of the following without Paradigm’s prior written consent:
      (a) use the System or the Software for any purposes other than as set
      forth herein; (b) copy any portion of the System or Software; (c) resell
      or otherwise distribute any of its Access Methods to any other party
      (other than Authorized Users); (d) reverse engineer, decompile,
      disassemble or otherwise attempt to discern the source code, underlying
      ideas, algorithms, software design and architecture or interface protocols
      of the System or Software; (e) modify, adapt, translate or reproduce the
      System or Software, merge any part of the System or Software with or into
      other software, or create derivative works based upon the System or
      Software; (f) use the System or Software or any Message Data to operate in
      or as a time-sharing, outsourcing, or service bureau environment, or as an
      application service provider, or in any way allow others to use the
      System, Software or Message Data for the benefit of any third party; or
      (g) take or authorize any action that could detrimentally interfere with
      the proper workings of the System or Software, use any robot, spider or
      other device or process to monitor or copy the System or Software, or
      knowingly transmit any virus, malware or other potentially harmful device
      in connection with use of the System or Software.
    </p>
    <p>
      1.4 User Responsibilities. User will be solely responsible for any losses,
      damages or costs that may be incurred as a result of errors made by, or
      the failure of, or delays caused by, the software or equipment that is
      used by User or any of its Authorized Users to access the System or
      Software, whether or not such software or equipment, including but not
      limited to any and all telecommunications circuits or other services, is
      provided by Paradigm, any Affiliate of Paradigm, its, theirs or other
      third party licensors or service providers. User’s use of the System and
      Software is at the User’s own discretion and risk and User is solely
      responsible for any damage, servicing, repair or corrections to its
      computer systems or other devices, as well as any interruptions in
      business operations or loss of data that results from such use or
      inability to use the System or Software.
    </p>
    <p>
      1.5 Market Data; Product Annexes; Additional Terms of Use. Market data,
      product-specific annexes or additional terms for use of the System or
      Software may be set forth in a separate supplement to this Agreement or on
      the System user interface, or otherwise made available to User pursuant to
      Section 1.12, and User agrees to be bound by such additional terms.
    </p>
    <p>
      1.6 Applications. Paradigm may, from time to time, make available
      applications created by or on behalf of third parties, which can be used
      on or with the System (the “Applications”). User acknowledges and agrees
      that the Applications are provided entirely “as-is” and that Paradigm
      makes no representations or warranties or assumes any liability regarding
      any such Applications that User may choose to purchase, download or
      otherwise access and User further agrees to comply with any additional
      restrictions on usage of such Applications that Paradigm or such third
      party may communicate to User from time to time, including any such
      restrictions that may be contained in any license agreement by and between
      User and such third party licensors.
    </p>
    <p>
      1.7 Limited Remote Support. Paradigm agrees to provide reasonable
      technical support at the times and in the manner set forth on Paradigm’s
      website.
    </p>
    <p>
      1.8 No Warranty. Paradigm does not warrant or guarantee that the System or
      Software will be uninterrupted or error-free or that the System will meet
      the requirements of User or any Authorized User.
    </p>
    <p>
      1.9 No Brokerage Relationship with Paradigm; No Responsibility for Message
      Data. User acknowledges and agrees that: (a) Paradigm is not now, and
      shall not be by virtue of this Agreement, a party to, the executing
      broker-dealer for or exchange for, any orders or transactions that result
      from Message Data that User sends through the System; (b) although the
      System may transmit the Message Data that User sends through the System,
      Paradigm is not accepting any orders for execution and is not responsible
      for the execution of any orders; (c) Paradigm is not responsible for any
      party to any transaction resulting from the transmission of Message Date;
      (d) Paradigm is not providing any advice (including investment related
      advice or the advisability of trading) to User or any Authorized User and
      (e) Paradigm has no obligation to accept or transmit all or any part of
      any Message Data that is sent through the System. Without limitation of
      the foregoing, Paradigm has no responsibility for transmissions that are
      inaccurate, are not received or are delayed by the System. For the
      avoidance of doubt, User is not a brokerage customer of Paradigm.
    </p>
    <p>
      1.10 Title to System. All right, title and interest in and to the System
      and Software, including the concepts, specifications, interfaces and
      technology and other Intellectual Property inherent in the System and
      Software, are, and at all times shall remain, the sole and exclusive
      property of Paradigm or an Affiliate of Paradigm. Except for the limited
      right to use the System and Software, as set forth herein, nothing
      contained in this Agreement shall directly or indirectly be construed to
      assign or grant to User or any Authorized Users any right, title or
      interest in and to the Intellectual Property of Paradigm or any Affiliate
      of Paradigm or any ownership rights in or to the System and Software. User
      agrees not to remove, obscure, or alter any proprietary rights notices
      that may be affixed to or contained within the System, Software, or any
      software or documentation provided in connection with this Agreement. User
      agrees to protect and not violate any proprietary rights of Paradigm or
      any Affiliate of Paradigm or those of its third party licensors or service
      providers and to honor and comply with Paradigm’s reasonable requests to
      protect its, and any of Paradigm’s Affiliates’ and third party licensors’
      and service providers’ contractual, statutory and common law rights in the
      System, Software, and any related software and documentation. If User
      becomes aware of any violation of the proprietary rights of Paradigm, any
      Affiliate of Paradigm, any third party licensors or service providers in
      the System or Software, User agrees to promptly notify Paradigm in
      writing.
    </p>
    <p>
      1.11 Export Control. The System and Software may be subject to the export
      control laws of the United States and other countries. In particular, but
      without limitation, the System may not be utilized by, and Software may
      not be exported, re-exported or provided to, (a) any U.S. embargoed
      countries or (b) anyone on the U.S. Treasury Department’s list of
      Specially Designated Nationals or the U.S. Department of Commerce Denied
      Person’s List or Entity List. User agrees to comply with all applicable
      export control laws, and User represents, warrants and covenants that none
      of User nor any of its Authorized Users is, or will be at any time during
      the Term, located in any restricted country or appear on any denied
      persons list.
    </p>
    <p>
      1.12 Electronic Communications. User hereby consents to the delivery of
      any required or optional communication under this Agreement or under any
      applicable law, including changes in the terms and conditions of this
      Agreement necessitated by regulatory or compliance with information
      security requirements (as provided below), via the System, by e-mail,
      website or other electronic means, subject to compliance with any
      applicable Law. Any such documents that are delivered electronically are
      deemed to be “in writing.” If a signature or acknowledgment is required or
      requested with respect to any such document and any Authorized User
      “clicks” in the appropriate space, or takes such other action as may be
      indicated on the System, User will be deemed to have signed or
      acknowledged the document to the same extent and with the same effect as
      if User had signed the document manually. User acknowledges that it has
      the right to withdraw its consent to the electronic delivery and signature
      of documents at any time by providing prior written notice to Paradigm.
      However, if User revokes its consent, its access to the System may be
      restricted or terminated. User acknowledges that Paradigm may, if required
      for regulatory reasons or to comply with information security
      requirements, modify any of the terms set forth in this Agreement via any
      of the notification methods set forth above. If User does not consent to
      any such modifications, User’s sole right will be to terminate this
      Agreement in accordance with Section 3.
    </p>
    <p>
      1.13 Regulatory Cooperation. User agrees to cooperate with any reasonable
      request that Paradigm may make in order to respond to any inquiries made
      by any exchanges or Governmental Authorities in connection with the System
      or User’s or an Authorized User’s use of the System.
    </p>
    <h4>
      Article II
      <br />
      Fees and Expenses
    </h4>
    <p>
      2.1 Fees and Expenses. User is responsible for, and agrees to pay, any and
      all fees, costs and expenses associated with or that arise from
      entitlement to use the System, including the execution and settlement of
      transactions (for example, commissions and other fees payable to
      broker-dealers, exchanges, data-providers or other Persons in connection
      with the execution of transactions, telecommunications, market data,
      hardware, and other connectivity costs, and costs of any third party
      software, equipment and any related maintenance services). Unless
      otherwise directed by Paradigm, User will pay such fees, costs and
      expenses to or on behalf of Paradigm in accordance with Paradigm’s payment
      instructions and Paradigm’s payment cycle.
    </p>
    <h4>
      Article III
      <br />
      Term and Termination
    </h4>
    <p>
      3.1 Term. This Agreement shall be effective as of the date User or its
      representative accepts this Agreement and will continue in effect until
      terminated in accordance with this Article 3 (the “Term”).
    </p>
    <p>
      3.2 Mutual Termination Rights. Either party may terminate this Agreement
      at any time, with or without cause, upon thirty (30) days’ written notice
      to the other party.
    </p>
    <p>
      3.3 Paradigm Termination or Suspension Rights. Regardless of any other
      provision of the Agreement, Paradigm has the right (a) to immediately
      suspend for any period of time as Paradigm believes is necessary or
      advisable or terminate (at any time, for any reason, with or without cause
      or prior notice) all or any part of the System or User’s or any or all
      Authorized Users’ access to the System or any component thereof, (b) to
      change the nature, composition or availability of the System or any
      component thereof, or (c) to change any controls, limits or parameters
      applicable to any Message Data that may be transmitted through the System.
      Paradigm shall use commercially reasonable efforts to promptly notify User
      of any such suspension, termination or change.
    </p>
    <p>
      3.4 Effect of Termination. In the event of termination of this Agreement
      for any reason whatsoever, User shall, and shall cause each of its
      Authorized Users to, cease any use of the System and to delete or destroy
      any copies of the Software and any Confidential Information of Paradigm in
      its or their possession. Notwithstanding any such termination, this
      Agreement will remain in effect with respect to Message Data transmitted
      by User or any Authorized User through the System prior to the time of the
      effective notice of termination. Termination of this Agreement shall not
      relieve either party of liability for any act, omission, occurrence or
      liability occurring prior to the expiration or termination of this
      Agreement. Sections 1.4, 1.8, 1.10, 1.13, 3.4 and 5.2 and Articles IV, VI
      and VII shall survive expiration or termination of this Agreement for any
      reason.
    </p>
    <h4>
      Article IV
      <br />
      Confidentiality
    </h4>
    <p>
      4.1 Non-Disclosure. Subject to the remainder of this Article IV, neither
      party shall disclose or make, in either case, publicly available, and
      shall cause its Representatives to not disclose or make, in either case,
      publicly available, any Confidential Information of the other party
      furnished or made available pursuant to this Agreement. In any case, each
      party shall, and shall cause its Representatives to, exercise the same
      standard of care to protect such information as it uses to protect its own
      proprietary data, which in no event shall be less than reasonable care and
      further agrees to take reasonable precautions (including at least those
      measures that it takes to protect its own Confidential Information) to
      protect the secrecy of and prevent the unauthorized use of Confidential
      Information.
    </p>
    <p>
      4.2 Exceptions from Confidentiality. Notwithstanding anything to the
      contrary in this Agreement, each party may disclose, without any liability
      hereunder, that portion (and only that portion) of the Confidential
      Information (a) that is requested or required by any Governmental
      Authority having jurisdiction over the receiving party or its
      Representatives, or that the receiving party deems it advisable to provide
      to such a Governmental Authority, whether in connection with an audit,
      examination or otherwise, (b) that the receiving party reasonably
      determines to be necessary or appropriate to disclose to a Governmental
      Authority or (c) that is reasonably necessary to defend itself against any
      claim or proceeding brought against the receiving party or its
      Representatives.
    </p>
    <p>4.3 Additional Exceptions from Confidentiality.</p>
    <p>
      (a) In addition to the provisions of Section 4.2, but subject to Section
      4.3(b), a party may disclose Confidential Information of the other party
      (i) if and to the extent such Confidential Information is required by
      subpoena, court order or similar process to be disclosed and/or (ii) to
      the extent such disclosure is otherwise required by applicable Law or
      requirement of any stock exchange on which the shares or stock of the
      receiving party or an Affiliate thereof are listed, in which case the
      receiving party will provide the disclosing party with prompt written
      notice of such disclosure.
    </p>
    <p>
      (b) If the receiving party is requested or required (by subpoena, court
      order or similar process) to disclose any Confidential Information of the
      disclosing party as provided in Section 4.3(a), the receiving party shall
      (i) provide the disclosing party with prompt written notice of such
      request or requirement and (ii) make reasonable efforts to cooperate with
      the disclosing party so that the disclosing party may seek a protective
      order or other appropriate remedy or protection. If such protective order
      or other remedy or protection is not obtained, or the disclosing party
      waives compliance with the provisions of this Agreement, the receiving
      party shall use reasonable efforts to disclose only that portion of the
      Confidential Information that is legally requested or required to be
      disclosed.
    </p>
    <p>
      4.4 Return or Destruction of Confidential Information Upon Termination.
      Upon termination of this Agreement (or earlier at the request of
      Paradigm), User shall promptly return or destroy, at User’s sole
      discretion, all Confidential Information of Paradigm and any copies of
      documents, papers or other material which may contain or be derived from
      any Confidential Information of Paradigm that is in its possession. At the
      request of Paradigm, User shall certify that it has satisfied its
      obligations under this Section 4.4.
    </p>
    <p>
      4.5 Message Data. Notwithstanding anything to the contrary in this
      Agreement, Paradigm may use and provide its Affiliates with Message Data
      in aggregated/anonymous form for any purpose, including for purposes of
      advertising trading volume or other statistical information, or in various
      marketing materials and publications. Paradigm and its Affiliates may, in
      Paradigm’s sole discretion, resell, distribute, market or license any or
      all aggregated/anonymous Message Data to any other Person (other than its
      Representatives), or otherwise use any or all such data as Paradigm or its
      Affiliates see fit and retain all compensation related thereto; provided,
      that neither Paradigm nor its Affiliates shall identify any User or
      Authorized User in connection with any Message Data, except: (a) as
      required pursuant to applicable Law; (b) for dispute resolution purposes
      with User or any other Person; or (c) to legal or accounting advisors on a
      confidential basis.
    </p>
    <h4>
      Article V<br />
      Representations, Warranties and Covenants
    </h4>
    <p>
      5.1 Representations, Warranties and Covenants. User hereby represents and
      warrants to Paradigm, as of the Effective Date, and continuously
      throughout the Term, that:
    </p>
    <p>
      (a) User has the legal capacity and authority to enter into this
      Agreement, to use the System and to transmit or receive Message Data
      through the System. The execution, delivery and performance of this
      Agreement have been duly approved and authorized by all necessary action
      of User. This Agreement constitutes the legal, valid and binding
      obligation of User, enforceable against such party in accordance with its
      terms, except as enforcement thereof may be limited by bankruptcy,
      insolvency, reorganization, moratorium, receivership, conservatorship, and
      other laws relating to or affecting creditors’ rights generally and by
      general principles of equity.
    </p>
    <p>
      (b) User’s execution and delivery of this Agreement and its performance of
      this Agreement will not (i) conflict with, violate or result in any breach
      of any of the terms and provisions of, or constitute (with or without
      notice or lapse of time or both) a default under, any material indenture,
      contract, agreement, mortgage, deed of trust, or other instrument to which
      User is a party or by which it or any of its properties are bound; (ii)
      violate User’s charter or bylaws or any other equivalent organizational
      document; (iii) require any consent of approval under any judgment, order,
      memorandum of understanding, writ, decree, permit or license to which User
      is a party or by which its assets are bound; or (iv) require the consent
      or approval of any other party to any material contract, instrument, or
      commitment to which User is a party or by which it is bound.
    </p>
    <p>
      (c) User’s use of the System complies and will comply with (i) all
      applicable Laws; (ii) the policies and practices of any securities and
      futures exchanges and clearing houses, alternative trading facilities, and
      self-regulatory organizations applicable to User and to Message Data
      entered by User or its Authorized Users on or through the System, and
      (iii) this Agreement and any other agreement (including any product annex)
      between User and Paradigm or any of its Affiliates, as may be amended from
      time to time. User has all consents, rights, authority and has taken all
      actions necessary, to use the System and to transmit or receive Message
      Data through the System, as set forth herein.
    </p>
    <p>
      5.2 Disclaimer of Warranties. THE SYSTEM AND SOFTWARE ARE PROVIDED ON AN
      “AS IS” AND “AS AVAILABLE” BASIS. PARADIGM MAKES NO WARRANTIES REGARDING
      THE CORRECTNESS, QUALITY, ACCURACY, SECURITY, COMPLETENESS, RELIABILITY,
      PERFORMANCE, TIMELINESS, PRICING OR CONTINUED AVAILABILITY OF THE SYSTEM,
      SOFTWARE, OR FOR THE FAILURE OF ANY CONNECTION OR COMMUNICATION SERVICE TO
      PROVIDE OR MAINTAIN ACCESS TO THE SYSTEM, OR FOR ANY INTERRUPTION OR
      DISRUPTION OF ACCESS TO THE SYSTEM OR SOFTWARE. PARADIGM SPECIFICALLY
      DISCLAIMS ALL WARRANTIES FOR THE SYSTEM AND SOFTWARE, EXPRESS OR IMPLIED,
      INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
      PARTICULAR PURPOSE, OPERATION OF THE SYSTEM AND SOFTWARE AND ANY
      PARTICULAR APPLICATION OR USE OF THE SYSTEM AND SOFTWARE. NEITHER PARADIGM
      NOR ANY OF ITS AFFILIATES WARRANTS THAT THE SYSTEM OR SOFTWARE WILL MEET
      USER’S OR ITS AUTHORIZED USERS’ REQUIREMENTS, OR THAT THE OPERATION OF THE
      SYSTEM AND SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS
      IN THE FOREGOING CAN OR WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION
      OR ADVICE GIVEN BY PARADIGM OR ITS AFFILIATES SHALL CREATE ANY WARRANTIES
      OR IN ANY WAY INCREASE THE SCOPE OF PARADIGM’S OBLIGATIONS HEREUNDER.
    </p>
    <h4>
      Article VI
      <br />
      Indemnification and Limitation of Liability
    </h4>
    <p>6.1 Indemnification.</p>
    <p>
      (a) User hereby agrees, to the maximum extent permitted under applicable
      Law, to indemnify and hold harmless Paradigm and its Representatives and
      its and their respective successors and permitted assigns (collectively,
      the “Paradigm Indemnified Parties”) from and against any Losses of any
      Paradigm Indemnified Party arising out of or related to: (i) the use of
      the System and Software by User or the Authorized Users, including any
      breaches of the security of the System; (ii) if User or any of its
      Affiliates is a broker-dealer, investment manager or investment adviser
      acting on behalf of customers, any claims that a trade was not suitable
      for or not authorized by a customer of such broker-dealer, investment
      manager or investment adviser; (iii) any breach by User of this Agreement,
      including the breach of any representation or warranty or failure by User
      to perform any covenant of User under this Agreement; (iv) any other acts
      or omissions of User or any of its Representatives or the Authorized
      Users; or (v) any violation of or noncompliance with applicable Law by
      User or any Authorized User. Paradigm shall notify User reasonably
      promptly in writing of any such claim or suit; provided, that the failure
      to so notify User shall not limit User’s indemnification obligations under
      this Section 6.1(a), unless User is materially adversely prejudiced
      thereby. Paradigm shall be entitled to defend any such claim or suit as it
      determines in its sole discretion. User agrees to provide Paradigm with
      full information and assistance (including of or relating to its
      Authorized Users) in settling and/or defending such claim or suit.
    </p>
    <p>
      (b) EXCEPT IN THE CASE OF A BREACH OF A PARTY’S OBLIGATIONS OF
      CONFIDENTIALITY IN ARTICLE IV OR USER’S OBLIGATIONS PURSUANT TO SECTION
      6.1(A), THE PARTIES EXPRESSLY AGREE THAT IN NO EVENT SHALL PARADIGM BE
      LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES
      ARISING FROM OR RELATED IN ANY WAY TO THIS AGREEMENT, THE SYSTEM OR THE
      SOFTWARE, INCLUDING BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE, OR
      ANY OTHER LEGAL THEORY, WHETHER IN TORT OR CONTRACT, EVEN IF A PARTY IS
      AWARE OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING, INCLUDING COMPENSATION,
      REIMBURSEMENT OR DAMAGES ON ACCOUNT OF THE LOSS OF PRESENT OR PROSPECTIVE
      PROFITS, EXPENDITURES, TRADING LOSSES, INVESTMENTS OR COMMITMENTS, WHETHER
      MADE IN THE ESTABLISHMENT, DEVELOPMENT OR MAINTENANCE OF BUSINESS
      REPUTATION OR GOODWILL, FOR LOSS OF DATA, OR FOR ANY OTHER REASON
      WHATSOEVER. NEITHER PARADIGM NOR ANY OF THE PARADIGM INDEMNIFIED PARTIES
      SHALL BE RESPONSIBLE FOR ANY LOSSES RESULTING FROM ALTERATION OR
      UNAUTHORIZED USE OF THE SYSTEM OR THE SOFTWARE, OR FROM THE UNINTENDED AND
      UNFORESEEN RESULTS OBTAINED BY USER OR ANY AUTHORIZED USERS RESULTING FROM
      SUCH USE. TERMINATION OF THIS AGREEMENT PURSUANT TO SECTION 3 SHALL NOT
      RESULT IN ANY LIABILITY OF PARADIGM OR ANY OF THE PARADIGM INDEMNIFIED
      PARTIES TO USER OR ANY AUTHORIZED USER. EXCEPT IN THE CASE OF A BREACH OF
      A PARTY’S OBLIGATIONS OF CONFIDENTIALITY UNDER ARTICLE IV OR USER’S
      OBLIGATIONS PURSUANT TO SECTION 6.1(A), EACH PARTY’S TOTAL, AGGREGATE
      LIABILITY TO THE OTHER PARTY SHALL NOT EXCEED $10,000 FOR ALL DAMAGES OF
      EVERY KIND AND TYPE (WHETHER IN CONTRACT OR TORT).
    </p>
    <p>
      6.2 User Acknowledgments. User and its Authorized Users are solely
      responsible for any losses, damages or costs that may be incurred as a
      result of errors made by, or the failure of, or delays caused by, the
      software or equipment used by User or its Authorized Users, as applicable,
      to connect to the System. User acknowledges that its and each of its
      Authorized Users’ connection to the System is at its and their discretion
      and risk, and User or its Authorized Users, as applicable, shall be solely
      responsible for any damage, servicing, repair or corrections to its
      computer systems or other devices, as well as any interruptions in
      business operations or loss of data that results from such connection to
      or inability to connect to the System. User acknowledges and agrees that
      Paradigm shall have no responsibility for transmissions that are
      inaccurate, not received or are delayed by the System or by User’s or its
      Authorized Users’ connection to the System, and may transmit any message
      on the terms actually received by the System. Additionally, Paradigm shall
      have no liability or responsibility for any regulatory or other reporting
      or record-keeping requirements of User arising in connection with any
      order or instruction transmitted to or by User or any Authorized User.
    </p>
    <p>
      6.3 Third Party Systems. Message Data transmitted by User or Authorized
      Users through the System will be transmitted through or routed to third
      party systems (each, a “Third Party System”). None of Paradigm or any
      Paradigm Indemnified Party will be responsible for any Losses that may
      result from errors made by any Third Party System in reading, processing,
      responding to or executing such Message Data, or if any Third Party System
      otherwise fails to properly read, process, respond to or execute the
      transactions described in such Message Data.
    </p>
    <h4>
      Article VII
      <br />
      Miscellaneous
    </h4>
    <p>7.1 Governing Law; Dispute Resolution.</p>
    <p>
      (a) This Agreement, and all matters or disputes arising out of or in
      connection with this Agreement, the subject matter hereof or the
      activities of the parties in connection with or contemplated by this
      Agreement shall be governed by, construed under and enforced in accordance
      with the laws of the State of New York.
    </p>
    <p>
      (b) Each of the parties hereby irrevocably submits to the exclusive
      jurisdiction of the federal courts located in the State of New York and
      courts of competent appellate jurisdiction therefrom (or if jurisdiction
      is not available therein, in the courts of the State of New York located
      in New York City, New York) solely in respect of this Agreement and the
      transactions contemplated by this Agreement, and hereby waives and agrees
      not to assert as a defense in any action, suit or proceeding, that it is
      not subject thereto or that such action, suit or proceeding may not be
      brought or is not maintainable in said courts or that the venue thereof
      may not be appropriate or that this Agreement may not be enforced in or by
      such courts. Each of the parties hereby consents to and grants any such
      court jurisdiction over the person of such parties and over the subject
      matter of such dispute and agrees that mailing of process or other papers
      in connection with any such action or proceeding in the manner provided in
      Section 7.2 or in such other manner as may be permitted by applicable Law
      shall be valid and sufficient service thereof. EACH OF THE PARTIES HEREBY
      IRREVOCABLY WAIVES ALL RIGHT TO TRIAL BY JURY IN ANY ACTION, PROCEEDING OR
      COUNTERCLAIM (WHETHER BASED ON CONTRACT, TORT OR OTHERWISE) ARISING OUT OF
      OR RELATING TO THIS AGREEMENT OR ANY OF THE TRANSACTIONS CONTEMPLATED
      HEREIN OR THE ACTIONS OF THE PARTIES HERETO IN THE NEGOTIATION,
      ADMINISTRATION, PERFORMANCE AND ENFORCEMENT OF THIS AGREEMENT.
    </p>
    <p>
      7.2 Notices. Except as provided in Section 1.12, all notices and demands
      pertaining to this Agreement shall be in writing and may be given in any
      manner described below to the address or number set out below, and will be
      deemed effective as follows: (a) if delivered in person or by overnight
      courier service, on the date it is delivered; (b) if sent by U.S. mail,
      six (6) Business Days after the notice is posted to the appropriate
      address (with return receipt requested); and (c) if sent via electronic
      mail to the correct electronic mail address; unless the date that delivery
      would be deemed effective is not a working day or the communication is
      given after the close of business on a working day in the place where the
      notice is delivered, in which case that communication will be deemed given
      and effective the first following day that is a working day. The initial
      addresses and contact details of the parties are as follows:
    </p>
    <p>Paradigm:</p>
    <p>
      Paradigm Connect, Inc.
      <br />
      110 Wall Street, Suite 5043
      <br />
      New York, New York 20001
      <br />
      Attn: Anand Gomes
    </p>
    <p>User: As set forth in User’s user agreement with Paradigm</p>
    <p>
      Each party may change its address or contact details from time to time by
      giving notice to that effect as provided in this Agreement.
    </p>
    <p>
      7.3 Waiver. Except as otherwise expressly provided herein, no delay,
      failure or waiver by either party to exercise any right or remedy under
      this Agreement and no partial or single exercise of any such right or
      remedy, will operate to limit, preclude, cancel, waive or otherwise affect
      such right or remedy, nor will any single or partial exercise of such
      right or remedy limit, preclude, impair or waive any further exercise of
      such right or remedy or the exercise of any other right or remedy. For
      purposes of this Agreement, no course of dealing among any or all of the
      parties hereto shall operate as a waiver of the rights hereof. Except as
      otherwise expressly provided herein, the rights and remedies herein
      provided shall be cumulative and not exclusive of any rights or remedies
      provided by applicable Law. No provision hereof may be waived otherwise
      than by a written instrument signed by the party so waiving such provision
      as contemplated herein.
    </p>
    <p>
      7.4 Entire Agreement and Modification. This Agreement constitutes the
      entire agreement between the parties with respect to the matters
      contemplated hereby and supersedes all prior and contemporaneous oral or
      written agreements or understandings of the parties. Except as otherwise
      provided in this Agreement, all subsequent changes and modifications, to
      be valid, shall be by written instrument executed by each party.
    </p>
    <p>
      7.5 Assignment; Successors. The provisions of this Agreement shall be
      binding upon and inure to the benefit of the parties and their successors
      and permitted assigns. User may not and shall not assign this Agreement or
      any of its rights hereunder without the prior written consent of Paradigm.
      Paradigm may assign this Agreement or any of its rights hereunder without
      the consent of User to any successor in interest of Paradigm. Any
      purported assignment or delegation made in violation of this Section 7.5
      shall be void and of no force or effect.
    </p>
    <p>
      7.6 No Third Party Rights. This Agreement shall be binding upon and inure
      solely to the benefit of the parties hereto, their successors and
      permitted assigns, and nothing in this Agreement, express or implied,
      other than Article VI (in the case of indemnitees), is intended to or
      shall confer upon any other Person any legal or equitable right, benefit
      or remedy of any nature whatsoever.
    </p>
    <p>
      7.7 Severability. If any provision of this Agreement is held to be
      illegal, invalid, or unenforceable under any present or future applicable
      Laws, such provision will be fully severable; this Agreement will be
      construed and enforced as if such illegal, invalid, or unenforceable
      provision had never comprised a part of this Agreement; and the remaining
      provisions of this Agreement will remain in full force and effect and will
      not be affected by the illegal, invalid, or unenforceable provision or by
      its severance from this Agreement.
    </p>
    <p>
      7.8 Expenses. Except as otherwise provided herein, each party hereto shall
      bear its own expenses incident to the preparation, negotiation, execution
      and delivery of this Agreement and the performance of its obligations
      hereunder or thereunder.
    </p>
    <p>
      7.9 Counterparts; Interpretation. This Agreement may be executed in one or
      more counterparts, each of which will be deemed to be an original copy of
      this Agreement and all of which, when taken together, will be deemed to
      constitute one and the same agreement. In this Agreement, unless the
      context otherwise requires: (a) reference herein to the singular shall
      include the plural and vice versa and reference to any gender shall
      include all genders; (b) the terms “Article”, “Section”, and “Exhibit”
      followed by a number, letter, or combination of numbers and letters refer,
      unless expressly contemplated otherwise, to the specified article or
      section of, or exhibit to, this Agreement, as amended, restated or
      replaced from time to time; reference herein to another agreement shall be
      construed as a reference to such other agreement as the same may have
      been, or may from time to time be, amended, supplemented or novated; the
      division of this Agreement into Articles, Sections, Schedules and
      Exhibits, the provision of a table of contents and the use of headings are
      for convenience of reference only and shall not affect the construction or
      interpretation of this Agreement; (c) use of the words “includes” and
      “including” and similar terms of inclusion will not, unless expressly
      modified by the words “only” or “solely”, be construed as terms of
      limitation, but rather will mean “includes but is not limited to” and
      “including but not limited to”, such that references to included matters
      will be regarded as illustrative and not defining or exhaustive; and (d)
      if an ambiguity or question of intent or interpretation arises as to any
      aspect of this Agreement, then it will be construed as if drafted jointly
      by the parties and no presumption or burden of proof will arise favoring
      or disfavoring either party by virtue of the authorship of any provision
      of this Agreement.
    </p>
    <p>
      7.10 Definitions. Capitalized terms used but not defined in the body of
      this Agreement have the following meanings:
    </p>
    <p>
      “Affiliate” means, with respect to a Person, any other Person, directly or
      indirectly, controlling, controlled by, or under common control with, such
      Person.
    </p>
    <p>
      “Business Day” means any day which is not a Saturday, Sunday or any public
      or legal holiday in the United States on which banks in New York State are
      authorized or required to close for retail banking operations.
    </p>
    <p>
      “Confidential Information” means non-public, proprietary or confidential
      information that is labeled as such or should reasonably be understood by
      the recipient to be confidential; provided, that Confidential Information
      shall not include any information that is (a) generally known to the
      industry or the public other than as a result of the recipient’s breach of
      this Agreement, (b) made legitimately available to a recipient by a third
      party without breach of any confidentiality obligation or (c) developed by
      such recipient without use of Confidential Information.
    </p>
    <p>
      “Governmental Authority” means any United States or foreign regulatory,
      self-regulatory, judicial, arbitral, or governmental (whether national,
      state or municipal) or quasi-governmental authority, or any agency,
      department, instrumentality, or sub-division thereof; or self-regulatory
      organization; or other Person exercising, or entitled to exercise, any
      administrative, executive, judicial, legislative, police, regulatory or
      taxing authority or power.
    </p>
    <p>
      “Intellectual Property” means all intellectual property rights,
      comprising, arising from or associated with the following: (a) Internet
      domain names; (b) trademarks, service marks, certification marks, logos,
      symbols and any other indicia of origin and any registrations or
      applications therefor, together with any goodwill symbolized by the
      foregoing; (c) patents and patent applications, inventions and discoveries
      (whether or not patentable); (d) works of authorship and copyrights
      (including rights in computer software) whether registered or unregistered
      and any applications therefor; (e) trade secret rights in information and
      know-how, including trade secret rights in any formula, pattern,
      compilation, program, device, method, technique, or process, that (i)
      derives independent economic value, actual or potential, from not being
      generally known to, and not being readily ascertainable by proper means
      by, other Persons who can obtain economic value from its disclosure or
      use, and (ii) is the subject of efforts that are reasonable under the
      circumstances to maintain its secrecy; and (f) all other intellectual
      property or proprietary rights that may be protectable under applicable
      Law (including with respect to data and databases).
    </p>
    <p>
      “Law” means any federal, state, county, local or foreign statute, law,
      ordinance, regulation, rule, constitution, treaty, code, order or rule of
      common law.
    </p>
    <p>
      “Losses” means any and all losses, penalties, fines, costs, damages (and
      any interest due thereon), liabilities, amounts paid in settlements and
      offsets and any reasonable out-of-pocket costs, expenses and attorneys’
      fees and penalties and interest.
    </p>
    <p>
      “Message Data” means instructions or messages entered into, transmitted
      through or made available on the System (including pre-trade, post-trade
      and other message related data).
    </p>
    <p>
      “Person” means any individual, partnership, limited partnership, foreign
      limited partnership, corporation, limited liability company, trust,
      estate, Governmental Authority, association, joint venture, pool,
      syndicate, sole proprietorship, joint stock company, unincorporated
      organization, custodian, trustee, executor, administrator, nominee and any
      other form of entity in its own or representative capacity.
    </p>
    <p>
      “System” means Paradigm’s communication platform that automates price
      negotiation and settlement workflows for OTC digital asset traders.
    </p>
    <p>
      “Representatives” means, with respect to either party, its Affiliates, and
      its and their managers, officers, directors, employees, consultants,
      independent contractors and agents.
    </p>
  </>
);

export default UserAgreement;
