import { selectIsAttemptingSessionReuse } from '@paradigm/store/auth';

export const selectIsInternetOnline = (state) => state.app.isInternetOnline;
export const selectIsCommunicationDown = (state) =>
  state.app.isCommunicationDown;

export const selectIsAppOffline = (state) =>
  !selectIsInternetOnline(state) || selectIsCommunicationDown(state);

export const selectIsAppRecovering = (state) => state.app.isRecovering;

export const selectIsLoadingInitialData = (state) =>
  state.app.isLoadingInitialData;

export const selectIsAppLoading = (state) =>
  state.electron.available ||
  selectIsAttemptingSessionReuse(state) ||
  selectIsAppRecovering(state) ||
  selectIsLoadingInitialData(state);
