import { AuthUser } from '@paradigm/store/src/auth/types';
import Active from '#/components/user-preferences/components/two-factor-auth/Active';
import NotActive from '#/components/user-preferences/components/two-factor-auth/NotActive';
import { SettingsView } from '#/components/settings-modal/SettingsModal';

interface Props {
  user: AuthUser;
  setView: (view: SettingsView) => void;
}
export interface ErrorResp {
  response: {
    data: {
      non_field_errors: string;
    };
  };
}

const UserTwoFactorAuth = (props: Props) => {
  const { setView } = props;

  if (props.user.has_totp_enabled) {
    return <Active setView={setView} />;
  }
  return <NotActive setView={setView} />;
};

export default UserTwoFactorAuth;
