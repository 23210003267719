import createPapiWebSocket from '@paradigm/api/src/internal/createPapiWebSocket';
import { ApiWebSocket } from '@paradigm/api/src/internal/createWebSocket';
import { availableToken } from '@paradigm/api/src/internal/token';

import { DrfqNotification } from '#/unified-rfqs/repositories/drfq-ws-types';
import { createDrfqNotificationProcessor } from '#/unified-rfqs/repositories/adapters/drfq-ws';
import {
  processDrfq,
  processDrfqOrder,
  processDrfqTrade,
  processDrfqTradeTape,
  processDrfqWsBbo,
} from '#/unified-rfqs/repositories/adapters/drfq-api';

export type DrfqWebSocket = ApiWebSocket<DrfqNotification>;

export function createDrfqWebSocket(): DrfqWebSocket {
  return createPapiWebSocket({
    urlProvider,
    processNotification,
  });
}

async function urlProvider() {
  const token = await availableToken();
  return (
    `${PAPI_URL_WS}/v2/drfq/` +
    '?cancel-on-disconnect=0' +
    `&token=${encodeURIComponent(token)}`
  );
}

const processNotification = createDrfqNotificationProcessor({
  rfqs: processDrfq,
  rfq_orders: (raw) => raw,
  orders: processDrfqOrder,
  trades: processDrfqTrade,
  trade_tape: processDrfqTradeTape,
  bbo: processDrfqWsBbo,
  mmp: (raw) => raw,
  unknown: (raw) => raw,
});
