/* eslint-disable @typescript-eslint/no-misused-promises */
import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
// the lib currently has no exported types, prevent TS error
// @ts-expect-error
import { qrcode } from 'bwip-js';

import { UserTwoFACodeEnable, UserGenereteBarCode } from '@paradigm/api/auth';
import { getUserInfoRequestAction } from '@paradigm/store/auth';

import { renderInputField } from '#/utils/renderFormFields';
import { renderError } from '#/utils/renderErrors';
import { SettingsView } from '#/components/settings-modal/SettingsModal';
import { ErrorResp } from '#/components/user-preferences/components/two-factor-auth';

interface Props {
  setView: (view: SettingsView) => void;
  getUserUserInfo: (payload?: unknown) => unknown;
}

interface State {
  error: null | string;
}
class NotActive extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  override componentDidMount() {
    UserGenereteBarCode()
      .then((res) => {
        const { config_url } = res.data as { config_url: string };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        qrcode('myBarCodeCanvas', {
          text: config_url, // Text to encode
          scale: 3, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: 'center', // Always good to set this
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error', error));
  }
  handleSubmit(values: { totp_token: string }) {
    if (values.totp_token.length !== 6) {
      this.setState({
        error: 'Ensure this field has 6 characters.',
      });
    } else {
      UserTwoFACodeEnable(values)
        .then(() => {
          this.props.getUserUserInfo();
          this.props.setView('Backup Codes');
        })
        .catch((res: ErrorResp) => {
          this.setState({
            error: res.response.data.non_field_errors,
          });
        });
    }
  }
  override render() {
    const { error } = this.state;
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          Turns on 2FA for an added layer of security on your Paradigm account.
          Once configured, you’ll be required to enter a code created by the
          Google Authenticator app.
        </label>
        <div className="divider-totp flex-shrink-0" />
        <p className="mb-2">
          <span className="opacity-7 mr-2 tiny-font">Step 1</span>Get the app
        </p>
        <p className="smaller-font">
          Download and install the
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 mr-1"
          >
            {' '}
            Google Authenticator
          </a>{' '}
          app
        </p>
        <div className="divider-totp flex-shrink-0" />
        <div className="d-flex" style={{ maxHeight: '130px' }}>
          <div style={{ width: '55%', lineHeight: '17px' }}>
            <p className="mb-2">
              <span className="opacity-7 mr-2 tiny-font">Step 2</span>Scan this
              barcode
            </p>
            <p className="smaller-font mb-1">
              Open the authentication app and:
            </p>
            <p className="smaller-font m-0">
              - Tap the “+” icon in the top-right of the app
            </p>
            <p className="smaller-font m-0">
              - Scan the image to the right, using your phone’s camera
            </p>
          </div>
          <div style={{ width: '45%' }} className="ml-1">
            <canvas
              id="myBarCodeCanvas"
              className="p-3"
              style={{
                maxWidth: '100%',
                height: '100%',
                backgroundColor: 'white',
              }}
            />
          </div>
        </div>
        <div className="divider-totp flex-shrink-0" />
        <p className="mb-2">
          <span className="opacity-7 mr-2 tiny-font">Step 3</span>Enter
          verification code
        </p>
        <p className="smaller-font mb-1">
          Once the barcode above is scanned, enter the 6-digit verification code
          generated by the app.
        </p>
        <Form
          onSubmit={(values: { totp_token: string }) =>
            this.handleSubmit(values)
          }
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={renderInputField as unknown as 'input'}
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                name="totp_token"
                containerClassName="form-group"
                label="Code"
                labelClassName="label-margin"
                className="form-control form-input"
                autoComplete="off"
              />
              <div className="mt-1">{renderError(error)}</div>
              <div className="d-flex justify-content-end mt-4">
                <button
                  disabled={submitting || pristine}
                  type="submit"
                  className="btn primary-btn-md"
                >
                  Verify code and activate
                </button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({
  getUserUserInfo: PropTypes.func,
});

const mapActionsToProps = {
  getUserUserInfo: getUserInfoRequestAction,
};

const ConnectedNotActive = connect(
  mapStateToProps,
  mapActionsToProps,
)(NotActive);
export default ConnectedNotActive;
