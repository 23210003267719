import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { createPath } from 'history';

import { getReopenRoute, setReopenRoute } from '#/utils/reopenRoute';

/**
 * Provide a route for redirection which is the last viewed page
 * on this machine. The route is just provided on the initial
 * render. If unavailable or not on initial render returns `null`.
 */
export default function useInitialRedirect() {
  const history = useHistory();
  const [initialRedirect, setInitialRedirect] = useState(getReopenRoute);

  useEffect(() => {
    /*
     * After redirecting to the initial route we must ensure the
     * user won't be stuck on a non-existent page. This will
     * unset the initial redirect route after the initial render,
     * thus forcing consumers of this hook to provide a different
     * fallback route to its redirect. So, if the initial route
     * doesn't match any path, the fallback hopefully will.
     */
    setInitialRedirect(null);

    const { location } = history;
    if (location.pathname !== '/' && location.pathname !== '') {
      setReopenRoute(createPath(location));
    }

    return history.listen((locationEvent) => {
      setReopenRoute(createPath(locationEvent));
    });
  }, [history]);

  return initialRedirect;
}
