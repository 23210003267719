import { datadogRum } from '@datadog/browser-rum';
import rootPackageJson from '../../../package.json';
import { ParadigmErrors } from './errors';
import type { History, LocationState } from 'history';
import type { Breadcrumb, CaptureContext } from '@sentry/types';

export interface SetupParams {
  /**
   * Router history instance.
   *
   * Required for web, must be undefined on React Native
   */
  readonly history?: History<LocationState>;
}

export interface LogExtras extends Omit<Breadcrumb, 'message' | 'level'> {
  level?: SeverityString;
}

interface GenerateLogUtilsArgs {
  addBreadcrumb: (breadcrumb: Breadcrumb) => void;
  captureException: (
    exception: unknown,
    captureContext?: CaptureContext | undefined,
  ) => string;
}

// If we rely on Severity enum from sentry, our abstraction will leak
// as we'll have to import the Severity enum from other packages
type SeverityString = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

/**
 * Generate log utils based on platform specific Sentry implementations
 *
 * This function helps to abstract the common logic for logging and handling errors
 */
export function generateLogUtils({
  addBreadcrumb: addBreadcrumbFn,
  captureException,
}: GenerateLogUtilsArgs) {
  const addBreadcrumb = (breadcrumb: Breadcrumb) =>
    addBreadcrumbFn(formatBreadCrumb(breadcrumb));

  const logException = (motive: string | Breadcrumb, error: unknown) => {
    const exception = convertToException(motive, error);
    if (ENVIRONMENT === 'development') {
      /* eslint-disable no-console */
      console.error(motive);
      console.error(exception);
      /* eslint-enable no-console */
    } else if (ENVIRONMENT === 'test-runner') {
      throw exception;
    }
    if (ENABLE_SENTRY === 'true') {
      const exceptionContext = getExceptionContextByError(exception);

      if (typeof motive === 'string') {
        addBreadcrumb({
          message: motive,
          level: 'error',
          ...exceptionContext,
        });
        captureException(exception, exceptionContext);
      } else {
        const { message, ...motiveContext } = motive;
        const context = { ...exceptionContext, ...motiveContext };
        addBreadcrumb({
          level: 'error',
          message,
          ...context,
        });
        captureException(exception, context);
      }
    }
    if (ENABLE_DATADOG === 'true') {
      datadogRum.addError(error);
    }
  };

  return {
    logException,
    log(message: string, extras?: LogExtras) {
      if (ENVIRONMENT === 'development') {
        devLog(message, extras);
      }
      if (ENABLE_SENTRY === 'true') {
        addBreadcrumbFn({ ...extras, message } as Breadcrumb);
      }
    },
    exceptionLogger(motive: string | Breadcrumb) {
      return (error: unknown) => logException(motive, error);
    },
  };
}

export function getVersion() {
  return ENVIRONMENT === 'production' || ENVIRONMENT === 'test'
    ? rootPackageJson.version
    : GIT_COMMIT_HASH?.slice(0, 7);
}

/**
 * Set the maximum log level to dispatch messages to console.
 * Affects development env only.
 */
const DEVELOPMENT_VERBOSITY = 3;

const LOG_LEVELS = {
  fatal: 0,
  critical: 1,
  error: 2,
  warning: 3,
  log: 4,
  info: 5,
  debug: 6,
} as const;

const CONSOLE_FN_NAME = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  log: 'log',
  info: 'log',
  debug: 'debug',
} as const;

function devLog(message: string, extras?: LogExtras) {
  const severity = extras?.level ?? 'log';
  if (LOG_LEVELS[severity] > DEVELOPMENT_VERBOSITY) return;

  const consoleFnName = CONSOLE_FN_NAME[severity];
  /* eslint-disable no-console */
  const logFn = console[consoleFnName];
  logFn(message);
  if (extras != null) logFn(extras);
  /* eslint-enable no-console */
}

function convertToException(motive: Breadcrumb | string, error: unknown) {
  const strMotive = typeof motive === 'string' ? motive : motive.message;
  if (error instanceof Error) return error;
  if (error == null)
    return new Error(`${strMotive} - Failed to get error details`);
  if (typeof error === 'object')
    return new Error(
      `${strMotive} - ${JSON.stringify(
        error,
        Object.getOwnPropertyNames(error),
      )}`,
    );

  if (typeof error === 'string') return new Error(`${strMotive} - ${error}`);

  return new Error(strMotive);
}

/**
 * Formats a breadcrumb object for Sentry. This function processes the keys of the breadcrumb,
 * ensuring known keys are directly set while all additional properties are placed under the 'data' property.
 * This is necessary because Sentry's breadcrumb recording allows additional keys but requires them to be
 * under 'data' to be passed along with the event.
 *
 * @param breadcrumb The breadcrumb object containing known and additional properties.
 * @returns The formatted breadcrumb suitable for Sentry, with additional properties encapsulated in 'data'.
 */
export const formatBreadCrumb = ({
  type,
  category,
  message,
  level,
  timestamp,
  ...otherProps
}: Breadcrumb): Breadcrumb => {
  const hasOtherProps = Object.keys(otherProps).length > 0;
  return {
    ...(type !== undefined && { type }),
    ...(category !== undefined && { category }),
    ...(message !== undefined && { message }),
    ...(level !== undefined && { level }),
    ...(timestamp !== undefined && { timestamp }),
    ...(hasOtherProps && { data: { ...otherProps } }),
  };
};

export function getExceptionContextByError(
  error: Error,
): CaptureContext | undefined {
  for (const ParadigmError of ParadigmErrors) {
    if (ParadigmError.matches(error)) return error.getContext();
  }
  return undefined;
}
