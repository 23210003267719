import { FormEvent, PropsWithChildren, useEffect, useRef } from 'react';
import ArrowDownIcon from '@paradigm/design-system/src/assets/ArrowDownIcon';
import CheckIcon from '#/assets/CheckIcon';
import { Box, Circle, HiddenCheckbox, Label, Line } from './styles';

export type CheckboxVariant = 'border' | 'filled';
type CheckboxState = 'CHECKED' | 'UNCHECKED' | 'LINE' | 'DOT' | 'ARROW';

export interface CheckboxProps {
  readonly isChecked: boolean;
  readonly onCheck?: (event: FormEvent<HTMLInputElement>) => void;
  readonly label?: string;
  readonly variant?: CheckboxVariant;
  readonly className?: string;
  readonly indeterminate?: boolean;
  readonly state?: CheckboxState;
  readonly dataTestId?: string;
}

export default function Checkbox({
  className,
  isChecked,
  label,
  dataTestId,
  variant = 'border',
  onCheck,
  indeterminate = false,
  state = isChecked ? 'CHECKED' : indeterminate ? 'DOT' : 'UNCHECKED',
  children,
}: PropsWithChildren<CheckboxProps>) {
  const ref = useRef<HTMLInputElement>(null!);
  const checked = Boolean(isChecked || indeterminate);
  useEffect(() => {
    ref.current.indeterminate = !isChecked && indeterminate;
  }, [ref, indeterminate, isChecked]);

  return (
    <Label isChecked={checked} className={className} data-testid={dataTestId}>
      <HiddenCheckbox
        ref={ref}
        readOnly={onCheck == null}
        onChange={onCheck}
        checked={checked}
      />
      <Box variant={variant}>
        <CheckboxIcon state={state} />
      </Box>
      {label == null ? children : label}
    </Label>
  );
}

function CheckboxIcon({ state }: { state: CheckboxState }): JSX.Element | null {
  switch (state) {
    case 'CHECKED':
      return <CheckIcon width={9} />;
    case 'DOT':
      return <Circle />;
    case 'LINE':
      return <Line />;
    case 'ARROW':
      return <ArrowDownIcon />;
    case 'UNCHECKED':
    default:
      return null;
  }
}
