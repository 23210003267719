import capitalize from 'lodash/capitalize';
import { Strategy } from '#/shared/strategies';
import { PRODUCTS } from '#/products/config';
import {
  isMgpProduct,
  isProductDisabledForType,
  Product,
} from '#/products/types';
import { FilterActiveProductsArgs, useProductsStore } from '#/products/store';
import { Maybe } from '@paradigm/utils/types';
import { AuctionType } from '../unified-rfqs/entities/shared';

export const getProduct = (code: string) => {
  const product = useProductsStore.getState().getProduct(code);
  if (product === undefined)
    throw new Error(`Product with code "${code}" doesn't exist.`);
  return product as Product;
};

export const findProduct = (code: string) => PRODUCTS[code];

// Should return only FUTURE and OPTION products
export const getActiveProducts = (
  filteringArgs: FilterActiveProductsArgs = {},
) =>
  useProductsStore.getState().getActiveProducts({
    ...filteringArgs,
    kinds: ['FUTURE', 'OPTION'],
  }) as Product[];

/**
 * @deprecated Prefer using getActiveProducts and pass venue
 */
export const getActiveProductsByVenue = (
  venue: string,
  auctionType?: Maybe<AuctionType>,
) => getActiveProducts({ venue, auctionType });

export function filterActiveProductsBy(filterProduct: Partial<Product> = {}) {
  return getActiveProducts().filter((product) => {
    return (Object.keys(filterProduct) as (keyof Product)[]).every(
      (key) => filterProduct[key] === product[key],
    );
  });
}

export const getDefaultProductCode = (venue: string) =>
  getActiveProductsByVenue(venue)[0]?.code;

export const getDefaultFutureProductCode = (venue: string) =>
  getActiveProductsByVenue(venue).find((product) => product.kind === 'FUTURE')
    ?.code;

export function supportsStrategy(product: Product, strategy: Strategy) {
  return (
    strategy.kind === product.kind &&
    (product.strategies === 'all' || product.strategies.includes(strategy.code))
  );
}

export function supportsHedging(productCode: string | undefined) {
  if (productCode == null) return true;

  const product = getProduct(productCode);
  return product.isHedgeDisabled !== true;
}

export function supportsUSDApproximation(productCode: string | undefined) {
  if (productCode == null) return true;

  const product = getProduct(productCode);
  return product.showUSDApproximation ?? true;
}

export function getProductName(product_code: string | undefined) {
  if (product_code == null) return '-';

  const product = getProduct(product_code);
  return `${product.currency} ${capitalize(product.kind)} - ${product.venue}`;
}

export function getLongNameWithoutVenue(longName: string) {
  return longName.slice(0, longName.lastIndexOf('-')).trim();
}

export function isFutureProduct(productCode: string) {
  return getProduct(productCode).kind === 'FUTURE';
}

export function isOptionProduct(productCode: string) {
  return getProduct(productCode).kind === 'OPTION';
}

/**
 * We have 3 types of structures:
 * - Options
 * - Options + hedge
 * - Futures
 *
 * The base index in hedged structures are always the OPTION product. So
 * this function tries to find an option product code, and if it is not present,
 * the future leg is returned
 *
 * @param productCodes List of valid product codes
 * @returns The base product code of the structure
 */
export function findBaseProductCode(productCodes: readonly string[]) {
  const optionProduct = productCodes.find(isOptionProduct);
  const futureProduct = productCodes.find(isFutureProduct);

  if (optionProduct != null) return optionProduct;

  return futureProduct;
}

export const isMgpProductCode = (productCode: Maybe<string>) =>
  productCode == null ? false : isMgpProduct(getProduct(productCode));

export const isProductCodeDisabledForType = (
  productCode: Maybe<string>,
  type: 'OB' | 'RFQ',
) => {
  if (productCode == null) return false;
  const product = getProduct(productCode);
  return isProductDisabledForType(product, type);
};

/**
 * WARNING: Make sure to properly catch errors when using this
 *
 * Finds and returns a product that can be used as hedge product
 * for the product code passed in.
 *
 * A hedge product is a FUTURE product that uses the same currency as an
 * OPTION product.
 *
 * @param for Code of an option product for which the hedge product is desired
 *
 * @returns The counterpart hedge product
 */
export function findHedgeProduct({
  for: optionProductCode,
}: {
  readonly for: string;
}) {
  const optionProduct = getProduct(optionProductCode);

  if (optionProduct.kind !== 'OPTION')
    throw new TypeError('Only OPTION products have a hedge-able counterpart');

  const products = Object.values(PRODUCTS);
  const hedgeProduct = products.find(
    (product) =>
      product.currency === optionProduct.currency &&
      product.contractType === optionProduct.marginType &&
      product.kind === 'FUTURE' &&
      optionProduct.venue === product.venue,
  );

  if (hedgeProduct === undefined)
    throw new TypeError(
      `Could not find a hedge product for product with '${optionProductCode}' code.` +
        ` Make sure a FUTURE product is defined with '${optionProduct.currency}' as currency.`,
    );

  return hedgeProduct;
}
