import { ComponentPropsWithoutRef } from 'react';

const SearchIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" {...props}>
    <defs>
      <path
        d="M9.655 8.5h-.594l-.21-.203a4.845 4.845 0 001.18-3.172c0-2.692-2.19-4.875-4.89-4.875-2.7 0-4.89 2.183-4.89 4.875S2.44 10 5.14 10a4.878 4.878 0 003.182-1.178l.204.21v.593l3.761 3.742 1.121-1.117L9.655 8.5zm-4.514 0a3.376 3.376 0 110-6.75 3.376 3.376 0 110 6.75z"
        id="searchicon"
      />
    </defs>
    <g
      transform="translate(-105 -22) translate(60) translate(34 12) translate(11 10)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <mask fill="currentColor">
        <use xlinkHref="#searchicon" />
      </mask>
      <use fill="currentColor" xlinkHref="#searchicon" />
    </g>
  </svg>
);

export default SearchIcon;
