import { createSlice } from '@reduxjs/toolkit';
import { loginSuccess } from '@paradigm/store/auth';

export const INITIAL_STATE = {
  isInternetOnline: true,
  isCommunicationDown: false,
  isRecovering: false,
  isLoadingInitialData: false,
};

const slice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    recoveryStart: (state) => {
      state.isRecovering = true;
    },
    recoveryFinish: (state) => {
      state.isRecovering = false;
    },
    /**
     * @param state
     * @param {import('@reduxjs/toolkit').PayloadAction<{ message: string }>} action
     */
    // eslint-disable-next-line no-unused-vars
    recoveryFailure: (state, action) => {
      state.isRecovering = false;
    },
    internetOffline: (state) => {
      state.isInternetOnline = false;
    },
    internetOnline: (state) => {
      state.isInternetOnline = true;
    },
    initialDataFetchStart: (state) => {
      state.isLoadingInitialData = true;
    },
    initialDataFetchSuccess: (state) => {
      state.isLoadingInitialData = false;
    },
    initialDataFetchFailure: (state) => {
      state.isLoadingInitialData = false;
    },
    // handled by the root reducer only
    resetRequest: (state) => state,
  },
  extraReducers: (builder) =>
    builder.addCase(loginSuccess, (state, { payload: { user } }) => {
      // This is a hack to prevent the loading spinner to reset the animation.
      // It happens because there's a short period of time where nothing is
      // really loading. The decision to do this was based on the fact that
      // the big loading spinner is not the best UX, thus should be removed
      // in the future as discussed with the design team. Once the UX changed,
      // this will no longer be a problem.
      // Also, we can't set this if user will be seeing the user agreement,
      // otherwise they will be stuck on a loading screen if they click on
      // the back arrow button.
      const { has_accepted_us_agreement, has_accepted_non_us_agreement } = user;
      if (has_accepted_us_agreement || has_accepted_non_us_agreement) {
        state.isLoadingInitialData = true;
      }
    }),
});

export default slice.reducer;

export const {
  recoveryStart,
  recoveryFinish,
  recoveryFailure,
  internetOffline,
  internetOnline,
  initialDataFetchStart,
  initialDataFetchSuccess,
  initialDataFetchFailure,
  resetRequest,
} = slice.actions;
