import { isNil, isObject } from 'lodash';

export function removeEmpty<T>(object: object): Record<string, T> {
  return Object.fromEntries(
    Object.entries(object)
      .filter(([_, value]) => !isNil(value))
      .map(([key, value]) => [
        key,
        isObject(value) ? removeEmpty(value) : value,
      ]),
  );
}
