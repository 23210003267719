import { ModalProps as ReactStrapModalProps } from 'reactstrap';
import { useRef } from 'react';
import { ModalContainer } from './styles';
import useModalFocus from './useModalFocus';

export interface ModalProps extends ReactStrapModalProps {
  /**
   * control modal visibility
   */
  isOpen: boolean;
  /**
   * Modal fixed width, will default to 971px if undefined
   */
  width?: string | number;
  /**
   * Control if it should take the whole screen for mobile views
   */
  isFullScreenMobile?: boolean;
  className?: string;
  /**
   * whether it should use reactstrap fade (default false)
   */
  fade?: boolean;
  /**
   * function that controls visibility state
   */
  toggle?: () => void;
}

export default function Modal({
  isOpen,
  className,
  toggle,
  width = 971,
  fade = false,
  isFullScreenMobile = true,
  ...props
}: ModalProps) {
  const ref = useRef(null);
  useModalFocus(ref);

  return (
    <ModalContainer
      {...props}
      innerRef={ref}
      isOpen={isOpen}
      width={width}
      fade={fade}
      isFullScreenMobile={isFullScreenMobile}
      className={className}
      toggle={toggle}
    />
  );
}
