import { useSelector } from 'react-redux';
import { selectIsAppLoading } from '#/redux/selectors/app';
import LoadingScreen from '#/containers/layout/loading';
import { useFeatureFlagStore } from '@paradigm/features/src/feature-flags/store';
import useProductsLoader from '@paradigm/features/src/products/loader';
import { useProductsStore } from '@paradigm/features/src/products/store';
import { selectIsAuthenticated } from '@paradigm/store/auth';

interface PageProps {
  readonly children: JSX.Element;
}

export default function PageLoader({ children }: PageProps) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAppLoading = useSelector(selectIsAppLoading) as boolean;
  if (isAuthenticated) return <PageLoaderPrivate>{children}</PageLoaderPrivate>;

  if (isAppLoading) {
    return <LoadingScreen />;
  }

  return children;
}

function PageLoaderPrivate({ children }: PageProps) {
  const hasFetchedFeatureFlag = useFeatureFlagStore(
    (state) => state.hasFetched,
  );
  const isProductsLoading = useProductsStore((state) => state.isLoading);
  useProductsLoader();

  if (!hasFetchedFeatureFlag || isProductsLoading) return <LoadingScreen />;

  return children;
}
