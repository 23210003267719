import createPapiWebSocket from '#/internal/createPapiWebSocket';
import {
  ApiWebSocket,
  AsyncWSResp,
  WSNotification,
  WSReqParams,
} from '#/internal/createWebSocket';
import { availableToken } from '#/internal/token';
import {
  processBbo,
  processOrder,
  processStrategy,
  processTapeTrade,
  processTrade,
  processFsPlatformState,
  processOrderBookDelta,
} from '#/process/future-spreads';
import {
  FsBbo,
  FsOrder,
  FsOrderBookDelta,
  FsStrategy,
  FsTapeTrade,
  FsTrade,
  PlatformState,
  RawFsBbo,
  RawFsOrder,
  RawFsOrderBookDelta,
  RawFsStrategy,
  RawFsTapeTrade,
  RawFsTrade,
  RawPlatformState,
} from '#/types/future-spreads';
import { MmpNotificationData, MMP_CHANNEL_TOPIC_ID } from '#/types/mmp';

export function createFsWebSocket(): FsWebSocket {
  return createPapiWebSocket({
    urlProvider,
    processNotification: processNotification(),
  });
}

async function urlProvider() {
  const token = await availableToken();
  return (
    `${FS_URL_WS}/v1/fs` +
    '?cancel-on-disconnect=0' +
    `&token=${encodeURIComponent(token)}`
  );
}

export interface FsWebSocket
  extends Omit<ApiWebSocket<FsNotification>, 'request'> {
  readonly request: FsWebSocketRequest;
}

interface FsWebSocketRequest {
  (req: SubscribeReq): AsyncWSResp<SubscribeResult>;
  (req: UnsubscribeReq): AsyncWSResp<UnsubscribeResult>;
}

interface SubscribeReq extends WSReqParams {
  readonly method: 'subscribe';
  readonly params: {
    readonly channel: string;
    readonly data?: unknown;
  };
}

type SubscribeResult = readonly string[];

interface UnsubscribeReq extends WSReqParams {
  readonly method: 'unsubscribe';
  readonly params: {
    readonly channel: string;
  };
}

type UnsubscribeResult = readonly string[];

const KNOWN_TOPICS = [
  'strategy_state',
  'order_book',
  'trade_tape',
  'venue_bbo',
  'orders',
  'trades',
  'system_state',
  'platform_state',
  MMP_CHANNEL_TOPIC_ID,
] as const;

type KnownTopic = typeof KNOWN_TOPICS[number];

export type FsTopic = KnownTopic | 'unknown';

interface FsChannelDataByTopic {
  readonly strategy_state: FsStrategy;
  readonly order_book: FsOrderBookDelta;
  readonly trade_tape: FsTapeTrade;
  readonly venue_bbo: FsBbo;
  readonly orders: FsOrder;
  readonly trades: FsTrade;
  readonly system_state: unknown;
  readonly market_maker_protection: MmpNotificationData;
  readonly platform_state: PlatformState;
  readonly unknown: unknown;
}

export type FsNotificationByTopic = {
  readonly [Topic in FsTopic]: {
    readonly channel: string;
    readonly topic: Topic;
    readonly data: FsChannelDataByTopic[Topic];
  };
};

export type FsNotification = FsNotificationByTopic[FsTopic];

export type FsStrategyNotification = FsNotificationByTopic['strategy_state'];
export type FsBboNotification = FsNotificationByTopic['venue_bbo'];
export type FsOrderBookNotification = FsNotificationByTopic['order_book'];
export type FsOrderNotification = FsNotificationByTopic['orders'];
export type FsTradeNotification = FsNotificationByTopic['trades'];
export type FsTradeTapeNotification = FsNotificationByTopic['trade_tape'];
export type FSPlatformStateNotification =
  FsNotificationByTopic['platform_state'];

function getFsChannelTopic(channel: string): FsTopic {
  const [topic] = channel.split('.');
  if (topic != null && isKnownTopic(topic)) return topic;
  return 'unknown';
}

function isKnownTopic(str: string): str is KnownTopic {
  return (KNOWN_TOPICS as readonly string[]).includes(str);
}

function processNotification() {
  return (raw: WSNotification): FsNotification => {
    const { channel, data } = raw.params;
    const topic = getFsChannelTopic(channel);

    switch (topic) {
      case 'strategy_state':
        return {
          channel,
          topic,
          data: processStrategy(data as RawFsStrategy),
        };

      case 'venue_bbo':
        return {
          channel,
          topic,
          data: processBbo(data as RawFsBbo),
        };

      case 'order_book':
        return {
          channel,
          topic,
          data: processOrderBookDelta(data as RawFsOrderBookDelta),
        };

      case 'orders':
        return {
          channel,
          topic,
          data: processOrder(data as RawFsOrder),
        };

      case 'trades':
        return {
          channel,
          topic,
          data: processTrade(data as RawFsTrade),
        };

      case 'trade_tape':
        return {
          channel,
          topic,
          data: processTapeTrade(data as RawFsTapeTrade),
        };
      case 'platform_state':
        return {
          channel,
          topic,
          data: processFsPlatformState(data as RawPlatformState),
        };

      case MMP_CHANNEL_TOPIC_ID:
        return {
          channel,
          topic,
          data: data as MmpNotificationData,
        };

      case 'system_state':
      case 'unknown':
        return {
          channel,
          topic,
          data,
        };

      // no default
    }
  };
}
