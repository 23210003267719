import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { CSSProp } from 'styled-components';
import { BaseTab, Container, Nav, Spacer } from '#/components/TabGroup/styles';

interface Tab<TabId extends string> {
  readonly id: TabId;
  readonly label: ReactNode;
}

export interface TabGroupProps<TabId extends string>
  extends ComponentPropsWithoutRef<'section'> {
  /**
   * List of tabs with id and label
   */
  readonly tabs: readonly Tab<TabId>[];
  /**
   * Function called whenever a tab is clicked
   */
  readonly onSetTab: (value: TabId) => void;
  /**
   * Current active tab id
   */
  readonly activeTabId: TabId;
  /**
   * Container classname
   */
  readonly className?: string;
  /**
   * individual tab css
   */
  readonly tabCss?: CSSProp;
  /**
   * Content displayed on the left
   */
  readonly rightContent?: ReactNode;
}

export default function TabGroup<TabId extends string = string>({
  tabs,
  onSetTab,
  activeTabId,
  className,
  tabCss,
  rightContent,
  ...props
}: TabGroupProps<TabId>) {
  return (
    <Container className={className} {...props}>
      <Nav role="tablist">
        {tabs.map((tab) => (
          <Tab
            activeTabId={activeTabId}
            key={tab.id}
            tabId={tab.id}
            setTab={onSetTab}
            tabCss={tabCss}
          >
            {tab.label}
          </Tab>
        ))}
        <Spacer />
        {rightContent}
      </Nav>
    </Container>
  );
}

interface TabProps<TabId extends string> {
  readonly activeTabId: TabId;
  readonly tabId: TabId;
  readonly setTab: (value: TabId) => void;
  readonly children: ReactNode;
  readonly tabCss?: CSSProp;
}

function Tab<TabId extends string>({
  activeTabId,
  tabId,
  setTab,
  children,
  tabCss,
}: TabProps<TabId>) {
  const isActive = tabId === activeTabId;

  return (
    <BaseTab
      css={tabCss}
      role="tab"
      aria-controls={tabId}
      aria-selected={isActive ? 'true' : 'false'}
      className={isActive ? 'active' : ''}
      onClick={() => setTab(tabId)}
    >
      {children}
    </BaseTab>
  );
}
