import { ComponentPropsWithoutRef } from 'react';

const FaqIcon = ({
  color = '#F5F5F5',
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="23px" height="23px" viewBox="0 0 23 23" color={color} {...props}>
    <g
      transform="translate(-556 -634) translate(520 184) translate(37.49 451)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <circle
        stroke="currentColor"
        strokeWidth={2}
        cx={10.2902266}
        cy={10.2902266}
        r={10.1344657}
      />
      <text fontSize={14} fontWeight={700} fill="currentColor">
        <tspan x={7.27535834} y={15.7902266}>
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default FaqIcon;
