import { ComponentPropsWithoutRef } from 'react';

export default function UnifiedMarketsIcon(
  props: ComponentPropsWithoutRef<'svg'>,
) {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 19 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="um-/-icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="um-/-tab-/-2"
          transform="translate(-20.000000, -81.000000)"
          stroke="#73A7F0"
        >
          <g id="Group-17" transform="translate(8.509804, 75.000000)">
            <g id="widget" transform="translate(5.000000, 0.000000)">
              <g id="icon-unified" transform="translate(7.000000, 7.000000)">
                <g id="um-icon">
                  <path
                    d="M8.99994124,17.9999412 C4.02945171,17.9999412 0,13.9704764 0,8.99997062 C0,4.02946486 4.02945171,0 8.99994124,0 C13.9704308,0 17.9998825,4.02946486 17.9998825,8.99997062"
                    id="Path"
                  />
                  <line
                    x1="9"
                    y1="5.87599692e-05"
                    x2="9"
                    y2="9.00002938"
                    id="Stroke-3"
                  />
                  <line
                    x1="9"
                    y1="8.99997062"
                    x2="9"
                    y2="17.9999412"
                    id="Stroke-5"
                  />
                  <line
                    x1="0.000117519555"
                    y1="8.99997062"
                    x2="18"
                    y2="8.99997062"
                    id="Stroke-7"
                  />
                  <path
                    d="M17.9998825,8.99997062 C17.9998825,6.51471774 13.9704308,4.50013221 8.99994124,4.50013221 C4.02945171,4.50013221 0,6.51471774 0,8.99997062 C0,11.4852235 4.02945171,13.499809 8.99994124,13.499809"
                    id="Path"
                  />
                  <path
                    d="M13.4998237,9.00002938 C13.4998237,4.02952362 11.4852448,5.87599692e-05 9,5.87599692e-05 C6.51475523,5.87599692e-05 4.50017628,4.02952362 4.50017628,9.00002938 C4.50017628,13.9705351 6.51475523,18 9,18"
                    id="Path"
                  />
                </g>
                <g id="Group-2" transform="translate(9.000059, 9.000000)">
                  <path
                    d="M8.99994124,0 C8.99994124,4.97050576 4.97048953,8.99997062 0,8.99997062"
                    id="Path"
                  />
                  <path
                    d="M0,4.49983841 C4.97048953,4.49983841 8.99994124,2.48525288 8.99994124,1.39266376e-12"
                    id="Path"
                  />
                  <path
                    d="M5.87597769e-05,9.00002938 C2.48530353,9.00002938 4.49988248,4.97056452 4.49988248,5.87599692e-05"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
