import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  error: '',
  view: 'register',
};

const slice = createSlice({
  name: 'registration',
  initialState: INITIAL_STATE,
  reducers: {
    registrationRequest: (state) => state,
    registrationSuccess: (state) => {
      state.error = '';
    },
    registrationFailure: (state, { payload }) => {
      state.error = payload.message;
      state.view = INITIAL_STATE.view;
    },
    setRegistrationView: (state, { payload }) => {
      state.view = payload;
    },
  },
});

export default slice.reducer;

export const {
  registrationRequest,
  registrationSuccess,
  registrationFailure,
  setRegistrationView,
} = slice.actions;
