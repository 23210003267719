import * as PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { FormGroup, Row } from 'reactstrap';
import { required, length, confirmation } from 'redux-form-validators';

import { renderInputField } from '#/utils/renderFormFields';
import { PASSWORD_VALIDATION_HINT } from '#/utils/customValidators';

const FORM_NAME = 'setPassword';
const NAME_SPACE = 'setPasswordMeta';

class SetPasswordFormPure extends PureComponent {
  render() {
    const {
      onPasswordReset,
      [NAME_SPACE]: { handleSubmit, submitting },
    } = this.props;
    return (
      <Form className="px-2" onSubmit={handleSubmit(onPasswordReset)}>
        <FormGroup className="form-group">
          <Field
            component={renderInputField}
            type="password"
            name="new_password1"
            autoComplete="new-password"
            labelClassName="font-weight-bold"
            className="form-control input-group-text"
            placeholder="New password"
            disabled={submitting}
            validate={[required(), length({ min: 10 })]}
          />
          <Field
            component={renderInputField}
            type="password"
            name="new_password2"
            autoComplete="new-password"
            labelClassName="font-weight-bold"
            className="form-control input-group-text mt-4"
            placeholder="Confirm new password"
            disabled={submitting}
            hint={PASSWORD_VALIDATION_HINT}
            validate={[
              required(),
              confirmation({
                field: 'new_password1',
                msg: 'Passwords do not match',
              }),
            ]}
          />
          <Row noGutters className="justify-content-end mt-4">
            <button
              type="submit"
              disabled={submitting}
              className="btn primary-button w-auto"
            >
              {!submitting ? 'Reset Password' : 'Resetting...'}
            </button>
          </Row>
        </FormGroup>
      </Form>
    );
  }
}

SetPasswordFormPure.propTypes = {
  onPasswordReset: PropTypes.func.isRequired,
};

/** @type {import('react').ComponentType<{
 *    onPasswordReset: (values: {
 *      new_password1: string,
 *      new_password2: string,
 *    }) => void;
 *  }>} */
const SetPasswordForm = reduxForm({
  form: FORM_NAME,
  propNamespace: NAME_SPACE,
})(SetPasswordFormPure);

export default SetPasswordForm;
