import { isBefore } from 'date-fns';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  required,
  email,
  length,
  confirmation,
  addValidator,
} from 'redux-form-validators';
import { COUNTRIES_BLOCKLIST } from '@paradigm/utils/src/countries';
import { registrationRequest, setRegistrationView } from '#/redux/registration';
import {
  renderInputField,
  renderComplexInputField,
} from '#/utils/renderFormFields';
import { renderError } from '#/utils/renderErrors';
import BackIcon from '@paradigm/design-system/src/assets/BackIcon';
import AcceptAgreement from '#/components/accept-agreement/AcceptAgreement';
import {
  CountryPickerField,
  COUNTRY_PICKER_OPTIONS,
} from '#/components/sign-up/CountryPickerField';
import CountryPickerLabel from '#/components/sign-up/CountryPickerLabel';
import { PASSWORD_VALIDATION_HINT } from '#/utils/customValidators';
import BirthDatePickerField from '#/components/sign-up/BirthDatePickerField';

const countriesBlocklist = addValidator({
  defaultMessage:
    'We are currently not providing services to the selected country',
  validator: (_options, country) => COUNTRIES_BLOCKLIST[country.value] == null,
});

const dateOfBirthValidation = addValidator({
  defaultMessage: 'Invalid Date',
  validator: (_options, value) => isBefore(value, Date.now()),
});

let SignUp = ({ handleSubmit }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.registration.error);
  const view = useSelector((state) => state.registration.view);

  const [validData, setValidData] = useState(null);

  const handleSuccessSubmit = (data) => {
    setValidData(data);
    dispatch(setRegistrationView('agreement'));
  };

  if (view === 'agreement') {
    return (
      <AcceptAgreement
        selectedCountry={validData?.country_of_residence.value}
        onBack={() => dispatch(setRegistrationView('register'))}
        onAccept={(agreement) =>
          dispatch(
            registrationRequest({
              ...validData,
              [agreement === 'us'
                ? 'has_accepted_us_agreement'
                : 'has_accepted_non_us_agreement']: true,
            }),
          )
        }
      />
    );
  }
  const searchParams = new URLSearchParams(location.search);
  const hasPreloadedKey = searchParams.has('key');
  const hasPreloadedEmail = searchParams.has('email');

  return view === 'register' ? (
    <form
      className="flex-center flex-column layout__container sign-up flex-shrink-0"
      onSubmit={handleSubmit(handleSuccessSubmit)}
      noValidate
    >
      <div className="row no-gutters align-items-center mb-3 flex-shrink-0">
        <div className="mr-3">
          <Link to="/" role="link" style={{ color: 'white' }}>
            <BackIcon />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <h2 className="sign-up__title">Activate your account</h2>
        </div>
      </div>
      <div className="flex-shrink-0">
        {renderError(error)}
        <Field
          component={renderComplexInputField}
          type="text"
          name="invitation_key"
          placeholder="Enter Registration Code"
          containerClassName="form-group"
          innerContainerClassName="input-group box-shadow"
          style={hasPreloadedKey ? { display: 'none' } : null}
          label={hasPreloadedKey ? '' : 'Registration Code'}
          labelClassName="label-margin"
          className="form-control sign-up__input sign-up__input--code"
          validate={[
            required(),
            length({ is: 16, msg: 'Invalid Registration Code' }),
          ]}
          autoComplete="off"
        />
        <div className="p-0 half-field">
          <div className="row">
            <Field
              component={renderInputField}
              type="text"
              name="first_name"
              placeholder="Enter First Name"
              containerClassName="col-6 form-group pr-2"
              label="First Name"
              labelClassName="label-margin"
              className="form-control sign-up__input"
              validate={required()}
              autoComplete="new-password"
            />
            <Field
              component={renderInputField}
              type="text"
              name="last_name"
              placeholder="Enter Last Name"
              containerClassName="col-6 form-group pl-1"
              label="Last Name"
              labelClassName="label-margin"
              className="form-control sign-up__input"
              validate={required()}
              autoComplete="new-password"
            />
          </div>
        </div>
        <Field
          type="text"
          name="date_of_birth"
          className="form-control sign-up__input"
          component={BirthDatePickerField}
          validate={[required(), dateOfBirthValidation()]}
        />
        <Field
          component={renderInputField}
          type="email"
          name="email"
          placeholder="Enter Email"
          disabled={hasPreloadedEmail}
          containerClassName="form-group"
          label="Email"
          labelClassName="label-margin"
          className="form-control sign-up__input"
          validate={[required(), email({ msg: 'Invalid Email address' })]}
          autoComplete="off"
        />
        <CountryPickerField
          name="country_of_residence"
          label={<CountryPickerLabel />}
          validate={[required(), countriesBlocklist()]}
        />
        <Field
          component={renderInputField}
          type="password"
          name="password"
          containerClassName="form-group"
          placeholder="Enter Password"
          label="Password"
          labelClassName="label-margin"
          className="form-control sign-up__input"
          validate={[required(), length({ min: 10 })]}
          autoComplete="off"
        />
        <Field
          component={renderInputField}
          type="password"
          name="password_confirmation"
          placeholder="Confirm Password"
          containerClassName="form-group"
          label="Confirm Password"
          labelClassName="label-margin"
          className="form-control sign-up__input"
          hint={PASSWORD_VALIDATION_HINT}
          validate={[
            required(),
            confirmation({
              field: 'password',
              fieldLabel: 'Password',
              msg: 'Passwords do not match',
            }),
          ]}
          autoComplete="off"
        />
      </div>
      <div className="flex-end align-items-end mt-4 flex-shrink-0">
        <button type="submit" className="btn primary-button">
          Next
        </button>
      </div>
    </form>
  ) : null;
};

SignUp.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

SignUp = connect((state) => {
  const searchParams = new URLSearchParams(state.router.location.search);
  return {
    initialValues: {
      invitation_key: searchParams.get('key'),
      email: searchParams.get('email'),
      first_name: searchParams.get('first_name'),
      last_name: searchParams.get('last_name'),
      country_of_residence: getCountryOfResidenceOption(
        searchParams.get('country_of_residence'),
      ),
    },
  };
})(reduxForm({ form: 'register' })(SignUp));

function getCountryOfResidenceOption(code) {
  return COUNTRY_PICKER_OPTIONS.find((option) => option.value === code) ?? null;
}

export default SignUp;
