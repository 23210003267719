import fetchApi, { BaseReq, request } from './fetch-api';

export interface AccountLoginReq extends BaseReq {
  readonly body: {
    readonly email: string;
    readonly password: string;
    readonly app_version: string;
    readonly totp_token?: string;
  };
}
export interface AgreementsResponseData {
  readonly id?: number;
  readonly content: string;
}

export interface AgreementsResponse {
  readonly data: AgreementsResponseData[];
}

export interface PreferencesResp {
  readonly data:
    | {
        readonly state: unknown; // We intentionally have an unknown type here to handle schema additions and migrations.
        readonly version: number;
      }
    | Record<string, never>;
}

export interface AccountLoginResp {
  readonly token: string;
  readonly user: {
    readonly id: number;
    readonly email: string;
    readonly firm: string;
    readonly first_name: string;
    readonly last_name: string;
    readonly phone_number: string | null;
    readonly trading_desk: {
      readonly id: number;
      readonly ticker: string;
    };
    readonly can_trade: boolean;
    readonly color: string;
    readonly has_accepted_us_agreement: boolean;
    readonly has_accepted_non_us_agreement: boolean;
    readonly has_bitcom_product: boolean;
    readonly has_deribit_product: boolean;
    readonly audio_notifications_enabled: boolean;
    readonly email_notifications_enabled: boolean;
    readonly visual_notifications_enabled: boolean;
    readonly trading_notifications_enabled: boolean;
    readonly visual_mobile_notifications_enabled: boolean;
    readonly audio_mobile_notifications_enabled: boolean;
    readonly restricted_ip_rfqs_left: unknown;
    readonly has_totp_enabled: boolean;
    readonly active_products: readonly string[];
    readonly paradex_token: string;
    readonly preferences: PreferencesResp;
  };
}

export async function accountLogin(req: AccountLoginReq) {
  return request<AccountLoginResp>({
    ...req,
    url: '/account/login/',
    method: 'POST',
    publicApi: true,
  });
}

interface UserLoginResp {
  readonly user: Record<string, unknown>;
  readonly token: string;
}

/** @deprecated Use `accountLogin` */
export const UserLogin = async (form: unknown) =>
  fetchApi.post<UserLoginResp>('/account/login/', form, {
    timeout: 60000,
    publicApi: true,
  });

export const UserLogout = async () => fetchApi.post('/account/logout/');

export const UserRegister = async (form: unknown) =>
  fetchApi.post('/account/create-user/', form, {
    timeout: 5000,
    publicApi: true,
  });

export const UserInfoUpdate = async (userForm: Record<string, unknown>) =>
  fetchApi.put('/account/user/', {
    first_name: userForm.first_name,
    last_name: userForm.last_name,
    email: userForm.email,
    phone_number: userForm.phone_number,
  });

export const UserForgotPasswordApi = async (data: unknown) =>
  fetchApi.post('/account/password-reset/request/', data, { publicApi: true });

interface UserSetNewPasswordBody {
  readonly uid: string;
  readonly token: string;
  readonly new_password1: string;
  readonly new_password2: string;
}

export const userSetNewPasswordApi = async (data: UserSetNewPasswordBody) =>
  fetchApi.post('/account/password-reset/', data, { publicApi: true });

export const UserPasswordChange = async (form: unknown) =>
  fetchApi.post('/account/password-change/', form);

export const UserInfo = async () => fetchApi.get('/account/user/');

export const UserNotificationsChange = async (form: unknown) =>
  fetchApi.once('patch', '/account/user/', {}, form);
export const acceptAgreementApi = async (agreement: 'us' | 'non-us') =>
  fetchApi.patch('/account/user/', {
    [agreement === 'us'
      ? 'has_accepted_us_agreement'
      : 'has_accepted_non_us_agreement']: true,
  });

export async function acceptNewAgreementApi(agreement: number) {
  return request({
    body: { agreement },
    url: '/account/user/agreements/',
    method: 'POST',
  });
}

export async function getAgreementsApi() {
  return request<AgreementsResponseData[]>({
    url: '/account/user/agreements/',
    method: 'GET',
  });
}
export const UserViewedMdrfq = async () =>
  fetchApi.post('/account/user/viewed-mdrfq/');

export const UserTwoFACodeEnable = async (form: unknown) =>
  fetchApi.post('/account/totp/enable/', form);
export const UserTwoFACodes = async () =>
  fetchApi.get('/account/totp/backups/');
export const UserGenereteBarCode = async () =>
  fetchApi.get('/account/totp/qr/');
export const UserBackUpCodes = async () =>
  fetchApi.get('/account/totp/backups/');
export const UserDeactivatePassword = async (form: unknown) =>
  fetchApi.post('/account/totp/email-token/', form);
export const UserDeactivateTFA = async (form: unknown) =>
  fetchApi.post('/account/totp/disable/', form);
export const UserBackupsRegenerate = async (form: unknown) =>
  fetchApi.post('/account/totp/backups/regenerate/', form);
export const UserActiveSessions = async () =>
  fetchApi.get('/account/user/sessions/');
export const UserActiveSessionsTerminateAll = async () =>
  fetchApi.delete('/account/user/sessions/');
export const UserActiveSessionTerminate = async (id: number) =>
  fetchApi.delete(`/account/user/sessions/${id}/`);
