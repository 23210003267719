import { PropsWithChildren } from 'react';
import { PinnedRfqsProvider } from '@paradigm/features/src/unified-rfqs/ui/rfqs/pinned-rfqs-context';
import useLocalStorage from '#/hooks/local-storage';

export function UnifiedRfqsPinnedRfqsProvider({ children }: PropsWithChildren) {
  const [pinnedRfqs, setPinnedRfqs] = useLocalStorage(
    'unified-rfq-table-pinned-items',
    {},
  );

  return (
    <PinnedRfqsProvider pinnedRfqs={pinnedRfqs} setPinnedRfqs={setPinnedRfqs}>
      {children}
    </PinnedRfqsProvider>
  );
}
