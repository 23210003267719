import { Workbox } from 'workbox-window/Workbox';
import { exceptionLogger, log, logException } from '@paradigm/logging';

let attemptedRegistration = false;

export default function registerServiceWorker() {
  if (NODE_ENV !== 'production') {
    log(`Skipping service worker registration because NODE_ENV=${NODE_ENV}`);
    return null;
  }

  if (attemptedRegistration) {
    throw new Error('Must register service worker only once');
  }
  attemptedRegistration = true;

  if (!('serviceWorker' in navigator)) {
    log('Skipped service worker registration due to lack of support');
    return null;
  }

  const wb = initialize();
  wb.register()
    .then(() => handleRegistered(wb))
    .catch((error) => {
      logException('Failed service worker registration', error);
      unregisterServiceWorker().catch(
        exceptionLogger('Failed to unregister existing service worker'),
      );
    });
  return wb;
}

function initialize() {
  const wb = new Workbox('/service-worker.js');
  wb.addEventListener('activated', handleAvailableUpdate);
  return wb;
}

function handleAvailableUpdate() {
  log('Service worker update available');
  window.location.reload();
}

function handleRegistered(wb: Workbox) {
  log('Service worker registered');
  // Check for updates regularly with a random 60 seconds jitter
  setInterval(() => {
    wb.update().catch((error) => {
      logException('Failed service worker update check', error);
      unregisterServiceWorker().catch(
        exceptionLogger('Failed to unregister existing service worker'),
      );
    });
  }, Math.floor(Math.random() * 60 * 1000) + 10 * 60 * 1000);
}

async function unregisterServiceWorker() {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  log('Attempting to unregister existing service worker');
  const registrations = await navigator.serviceWorker.getRegistrations();
  for (const registration of registrations) {
    await registration.unregister();
  }
}
