import { PureComponent } from 'react';
import styled from 'styled-components';
import { paradigmLightBlue } from '@paradigm/design-system/src/colors';

class NoInternet extends PureComponent {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div className="overlay">
        <div className="d-flex align-items-center mb-2">
          <h5>Disconnected from Paradigm servers</h5>
          <i className="material-icons white-icon">cloud_off</i>
        </div>
        <div className="reconnecting">
          Please check your network connectivity. <br />
          Trying to reconnect...
        </div>
        <LinkButton onClick={() => window.location.reload()}>
          Try now
        </LinkButton>
      </div>
    );
  }
}

const LinkButton = styled.button`
  display: inline;
  border: none;
  color: ${paradigmLightBlue};
  background-color: transparent;
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0;
`;

export default NoInternet;
