import styled, { css } from 'styled-components';
import FormattedNumber from 'react-number-format';
import { pickledBluewood, ripeLemon, nepal } from '#/colors';
import { InputProps } from './Input';
import { INPUT_SIZES } from './config';

const DISABLED_STYLES = css`
  cursor: default;
  opacity: 0.3;
`;

export const BASE_STYLES = css`
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  color: white;
`;

export const WARNING_STYLES = css`
  &,
  &::placeholder {
    color: ${ripeLemon};
  }
`;

type StyledProps = Pick<
  InputProps,
  'variant' | 'size' | 'disabled' | 'warning'
>;

const withVariant = ({ variant = 'default', warning = false }: StyledProps) => {
  switch (variant) {
    case 'transparent':
      return css`
        background-color: transparent;
        border: none;
        outline: none;
      `;
    case 'default':
    default:
      return css`
        background: ${pickledBluewood};
        border-radius: 3px;
        border: none;
        box-shadow: ${warning ? `0 0 0 1px ${ripeLemon}` : 'none'};
      `;
  }
};

const withFontSize = ({ size = 'medium' }: StyledProps) => {
  return css`
    font-size: ${INPUT_SIZES[size].fontSize};
  `;
};

const withWrapperSize = ({ size = 'medium' }: StyledProps) => {
  switch (size) {
    case 'small':
      return css`
        padding: 0 0.6rem;
      `;
    case 'large':
      return css`
        height: 40px;
        padding: 0 8px;
      `;
    case 'medium':
    default:
      return css`
        height: 32px;
        padding: 0 8px;
      `;
  }
};

export const InputWrapper = styled.div<StyledProps>`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    min-width: 0;
  }

  input {
    flex: 1;
  }
  ${withVariant}
  ${withWrapperSize}
`;

export const Suffix = styled.div<StyledProps>`
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  ${withFontSize}
`;

export const Prefix = styled.div<StyledProps>`
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-right: 8px;
  ${withFontSize}
`;

export const Label = styled.label<StyledProps>`
  color: ${nepal};
  ${withFontSize}
  margin-bottom: 0.1rem;
`;

export const HelperContent = styled.div<StyledProps>`
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: ${(props) => INPUT_SIZES[props.size ?? 'medium'].helperFontSize};
  line-height: 1em;
  margin-top: 6px;
  word-break: break-word;
`;

export const StyledReadonlyNumber = styled.span<StyledProps>`
  ${BASE_STYLES}
  ${DISABLED_STYLES}
  ${withFontSize}
  ${withWrapperSize}
`;

const INPUT_STYLED_CONFIG = {
  shouldForwardProp: (prop: string) =>
    !['warning', 'size', 'inputSize', 'inputType'].includes(prop),
};

export const StyledFormattedNumber = styled(FormattedNumber).withConfig<{
  inputSize: StyledProps['size'];
  warning: boolean;
  disabled: boolean;
}>(INPUT_STYLED_CONFIG)`
  ${BASE_STYLES}
  ${(props) => withFontSize({ size: props.inputSize })}
  ${(props) => props.warning && WARNING_STYLES}
  ${(props) => props.disabled && DISABLED_STYLES}
`;

export const StyledInput = styled('input').withConfig<{
  inputSize: StyledProps['size'];
  warning: boolean;
  disabled: boolean;
}>(INPUT_STYLED_CONFIG)`
  ${BASE_STYLES}
  ${(props) => withFontSize({ size: props.inputSize })}
  ${(props) => props.warning && WARNING_STYLES}
  ${(props) => props.disabled && DISABLED_STYLES}
`;

export const Container = styled.div<StyledProps>`
  ${(props) => props.variant === 'default' && 'margin-bottom: 1rem;'}
`;
