import BigNumber from 'bignumber.js';
import {
  Grfq,
  GrfqLeg,
  GrfqLastTrade,
  GrfqQuote,
  GrfqTrade,
  GrfqTradeDetails,
  RawGrfqLeg,
  GrfqTradeTape,
  isTaker,
} from '#/unified-rfqs/entities/grfq/domain';
import { GrfqWsBbo } from '#/unified-rfqs/entities/grfq/bbo';
import { convertToBigNumber } from '@paradigm/utils/formatDecimal';
import {
  GrfqQuoteV2CreateResp,
  RawGrfq,
  RawGrfqLastTrade,
  RawGrfqQuote,
  RawGrfqQuoteV2CreateResp,
  RawGrfqTrade,
  RawGrfqTradeDetails,
  RawGrfqTradeTape,
  RawGrfqWsBbo,
} from '#/unified-rfqs/repositories/grfq-types';
import { getOppositeSide } from '#/unified-rfqs/entities/shared';
import { processDisplayValues } from '#/unified-rfqs/utils/trade-display-values';

export function processGRFQLeg(leg: RawGrfqLeg): GrfqLeg {
  return {
    ...leg,
    isHedge: leg.price != null,
  };
}

export function processGrfq(rawGrfq: RawGrfq): Grfq {
  return {
    ...rawGrfq,
    created: new Date(rawGrfq.created),
    last_trade:
      rawGrfq.last_trade != null
        ? processGrfqLastTrade(rawGrfq.last_trade)
        : null,
    latest_activity: new Date(rawGrfq.latest_activity),
    type: 'grfq',
    legs: rawGrfq.legs.map(processGRFQLeg),
  };
}

export function processGrfqLastTrade(
  rawLastTrade: RawGrfqLastTrade,
): GrfqLastTrade {
  return {
    ...rawLastTrade,
    traded: new Date(rawLastTrade.traded),
  };
}

export function processGrfqQuote(rawQuote: RawGrfqQuote): GrfqQuote {
  const { filled_quantity, original_quantity } = rawQuote;

  return {
    ...rawQuote,
    rfqType: 'grfq',
    canceled_quantity: BigNumber(original_quantity)
      .minus(filled_quantity)
      .toString(),
    completed_quantity: filled_quantity,
    created: new Date(rawQuote.created),
    last_activity: new Date(rawQuote.last_activity),
    legs: rawQuote.legs.map(processGRFQLeg),
  };
}

export function processGrfqQuoteAPIV2(
  rawQuote: RawGrfqQuoteV2CreateResp,
): GrfqQuoteV2CreateResp {
  return {
    ...rawQuote,
    rfqType: 'grfq',
    created: new Date(rawQuote.created),
  };
}

export function processGrfqOwnQuote(rawQuote: RawGrfqQuote): GrfqQuote {
  const { original_quantity, order, remaining_quantity } = rawQuote;

  // is not crossed quote
  if (
    order == null ||
    /* eslint-disable  @typescript-eslint/no-unnecessary-condition */
    /* Note: API is returning values against the spec */
    order.filled_quantity == null ||
    order.requested_quantity == null
    /* eslint-enable  @typescript-eslint/no-unnecessary-condition */
  )
    return processGrfqQuote(rawQuote);

  return {
    ...rawQuote,
    rfqType: 'grfq',
    original_quantity: BigNumber(original_quantity)
      .plus(order.requested_quantity)
      .toString(),
    remaining_quantity,
    completed_quantity: BigNumber(original_quantity)
      .minus(remaining_quantity)
      .plus(order.filled_quantity)
      .toString(),
    canceled_quantity: BigNumber(remaining_quantity)
      .plus(order.requested_quantity)
      .minus(order.filled_quantity)
      .toString(),
    created: new Date(rawQuote.created),
    last_activity: new Date(rawQuote.last_activity),
    legs: rawQuote.legs.map(processGRFQLeg),
  };
}

export function processGrfqTrade(rawTrade: RawGrfqTrade): GrfqTrade {
  const side = rawTrade.action;
  const makerSide =
    side != null
      ? isTaker(rawTrade)
        ? getOppositeSide(side)
        : side
      : undefined;

  return {
    ...rawTrade,
    rfqType: 'grfq',
    status: rawTrade.status === 'COMPLETED' ? 'FILLED' : rawTrade.status,
    traded: new Date(rawTrade.traded),
    displayValues: processDisplayValues(
      rawTrade.price,
      rawTrade.mark_price,
      makerSide,
      rawTrade.description,
      // For GRFQs, the base product is always the first product code.
      rawTrade.product_codes.length ? rawTrade.product_codes[0] : undefined,
    ),
  };
}

export function processGrfqTradeTape(
  rawTrade: RawGrfqTradeTape,
): GrfqTradeTape {
  return {
    ...rawTrade,
    rfqType: 'grfq',
    traded: new Date(rawTrade.traded),
    displayValues: processDisplayValues(
      rawTrade.price,
      rawTrade.mark_price,
      // Trade tape resolves Side from Taker's perspective, flip to get Maker's.
      getOppositeSide(rawTrade.action),
      rawTrade.description,
      // For GRFQs, the base product is always the first product code.
      rawTrade.product_codes.length ? rawTrade.product_codes[0] : undefined,
    ),
  };
}

export function processGrfqTradeDetails(
  rawTrade: RawGrfqTradeDetails,
): GrfqTradeDetails {
  return {
    ...rawTrade,
    traded: new Date(rawTrade.traded),
  };
}

function processIvValues(str?: string | null, precision = 2): string {
  if (str == null || str === '') return '';
  const numericValue = convertToBigNumber(str);
  if (numericValue.isEqualTo(0)) return '';
  return numericValue.toFixed(precision);
}

export function processWsGrfqBbo(rawWsBbo: RawGrfqWsBbo): GrfqWsBbo {
  return {
    ...rawWsBbo,
    published: new Date(rawWsBbo.published),
    legs: rawWsBbo.legs.map((leg) => ({
      instrument: leg.instrument,
      mark_price: leg.mark_price,
      best_bid_price: leg.best_bid.price,
      best_bid_quantity: leg.best_bid.quantity,
      best_ask_price: leg.best_ask.price,
      best_ask_quantity: leg.best_ask.quantity,
      mark_price_iv: processIvValues(leg.mark_price_iv),
      best_ask_iv: processIvValues(leg.best_ask_iv),
      best_bid_iv: processIvValues(leg.best_bid_iv),
    })),
  };
}
