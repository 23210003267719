import { FocusEventHandler } from 'react';
import { BaseFieldProps } from 'redux-form';
import { DatePicker } from '@paradigm/design-system/src/components/DatePicker';

const BirthDatePickerField: BaseFieldProps['component'] = ({ input, meta }) => {
  const htmlFor = 'date-of-birth';
  const label = 'Date of Birth';
  const placeholder = 'Select Date';
  const hasError =
    meta.touched &&
    meta.active != null &&
    !meta.active &&
    meta.error != null &&
    Boolean(meta.error).valueOf();
  const value = input.value as Date;
  const isFocused = meta.active ?? false;
  const onBlur: FocusEventHandler<HTMLInputElement> = (_) =>
    input.onBlur(value);

  const onFocus: FocusEventHandler<HTMLInputElement> = (_) =>
    /*  eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
    input.onFocus(input.value);

  return (
    <DatePicker
      value={value}
      label={label}
      error={meta.error as string}
      onBlur={onBlur}
      htmlFor={htmlFor}
      onFocus={onFocus}
      onChange={input.onChange}
      hasError={hasError}
      isFocused={isFocused}
      placeholder={placeholder}
    />
  );
};

export default BirthDatePickerField;
