import { ProductCode, Product } from './types';

export const DBT_ALT_COINS_PRODUCT_CODES = ['OS', 'SF', 'MT', 'MA', 'RP', 'RF'];
export const PRODUCT_CODES = [
  // Deribit
  'DO',
  'EH',
  'CF',
  'AZ',
  'SL',
  'SO',
  'OS',
  'SF',
  'MT',
  'MA',
  'RP',
  'RF',
  // Bit.com
  'BO',
  'VT',
  'OJ',
  'GD',
  'BF',
  'ZW',
  'HM',
  'AU',
  // Bybit
  'JB',
  'BE',
  'JC',
  'BX',
  'BB',
  'EF',
  'EO',
  'XR',
];

export const PRODUCTS_STRICT: {
  [Code in ProductCode]: Product<Code>;
} = {
  // Deribit
  DO: {
    code: 'DO',
    venue: 'DBT',
    kind: 'OPTION',
    currency: 'BTC',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'BTC Option European - DBT',
    longName: 'DO - BTC Option - European - Deribit',
    contractSize: 1,
    contractCurrency: 'BTC',
    quoteCurrency: 'BTC',
    minBlockSize: 25,
    strategies: 'all',
    quantityPrecision: 1,
    pricePrecision: 4,
    ratioPrecision: 1,
    minContractIncrement: 0.1,
  },
  EH: {
    code: 'EH',
    venue: 'DBT',
    kind: 'OPTION',
    currency: 'ETH',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'ETH Option European - DBT',
    longName: 'EH - ETH Option - European - Deribit',
    contractSize: 1,
    contractCurrency: 'ETH',
    quoteCurrency: 'ETH',
    minBlockSize: 250,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 4,
    ratioPrecision: 1,
    minContractIncrement: 1,
  },
  CF: {
    code: 'CF',
    venue: 'DBT',
    kind: 'FUTURE',
    currency: 'BTC',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'BTC Future Inverse - DBT',
    longName: 'CF - BTC Future - Inverse - Deribit',
    contractSize: 10,
    contractCurrency: 'USD',
    quoteCurrency: 'USD',
    minBlockSize: 20_000,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
  },
  AZ: {
    code: 'AZ',
    venue: 'DBT',
    kind: 'FUTURE',
    currency: 'ETH',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'ETH Future Inverse - DBT',
    longName: 'AZ - ETH Future - Inverse - Deribit',
    contractSize: 1,
    contractCurrency: 'USD',
    quoteCurrency: 'USD',
    minBlockSize: 100_000,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
  },
  SL: {
    code: 'SL',
    venue: 'DBT',
    currency: 'SOL',
    longName: 'SL - SOL Option - European - Deribit',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'SOL Option European - DBT',
    kind: 'OPTION',
    minBlockSize: 2_500,
    strategies: 'all',
    contractCurrency: 'SOL',
    minContractIncrement: 1,
    quantityPrecision: 0,
    contractSize: 1,
    ratioPrecision: 1,
    pricePrecision: 3,
    isDisabled: true,
  },
  SO: {
    code: 'SO',
    venue: 'DBT',
    currency: 'SOL',
    longName: 'SO - SOL Future - Inverse - Deribit',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'SOL Future Inverse - DBT',
    kind: 'FUTURE',
    minBlockSize: 20_000,
    strategies: 'all',
    contractCurrency: 'USD',
    minContractIncrement: 1,
    quantityPrecision: 0,
    contractSize: 10,
    ratioPrecision: 2,
    pricePrecision: 2,
    isDisabled: true,
  },
  OS: {
    code: 'OS',
    venue: 'DBT',
    currency: 'SOL',
    longName: 'OS - SOL Option - European - Linear - Deribit',
    contractType: 'EUROPEAN',
    marginType: 'LINEAR',
    shortName: 'SOL Option Linear - DBT',
    kind: 'OPTION',
    strategies: 'all',
    contractCurrency: 'SOL',
    quoteCurrency: 'USDC',
    minContractIncrement: 1,
    quantityPrecision: 0,
    contractSize: 10,
    ratioPrecision: 1,
    pricePrecision: 1,
    minGrossPremium: 2500,
    isDisabled: true,
    isDisabledForOb: true,
    isHedgeDisabled: true, // Enable hedging once we integrate alt coins Futures
  },
  MT: {
    code: 'MT',
    venue: 'DBT',
    currency: 'MATIC',
    longName: 'MT - MATIC Option - European - Linear - Deribit',
    contractType: 'EUROPEAN',
    marginType: 'LINEAR',
    shortName: 'MATIC Option Linear - DBT',
    kind: 'OPTION',
    strategies: 'all',
    contractCurrency: 'MATIC',
    quoteCurrency: 'USDC',
    minContractIncrement: 1,
    quantityPrecision: 0,
    contractSize: 1000,
    ratioPrecision: 1,
    pricePrecision: 4,
    minGrossPremium: 2500,
    isDisabled: true,
    isDisabledForOb: true,
    isHedgeDisabled: true, // Enable hedging once we integrate alt coins Futures
  },
  RP: {
    code: 'RP',
    venue: 'DBT',
    currency: 'XRP',
    longName: 'RP - XRP Option - European - Linear - Deribit',
    contractType: 'EUROPEAN',
    marginType: 'LINEAR',
    shortName: 'XRP Option Linear - DBT',
    kind: 'OPTION',
    strategies: 'all',
    quoteCurrency: 'USDC',
    contractCurrency: 'XRP',
    minContractIncrement: 1,
    quantityPrecision: 0,
    contractSize: 1000,
    ratioPrecision: 1,
    pricePrecision: 4,
    minGrossPremium: 2500,
    isDisabled: true,
    isDisabledForOb: true,
    isHedgeDisabled: true, // Enable hedging once we integrate alt coins Futures
  },
  SF: {
    code: 'SF',
    venue: 'DBT',
    currency: 'SOL',
    longName: 'SF - SOL Future - Linear - Deribit',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'SOL Future Linear - DBT',
    kind: 'FUTURE',
    minBlockSize: 200_000,
    strategies: 'all',
    quoteCurrency: 'USDC',
    contractCurrency: 'SOL',
    minContractIncrement: 1,
    quantityPrecision: 2,
    contractSize: 0.1,
    ratioPrecision: 2,
    pricePrecision: 2,
    isDisabled: true,
    isDisabledForOb: true,
  },
  MA: {
    code: 'MA',
    venue: 'DBT',
    currency: 'MATIC',
    longName: 'MA - MATIC Future - Linear - Deribit',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'MATIC Future Linear - DBT',
    kind: 'FUTURE',
    minBlockSize: 200_000,
    strategies: 'all',
    quoteCurrency: 'USDC',
    contractCurrency: 'MATIC',
    minContractIncrement: 1,
    quantityPrecision: 2,
    contractSize: 10,
    ratioPrecision: 2,
    pricePrecision: 4,
    isDisabled: true,
    isDisabledForOb: true,
  },
  RF: {
    code: 'RF',
    venue: 'DBT',
    currency: 'XRP',
    longName: 'RF - XRP Future - Linear - Deribit',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'XRP Future Linear - DBT',
    kind: 'FUTURE',
    minBlockSize: 200_000,
    strategies: 'all',
    quoteCurrency: 'USDC',
    contractCurrency: 'XRP',
    minContractIncrement: 1,
    quantityPrecision: 2,
    contractSize: 10,
    ratioPrecision: 2,
    pricePrecision: 4,
    isDisabled: true,
    isDisabledForOb: true,
  },
  // Bit.com
  GD: {
    code: 'GD',
    currency: 'BCH',
    longName: 'GD - BCH Option - European - Inverse - Bit.com',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'BCH Option European Inverse - BIT',
    venue: 'BIT',
    kind: 'OPTION',
    contractSize: 1,
    contractCurrency: 'BCH',
    minBlockSize: 100,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 4,
    ratioPrecision: 1,
    minContractIncrement: 1,
    quoteCurrency: 'BCH',
    isDisabled: true,
  },
  BO: {
    code: 'BO',
    currency: 'BTC',
    longName: 'BO - BTC Option - European - Inverse - Bit.com',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'BTC Option European Inverse - BIT',
    venue: 'BIT',
    kind: 'OPTION',
    contractSize: 1,
    contractCurrency: 'BTC',
    minBlockSize: 1,
    strategies: 'all',
    quantityPrecision: 1,
    pricePrecision: 4,
    ratioPrecision: 1,
    minContractIncrement: 0.1,
    quoteCurrency: 'BTC',
    isDisabled: true,
  },
  VT: {
    code: 'VT',
    currency: 'ETH',
    longName: 'VT - ETH Option - European - Inverse - Bit.com',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'ETH Option European Inverse - BIT',
    venue: 'BIT',
    kind: 'OPTION',
    contractSize: 1,
    contractCurrency: 'ETH',
    minBlockSize: 20,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 4,
    ratioPrecision: 1,
    minContractIncrement: 1,
    quoteCurrency: 'ETH',
    isDisabled: true,
  },
  OJ: {
    code: 'OJ',
    currency: 'TON',
    longName: 'OJ - TON Option - European - Linear - Bit.com',
    contractType: 'EUROPEAN',
    marginType: 'LINEAR',
    shortName: 'TON Option European Linear - BIT',
    venue: 'BIT',
    kind: 'OPTION',
    contractCurrency: 'TON',
    strategies: 'all',
    contractSize: 1,
    minBlockSize: 1,
    pricePrecision: 4,
    ratioPrecision: 2,
    quantityPrecision: 0,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
  },
  HM: {
    code: 'HM',
    currency: 'BCH',
    longName: 'HM - BCH Future - Inverse - Bit.com',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'BCH Future Inverse - BIT',
    venue: 'BIT',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'USD',
    minBlockSize: 5_000,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
    isDisabled: true,
  },
  BF: {
    code: 'BF',
    currency: 'BTC',
    longName: 'BF - BTC Future - Inverse - Bit.com',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'BTC Future Inverse - BIT',
    venue: 'BIT',
    kind: 'FUTURE',
    contractSize: 10,
    contractCurrency: 'USD',
    minBlockSize: 500,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
    isDisabled: true,
  },
  ZW: {
    code: 'ZW',
    currency: 'ETH',
    longName: 'ZW - ETH Future - Inverse - Bit.com',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'ETH Future Inverse - BIT',
    venue: 'BIT',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'USD',
    minBlockSize: 5_000,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
    isDisabled: true,
  },
  BD: {
    code: 'BD',
    currency: 'BTC',
    longName: 'BD - BTC Option - European - Linear - Bit.com',
    contractType: 'EUROPEAN',
    marginType: 'LINEAR',
    shortName: 'BTC Option European Linear - BIT',
    venue: 'BIT',
    kind: 'OPTION',
    contractSize: 1,
    contractCurrency: 'BTC',
    strategies: 'all',
    minContractIncrement: 0.01,
    minBlockSize: 0.01,
    pricePrecision: 0,
    quantityPrecision: 2,
    ratioPrecision: 1,
    quoteCurrency: 'USD',
  },
  WV: {
    code: 'WV',
    currency: 'ETH',
    longName: 'WV - ETH Option - European - Linear - Bit.com',
    contractType: 'EUROPEAN',
    marginType: 'LINEAR',
    shortName: 'ETH Option European Linear - BIT',
    venue: 'BIT',
    kind: 'OPTION',
    strategies: 'all',
    contractSize: 1,
    contractCurrency: 'ETH',
    minContractIncrement: 0.1,
    minBlockSize: 0.1,
    quantityPrecision: 1,
    pricePrecision: 0,
    ratioPrecision: 1,
    quoteCurrency: 'USD',
  },
  PE: {
    code: 'PE',
    currency: 'BTC',
    longName: 'PE - BTC Future - Linear - Bit.com',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'BTC Future Linear - BIT',
    venue: 'BIT',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'BTC',
    strategies: 'all',
    minContractIncrement: 0.01,
    minBlockSize: 0.01,
    quantityPrecision: 2,
    pricePrecision: 1,
    ratioPrecision: 1,
    quoteCurrency: 'USD',
  },
  DE: {
    code: 'DE',
    currency: 'ETH',
    longName: 'DE - ETH Future - Linear - Bit.com',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'ETH Future Linear - BIT',
    venue: 'BIT',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'ETH',
    strategies: 'all',
    minContractIncrement: 0.1,
    minBlockSize: 0.1,
    pricePrecision: 2,
    quantityPrecision: 1,
    ratioPrecision: 1,
    quoteCurrency: 'USD',
  },
  AU: {
    code: 'AU',
    currency: 'TON',
    longName: 'AU - TON Future - Linear - Bit.com',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'TON Future Linear - BIT',
    venue: 'BIT',
    kind: 'FUTURE',
    contractCurrency: 'TON',
    strategies: 'all',
    contractSize: 1,
    minBlockSize: 1,
    pricePrecision: 3,
    ratioPrecision: 2,
    quantityPrecision: 0,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
  },
  // Bybit
  BE: {
    code: 'BE',
    currency: 'BTC',
    longName: 'BE - BTC Option - European - ByBit',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'BTC Option European - BYB',
    venue: 'BYB',
    kind: 'OPTION',
    contractCurrency: 'BTC',
    strategies: 'all',
    isHedgeDisabled: true,
    contractSize: 1,
    quantityPrecision: 2,
    pricePrecision: 2,
    ratioPrecision: 1,
    minContractIncrement: 0.01,
    minBlockSize: 0.01,
    quoteCurrency: 'USDC',
  },
  JB: {
    code: 'JB',
    currency: 'SOL',
    longName: 'JB - SOL Option - European - ByBit',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'SOL Option European - BYB',
    venue: 'BYB',
    kind: 'OPTION',
    contractCurrency: 'SOL',
    strategies: 'all',
    isHedgeDisabled: true,
    contractSize: 1,
    quantityPrecision: 0,
    pricePrecision: 3,
    ratioPrecision: 1,
    minContractIncrement: 1,
    minBlockSize: 1,
    quoteCurrency: 'USD',
    isDisabled: true,
  },
  JC: {
    code: 'JC',
    currency: 'ETH',
    longName: 'JC - ETH Option - European - ByBit',
    contractType: 'EUROPEAN',
    marginType: 'INVERSE',
    shortName: 'ETH Option European - BYB',
    venue: 'BYB',
    kind: 'OPTION',
    contractCurrency: 'ETH',
    strategies: 'all',
    isHedgeDisabled: true,
    contractSize: 1,
    quantityPrecision: 1,
    pricePrecision: 2,
    ratioPrecision: 1,
    minContractIncrement: 0.1,
    minBlockSize: 0.1,
    quoteCurrency: 'USDC',
  },
  BX: {
    code: 'BX',
    currency: 'BTC',
    longName: 'BX - BTC Future - Linear - ByBit',
    contractType: 'LINEAR',
    marginType: 'LINEAR',
    shortName: 'BTC Future Linear - BYB',
    venue: 'BYB',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'BTC',
    minBlockSize: 0.001,
    minContractIncrement: 0.001,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 3,
    ratioPrecision: 2,
    quoteCurrency: 'USD',
    isDisabled: true,
  },
  BB: {
    code: 'BB',
    currency: 'BTC',
    longName: 'BB - BTC Future - Inverse - ByBit',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'BTC Future Inverse - BYB',
    venue: 'BYB',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'USD',
    minBlockSize: 1,
    strategies: 'all',
    quantityPrecision: 2,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
  },
  EF: {
    code: 'EF',
    currency: 'ETH',
    longName: 'EF - ETH Future - Inverse - ByBit',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'ETH Future Inverse - BYB',
    venue: 'BYB',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'USD',
    minBlockSize: 1,
    strategies: 'all',
    quantityPrecision: 0,
    pricePrecision: 2,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
  },
  EO: {
    code: 'EO',
    currency: 'EOS',
    longName: 'EO - EOS Future - Inverse - ByBit',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'EOS Future Inverse - BYB',
    venue: 'BYB',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'USD',
    minBlockSize: 1,
    strategies: ['Future'],
    quantityPrecision: 0,
    pricePrecision: 3,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
  },
  XR: {
    code: 'XR',
    currency: 'XRP',
    longName: 'XR - XRP Future - Inverse - ByBit',
    contractType: 'INVERSE',
    marginType: 'INVERSE',
    shortName: 'XRP Future Inverse - BYB',
    venue: 'BYB',
    kind: 'FUTURE',
    contractSize: 1,
    contractCurrency: 'USD',
    minBlockSize: 1,
    strategies: ['Future'],
    quantityPrecision: 0,
    pricePrecision: 4,
    ratioPrecision: 2,
    minContractIncrement: 1,
    quoteCurrency: 'USD',
  },
};

export const PRODUCTS: Readonly<Record<string, Product>> = PRODUCTS_STRICT;
