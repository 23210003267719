import { useEffect, useState } from 'react';

import {
  createGrfqWebSocket,
  GrfqWebSocket,
} from '@paradigm/features/src/unified-rfqs/repositories/grfq-ws';
import {
  createFsWebSocket,
  FsWebSocket,
} from '@paradigm/api/src/ws/future-spreads';
import {
  MmpNotification,
  MmpNotificationData,
  MMP_CHANNEL_TOPIC_ID,
  MMP_NOTIF_KINDS,
} from '@paradigm/api/src/types/mmp';
import { retryWsRequest } from '@paradigm/api/src/ws/ws-utils';
import { exceptionLogger } from '@paradigm/logging';
import { SUBSCRIBE_TIMEOUT_MS } from '@paradigm/api/src/internal/createWebSocket';

import toast from '#/components/toasts/toast';

export default function MmpNotificator() {
  const [grfqWs] = useState(createGrfqWebSocket);
  const [fsWs] = useState(() => createFsWebSocket());

  useEffect(() => setup(grfqWs, 'GRFQ/DRFQ'), [grfqWs]);
  useEffect(() => setup(fsWs, 'Future Spreads'), [fsWs]);

  return null;
}

function setup(ws: GrfqWebSocket | FsWebSocket, name: string) {
  const handleOpen = () => {
    retryWsRequest(async () =>
      ws.request({
        method: 'subscribe',
        params: { channel: MMP_CHANNEL_TOPIC_ID },
        timeout: SUBSCRIBE_TIMEOUT_MS,
      }),
    ).catch(exceptionLogger('Failed to subscribe to MMP channel'));
  };

  ws.addEventListener('open', handleOpen);
  if (ws.readyState === WebSocket.OPEN) handleOpen();

  const handleNotif = (notif: GenericNotification) => {
    if (!isMmpNotification(notif)) return;
    const { data } = notif;
    switch (data.kind) {
      case 'TRIGGERED':
        toast(`All ${name} quotes canceled: MMP limit reached`);
        break;
      // no default
    }
  };

  ws.addNotificationHandler(handleNotif);

  return () => {
    ws.removeEventListener('open', handleOpen);
    ws.removeNotificationHandler(handleNotif);
    ws.close(1000);
  };
}

interface GenericNotification {
  readonly channel: string;
  readonly data: unknown;
}

function isMmpNotification(
  notif: GenericNotification,
): notif is MmpNotification {
  return (
    notif.channel === MMP_CHANNEL_TOPIC_ID && isMmpNotificationData(notif.data)
  );
}

function isMmpNotificationData(data: unknown): data is MmpNotificationData {
  if (typeof data !== 'object' || data == null) return false;

  const { kind } = data as { kind: unknown };

  return (
    typeof kind === 'string' &&
    (MMP_NOTIF_KINDS as readonly string[]).includes(kind)
  );
}
