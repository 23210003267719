import * as types from '#/redux/types/electron';

const initialState = {
  available: false,
  progress: 0,
  ready: false,
};

export default function electronReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case types.UPDATE_AVAILABLE:
      return {
        ...state,
        available: payload,
      };
    case types.UPDATE_PROGRESS:
      return {
        ...state,
        progress: payload,
      };
    case types.UPDATE_READY:
      return {
        ...state,
        ready: payload,
      };
    case types.UPDATE_RESET:
      return initialState;
    default:
      return state;
  }
}
