import loadingIcon from '#/assets/loading.svg';
import { StyledImg } from './styles';

interface Props {
  readonly className?: string;
  /**
   * width and height of spinner
   */
  readonly size?: number;
  /**
   * Description of loading spinner
   */
  readonly alt?: string;
}

export default function LoadingSpinner(props: Props) {
  const { className, size = 20, alt = '' } = props;
  return (
    <StyledImg
      className={className}
      width={size}
      height={size}
      alt={alt}
      src={loadingIcon}
      aria-label="loading"
    />
  );
}
