import { ComponentPropsWithoutRef } from 'react';

const SettingsIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg version="1.1" viewBox="0 0 20.582 20.581" {...props}>
    <g
      transform="translate(.49967 .4999)"
      fill="none"
      fillRule="evenodd"
      stroke="#73a7f0"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m14.077 9.7905c0 2.367-1.9192 4.2857-4.2857 4.2857-2.367 0-4.2857-1.9187-4.2857-4.2857s1.9187-4.2857 4.2857-4.2857c2.3665 0 4.2857 1.9187 4.2857 4.2857z" />
      <path d="m19.582 12.026v-4.4705h-2.0646c-0.1674-0.57909-0.39387-1.1324-0.68037-1.6496l1.4578-1.4578-3.1608-3.1613-1.4578 1.4578c-0.51719-0.28603-1.0705-0.51251-1.6496-0.6799v-2.0646h-4.4705v2.0646c-0.57956 0.1674-1.1324 0.39387-1.6501 0.6799l-1.4573-1.4578-3.1613 3.1613 1.4578 1.4578c-0.28603 0.51719-0.51297 1.0705-0.6799 1.6496h-2.065v4.4705h2.065c0.1674 0.57909 0.39387 1.1324 0.6799 1.6496l-1.4578 1.4573 3.1613 3.1618 1.4573-1.4578c0.51766 0.28603 1.0705 0.5125 1.6501 0.6799v2.0646h4.4705v-2.0646c0.57909-0.1674 1.1324-0.39387 1.6496-0.6799l1.4578 1.4578 3.1608-3.1618-1.4578-1.4573c0.2865-0.51719 0.51297-1.0705 0.68037-1.6496z" />
    </g>
  </svg>
);

export default SettingsIcon;
