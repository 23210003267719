const NEGATIVE_SIGN_REGEX = /^-/u;
const STANDARD_NEGATIVE_SIGN = '-';
export const NEGATIVE_SIGN = '–';

/**
 * Enhance negative sign from `-` to `–`. The new dash is suposed to
 * be more visible.
 *
 * Acceps nullable string or number. If called with nulish value it
 * will return with `undefined`, this is usefull to avoid control
 * flow on calling code.
 *
 * @example
 * enhanceNegativeSign(-1) // –1
 * enhanceNegativeSign(null) // undefined
 * enhanceNegativeSign(null) ?? '--' // '--'
 */
function enhanceNegativeSign(value: string | number): string;
function enhanceNegativeSign(value: undefined | null): undefined;
function enhanceNegativeSign(
  value: string | number | undefined | null,
): string | undefined;
function enhanceNegativeSign(value: string | number | undefined | null) {
  return value?.toString().replace(NEGATIVE_SIGN_REGEX, NEGATIVE_SIGN);
}

export function unformatNegativeSign(value: string) {
  return value.replace(NEGATIVE_SIGN, STANDARD_NEGATIVE_SIGN);
}

export default enhanceNegativeSign;
