import { Field } from 'redux-form';
import {
  BaseFieldProps,
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from 'redux-form/lib/Field';
import { Props } from 'react-select';
import { Wrapper } from '#/utils/helpers';
import {
  firefly,
  amaranth,
  nepal,
  pickledBluewood,
} from '@paradigm/design-system/src/colors';
import DefaultSelect from '@paradigm/design-system/src/components/Select';
import { COUNTRIES } from '#/resources/countries';

interface SelectFieldProps {
  containerClassName: string;
  labelClassName: string;
  innerContainerClassName: string;
  label: string;
  placeholder: string;
  hint: string;
  classNamePrefix: string;
  options: Props['options'];
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
}

export const COUNTRY_PICKER_OPTIONS = COUNTRIES.map((country) => ({
  value: country.code,
  label: country.name,
}));

const CountryPickerSelect = ({
  containerClassName,
  labelClassName,
  innerContainerClassName,
  label,
  placeholder,
  hint,
  classNamePrefix,
  options,
  input,
  meta,
}: SelectFieldProps) => {
  const feedback = meta.touched
    ? meta.error != null
      ? String(meta.error)
      : ''
    : '';
  const hasError = feedback.length > 0;
  return (
    <Wrapper className={containerClassName}>
      {label ? <label className={labelClassName}>{label}</label> : ''}
      <div className={innerContainerClassName}>
        <DefaultSelect
          {...input}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur(input.value)}
          styles={{
            input: (provided) => ({
              ...provided,
              color: 'white',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'white',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#e8e6e3',
              fontWeight: '500',
              fontSize: '15px',
              opacity: '0.5',
            }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: pickledBluewood,
              borderColor: hasError
                ? amaranth
                : state.isFocused
                ? 'transparent'
                : pickledBluewood,
              boxShadow: hasError
                ? `0 0 10px ${amaranth}`
                : state.isFocused
                ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important'
                : 'none',
              '&:hover': {},
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: firefly,
              color: nepal,
            }),
          }}
          classNamePrefix={classNamePrefix}
          options={options}
          placeholder={placeholder}
        />
      </div>
      {hint ? <small className="text-muted">{hint}</small> : ''}
      <div className="feedback-invalid">{feedback}</div>
    </Wrapper>
  );
};

export interface CountryPickerFieldProps {
  name: string;
  label: string;
  validate: BaseFieldProps['validate'];
}

export const CountryPickerField = ({
  name,
  label,
  validate,
}: CountryPickerFieldProps) => {
  return (
    <Field
      name={name}
      label={label}
      component={CountryPickerSelect}
      labelClassName="label-margin"
      className="form-control sign-up__input"
      placeholder="Select Country"
      validate={validate}
      options={COUNTRY_PICKER_OPTIONS}
    />
  );
};
