import { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { UserBackUpCodes } from '@paradigm/api/auth';
import { exceptionLogger } from '@paradigm/logging/src/logging';

type Data = { token: string }[];
interface State {
  data: Data | null;
}

class BackUpCodes extends Component<object, State> {
  constructor(props: object) {
    super(props);
    this.state = {
      data: null,
    };
  }
  override componentDidMount() {
    UserBackUpCodes()
      .then((res) => {
        this.setState({
          data: res.data as Data,
        });
      })
      .catch(exceptionLogger('Failed to fetch user back up codes'));
  }
  getCodes() {
    const { data } = this.state;
    return (
      data &&
      data.map((item) => (
        <Col key={item.token} className="mt-2" xs={{ size: 6 }}>
          <li>{item.token}</li>
        </Col>
      ))
    );
  }
  override render() {
    return (
      <div>
        <p className="smaller-font opacity-7">
          If you lose access to your authentication device, you can use one of
          these backup codes to login to your account. Each code may be used
          only once. Make a copy of these codes, and store it somewhere safe.
        </p>
        <div className="divider-totp flex-shrink-0" />
        <div className="backup-codes pt-2">
          <ul>
            <Row>{this.getCodes()}</Row>
          </ul>
        </div>
      </div>
    );
  }
}

export default BackUpCodes;
