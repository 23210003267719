import { useCallback, useEffect, useRef, useState } from 'react';
import { lowerCase, upperFirst } from 'lodash';

import createTopic from '@paradigm/utils/createTopic';
import isAuthInvalidMessage from '@paradigm/utils/isAuthInvalidMessage';
import { logException } from '@paradigm/logging';

import { trimParams } from './internal/utils';
import { getApiToken } from './internal/token';

import type { JsonValue } from 'type-fest';

const onceControllers: Record<string, AbortController> = {};

export const tokenExpirationTopic =
  createTopic<Record<string, unknown>>('token-expiration');

export const refreshedTokenTopic =
  createTopic<{ readonly token: string }>('refreshed-token');

const parseResponse = async (response: Response): Promise<unknown> => {
  if (response.status === 204) return '';
  const contentType = response.headers.get('content-type');
  if (contentType == null) return '';
  const promise = contentType.includes('application/json')
    ? response.json()
    : contentType.includes('csv')
    ? response.text()
    : Promise.resolve('');
  return promise.catch((error: Error) => {
    logException('Failed parsing API response', error);
    // This catches a case where the fetch was resolved, but the request was
    // aborted before the response was read to completion. This is the only
    // case that is handled, because some code throughout the project depends
    // on this not throwing an error when an error happens (the returned
    // promise would resolve to `undefined`). We want to preserve that behavior
    // until we're sure all callers properly handle the response in case of an
    // error at this level.
    if (error.name === 'AbortError') throw error;
  });
};

interface Config extends RequestInit {
  readonly timeout?: number;
  readonly signal?: AbortSignal;
  readonly publicApi?: boolean;
  /** Only valid for GET requests! */
  readonly params?: Record<string, string | number | boolean>;
}

/** @deprecated Prefer using `request` */
async function fetchApi<T = unknown>(
  url: string,
  userConfig: Config = {},
): Promise<{ data: T }> {
  const { timeout = 0, signal, publicApi = false, ...config } = userConfig;

  let timeoutId;
  const defaults: {
    credentials: RequestInit['credentials'];
    headers: Record<string, string>;
    signal?: AbortSignal;
  } = {
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  if (signal) defaults.signal = signal;

  const apiToken = getApiToken();
  if (apiToken != null && apiToken.length > 0 && !publicApi) {
    defaults.headers.Authorization = `JWT ${apiToken}`;
  }

  if (timeout > 0 && !defaults.signal) {
    const controller = new AbortController();
    defaults.signal = controller.signal;
    timeoutId = setTimeout(() => {
      controller.abort();
    }, timeout);
  }

  try {
    const resp = await fetch(`${API_URL}${url}`, { ...defaults, ...config });
    if (timeoutId != null) clearTimeout(timeoutId);
    publishRefreshedTokenIfAvailable(resp);
    const data = await parseResponse(resp);

    if (resp.ok) return { data: data as T };

    throw new ApiError(resp.status, data);
  } catch (error) {
    if ((error as Error).name === 'AbortError') {
      // We should turn this into a custom error class
      throw { [signal ? 'once' : 'timeout']: true }; // eslint-disable-line @typescript-eslint/no-throw-literal
    }
    if (error instanceof ApiError) {
      const { status } = error.response;
      const { detail } = error.response.data;
      const isTokenExpired =
        status === 401 || (status === 403 && isAuthInvalidMessage(detail));
      if (isTokenExpired) {
        tokenExpirationTopic.publish({});
      }
    }
    throw error;
  }
}

class ApiError extends Error {
  readonly response: {
    readonly status: number;
    readonly data: {
      readonly detail: string;
      readonly [k: string]: unknown;
    };
  };

  constructor(status: number, data: unknown) {
    const ensuredData: ErrorBody =
      typeof data === 'object' && data != null ? data : {};
    const message = getMessage(ensuredData) ?? 'Unknown error';
    super(message);
    this.name = 'ApiError';
    this.response = {
      status,
      data: { ...ensuredData, detail: ensuredData.detail ?? message },
    };
  }
}

const methods = {
  async get(url: string, { params, ...config }: Config = {}) {
    const urlParams = trimParams(params);
    let urlQuery;
    if (urlParams == null) {
      urlQuery = null;
    } else {
      urlQuery = new URLSearchParams();
      for (const [key, value] of Object.entries(urlParams)) {
        urlQuery.append(key, `${value}`);
      }
    }
    return fetchApi(!urlQuery ? url : `${url}?${urlQuery.toString()}`, config);
  },
  async post<T = unknown>(url: string, body?: unknown, config: Config = {}) {
    return fetchApi<T>(url, {
      method: 'POST',
      body: JSON.stringify(body),
      ...config,
    });
  },
  async put(url: string, body?: unknown, config: Config = {}) {
    return fetchApi(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      ...config,
    });
  },
  async patch(url: string, body?: unknown, config: Config = {}) {
    return fetchApi(url, {
      method: 'PATCH',
      body: JSON.stringify(body),
      ...config,
    });
  },
  async delete(url: string, config: Config = {}) {
    return fetchApi(url, { method: 'DELETE', ...config });
  },
  async once(
    type: 'get' | 'post' | 'put' | 'patch' | 'delete',
    url: string,
    config?: Config,
    data?: unknown,
  ) {
    const currentController = onceControllers[url];
    if (currentController != null && !currentController.signal.aborted) {
      currentController.abort();
    }

    const newController = new AbortController();
    onceControllers[url] = newController;

    const extendedConfig = { signal: newController.signal, ...config };

    if (type === 'get' || type === 'delete')
      return this[type](url, extendedConfig);

    return this[type](url, data, extendedConfig);
  },
};

export default methods;

export interface BaseReq {
  readonly signal?: AbortSignal;
  readonly body?: unknown;
}

export interface BasePaginatedReq {
  readonly cursor?: string | null;
  readonly page_size?: string;
}

export interface BasePaginatedResp {
  readonly count: number;
  readonly next: string | null;
}

export interface PaginatedResp<T = unknown> extends BasePaginatedResp {
  readonly results: readonly T[];
}

export interface RequestParams extends BaseReq {
  readonly url: string;
  readonly method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'HEAD' | 'PUT';
  readonly publicApi?: boolean;
}

export interface BaseResp<T> {
  readonly ok: boolean;
  readonly status: number;
  readonly error: string;
  readonly message: string;
  readonly data: T;
}

export interface SuccessResp<T> extends BaseResp<T> {
  readonly ok: true;
  readonly data: T;
}

export interface FailedResp extends BaseResp<ErrorBody> {
  readonly ok: false;
}

interface ErrorData {
  [name: string]: JsonValue;
}

export interface ErrorBody {
  detail?: string;
  non_field_errors?: string | readonly string[];
  email?: readonly string[];
  // present only in papi errors
  code?: number;
  data?: ErrorData;
  message?: string;
  error?: string;

  /** Parsed number from retry-after header, should represent seconds */
  retryAfter?: number;
}

export type Resp<T> = SuccessResp<T> | FailedResp;

export type AsyncResp<T> = Promise<Resp<T>>;

export async function request<T>(params: RequestParams): AsyncResp<T> {
  return requestInternal<T>(API_URL, params);
}

export async function requestPapi<T>(params: RequestParams): AsyncResp<T> {
  return requestInternal<T>(PAPI_URL, params);
}

export async function requestFs<T>(params: RequestParams): AsyncResp<T> {
  return requestInternal<T>(FS_URL, params);
}

async function requestInternal<T>(
  baseUrl: string,
  params: RequestParams,
): AsyncResp<T> {
  const { url, method, body, signal, publicApi = false } = params;

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };
  if (!publicApi) headers.Authorization = `JWT ${getApiToken()}`;

  const stringifiedBody: string | undefined = JSON.stringify(body);

  let resp;
  try {
    resp = await fetch(`${baseUrl}${url}`, {
      method,
      headers,
      signal,
      body: stringifiedBody,
      credentials: 'omit',
    });
  } catch (_error) {
    const error = _error as Error;
    return {
      ok: false,
      status: 0,
      error: error.name,
      message: error.message,
      data: {},
    };
  }

  if (!publicApi && resp.status === 401) {
    tokenExpirationTopic.publish({});
  }

  publishRefreshedTokenIfAvailable(resp);

  let data: unknown;
  try {
    data = await parseJson(resp);
  } catch (_error) {
    const error = _error as Error;

    if (error.name === 'SyntaxError') {
      logException(
        {
          message: 'Failed parsing API response as JSON',
          data: { method, url, status: resp.status },
        },
        error,
      );
    }

    return {
      ok: false,
      status: resp.status,
      error: error.name,
      message: error.message,
      data: {},
    };
  }

  if (!resp.ok) {
    const msg = data != null ? getMessage(data as ErrorBody) : null;
    const ensuredData = (data ?? {}) as ErrorBody;

    if (
      !publicApi &&
      resp.status === 403 &&
      isAuthInvalidMessage(ensuredData.detail)
    ) {
      tokenExpirationTopic.publish({});
    }

    if (resp.status === 429 && ensuredData.retryAfter == null) {
      const retryAfter = getRetryAfter(resp);
      if (retryAfter != null) ensuredData.retryAfter = retryAfter;
    }

    return {
      ok: false,
      status: resp.status,
      error: 'ApiStatusFailure',
      message: msg ?? `Response failed with status ${resp.status}`,
      data: ensuredData,
    };
  }

  return {
    ok: true,
    status: resp.status,
    error: '',
    message: '',
    data: data as T,
  };
}

/**
 * Publish the token if the response contains a refreshed token.
 * @param resp Fetch response
 */
function publishRefreshedTokenIfAvailable(resp: Response) {
  const refreshedToken = resp.headers.get('Refreshed-Token');
  if (refreshedToken != null)
    refreshedTokenTopic.publish({ token: refreshedToken });
}

async function parseJson(resp: Response): Promise<unknown> {
  if (resp.status === 204) return null;
  const contentType = resp.headers.get('content-type');
  if (contentType == null) return null;
  return contentType.includes('application/json') ? resp.json() : null;
}

function getMessage(data: ErrorBody): string | null {
  const nonFieldErrors = data.non_field_errors;
  if (nonFieldErrors != null) {
    if (typeof nonFieldErrors === 'string') return nonFieldErrors;
    if (typeof nonFieldErrors[0] === 'string') return nonFieldErrors[0];
  }

  if (typeof data.detail === 'string') return data.detail;

  if (typeof data.message === 'string') return data.message;

  if (typeof data.error === 'string') return upperFirst(lowerCase(data.error));

  return null;
}

function getRetryAfter(resp: Response): number | null {
  const retryAfterRaw = resp.headers.get('retry-after');
  if (retryAfterRaw == null) return null;
  const retryAfter = Number.parseFloat(retryAfterRaw);
  return Number.isFinite(retryAfter) ? retryAfter : null;
}

export type ApiFn<R extends BaseReq, T> = (req: R) => AsyncResp<T>;

export interface ApiState<T> {
  readonly isLoading: boolean;
  readonly resp: Resp<T> | null;
}

export interface ApiCall<T = unknown> {
  /**
   * Avoid using this as you will have to handle the _AbortError_.
   * Prefer using the callback on the execute function.
   */
  readonly promise: AsyncResp<T>;
  readonly abort: () => void;
}

const INITIAL_STATE = {
  isLoading: false,
  resp: null,
};

export function useApi<R extends BaseReq, T>(
  api: ApiFn<R, T>,
  initialState: ApiState<T> = INITIAL_STATE,
): [
  state: ApiState<T>,
  execute: (req: R, cb?: (resp: Resp<T>) => void) => ApiCall<T>,
] {
  const abortControllerSetRef = useRef<Set<AbortController>>();

  useEffect(
    () => () => {
      const abortControllerSet = abortControllerSetRef.current;
      if (abortControllerSet == null) return;
      for (const abortController of abortControllerSet) {
        abortController.abort();
      }
    },
    [],
  );

  const [state, setState] = useState(initialState);

  const exec = useCallback(
    (req: R, cb?: (resp: Resp<T>) => void): ApiCall<T> => {
      setState((currState) => ({ ...currState, isLoading: true }));

      let finalReq: R;
      let abortController: AbortController | undefined;

      // only create a controller when caller is not using its own signal
      if (req.signal == null) {
        abortController = new AbortController();
        abortControllerSetRef.current ??= new Set();
        abortControllerSetRef.current.add(abortController);
        finalReq = { ...req, signal: abortController.signal };
      } else {
        finalReq = req;
      }

      const callApi = async () => {
        try {
          const resp = await api(finalReq);
          // skip side effects in case the request was aborted
          if (resp.error !== 'AbortError') {
            cb?.(resp);
            setState({ isLoading: false, resp });
          }
          return resp;
        } catch (error) {
          // Api functions should never throw, fix should be on our code
          logException('Unexpected exception from api function', error);
          throw error;
        } finally {
          // remove abort controller from set so we don't leak memory
          if (abortController != null)
            abortControllerSetRef.current?.delete(abortController);
        }
      };

      return {
        promise: callApi(),
        abort: () => {
          if (abortController == null) {
            throw new Error(
              "You've provided your own signal, use your abort controller to cancel.",
            );
          }
          abortController.abort();
        },
      };
    },
    [api],
  );

  return [state, exec];
}

export async function fetchAll<Resource, Request extends BasePaginatedReq>(
  req: Request,
  fetcher: (req: Request) => AsyncResp<PaginatedResp<Resource>>,
  prevResult: readonly Resource[] = [],
): AsyncResp<PaginatedResp<Resource>> {
  const resp = await fetcher(req);
  if (!resp.ok) return resp;

  const cursor = resp.data.next;
  if (cursor !== null)
    return fetchAll({ ...req, cursor }, fetcher, [
      ...prevResult,
      ...resp.data.results,
    ]);

  return {
    ...resp,
    data: { ...resp.data, results: [...prevResult, ...resp.data.results] },
  };
}
