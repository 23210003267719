import { log } from '@paradigm/logging';
import createObservable from '@paradigm/utils/src/createObservable';

export const observableToken = createObservable<string | null>(
  'api-token',
  null,
);

export const setApiToken = (token: string | null) => {
  observableToken.set(token);
};

export const getApiToken = () => observableToken.get();

/**
 * Wait until a token is available and resolve with it. Will resolve
 * immediately if a token is already available.
 * @param timeout Maximum wait time in milliseconds
 * @returns Promise that resolves with a token, will resolve with an
 * empty string in case of timeout.
 */
export async function availableToken(timeout = 5000): Promise<string> {
  return new Promise<string>((resolve) => {
    const clear = () => {
      clearTimeout(timeoutHandle);
      observableToken.unobserve(tokenHandler);
    };

    const timeoutHandle = setTimeout(() => {
      log('Timed out waiting for available token');
      clear();
      resolve('');
    }, timeout);

    const tokenHandler = (token: string | null) => {
      if (token != null) {
        clear();
        resolve(token);
      }
    };

    observableToken.observe(tokenHandler);
  });
}
