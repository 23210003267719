import { createSelector } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { getVenues } from '@paradigm/utils/src/rfq-config';

import type { AuthState } from './reducer';

interface State {
  auth: AuthState;
}

const selectSlice = (state: State) => state.auth;

export const getUser = (state: State) => selectSlice(state).user;

/**
 * @returns Ticker when user is signed in and part of a trading
 * desk. `undefined` otherwise.
 */
export const getUserTicker = (state: State) =>
  getUser(state).trading_desk?.ticker;

/**
 * @returns Desk ID when user is signed in and part of a trading
 * desk. `undefined` otherwise.
 */
export const getUserDeskId = (state: State) => getUser(state).trading_desk?.id;

export const getUserId = (state: State) => getUser(state).id;

export const isSoundNotificationsEnabled = (state: State) =>
  getUser(state).audio_notifications_enabled;

export const isVisualNotificationsEnabled = (state: State) =>
  getUser(state).visual_notifications_enabled;

export const isTradingNotificationsEnabled = (state: State) =>
  getUser(state).trading_notifications_enabled;

export const canTradeSelector = (state: State) =>
  Boolean(getUser(state).can_trade);

export const selectCanViewPositionTable = (state: State) =>
  Boolean(getUser(state).has_position_readable_permission);

export const selectActiveProducts = (state: State) =>
  getUser(state).active_products;

export const selectIsFirmDisabledLeaderboardAnonymity = (state: State) =>
  Boolean(getUser(state).firm_disabled_leaderboard_anonymity);

export const selectIsUserPrime = (state: State) =>
  Boolean(getUser(state).firm_has_liquidity_providers);

export const getUserID = (state: State) => getUser(state).id;

export const getTradingFirmName = (state: State) => getUser(state).firm;

export const getIsUserLoggingIn = (state: State) =>
  selectSlice(state).isLoggingIn;

export const selectIsAttemptingSessionReuse = (state: State) =>
  selectSlice(state).isAttemptingSessionReuse;

export const selectIsAuthenticated = (state: State) =>
  selectSlice(state).isAuthenticated;

export const getTwoFA = (state: State) =>
  Boolean(getUser(state).has_totp_enabled);

export const getUserLoginCredentials = (state: State) =>
  omit(getUser(state), 'has_totp_enabled');

export const getHasRfqIpRestriction = (state: State) =>
  getUser(state).restricted_ip_rfqs_left !== null;

export const getHasAcceptedAgreement = (state: State) => {
  const user = getUser(state);
  const { has_accepted_us_agreement, has_accepted_non_us_agreement } = user;
  return has_accepted_us_agreement || has_accepted_non_us_agreement;
};

export const selectHasUnacceptedAgreement = (state: State) =>
  Boolean(getUser(state).has_unaccepted_agreement);

export const selectActiveVenues = createSelector(
  selectActiveProducts,
  (activeProducts) =>
    getVenues().filter((venue) => activeProducts.includes(venue.id)),
);

export const getLoginError = (state: State) => selectSlice(state).loginError;
