import { useEffect } from 'react';
import {
  useDbtAltCoinsObFeatureFlag,
  useDbtAltCoinsRfqFeatureFlag,
} from '@paradigm/features/src/feature-flags/context';
import { DBT_ALT_COINS_PRODUCT_CODES } from '@paradigm/features/src/products/config';
import { useProductsStore } from '@paradigm/features/src/products/store';
import { getProduct } from '@paradigm/features/src/products/domain';

/**
 * Mark alt coins as enabled if FF is on
 */
export default function useIncludeDbtAltCoins() {
  const isDbtAltCoinsEnabled = useDbtAltCoinsRfqFeatureFlag();
  const isDbtAltCoinsObEnabled = useDbtAltCoinsObFeatureFlag();
  const updateProduct = useProductsStore((s) => s.updateProduct);

  useEffect(() => {
    if (isDbtAltCoinsEnabled) {
      DBT_ALT_COINS_PRODUCT_CODES.forEach((productCode) => {
        const product = getProduct(productCode);
        if (product.kind !== 'OPTION') return;
        updateProduct(productCode, {
          isDisabled: false,
          isDisabledForOb: !isDbtAltCoinsObEnabled,
        });
      });
    }
  }, [isDbtAltCoinsEnabled, isDbtAltCoinsObEnabled, updateProduct]);
}
