import clx from 'classnames';
import { SettingsView } from '#/components/settings-modal/SettingsModal';

interface Props {
  view: SettingsView;
  setView: (view: SettingsView) => void;
}

const SettingsSecurity = (props: Props) => {
  const { view, setView } = props;

  return (
    <div>
      <h3
        className={clx('settings-link d-flex align-items-center', {
          pointer: Boolean(view),
        })}
        {...(Boolean(view) && {
          onClick: () => setView('Active Sessions'),
        })}
      >
        Active Sessions
      </h3>
      <h3
        className={clx('settings-link d-flex align-items-center', {
          pointer: Boolean(view),
        })}
        {...(Boolean(view) && {
          onClick: () => setView('Change Password'),
        })}
      >
        Change Password
      </h3>
      <h3
        className={clx('settings-link d-flex align-items-center', {
          pointer: Boolean(view),
        })}
        {...(Boolean(view) && {
          onClick: () => setView('Two-Factor Authentication'),
        })}
      >
        Two-Factor Authentication
      </h3>
    </div>
  );
};

export default SettingsSecurity;
