import { format } from 'date-fns';
import { startSubmit, stopSubmit } from 'redux-form';
import { takeEvery, call, put } from 'redux-saga/effects';

import { UserRegister } from '@paradigm/api/auth';

import {
  registrationRequest,
  registrationSuccess,
  registrationFailure,
} from '#/redux/registration';

/**
 * Handles registration's form submission and errors and validation
 * handling with redux-form action creators and auth reducer on server
 * error shows error on redux form by field
 * If post successful redirect to account to chat (main) view
 * else display errors by field or top level on the form
 */
function* registrationSaga({ payload: registrationData }) {
  try {
    yield put(startSubmit('register'));
    const response = yield call(UserRegister, {
      ...registrationData,
      date_of_birth: format(registrationData.date_of_birth, 'yyyy-MM-dd'),
      country_of_residence: registrationData.country_of_residence.value,
    });
    const {
      data: { user, token },
    } = response;
    yield put(stopSubmit('register'));
    yield put(registrationSuccess({ user, token }));
  } catch (error) {
    let errorMessage = '';
    let errorFields = {};

    if (error.timeout) {
      errorMessage = 'Network error. Please check your internet connection.';
    } else if (error.response) {
      const { data: errorData } = error.response;
      const hasNonFieldErrors = errorData && errorData.non_field_errors;

      if (hasNonFieldErrors) {
        errorMessage = Array.isArray(errorData.non_field_errors)
          ? errorData.non_field_errors[0]
          : errorData.non_field_errors;
      } else {
        errorFields = errorData;
      }
    }

    yield put(stopSubmit('register', errorFields));
    yield put(registrationFailure({ message: errorMessage }));
  }
}

export function* registrationListener() {
  yield takeEvery(registrationRequest, registrationSaga);
}

const registrationSagas = [registrationListener];

export default registrationSagas;
