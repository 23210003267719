import { AsyncResp, request } from '@paradigm/api/src/fetch-api';
import {
  FeatureFlags,
  ListFeatureFlagReq,
  processRawFeatureFlag,
  RawFeatureFlags,
} from './adapters';

export async function listFeatureFlags(
  req: ListFeatureFlagReq,
): AsyncResp<FeatureFlags> {
  const resp = await request<RawFeatureFlags>({
    ...req,
    method: 'GET',
    url: `/feature-flags/configs/`,
  });

  if (!resp.ok) return resp;

  return {
    ...resp,
    data: processRawFeatureFlag(resp.data),
  };
}
