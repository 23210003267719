/**
 * Functions to format strings representing decimal numbers
 * in the format `\d*(\.\d+)?`. This is the format used by our
 * api when the number is not already formatted.
 */
import BigNumber from 'bignumber.js';
import { unformatNegativeSign } from './enhanceNegativeSign';

/**
 * Matches the digits that are followed by 3 digit groups. In
 * other words, matches the digits which may include a thousand
 * separator afterwards.
 */
const groupEndRe = /(\d)(?=(?:\d{3})+(?!\d))/gu;

const DEC_SEP = '.';

/**
 * Default format with commas as thousands separator
 */
export default function formatDecimal(value: string) {
  const [int, dec = ''] = value.split(DEC_SEP);
  const intGrouped = int!.replace(groupEndRe, '$1,');
  const decSeparator = dec.length > 0 ? DEC_SEP : '';
  return `${intGrouped}${decSeparator}${dec}`;
}

const allCommasRe = /,/gu;

/**
 * Unformat decimal:
 * - Remove enhanced negative sign
 * - Remove thousands separator commas
 */
export function unformatDecimal(value: string) {
  const unformattedNegative = unformatNegativeSign(value);
  return unformattedNegative.replace(allCommasRe, '');
}

const leftZeroRe = /^0+(?!$)/u;

/**
 * Format for quote prices.
 *   - Remove left zeros
 *   - Truncate the precision to a given number of decimal digits
 *
 * @param precision number of digits after decimal separator to keep
 * @param value string representing a decimal
 */
export function formatQuote(precision: number, value: string) {
  if (!Number.isInteger(precision))
    throw new TypeError(`Precision must be an integer, received ${precision}`);
  if (precision < 0)
    throw new TypeError(`Precision must be postive, received ${precision}`);

  const [int, dec] = value.split(DEC_SEP);

  const cleanedInt = int!.replace(leftZeroRe, '');

  if (dec == null) return cleanedInt;
  if (dec.length === 0) return `${cleanedInt}${DEC_SEP}`;

  const truncatedDec = dec.slice(0, precision);
  return `${cleanedInt}${DEC_SEP}${truncatedDec}`;
}

export function formatMark(value: string): string {
  const numeric = Number.parseFloat(value);
  const fixed = numeric.toFixed(4);
  const truncated = removeTrailingZeroes(fixed);
  const formatted = formatDecimal(truncated);
  return formatted;
}

export function formatPrecision(value: string, precision: number): string {
  const numeric = Number.parseFloat(value);
  const fixed = numeric.toFixed(precision);
  const formatted = formatDecimal(fixed);
  return formatted;
}

const TRAILING_ZEROES_RE = new RegExp(
  `(\\${DEC_SEP}|(\\${DEC_SEP}\\d+?))0*$`,
  'u',
);

/**
 * Remove trailing zeroes from the decimal part of a number.
 *
 * @example
 *   removeTrailingZeroes("1") // => "1"
 *   removeTrailingZeroes("1.0") // => "1"
 *   removeTrailingZeroes("1.02") // => "1.02"
 *   removeTrailingZeroes("1.020") // => "1.02"
 *
 * @param value A number as a string. E.g. "2.030"
 */
export function removeTrailingZeroes(value: string) {
  return value.replace(TRAILING_ZEROES_RE, '$2');
}

export const isValidDecimal = (textValue: string) => {
  const sanitizedText = unformatDecimal(textValue);
  const numericValue = Number.parseFloat(sanitizedText);
  return Number.isFinite(numericValue);
};

export const parseFormattedFloat = (textValue: string): number => {
  const sanitizedText = unformatDecimal(textValue);
  const numericValue = Number.parseFloat(sanitizedText);

  if (!Number.isFinite(numericValue))
    throw new TypeError(`"${textValue}" could not be parsed into a number`);

  return numericValue;
};

/** Parse a number from a user input */
export function parseInputNumber(textInput: string) {
  const sanitizedText = unformatDecimal(textInput);
  const numericValue = Number.parseFloat(sanitizedText);
  if (!Number.isFinite(numericValue)) return null;
  return numericValue;
}

function getSign(value: number): string {
  return value > 0 ? '+' : value < 0 ? '-' : '';
}

/**
 * Adds + or - sign to positive or negative number.
 * Does not add a sign to 0.
 *
 * If a formatter is passed, the value is run through
 * the formatter before receiving the sign.
 *
 * @param value Value to which the sign will be added
 * @param formatter A formatter function for the absolute value
 */
export function addSign(
  value: number,
  formatter = addSignDefaultFormatter,
): string {
  const sign = getSign(value);
  const absValue = Math.abs(value);
  const textValue = formatter(absValue);
  return `${sign}${textValue}`;
}

function addSignDefaultFormatter(absoluteValue: number): string {
  return absoluteValue.toString();
}

/**
 * Round number to the nearest multiplier
 *
 * @example
 *   roundToMultiplier(21, 0.1) // => 21
 *   roundToMultiplier(21.2, 0.1) // => 21.2
 *   roundToMultiplier(21.21, 0.1) // => 21.2
 *   roundToMultiplier(21.25, 0.1) // => 21.3
 *   roundToMultiplier(21.8, 1) // => 22
 *   roundToMultiplier(21.4, 1) // => 21
 *   roundToMultiplier(22, 5) // => 20
 *   roundToMultiplier(23, 5) // => 25
 *
 * @param value Value to round
 * @param multiplier Multiplier used for rounding
 */
export function roundToMultiplier(value: number, multiplier: number) {
  return _roundToMultiplier(value, multiplier, Math.round);
}

/**
 * Round number to the next multiplier
 *
 * @example
 *   roundUpToMultiplier(21, 0.1) // => 21
 *   roundUpToMultiplier(21.2, 0.1) // => 21.2
 *   roundToMultiplier(21.21, 0.1) // => 21.3
 *   roundToMultiplier(21.25, 0.1) // => 21.3
 *   roundUpToMultiplier(21.8, 1) // => 22
 *   roundUpToMultiplier(21.4, 1) // => 22
 *   roundUpToMultiplier(22, 5) // => 25
 *   roundUpToMultiplier(23, 5) // => 25
 *
 * @param value Value to round
 * @param multiplier Multiplier used for rounding
 */
export function roundUpToMultiplier(value: number, multiplier: number) {
  return _roundToMultiplier(value, multiplier, Math.ceil);
}

/**
 * Round number to the previous multiplier
 *
 * @example
 *   roundToMultiplier(21, 0.1) // => 21
 *   roundToMultiplier(21.2, 0.1) // => 21.2
 *   roundToMultiplier(21.21, 0.1) // => 21.2
 *   roundToMultiplier(21.25, 0.1) // => 21.2
 *   roundToMultiplier(21.8, 1) // => 21
 *   roundToMultiplier(21.4, 1) // => 21
 *   roundToMultiplier(22, 5) // => 20
 *   roundToMultiplier(23, 5) // => 20
 *
 * @param value Value to round
 * @param multiplier Multiplier used for rounding
 */
export function roundDownToMultiplier(value: number, multiplier: number) {
  return _roundToMultiplier(value, multiplier, Math.floor);
}

function _roundToMultiplier(
  value: number,
  multiplier: number,
  roundFn: (n: number) => number,
) {
  const inv = 1 / multiplier;
  return roundFn(value * inv) / inv;
}

/**
 * Round numbers with decimal precision
 *
 * @param value Value to which will be rounded by Math Fn
 * @param precision Decimal precision to be returned
 * @param mathFn Math function to use for rounding, default is Math.round
 */
function _decimalRound(value: number, precision = 0, mathFn = Math.round) {
  const signMultiplier = value >= 0 ? 1 : -1;
  const absValue = Math.abs(value);

  if (!Number.isSafeInteger(precision) || precision <= 0)
    return mathFn(absValue) * signMultiplier;

  const decimalScaleMultiplier = 10 ** precision;
  const absValueRounded =
    mathFn(absValue * decimalScaleMultiplier) / decimalScaleMultiplier;
  return absValueRounded * signMultiplier;
}

/**
 * Round numbers with decimal precision
 *
 * @param value Value to which will be rounded
 * @param precision Decimal precision to be returned
 */
export function decimalRound(value: number, precision = 0) {
  return _decimalRound(value, precision);
}

/**
 * Round up numbers with decimal precision
 *
 * @param value Value to which will be rounded up
 * @param precision Decimal precision to be returned
 */
export function decimalRoundUp(value: number, precision = 0) {
  return _decimalRound(value, precision, Math.ceil);
}

export function parseGreekValue(value: string, precision: number) {
  if (value === '') return '-';
  return decimalRound(Number(unformatDecimal(value)), precision).toFixed(
    precision,
  );
}

/**
 * Converts a string decimal value into a big number which is safer for calculations
 * @param value
 */
export function convertToBigNumber(value?: string): BigNumber {
  // Almost never return exponential notation:
  BigNumber.config({ EXPONENTIAL_AT: [-1e9, 1e9] });
  const result = value === '' ? '0' : value;
  return new BigNumber(result ?? '0');
}

/**
 * Format value to en-US ordinal.
 * E.g: 1st, 2nd, 3rd ...
 */
export function formatOrdinal(value: string | number): string {
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
  const numValue = isValidDecimal(value.toString()) ? Number(value) : 0;

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);
  const rule = pr.select(numValue);
  const suffix = suffixes.get(rule);
  return `${numValue}${suffix}`;
}
