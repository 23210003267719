import { RfqOrder } from '#/unified-rfqs/entities/drfq/orders';
import { RfqOrderNotificationData } from '#/unified-rfqs/repositories/drfq-ws-types';

export function isSilentNotification(
  data: RfqOrderNotificationData,
  bestOrder: RfqOrder | undefined,
): boolean {
  if (bestOrder == null) return true;
  if (data.side === 'BUY' && data.price <= bestOrder.price) return true;
  if (data.side === 'SELL' && data.price >= bestOrder.price) return true;
  return false;
}
