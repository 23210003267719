import { cloudBurst, amaranth, paradigmLightBlue } from '#/colors';

export const getDatePickerConfig = ({ hasError }: { hasError: boolean }) => ({
  colorBgContainer: cloudBurst,
  colorBgElevated: cloudBurst,
  colorText: 'rgba(255,255,255,0.70)',
  colorTextPlaceholder: '#ffffff',
  colorPrimary: paradigmLightBlue,
  colorLink: 'rgba(255,255,255,0.70)',
  colorTextDisabled: 'rgba(255,255,255,0.70)',
  colorIcon: 'rgba(255,255,255,0.70)',
  fontFamily: '"IBM Plex Sans", sans-serif',
  colorBorder: hasError ? amaranth : 'transparent',
  borderRadius: 3,
});
